import {
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input, OnChanges,
  OnInit,
  Optional,
  Output, SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter, finalize } from 'rxjs/operators';
import { DurationVideoService, Upload } from '@kitch/data-access/services';
import { initVideoPlayer, parseVideo } from '@kitch/util';

@Component({
  selector: 'app-video-chooser',
  templateUrl: './video-chooser.component.html',
  styleUrls: ['./video-chooser.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VideoChooserComponent,
      multi: true,
    },
  ],
})
export class VideoChooserComponent implements ControlValueAccessor, OnInit, OnChanges {
  @ViewChild('video', { static: true }) imageView: ElementRef;
  @ViewChild('uploadInput', { static: true }) uploadInput: ElementRef;

  @Input() formControlName: string;
  @Input() uploadStatus: Upload;

  @Output() deleteVideo: EventEmitter<boolean> = new EventEmitter<boolean>();

  video = '';
  videoName = '';

  videoLimits: VideoLimits = { type: 'video/mp4,video/x-m4v,video/*' };

  formControl: AbstractControl;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private durationVideoService: DurationVideoService,
  ) {}

  ngOnInit() {
    if (!this.controlContainer) {
      console.warn('Can\'t find parent FormGroup directive');

      return;
    }

    if (!this.formControlName) {
      console.warn('Missing FormControlName directive from host element of the component');

      return;
    }

    this.formControl = this.controlContainer.control.get(this.formControlName);
    this.formControl.valueChanges
      .pipe(filter((value) => typeof value === 'string'))
      .subscribe((value) => {
        this.video = value;
        if (this.video) {
          this.uploadStatus = { state: 'DONE', progress: 100 };
          setTimeout(() => this.initVideoPlayer());
        }
      });
    this.video = this.formControl.value;
    if (this.video) {
      this.uploadStatus = { state: 'DONE', progress: 100 };
      setTimeout(() => this.initVideoPlayer());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.uploadStatus?.currentValue?.state === 'DONE') {
      setTimeout(() => this.initVideoPlayer());
      setTimeout(() => {
        const video: any = document.getElementById('video-player');

        if (video) {
          video.onloadedmetadata = () => this.durationVideoService.updateDurationVideo(video.duration);
        }
      });
    }
  }

  onFileChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const file: File = files[0];

    if (!file) {
      return;
    }

    this.uploadStatus = { state: 'IN_PROGRESS', progress: 0 };

    fromPromise(parseVideo(file))
      .pipe(finalize(() => this.formControl.markAsTouched()))
      .subscribe(() => {
        this.formControl.patchValue(file);
        this.formControl.markAsDirty();
        this.controlContainer.control.updateValueAndValidity();
        this.videoName = file.name;
      });
  }

  onFileClick(): void {
    this.uploadInput.nativeElement.value = null;
  }

  onDeleteImage(event: Event): void {
    event.preventDefault();
    this.video = '';
    this.videoName = '';
    this.uploadStatus.state = 'PENDING';
    this.deleteVideo.emit(true);
    this.formControl.markAsTouched();
  }

  private initVideoPlayer() {
    initVideoPlayer('video-player', this.video);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  writeValue(obj: any): void {}
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn: any): void {}
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn: any): void {}
}

interface VideoLimits {
  type: string;
}
