import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { WINDOW } from '@ng-web-apis/common';
import { disableScroll, enableScroll } from '@kitch/util';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  isActive = false;

  @Input() backgroundColor = 'white';
  @Input() showCloseIcon = true;

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private element: ElementRef,
    @Inject(WINDOW) private window: Window,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.element.nativeElement.remove();
    enableScroll(this.window);
  }

  open(): void {
    this.isActive = true;
    disableScroll(this.window);
    this.window.document.body.appendChild(this.element.nativeElement);
  }

  close(): void {
    this.isActive = false;
    enableScroll(this.window);
    this.closed.emit();
    this.element.nativeElement.remove();
  }
}
