<app-modal (closed)="onModalClosed()" #modal>
  <!-- HEADER >> -->
  <ng-container header>
    <button
      *ngIf="isPhoneShown && loginStep === loginStepDefault"
      (click)="togglePhoneSection()"
      class="back-btn"
      title="Back to social login section"
    >
      <img
        class="back-icon"
        src="assets/ui/images/svg/arrow-left.svg"
        alt="Back to social login section"
        width="24"
        height="24"
      />
    </button>

    <h2 data-testid="modal-title" class="modal-title">Login to kittch</h2>

    <p class="new-user">
      <span class="new-user__text">Don’t have a Kittch account?&nbsp;</span>
      <button
        data-testid="sign-up-link"
        class="sign-up-in-link"
        (click)="emitOpenRegisterModal()"
      >Sign up</button>
    </p>
  </ng-container>
  <!-- << HEADER -->

  <!-- LOGIN STEP >> -->
  <ng-container body [ngSwitch]="loginStep">
    <div *ngSwitchCase="loginStepDefault" class="login">
      <!-- SOCIALS BLOCK >>  -->
      <ng-container *ngIf="!isPhoneShown; else phoneSection">
        <app-social-buttons
          class="login__social"
          [isLogin]="true"
          (metaBtnClick)="onMetaBtnClick($event)"
        ></app-social-buttons>

        <app-button
          data-testid="login-modal-phone-btn"
          class="phone-btn"
          [text]="'Login with phone'"
          [type]="'button'"
          [role]="'secondary'"
          [iconUrl]="'assets/ui/images/svg/phone-icon.svg'"
          [iconHeight]="26"
          [iconWidth]="30"
          (clicked)="togglePhoneSection()"
        ></app-button>
      </ng-container>
      <!-- << SOCIALS BLOCK  -->

      <!-- PHONE BLOCK >> -->
      <ng-template #phoneSection>
        <div class="phone" >
          <app-login-form [submitButtonText]="'Login'" (login)="login($event)"></app-login-form>
        </div>
      </ng-template>
      <!-- << PHONE BLOCK -->

      <app-entrance-questions></app-entrance-questions>
    </div>
    <!-- << LOGIN STEP -->

    <!-- VERIFICATION STEP >> -->
    <div class="verification" *ngSwitchCase="loginStepVerification">
      <div>
        <app-login-verification [code]="otpCode" [loading]="loading" [error]="error$.asObservable()"
          (verifyAuthCode)="onVerifyAuthCode($event)" (resendAuthCode)="onResendAuthCode()">
        </app-login-verification>
      </div>
      <ul>
        <li class="verification__item">
          <p class="resend-code">
            <span class="resend-code__text">Didn’t receive a code?</span>
            <button
              data-testid="resend-code-btn"
              type="button"
              class="resend-code__button"
              (click)="onResendAuthCode()"
            >RESEND</button>
          </p>
        </li>
        <li class="verification__item">
          <p class="privacy-policy">
            <span class="privacy-policy__text">View our&nbsp;</span>
            <a class="privacy-policy__link" [routerLink]="'/privacy-policy'">Privacy Policy</a>
          </p>
        </li>
      </ul>
    </div>
    <!-- << VERIFICATION STEP -->
  </ng-container>
</app-modal>

<app-user-not-found-modal
  [isOpen]="isNotFoundModal"
  (userAction)="handleUserNotFoundModalAction($event)"
  (isUserNotFoundModalOpen)="setNotFoundModal($event)">
</app-user-not-found-modal>
