<app-shopping-cart
  *ngIf="hasShoppingCart"
  [walmartRecipeId]="walmartRecipeId"
  [portions]="walmartRecipePortions"
  [isShown]="isShoppingCardOpened"
  (closed)="closeShoppingCart()">
</app-shopping-cart>

<div class="wrap" [ngClass]="{'small-on-tablet': isSmallOnTablet}">
  <app-button
    *ngIf="hasShoppingCart; else shopWithWalmartButton"
    [text]="title"
    [type]="'button'"
    [buttonClass]="'walmart'"
    [isSmallOnTablet]="isSmallOnTablet"
    [isDisabled]="isLoading"
    (clicked)="openShoppingCart()">
  </app-button>

  <div class="wrap-inner">
    <div class="logo">
      <span class="logo__text">Fulfilled&nbsp;by&nbsp;</span>
      <img
        width="192"
        height="46"
        class="logo__img"
        src="assets/ui/images/png/walmart-logo.png"
        alt="walmart"
      />
    </div>

    <div class="discount">
      <span class="discount__text discount__text--big">$10</span>
      <span *ngIf="isGuest" class="discount__text discount__text--small">off your<br>groceries!</span>
      <span *ngIf="!isGuest" class="discount__text discount__text--small">off with code<br>HOLIDAY10</span>
    </div>
  </div>
</div>

<ng-template #shopWithWalmartButton>
  <a
    href="https://goto.walmart.com/c/3301973/565706/9383?veh=aff&sourceid=imp_000011112222333344&u=https%3A%2F%2Fwww.walmart.com"
    rel="noopener"
    target="_blank"
    class="walmart-link"
  >
    <app-button
      [text]="title"
      [type]="'button'"
      [buttonClass]="'walmart'"
      [isSmallOnTablet]="isSmallOnTablet"
      (clicked)="trackClick()"
    ></app-button>
  </a>
</ng-template>
