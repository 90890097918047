<div>
  <a [ngClass]="{ 'disabled': isDisabled }" [routerLink]="profileLink">
    <ng-container *ngIf="photo; else withoutPhoto">
      <picture [class.bg-live]="isLive">
        <img class="photo" [class.photo--bg-live]="isLive"
          [ngSrc]="photo"
          width="40"
          height="40"
          alt="{{ fullName || displayName }}" />
      </picture>
    </ng-container>
    <ng-template #withoutPhoto>
      <div class="photo">
        <div class="photo photo-container-name">
          <div class="photo-name">{{upperCaseName}}</div>
        </div>
      </div>
    </ng-template>
  </a>
</div>
