import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from '@kitch/data-access/services';

@Pipe({ name: 'durationVideo' })
export class DurationVideoPipe implements PipeTransform {
  constructor(private logger: LoggerService) {
  }

  transform(seconds: number): string {
    if (seconds == null) {
      return '';
    }

    const sec: number = Math.floor(seconds % 60);
    let minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);

    minutes %= 60;

    let duration;

    if (hours < 1 || hours >= 10) {
      duration = '';
      this.logger.info(`#DurationVideoPipe video duration ${seconds} out of range 1-10 hours`);
    } else {
      this.logger.info(hours);
      duration = `0${hours.toString()}`;
    }
    if (duration) {
      duration += `:${minutes < 10 ? `0${minutes}` : minutes}`;
      duration += `:${sec < 10 ? `0${sec}` : sec}`;
    } else {
      duration += (minutes < 10 ? `0${minutes}` : minutes);
      duration += `:${sec < 10 ? `0${sec}` : sec}`;
    }

    return duration;
  }
}
