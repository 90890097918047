import IMask from 'imask';

export const PHONE_NUMBER: IMask.MaskedPatternOptions = {
  mask: '+num',
  blocks: {
    num: {
      mask: '[0000000000000000]',
    },
  },
};

export const ONE_DIGIT: IMask.MaskedPatternOptions<RegExp> = {
  mask: /^\d$/,
};

export const VIDEO_TIMESTAMP: IMask.MaskedPatternOptions = {
  overwrite: true,
  mask: 'HH:MM:SS',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
      maxLength: 2,
      autofix: true,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
      autofix: true,
    },
    SS: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
      autofix: true,
    },
  },
};
