export const NEED_APPROVE_ERROR = 'User is not approved';
export const NOT_FOUND_USER_ERROR = 'User not found';
export const NOT_FOUND_RECIPE_ERROR = 'Recipe not found';
export const INVALID_REF_CODE_ERROR = 'Incorrect referral code';
export const INVALID_CAPTCHA_ERROR = 'Captcha is not provided';

export enum InputTypes {
  FILE = 'FILE',
  MULTISELECT = 'MULTISELECT',
  SELECT = 'SELECT',
  TEXT = 'TEXT'
}

export enum MaxLengthErrors {
  DEFAULT = 'DEFAULT',
  WITHOUT_NUMBERS = 'WITHOUT_NUMBERS',
}
