<form
  class="admin-checkbox-form form entity-form"
  [formGroup]="adminCheckboxForm"
>
  <div class="admin-checkbox-form__content-row">
    <label class="checkmark">
      <input
        class="checkmark__input"
        type="checkbox"
        formControlName="isChefRecommended"
      />
      <span class="checkmark__label"></span>
    </label>

    <span class="checkmark__text">Chef Recommended</span>
  </div>

  <div class="admin-checkbox-form__content-row">
    <label class="checkmark">
      <input
        class="checkmark__input"
        type="checkbox"
        formControlName="featured"
      />
      <span class="checkmark__label"></span>
    </label>

    <span class="checkmark__text">Featured</span>
  </div>

  <div class="admin-checkbox-form__content-row">
    <label class="checkmark">
      <input
        class="checkmark__input"
        type="checkbox"
        formControlName="staged"
      />
      <span class="checkmark__label"></span>
    </label>

    <span class="checkmark__text">Main Stage</span>
  </div>

  <div
    *ngIf="checkboxControls.staged.value"
    class="admin-checkbox-form__content-column"
  >
    <input
      appWasInvolved
      class="form-input portions-input"
      formControlName="stagedOrder"
      type="number"
      placeholder="Main Stage Position"
      min="1"
    />
    <app-errors-list
      class="errors"
      formControlName="stagedOrder"
      errorName="amount"
    ></app-errors-list>
  </div>
</form>

