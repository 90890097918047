import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export class FormTool {
  static disableControls(
    form: UntypedFormGroup,
    controls: string[],
    emitEvent = true,
  ): void {
    controls
      .filter((control) => !!form.get(control))
      .forEach((control) => {
        form.get(control).disable({ emitEvent });
      });
  }

  static enableControls(form: UntypedFormGroup, controls: string[]): void {
    controls
      .filter((control) => !!form.get(control))
      .forEach((control) => {
        form.get(control).enable();
      });
  }

  static patchArray(control: UntypedFormArray, array: any[]): void {
    control.clear();

    array.forEach((item) => {
      control.push(new UntypedFormControl(item));
    });
  }

  static patchErrors(
    control: AbstractControl,
    newErrors: { [key: string]: any },
  ): void {
    control.setErrors({
      ...control.errors,
      ...newErrors,
    });
  }

  static removeErrors(
    control: AbstractControl,
    errors: string[] | string,
  ): void {
    const controlErrors = control.errors || {};

    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        delete controlErrors[error];
      });
    } else {
      delete controlErrors[errors];
    }

    Object.keys(controlErrors).length
      ? control.setErrors(controlErrors)
      : control.setErrors(null);
  }

  static toggleErrors(
    control: AbstractControl,
    status: boolean,
    errors: { [key: string]: any },
  ): void {
    status
      ? this.patchErrors(control, errors)
      : this.removeErrors(control, Object.keys(errors));
  }

  static markControlAsDeepDirty(control: AbstractControl): void {
    if (control instanceof FormControl) {
      // Simple control
      control.markAsDirty();
    } else if (control instanceof FormGroup) {
      // FormGroup
      Object.values(control.controls).forEach(control => {
        if (control instanceof FormControl) {
          control.markAsDirty();
        } else if (control instanceof FormGroup) {
          this.markControlAsDeepDirty(control);
        }
      });
    } else if (control instanceof FormArray) {
      // FormArray
      control.controls.forEach(control => this.markControlAsDeepDirty(control));
    }
  }
}
