<section class="app-tags">
  <form
    class="tags-container"
    [formGroup]="tagsForm"
  >
    <ng-container *ngFor="let tagType of tagTypes">
      <section class="app-tag" [ngClass]="'app-tag--' + tagType">
        <h3 class="app-tag__title">{{ tagNames[tagType] }}</h3>

        <div *ngIf="tagType !== tagsTypeLowercase.style else selectTag" class="app-tag__select">
          <app-select-dropdown
            [items]="tags[tagType]"
            [placeholder]="tagPlaceholder[tagType]"
            [formControlName]="tagType"
            [maxSelectedItems]="maxSelectedTags"
            [isAddTag]="tagType === 'free'"
            [selectedItems]="selectedTagsIds[tagType]"
            (selectedOptions)="onTagSelect($event, tagType)"
            (addNewOptions)="onAddFreeTag($event, tagType)"
          ></app-select-dropdown>
        </div>

        <ng-template #selectTag>
          <div class="app-tag__select app-tag__select--not-multi select-wrap">
            <select
              name="style"
              id="style"
              class="select-wrap__select"
              [formControlName]="tagType"
            >
              <option
                [ngValue]="null"
                selected
                disabled
                hidden
              >e.g. Classic, With A Twist, Exotic</option>
              <option *ngFor="let style of tags[tagType]" [ngValue]="style">
                {{ style.name }}
              </option>
            </select>
          </div>
        </ng-template>
      </section>
    </ng-container>
  </form>
</section>
