<p class="text-content text-content--no-intend text-content--vertical-intend">
  <i>Last Updated: July 6, 2021</i>
</p>

<p class="text-content text-content--no-intend">Kittch provides a social platform where chefs and other creatives can
  provide live and interactive culinary content and products. This Privacy Policy is designed to help you understand how
  we collect, use, and share your personal information and to help you understand and exercise your privacy rights.
</p>

<ol class="level-1">
  <li>
    <h3 class="section-title">SCOPE</h3>
    <p class="text-content text-content--no-intend">This Privacy Policy applies to personal information processed by us,
      including on our websites, mobile applications, and other online or offline offerings. To make this Privacy Policy
      easier to read, our websites, mobile applications, and other offerings are collectively called <span class="bold">"Services"</span>.
    </p>
  </li>

  <li>
    <h3 id="personal-information" class="section-title">PERSONAL INFORMATION WE COLLECT</h3>
    <p class="text-content text-content--no-intend">
      The categories of personal information we collect depend on how you interact with us (e.g., whether you are a user
      or a content creator), our Services, and the requirements of applicable law. We collect information that you
      provide to us, information we obtain automatically when you use our Services, and information from other sources
      such as third-party services and organizations, as described below.
    </p>
    <ol class="level-2">
      <li>
        <h4 class="section-title">Information You Provide to Us Directly</h4>
        <p class="text-content text-content--no-intend">We may collect the following personal information that you
          provide to us.</p>
        <ul class="level-3">
          <li class="text-content">
            <span class="bold">User and Content Creator Account Information.</span> We may collect information when you
            create a user or content creator account, such as your name, email address, username, and password. We may
            also collect information about your interests and culinary background in order to provide you with curated
            content.
          </li>
          <li class="text-content">
            <span class="bold">Content Creator Information.</span> We may collect information from you if you provide
            culinary content, including your name, biography and/or employment, and background information, content that
            you upload to the Services, and other information related to the content you provide.
          </li>
          <li class="text-content">
            <span class="bold">Payment Information.</span> We may collect personal information and details associated
            with your purchases, including payment information. Any payments made via our Services are processed by
            third-party payment processors, such as Stripe. We do not directly collect or store any payment card
            information entered through our Services, but we may receive information associated with your payment card
            information (e.g., your billing details). For more information about Stripe, please see <a
            href="https://stripe.com/privacy" target="_blank">Stripe’s Privacy Policy</a>.
          </li>
          <li class="text-content">
            <span class="bold">Your Communications with Us.</span> We may collect personal information, such as name,
            email address, phone number, or mailing address when you request information about our Services, join our
            waitlist, register for our newsletter or rewards program, request customer or technical support, apply for a
            job, or otherwise communicate with us.
          </li>
          <li class="text-content">
            <span class="bold">Surveys.</span> We may contact you to participate in surveys. If you decide to
            participate, you may be asked to provide certain information which may include personal information.
          </li>
          <li class="text-content">
            <span class="bold">Interactive Features.</span> We and others who use our Services may collect personal
            information that you submit or make available through our interactive features (e.g., messaging and chat
            features, commenting functionalities, live video interactions, forums, blogs, and social media pages). Any
            information you provide using the public sharing features of the Services (referred to herein as <span
            class="bold">"User Content"</span>) will be considered "public", unless otherwise required by applicable
            law, and is not subject to the privacy protections referenced herein. Please exercise caution before
            revealing any information that may identify you in the real world to other users.
          </li>
          <li class="text-content">
            <span class="bold">Sweepstakes, Contests, and Rewards Programs.</span> We may collect personal information
            you provide for any sweepstakes, contests, or rewards programs that we offer. In some jurisdictions, we are
            required to publicly share information of sweepstakes and contest winners.
          </li>
          <li class="text-content">
            <span class="bold">Conferences, Trade Shows, and Other Events.</span> We may collect personal information
            from individuals when we attend conferences, trade shows, and other events.
          </li>
          <li class="text-content">
            <span class="bold">Business Development and Strategic Partnerships.</span> We may collect personal
            information from individuals and third parties to assess and pursue potential business opportunities.
          </li>
          <li class="text-content">
            <span class="bold">Job Applications.</span> We may post job openings and opportunities on our Services. If
            you reply to one of these postings by submitting your application, CV and/or cover letter to us, we will
            collect and use your information to assess your qualifications.
          </li>
        </ul>
      </li>
      <li>
        <h4 class="section-title">Information Collected Automatically</h4>
        <p class="text-content text-content--no-intend">We may collect personal information automatically when you use
          our Services:</p>
        <ul class="level-3">
          <li class="text-content">
            <span class="bold">Automatic Data Collection.</span> We may collect certain information automatically when
            you use our Services, such as your Internet protocol (IP) address, user settings, MAC address, cookie
            identifiers, mobile carrier, mobile advertising and other unique identifiers, browser or device information,
            location information (including approximate location derived from IP address), and Internet service
            provider. We may also automatically collect information regarding your use of our Services, such as pages
            that you visit before, during and after using our Services, information about the links you click, the types
            of content you interact with, the frequency and duration of your activities, and other information about how
            you use our Services. In addition, we may collect information that other people provide about you when they
            use our Services, including information about you when they tag you.
          </li>
          <li class="text-content">
            <span class="bold">Location Information.</span> We may collect precise location information, such as GPS
            coordinates, to provide content that is more relevant to your location and to otherwise improve your
            interactions with our Services.
          </li>
          <li class="text-content">
            <span class="bold">Cookies, Pixel Tags/Web Beacons, and Other Technologies.</span> We, as well as third
            parties that provide content, advertising, or other functionality on our Services, may use cookies, pixel
            tags, local storage, and other technologies (<span class="bold">"Technologies"</span>) to automatically
            collect information through your use of our Services.
          </li>
          <li class="text-content empty-list-style">
            <ul class="level-3">
              <li class="text-content">
                <span class="bold">Cookies.</span> Cookies are small text files placed in device browsers that store
                preferences and facilitate and enhance your experience.
              </li>
              <li class="text-content">
                <span class="bold">Pixel Tags/Web Beacons.</span> A pixel tag (also known as a web beacon) is a piece of
                code embedded in our Services that collects information about engagement on our Services. The use of a
                pixel tag allows us to record, for example, that a user has visited a particular web page or clicked on
                a particular advertisement. We may also include web beacons in e-mails to understand whether messages
                have been opened, acted on, or forwarded.
              </li>
            </ul>
            <p class="text-content text-content--no-intend">Our uses of these Technologies fall into the following
              general categories: </p>
            <ul class="level-3">
              <li class="text-content">
                <span class="bold">Operationally Necessary.</span> This includes Technologies that allow you access to
                our Services, applications, and tools that are required to identify irregular website behavior, prevent
                fraudulent activity and improve security or that allow you to make use of our functionality;
              </li>
              <li class="text-content">
                <span class="bold">Performance-Related.</span> We may use Technologies to assess the performance of our
                Services, including as part of our analytic practices to help us understand how individuals use our
                Services (see Analytics below);
              </li>
              <li class="text-content">
                <span class="bold">Functionality-Related.</span> We may use Technologies that allow us to offer you
                enhanced functionality when accessing or using our Services. This may include identifying you when you
                sign into our Services or keeping track of your specified preferences, interests, or past items viewed;
              </li>
              <li class="text-content">
                <span class="bold">Advertising- or Targeting-Related.</span> We may use first party or third-party
                Technologies to deliver content, including ads relevant to your interests, on our Services or on
                third-party websites.
              </li>
            </ul>
            <p class="text-content text-content--no-intend">See "<a routerLink="./" fragment="privacy-choices"
                                                                    (click)="scrollTo('privacy-choices')">Your Privacy
              Choices and Rights</a>" below to understand your choices regarding these Technologies.</p>
          </li>
          <li class="text-content">
            <span class="bold">Analytics.</span> We may use Technologies and other third-party tools to process
            analytics information on our Services, such as Google Analytics. For more information, please visit <a
            href="https://www.google.com/policies/privacy/partners/" target="_blank">Google Analytics’ Privacy
            Policy</a>. To learn more about how to opt-out of Google Analytics’ use of your information, please click <a
            href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>.
          </li>
          <li class="text-content">
            <span class="bold">Social Media Platforms.</span> Our Services may contain social media buttons such as
            YouTube, LinkedIn, and Facebook (that might include widgets such as the “share this” button or other
            interactive mini programs). These features may collect your IP address, which page you are visiting on our
            Services, and may set a cookie to enable the feature to function properly. Your interactions with these
            platforms are governed by the privacy policy of the company providing it.
          </li>
        </ul>
      </li>
      <li>
        <h4 class="section-title">Information Collected from Other Sources</h4>
        <p class="text-content text-content--no-intend">We may obtain information about you from other sources,
          including through third-party services and organizations. For example, if you access our Services through a
          third-party application, such as an app store, a third-party login service, or a social networking site, we
          may collect information about you from that third-party application that you have made available via your
          privacy settings.</p>
      </li>
    </ol>
  </li>

  <li>
    <h3 id="how-we-use-your-information" class="section-title">HOW WE USE YOUR INFORMATION</h3>
    <p class="text-content text-content--no-intend">
      We use your information for a variety of business purposes, including to provide our Services, for administrative
      purposes, and to market our products and Services, as described below.
    </p>
    <ol class="level-2">
      <li>
        <h4 class="section-title">Provide Our Services</h4>
        <p class="text-content text-content--no-intend">We use your information to fulfil our contract with you and
          provide you with our Services, such as:</p>
        <ul class="level-3">
          <li class="text-content">
            Managing your information and accounts;
          </li>
          <li class="text-content">
            Providing access to certain areas, functionalities, and features of our Services;
          </li>
          <li class="text-content">
            Answering requests for customer or technical support;
          </li>
          <li class="text-content">
            Communicating with you about your account, activities on our Services, and policy changes;
          </li>
          <li class="text-content">
            Processing your financial information and other payment methods for products or Services purchased;
          </li>
          <li class="text-content">
            Processing applications if you apply for a job we post on our Services; and
          </li>
          <li class="text-content">
            Allowing you to register for events.
          </li>
        </ul>
      </li>
      <li>
        <h4 class="section-title">Administrative Purposes</h4>
        <p class="text-content text-content--no-intend">We use your information for various administrative purposes,
          such as:</p>
        <ul class="level-3">
          <li class="text-content">
            Pursuing our legitimate interests such as direct marketing, research and development (including marketing
            research), network and information security, and fraud prevention;
          </li>
          <li class="text-content">
            Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and
            prosecuting those responsible for that activity;
          </li>
          <li class="text-content">
            Measuring interest and engagement in our Services;
          </li>
          <li class="text-content">
            Short-term, transient use, such as contextual customization of ads;
          </li>
          <li class="text-content">
            Improving, upgrading or enhancing our Services;
          </li>
          <li class="text-content">
            Developing new products and Services;
          </li>
          <li class="text-content">
            Ensuring internal quality control and safety;
          </li>
          <li class="text-content">
            Authenticating and verifying individual identities, including requests to exercise your rights under this
            policy;
          </li>
          <li class="text-content">
            Debugging to identify and repair errors with our Services;
          </li>
          <li class="text-content">
            Auditing relating to interactions, transactions and other compliance activities;
          </li>
          <li class="text-content">
            Enforcing our agreements and policies; and
          </li>
          <li class="text-content">
            Complying with our legal obligations.
          </li>
        </ul>
      </li>
      <li>
        <h4 class="section-title">Marketing and Advertising our Products and Services</h4>
        <p class="text-content text-content--no-intend">We may use personal information to tailor and provide you with
          content and advertisements. We may provide you with these materials as permitted by applicable law.</p>
        <p class="text-content text-content--no-intend text-content--vertical-intend">Some of the ways we market to you include email campaigns,
          custom audiences advertising, and "interest-based" or "personalized advertising", including through
          cross-device tracking.</p>
        <p class="text-content text-content--no-intend text-content--vertical-intend">If you have any questions about our marketing practices or if
          you would like to opt out of the use of your personal information for marketing purposes, you may contact us
          at any time as set forth in "<a routerLink="./" fragment="contact-us" (click)="scrollTo('contact-us')">Contact
            Us</a>" below.</p>
      </li>
      <li>
        <h4 class="section-title">Other Purposes</h4>
        <p class="text-content text-content--no-intend">We also use your information for other purposes as requested by
          you or as permitted by applicable law.</p>
        <ul class="level-3">
          <li class="text-content">
            <span class="bold">Consent.</span> We may use personal information for other purposes that are clearly
            disclosed to you at the time you provide personal information or with your consent.
          </li>
          <li class="text-content">
            <span class="bold">De-identified and Aggregated Information.</span> We may use personal information and
            other information about you to create de-identified and/or aggregated information, such as de-identified
            demographic information, de-identified location information, information about the device from which you
            access our Services, or other analyses we create.
          </li>
          <li class="text-content">
            <span class="bold">Share Content with Friends or Colleagues.</span> Our Services may offer various tools and
            functionalities. For example, we may allow you to provide information about your friends through our
            referral services. Our referral services may allow you to forward or share certain content with a friend or
            colleague, such as an email inviting your friend to use our Services. Please only share with us contact
            information of people with whom you have a relationship (e.g., relative, friend neighbor, or co-worker).
          </li>
        </ul>
      </li>
    </ol>
  </li>

  <li>
    <h3 class="section-title">HOW WE DISCLOSE YOUR INFORMATION</h3>
    <p class="text-content text-content--no-intend">
      We disclose your information to third parties for a variety of business purposes, including to provide our
      Services, to protect us or others, or in the event of a major business transaction such as a merger, sale, or
      asset transfer, as described below.
    </p>
    <ol class="level-2">
      <li>
        <h4 class="section-title">Disclosures to Provide our Services</h4>
        <p class="text-content text-content--no-intend">The categories of third parties with whom we may share your
          information are described below.</p>
        <ul class="level-3">
          <li class="text-content">
            <span class="bold">Service Providers.</span> We may share your personal information with our third-party
            service providers who use that information to help us provide our Services. This includes service providers
            that provide us with IT support, hosting, payment processing, customer service, and related services.
          </li>
          <li class="text-content">
            <span class="bold">Business Partners.</span> We may share your personal information with business partners
            to provide you with a product or service you have requested. We may also share your personal information to
            business partners with whom we jointly offer products or services.
          </li>
          <li class="text-content">
            <span class="bold">Affiliates.</span> We may share your personal information with our company affiliates.
          </li>
          <li class="text-content">
            <span class="bold">Other Users/Website Visitors.</span>
            As described above in "<a routerLink="./" fragment="personal-information"
                                      (click)="scrollTo('personal-information')">Personal Information We Collect</a>",
            our Services allow you to share your profile and/or User Content with other
            Users/publicly, including to those who do not use our Services.
          </li>
          <li class="text-content">
            <span class="bold">Advertising Partners.</span> We may share your personal information with third-party
            advertising partners. These third-party advertising partners may set Technologies and other tracking tools
            on our Services to collect information regarding your activities and your device (e.g., your IP address,
            cookie identifiers, page(s) visited, location, time of day). These advertising partners may use this
            information (and similar information collected from other services) for purposes of delivering personalized
            advertisements to you when you visit digital properties within their networks. This practice is commonly
            referred to as "interest-based advertising" or "personalized advertising".
          </li>
          <li class="text-content">
            <span class="bold">APIs/SDKs.</span> We may use third-party Application Program Interfaces ("APIs") and
            Software Development Kits ("SDKs") as part of the functionality of our Services. For more information about
            our use of APIs and SDKs, please contact us as set forth in "<a routerLink="./" fragment="contact-us"
                                                                            (click)="scrollTo('contact-us')">Contact
            Us</a>" below.
          </li>
        </ul>
      </li>
      <li>
        <h4 class="section-title">Disclosures to Protect Us or Others</h4>
        <p class="text-content text-content--no-intend">We may access, preserve, and disclose any information we store
          associated with you to external parties if we, in good faith, believe doing so is required or appropriate to:
          comply with law enforcement or national security requests and legal process, such as a court order or
          subpoena; protect your, our, or others’ rights, property, or safety; enforce our policies or contracts;
          collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal
          activity.</p>
      </li>
      <li>
        <h4 class="section-title">Disclosure in the Event of Merger, Sale, or Other Asset Transfers</h4>
        <p class="text-content text-content--no-intend">If we are involved in a merger, acquisition, financing due
          diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to
          another provider, your information may be sold or transferred as part of such a transaction, as permitted by
          law and/or contract.</p>
      </li>
    </ol>
  </li>

  <li>
    <h3 id="privacy-choices" class="section-title">YOUR PRIVACY CHOICES AND RIGHTS</h3>
    <p class="text-content text-content--no-intend">
      <span class="bold">Your Privacy Choices.</span> The privacy choices you may have about your personal information
      are determined by applicable law and are described below.
    </p>
    <ul class="level-3">
      <li class="text-content">
        <span class="bold">Email and Telephone Communications.</span> If you receive an unwanted email from us, you can
        use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you
        will continue to receive transaction-related emails regarding products or Services you have requested. We may
        also send you certain non-promotional communications regarding us and our Services, and you will not be able to
        opt out of those communications (e.g., communications regarding our Services or updates to our Terms or this
        Privacy Policy).
      </li>
      <li class="text-content">
        <span class="bold">Text Messages.</span> You may opt out of receiving text messages from us by following the
        instructions in the text message you have received from us or by otherwise contacting us.
      </li>
      <li class="text-content">
        <span class="bold">Mobile Devices.</span> We may send you push notifications through our mobile application. You
        may opt out from receiving these push notifications by changing the settings on your mobile device. With your
        consent, we may also collect precise location-based information via our mobile application. You may opt out of
        this collection by changing the settings on your mobile device.
      </li>
      <li class="text-content">
        <span class="bold">"Do Not Track."</span> Do Not Track ("DNT") is a privacy preference that users can set in
        certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms
        transmitted by web browsers.
      </li>
      <li class="text-content">
        <span class="bold">Cookies and Interest-Based Advertising.</span> You may stop or restrict the placement of
        Technologies on your device or remove them by adjusting your preferences as your browser or device permits.
        However, if you adjust your preferences, our Services may not work properly. Please note that cookie-based
        opt-outs are not effective on mobile applications. However, you may opt-out of personalized advertisements on
        some mobile applications by following the instructions for <a
        href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en" target="_blank">Android</a>,
        <a href="https://support.apple.com/en-us/HT202074" target="_blank">iOS</a> and <a
        href="https://www.networkadvertising.org/mobile-choice/" target="_blank">others</a>.
      </li>
    </ul>
    <p class="text-content text-content--intended">The online advertising industry also provides websites from which you
      may opt out of receiving targeted ads from data partners and other advertising partners that participate in
      self-regulatory programs. You can access these and learn more about targeted advertising and consumer choice and
      privacy by visiting the <a href="https://www.networkadvertising.org/managing/opt_out.asp" target="_blank">Network
        Advertising Initiative</a>, <a href="https://www.aboutads.info/choices/" target="_blank">the Digital Advertising
        Alliance</a>, <a href="https://www.youronlinechoices.eu/" target="_blank">the European Digital Advertising
        Alliance</a>, and <a href="https://youradchoices.ca/en/tools" target="_blank">the Digital Advertising Alliance
        of Canada</a>.</p>
    <p class="text-content text-content--intended">Please note you must separately opt out in each browser and on each
      device.</p>

    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Your Privacy Rights.</span> In accordance with applicable law, you may have the right to:
    </p>
    <ul class="level-3">
      <li class="text-content">
        <span class="bold">Access Personal Information</span> about you, including: (i) confirming whether we are
        processing your personal information; (ii) obtaining access to or a copy of your personal information in a
        structured, commonly used, and machine readable format; and (iii) receiving an electronic copy of personal
        information that you have provided to us, or asking us to send that information to another company in a
        structured, commonly used, and machine readable format (the "right of data portability");
      </li>
      <li class="text-content">
        <span class="bold">Request Correction</span> of your personal information where it is inaccurate or incomplete.
        In some cases, we may provide self-service tools that enable you to update your personal information;
      </li>
      <li class="text-content">
        <span class="bold">Request Deletion</span> of your personal information;
      </li>
      <li class="text-content">
        <span class="bold">Request Restriction of or Object to</span> our processing of your personal information; and
      </li>
      <li class="text-content">
        <span class="bold">Withdraw your Consent</span> to our processing of your personal information.
      </li>
    </ul>
    <p class="text-content text-content--no-intend">If you would like to exercise any of these rights, please contact us
      as set forth in "<a routerLink="./" fragment="contact-us" (click)="scrollTo('contact-us')">Contact Us</a>" below.
      We will process such requests in accordance with applicable laws.</p>
  </li>

  <li>
    <h3 class="section-title">SECURITY OF YOUR INFORMATION</h3>
    <p class="text-content text-content--no-intend">
      We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy.
      Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you
      provide to us. We have taken appropriate safeguards to require that your personal information will remain
      protected and require our third-party service providers and partners to have appropriate safeguards as well. To
      the fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosure.
    </p>

    <p class="text-content text-content--no-intend text-content--vertical-intend">
      By using our Services or providing personal information to us, you agree that we may communicate with you
      electronically regarding security, privacy, and administrative issues relating to your use of our Services. If we
      learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on our
      Services, by mail or by sending an email to you.
    </p>
  </li>

  <li>
    <h3 class="section-title">INTERNATIONAL DATA TRANSFERS</h3>
    <p class="text-content text-content--no-intend">
      All information processed by us may be transferred, processed, and stored anywhere in the world, including, but
      not limited to, the United States or other countries, which may have data protection laws that are different from
      the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable
      laws.
    </p>

    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If we transfer personal information to countries outside the European Economic Area, we will put in place
      appropriate safeguards to ensure that this transfer complies with the applicable laws and regulations. For more
      information about these safeguards, please contact us as set forth below.
    </p>
  </li>

  <li>
    <h3 class="section-title">RETENTION OF PERSONAL INFORMATION</h3>
    <p class="text-content text-content--no-intend">
      We store the personal information we collect as described in this Privacy Policy for as long as you use our
      Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve
      disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements,
      and comply with applicable laws.
    </p>
  </li>

  <li>
    <h3 class="section-title">SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</h3>
    <p class="text-content text-content--no-intend">
      This Supplemental California Privacy Notice only applies to our processing of personal information that is subject
      to the California Consumer Privacy Act of 2018 ("CCPA"). The CCPA provides California residents with the right to
      know what categories of personal information Kittch has collected about them and whether Kittch disclosed that
      personal information for a business purpose (e.g., to a service provider) in the preceding 12 months. California
      residents can find this information below:
    </p>
    <table class="table">
      <tr class="table-row">
        <th>
          <div class="text-content text-content--no-intend bold">Category of Personal Information Collected by Kittch</div>
        </th>
        <th>
          <div class="text-content text-content--no-intend bold">Category of Third Parties Information is Disclosed to for a Business Purpose</div>
        </th>
      </tr>
      <tr class="table-row">
        <td>
          <div class="text-content text-content--no-intend">
            <div class="bold">Identifiers.</div>
            A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol
            address, email address, account name, or other similar identifiers.
          </div>
        </td>
        <td>
          <ul class="level-3">
            <li class="text-content">
              Service providers
            </li>
            <li class="text-content">
              Business partners
            </li>
            <li class="text-content">
              Affiliates
            </li>
            <li class="text-content">
              Advertising partners
            </li>
            <li class="text-content">
              Other Users/Public (name)
            </li>
          </ul>
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="text-content text-content--no-intend">
            <div class="bold">Personal information categories listed in the California Customer Records statute (Cal.
              Civ. Code § 1798.80(e))
            </div>
            A name, physical characteristics or description, address, telephone number, education, employment, and
            employment history.
          </div>
        </td>
        <td>
          <ul class="level-3">
            <li class="text-content">
              Service providers
            </li>
            <li class="text-content">
              Business partners
            </li>
            <li class="text-content">
              Affiliates
            </li>
            <li class="text-content">
              Advertising partners
            </li>
            <li class="text-content">
              Other Users/Public (name)
            </li>
          </ul>
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="text-content text-content--no-intend">
            <div class="bold">Commercial information</div>
            Records of personal property, products or services purchased, obtained, or considered, or other purchasing
            or consuming histories or tendencies.
          </div>
        </td>
        <td>
          <ul class="level-3">
            <li class="text-content">
              Service providers
            </li>
            <li class="text-content">
              Business partners
            </li>
            <li class="text-content">
              Affiliates
            </li>
            <li class="text-content">
              Advertising partners
            </li>
          </ul>
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="text-content text-content--no-intend">
            <div class="bold">Internet or other electronic network activity</div>
            Browsing history, search history, information on a consumer's interaction with an internet website,
            application, or advertisement.
          </div>
        </td>
        <td>
          <ul class="level-3">
            <li class="text-content">
              Service providers
            </li>
            <li class="text-content">
              Business partners
            </li>
            <li class="text-content">
              Affiliates
            </li>
            <li class="text-content">
              Advertising partners
            </li>
          </ul>
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="text-content text-content--no-intend">
            <div class="bold">Geolocation data</div>
            Physical location or movements.
          </div>
        </td>
        <td>
          <ul class="level-3">
            <li class="text-content">
              Service providers
            </li>
            <li class="text-content">
              Business partners
            </li>
            <li class="text-content">
              Affiliates
            </li>
            <li class="text-content">
              Advertising partners
            </li>
          </ul>
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="text-content text-content--no-intend">
            <div class="bold">Professional or employment-related information</div>
            Current or past job history or performance evaluations.
          </div>
        </td>
        <td>
          <ul class="level-3">
            <li class="text-content">
              Service providers
            </li>
            <li class="text-content">
              Other Users (employment, current job of content creators)
            </li>
          </ul>
        </td>
      </tr>
      <tr class="table-row">
        <td>
          <div class="text-content text-content--no-intend">
            <div class="bold">Inferences drawn from other personal information to create a profile about a consumer
            </div>
            Profile reflecting a consumer's preferences, characteristics, psychological trends, predispositions,
            behavior, attitudes, intelligence, abilities, and aptitudes.
          </div>
        </td>
        <td>
          <ul class="level-3">
            <li class="text-content">
              Service providers
            </li>
            <li class="text-content">
              Business partners
            </li>
            <li class="text-content">
              Affiliates
            </li>
            <li class="text-content">
              Advertising partners
            </li>
          </ul>
        </td>
      </tr>
    </table>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The categories of sources from which we collect personal information and our business and commercial purposes for
      using personal information are set forth in "<a routerLink="./" fragment="personal-information"
                                                      (click)="scrollTo('personal-information')">Personal Information We
      Collect</a>" and "<a routerLink="./" fragment="how-we-use-your-information"
                           (click)="scrollTo('how-we-use-your-information')">How We Use Your Personal Information</a>"
      above, respectively.
    </p>

    <h4 class="section-title">"Sales" of Personal Information under the CCPA</h4>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      For purposes of the CCPA, Kittch does not "sell" personal information, nor do we have actual knowledge of any
      "sale" of personal information of minors under 16 years of age.
    </p>

    <h4 class="section-title">Additional Privacy Rights for California Residents</h4>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Non-Discrimination.</span> California residents have the right not to receive discriminatory
      treatment by us for the exercise of their rights conferred by the CCPA.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Authorized Agent.</span> Only you, or someone legally authorized to act on your behalf, may
      make a verifiable consumer request related to your personal information. You may also make a verifiable consumer
      request on behalf of your minor child. To designate an authorized agent, please contact us as set forth in "<a
      routerLink="./" fragment="contact-us" (click)="scrollTo('contact-us')">Contact Us</a>" below and provide written
      authorization signed by you and your designated agent.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Verification.</span> To protect your privacy, we will take steps the following steps to verify
      your identity before fulfilling your request. When you make a request, we will ask you to provide sufficient
      information that allows us to reasonably verify you are the person about whom we collected personal information or
      an authorized representative, which may include asking you to answer questions regarding your account and use of
      our Services.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If you are a California resident and would like to exercise any of your rights under the CCPA, please contact us
      as set forth in "<a routerLink="./" fragment="contact-us" (click)="scrollTo('contact-us')">Contact Us</a>" below.
      We will process such requests in accordance with applicable laws.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Refer-a-Friend and Similar Incentive Programs.</span>
      As described above in <a routerLink="./" fragment="personal-information"
                               (click)="scrollTo('personal-information')">How We Use Your
      Personal Information</a> ("Share Content with Friends or Colleagues"), we may offer referral programs or other
      incentivized data collection programs. For example, we may offer incentives to you such as discounts or
      promotional items or credit in connection with these programs, wherein you provide your personal information in
      exchange for a reward, or provide personal information regarding your friends or colleagues (such as their email
      address) and receive rewards when they sign up to use our Services. (The referred party may also receive rewards
      for signing up via your referral.) These programs are entirely voluntary and allow us to grow our business and
      provide additional benefits to you. The value of your data to us depends on how you ultimately use our Services,
      whereas the value of the referred party’s data to us depends on whether the referred party ultimately becomes a
      user and uses our Services. Said value will be reflected in the incentive offered in connection with each program.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Accessibility.</span> This Privacy Policy uses industry-standard technologies and was developed
      in line with the World Wide Web Consortium’s Web Content Accessibility Guidelines, version 2.1. If you wish to
      print this policy, please do so from your web browser or by saving the page as a PDF.
    </p>
  </li>

  <li>
    <h3 class="section-title">SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</h3>
    <p class="text-content text-content--no-intend">
      If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Information to
      third parties who intend to license or sell that Personal Information. Please note that we do not currently sell
      your Personal Information as sales are defined in Nevada Revised Statutes Chapter 603A. If you have any questions,
      or to request that we do not sell your information in the future, please contact us as set forth below.
    </p>
  </li>

  <li>
    <h3 class="section-title">CHILDREN’S INFORMATION</h3>
    <p class="text-content text-content--no-intend">
      The Services are not directed to children under 13 (or other age as required by local law), and we do not
      knowingly collect personal information from children.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If you are a parent or guardian and believe your child has uploaded personal information to our site without your
      consent, wish to review information collected from your child, or have that information modified or deleted, you
      may contact us as described in "<a routerLink="./" fragment="contact-us" (click)="scrollTo('contact-us')">Contact
      Us</a>" below. If we become aware that a child has provided us with personal information in violation of
      applicable law, we will delete any personal information we have collected, unless we have a legal obligation to
      keep it, and terminate the child’s account.
    </p>
  </li>

  <li>
    <h3 class="section-title">OTHER PROVISIONS</h3>
    <p class="text-content text-content--no-intend">
      <span class="bold">Third-Party Websites/Applications.</span> The Services may contain links to other
      websites/applications and other websites/applications may reference or link to our Services. These third-party
      services are not controlled by us. We encourage our users to read the privacy policies of each website and
      application with which they interact. We do not endorse, screen or approve, and are not responsible for, the
      privacy practices or content of such other websites or applications. Providing personal information to third-party
      websites or applications is at your own risk.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Supervisory Authority.</span> If you are located in the European Economic Area, Switzerland,
      the United Kingdom or Brazil, you have the right to lodge a complaint with a supervisory authority if you believe
      our processing of your personal information violates applicable law.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      <span class="bold">Changes to our Privacy Policy.</span> We may revise this Privacy Policy from time to time in
      our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by
      applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if
      you continue to use our Services after the new Privacy Policy takes effect.
    </p>
  </li>

  <li>
    <h3 id="contact-us" class="section-title">CONTACT US</h3>
    <p class="text-content text-content--no-intend">
      If you have any questions about our privacy practices or this Privacy Policy, or to exercise your rights as
      detailed in this Privacy Policy, please contact us at <a href="mailto:support@kittch.com">support@kittch.com</a>.
    </p>
  </li>
</ol>
