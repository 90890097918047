<div class="dialog-wrap">
  <h1 mat-dialog-title>Please, confirm your action</h1>
  <div mat-dialog-content>
    <p>{{ data.title }}</p>
  </div>
  <div class="dialog__actions" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </div>
</div>
