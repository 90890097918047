import { RequiredSubscriptionPlan } from '@kitch/data-access/models/stream';
import { Product } from '@kitch/ui/models';
import { CustomButton } from '@kitch/ui/models/custom-button-form';

export enum ProfileRole {
  CHEF = 'CHEF',
  USER = 'USER'
}

export interface Profile {
  channel: Channel;
  chefName: string;
  chefSlug: string;
  fullName?: string;
  id: string;
  isLive: boolean;
  photo: string;
  user: ProfileRole;
}

export interface Channel {
  bannerImage?: string;
  currency?: string;
  id: string;
  isClub: boolean;
  isWalmart: boolean;
  name: string;
  price?: number;
  requiredClubSubscriptionPlan: RequiredSubscriptionPlan | null;
  slug: string;
  subscribedToClub: boolean;
  tileImage: string;
}

export interface ShortProfileInfo {
  chefSlug: string;
  displayName: string;
  id: string;
  photo: string;
  refCode: string;
}

export interface FullProfileInfo {
  profile: ProfileChannel;
}

export interface ProfileChannel {
  bio: string;
  channel: Channel;
  chefName: string;
  chefSlug: string;
  displayName: string;
  fullName?: string;
  hidden?: boolean;
  hometown: string;
  id: string;
  isLive: boolean;
  photo: string;
  products: Product[];
  recommendedPlaces: CustomButton[],
  websites: CustomButton[],
  zipCode?: number;
}

export interface ProfileSlugResponse {
  profileId: string;
}

export enum ProfileOrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ProfileOrderField {
  CREATED_AT = 'createdAt',
  FEATURED = 'featured',
  NAME = 'name'
}
