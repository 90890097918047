import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AppPagesItem,
  CreatePlanResponse,
  FeaturedResponse,
  NewUser,
  SimpleResponse,
  User,
} from '@kitch/data-access/models';
import { UsersSearchParams } from '@kitch/data-access/models/search-params';
import { ApiService } from '@kitch/data-access/services';
import { makeUri } from '@kitch/util';
import { ChefFullInfo, UsersListItem } from '@kitch/admin/shared/models/user';

import { USERS_URL } from '../constants/endpoints';
import { BulkInvitesResponse, UserInviteObject, UserInviteStatusObject } from '../models/invites';

@Injectable()
export class UsersService {
  constructor(private apiService: ApiService) {}

  getUsers(
    searchParams?: UsersSearchParams,
  ): Observable<AppPagesItem<UsersListItem>> {
    return this.apiService.get(USERS_URL, searchParams);
  }

  createUser(user: User): Observable<NewUser> {
    return this.apiService.post(USERS_URL, user);
  }

  updateUser(user: User, id: string): Observable<User> {
    return this.apiService.patch(makeUri(USERS_URL, id), user);
  }

  getUserPlan(id: string): Observable<CreatePlanResponse> {
    return this.apiService.get(makeUri(USERS_URL, id, 'get-plan'));
  }

  deleteUser(id: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(USERS_URL, id));
  }

  getUserById(id: string): Observable<ChefFullInfo> {
    return this.apiService.get(makeUri(USERS_URL, id));
  }

  approve(id: string, isApproved: boolean): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(USERS_URL, id, 'approve'), { approved: isApproved });
  }

  addFeatured(id: string): Observable<FeaturedResponse> {
    return this.apiService.post(makeUri(USERS_URL, id, 'featured'));
  }

  deleteFeatured(id: string): Observable<FeaturedResponse> {
    return this.apiService.delete(makeUri(USERS_URL, id, 'featured'));
  }

  sendInvites(
    emailBody: string,
    invites: UserInviteObject[],
    emailSubject: string,
    referalNote: string,
    streamUrl?: string,
    onBehalf?: string,
    depositClams?: number,
    invitedBy?: string,
  ): Observable<BulkInvitesResponse> {
    return this.apiService.post(makeUri(USERS_URL, 'invites', 'bulk'), {
      emailBody, emailSubject, referalNote, invites, streamUrl, onBehalf, depositClams, invitedBy,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getStatusInvitedUsers(searchParams): Observable<AppPagesItem<UserInviteStatusObject>> {
    return this.apiService.get(makeUri(USERS_URL, 'invites'), searchParams);
  }

  deleteInvite(id: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(USERS_URL, 'invites', id));
  }
}
