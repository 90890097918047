import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { of, Subject, throwError } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { FORBIDDEN, NEED_APPROVE_ERROR, NOT_FOUND, NOT_FOUND_USER_ERROR } from '@kitch/data-access/constants';
import { CommonUserRole, REDIRECT_URL_KEY, ServerError, LoginResponse } from '@kitch/data-access/models';
import { AuthService } from '@kitch/data-access/services';
import { formatPhone } from '@kitch/util';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { UserAction } from '@kitch/ui/components/modals';
import { AlertService } from '@kitch/ui/services';

type LoginStep = 'default' | 'verification';

@UntilDestroy()
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('userApprovalModal', { static: false }) userApprovalModal: ModalComponent;

  loginForm: UntypedFormGroup;
  loginStep: LoginStep = 'default';
  loading = false;
  isUserLoggingIn = false;
  error$: Subject<string> = new Subject<string>();

  code: string;
  error: string;
  redirectUrl: string;

  isSubmitted: boolean;

  dialCode: string;
  isUserNotFoundModalOpen = false;
  isPrivacyPolicyModalOpen = false;

  userRole: CommonUserRole;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    protected $gaService: GoogleAnalyticsService,
  ) {
    this.error = this.router.getCurrentNavigation().extras?.state?.error;
    this.redirectUrl = this.router.getCurrentNavigation().extras?.state?.redirectUrl;

    if (this.redirectUrl) {
      window.sessionStorage.setItem(REDIRECT_URL_KEY, this.redirectUrl);
    }
  }

  ngOnInit(): void {
    this.createLoginForm();
    this.userRole = this.route.snapshot.data.role;
    this.$gaService.pageView('/login', 'Login Visit');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.error) {
        this.loginForm.get('phone_number').setErrors({ serverError: this.error });
        this.loginForm.markAllAsTouched();
      }
    }, 0);
  }

  ngOnDestroy() {}

  setStatusPrivacyPolicyModal(status: boolean): void {
    this.isPrivacyPolicyModalOpen = status;
  }

  setDialCode(dialCode: string): void {
    this.dialCode = dialCode;
  }

  setStatusModal(status: boolean): void {
    this.isUserNotFoundModalOpen = status;
  }

  handleUserNotFoundModalAction(action: UserAction): void {
    switch (action) {
      case 'login':
        this.router.navigate(['/login']);
        break;
      case 'signup':
        this.router.navigate(['/sign-up']);
        break;
    }
  }

  onLoginResolve(response: LoginResponse): void {
    this.loginStep = 'verification';
    if (this.loginStep === 'verification') {
      setTimeout(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
          const x = window.scrollX;
          const y = window.scrollY;

          window.scrollTo(x, y);
        }, 0);
      }, 0);
    }
    if (response.otp) {
      this.code = response.otp;
    }
  }

  onLoginReject(error: ServerError): void {
    if ([NOT_FOUND].includes(error.statusCode) && [NOT_FOUND_USER_ERROR].includes(error.message)) {
      this.isUserNotFoundModalOpen = true;
    }

    if ([FORBIDDEN].includes(error.statusCode) && [NEED_APPROVE_ERROR].includes(error.message)) {
      this.userApprovalModal.open();
    }
  }

  onLogin(): void {
    if (!this.loginForm.valid) {
      return;
    }
    this.isSubmitted = false;
    const phoneNumber = formatPhone(this.loginForm.value.phoneNumber, this.dialCode);

    this.isUserLoggingIn = true;
    this.authService
      .login(phoneNumber)
      .pipe(
        switchMap((response) => {
          return this.validateRole()
            ? of(response)
            : throwError(() => of({ message: 'User not found', statusCode: 404 }));
        }),
        finalize(() => (this.isUserLoggingIn = false)),
        untilDestroyed(this),
      )
      .subscribe({
        next: (response: LoginResponse) => this.onLoginResolve(response),
        error: (error: ServerError) => this.onLoginReject(error),
      });
    this.$gaService.pageView('/login', 'Login Submit', undefined, { user_phone: phoneNumber });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onVerifyAuthCode(code): void {
    this.loading = true;
    this.authService
      .verifyAuthCode(code)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        () => {
          const redirectUrl =
            this.redirectUrl ||
            window.sessionStorage.getItem(REDIRECT_URL_KEY) ||
            '';

          this.router.navigate([redirectUrl])
            .then(() => window.sessionStorage.removeItem(REDIRECT_URL_KEY));
        },
        (error: ServerError) => {
          this.error$.next(error.message);
        },
      );

    this.$gaService.pageView('/login', 'Login Verity Number Submit', undefined, {});
  }

  onResendAuthCode(): void {
    const phone = formatPhone(this.loginForm.value.phoneNumber, this.dialCode);

    this.authService.resendAuthCode(phone).subscribe(
      (response) => {
        this.alertService.success('SMS code has been sent to your phone');
        if (response.otp) {
          this.code = response.otp;
        }
      },
      (error: ServerError) => {
        this.error$.next(error.message);
      },
    );
  }

  private createLoginForm(): void {
    const phoneFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]);

    this.loginForm = this.fb.group({ phoneNumber: phoneFormControl });
  }

  private validateRole() {
    return this.authService.isValidRole(this.route.snapshot.data.role);
  }
}
