import {
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { GA_EVENT_IMAGE_UPLOAD } from '@kitch/data-access/constants';
import { UserAccount } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';
import { FormTool, ImageTool } from '@kitch/util';
import { IMAGE_UPLOAD_CONTENT_TYPES } from '@kitch/ui/constants';

@Component({
  selector: 'app-image-chooser',
  templateUrl: './image-chooser.component.html',
  styleUrls: ['./image-chooser.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImageChooserComponent,
      multi: true,
    },
  ],
})
export class ImageChooserComponent implements ControlValueAccessor, OnInit {
  @ViewChild('img', { static: true }) imageView: ElementRef;
  @ViewChild('uploadInput', { static: true }) uploadInput: ElementRef;

  @Input() useLikeAvatar = false;
  @Input() accountDetails: UserAccount;
  @Input() formControlName: string;
  @Input() imageAlt: string;
  @Input() hasDeleteBtn = true;
  @Input() imageType = 'default';
  @Input() isUser = false;
  @Input() isAdmin = false;
  @Input() showDescription = true;
  @Input() showImageCreator = false;
  @Input() dishName: string;
  @Output() deleteImage: EventEmitter<boolean> = new EventEmitter<boolean>();

  image = '';
  imageName = '';
  originalImage = '';
  croppedImageVersion = '';
  imageLoadFailed = false;
  firstLoad = false;

  imageChangedEvent: unknown = '';
  croppedImage = '';
  showCropperButton = false;
  showCropper = false;
  imageAspectRatio = 1 / 1;
  imageAspectRatioText = '1 / 1';
  imageMaxWidth = 0;
  imageMaxHeigth = 0;
  imageBase64: string;

  imageFile: File;

  imageLimits: ImageLimits = { type: IMAGE_UPLOAD_CONTENT_TYPES };

  formControl: AbstractControl;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) {}

  ngOnInit() {
    this.getImageAspectRatio();
    this.getImageMaxHeight();
    this.getImageMaxWidth();

    if (!this.controlContainer) {
      console.warn('Can\'t find parent FormGroup directive');

      return;
    }

    if (!this.formControlName) {
      console.warn('Missing FormControlName directive from host element of the component');

      return;
    }

    this.formControl = this.controlContainer.control.get(this.formControlName);
    if (this.useLikeAvatar) {
      this.formControl.valueChanges.subscribe((value) => {
        this.image = value;
      });
      this.image = this.accountDetails.profile.photo ?
        this.accountDetails.profile.photo : '/assets/ui/images/png/no-image.png';
      this.imageName = this.accountDetails.profile.displayName;
      this.hasDeleteBtn = false;
    } else {
      this.formControl.valueChanges.subscribe((value) => {
        this.image = value;
        this.imageName = value?.name;
        this.hasDeleteBtn = true;
      });
      this.image = this.formControl.value;
      this.imageName = this.formControl.value;
      this.hasDeleteBtn = true;
    }
  }

  onFileChanged(event: Event): void {
    this.imageBase64 = '';
    this.displayCropper();
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const file: File = files[0];

    if (!file) {
      return;
    }

    const imageTypeError: ForbiddenTypeError = this.validateImageType(file.type);

    FormTool.toggleErrors(this.formControl, !!imageTypeError, imageTypeError || {});

    if (imageTypeError) {
      this.formControl.markAsTouched();
      this.controlContainer.control.updateValueAndValidity();

      return;
    }

    this.showCropperButton = true;
    this.imageChangedEvent = event;
    this.sendAnalytics();
  }

  onFileClick(): void {
    this.uploadInput.nativeElement.value = null;
  }

  onImageLoadError(): void {
    this.imageLoadFailed = true;
    this.imageView.nativeElement.src = 'assets/ui/images/png/no-image.png';
  }

  onDeleteImage(event: Event): void {
    event.preventDefault();
    this.image = '';
    this.showCropperButton = false;
    this.imageName = '';
    this.deleteImage.emit(true);
    this.imageChangedEvent = null;
    this.formControl.markAsTouched();
  }

  private validateImageType(type: string): ForbiddenTypeError | null {
    const forbiddenType = !this.imageLimits.type.includes(type);

    return forbiddenType ? { forbiddenType: { expected: this.imageLimits.type } } : null;
  }

  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    const imageFile = await ImageTool.dataUrlToFile(event.base64, 'Cropped image');

    this.formControl.patchValue(imageFile);
    this.formControl.markAsDirty();
    this.image = event.base64;
    this.controlContainer.control.updateValueAndValidity();

    this.formControl.markAsTouched();

    if (this.firstLoad) {
      this.croppedImageVersion = this.image;
    }
    this.firstLoad = false;
  }

  imageLoaded(): void {
    this.firstLoad = true;
  }

  cropperReady(): void {
    this.showCropper = false;
  }

  loadImageFailed(): void {
    // show message
  }

  getImageAspectRatio(): void {
    switch (this.imageType) {
      case 'banner':
        this.imageAspectRatio = 17 / 7;
        this.imageAspectRatioText = '17 / 7';
        break;
      case 'tile':
        this.imageAspectRatio = 7 / 10;
        this.imageAspectRatioText = '7 / 10';
        break;
      case 'stream':
        this.imageAspectRatio = 16 / 11;
        this.imageAspectRatioText = '16 / 11';
        break;
      case 'recipe':
        this.imageAspectRatio = 3 / 2;
        this.imageAspectRatioText = '3 / 2';
        break;
      case 'avatar':
      case 'default':
        this.imageAspectRatio = 1 / 1;
        this.imageAspectRatioText = '1 / 1';
        break;
    }
  }

  getImageMaxWidth(): void {
    switch (this.imageType) {
      case 'avatar':
        this.imageMaxWidth = 164;
        break;
      case 'banner':
        this.imageMaxWidth = 1400;
        break;
      case 'tile':
        this.imageMaxWidth = 600;
        break;
      case 'stream':
        this.imageMaxWidth = 800;
        break;
      case 'recipe':
        this.imageMaxWidth = 540;
        break;
      case 'badge':
        this.imageMaxWidth = 164;
        break;
      case 'default':
        this.imageMaxWidth = 0;
        break;
    }
  }

  getImageMaxHeight(): void {
    switch (this.imageType) {
      case 'avatar':
        this.imageMaxHeigth = 164;
        break;
      case 'banner':
        this.imageMaxHeigth = 600;
        break;
      case 'tile':
        this.imageMaxHeigth = 800;
        break;
      case 'stream':
        this.imageMaxHeigth = 550;
        break;
      case 'recipe':
        this.imageMaxHeigth = 360;
        break;
      case 'badge':
        this.imageMaxWidth = 164;
        break;
      case 'default':
        this.imageMaxHeigth = 0;
        break;
    }
  }

  cropperSaveButtonClick(): void {
    this.showCropper = false;
    this.croppedImageVersion = this.image;
  }

  displayCropper(): void {
    this.showCropper = true;
  }

  saveGeneratedImage(image: string): void {
    this.imageChangedEvent = null;
    this.image = image;
    this.imageBase64 = image;
    this.croppedImageVersion = this.image;
    this.displayCropper();
    this.showCropperButton = true;
  }

  async cropperCancelButtonClick(): Promise<void> {
    const imageFile = await ImageTool.dataUrlToFile(this.croppedImageVersion, 'Cropped image');

    this.formControl.patchValue(imageFile);
    this.formControl.markAsDirty();
    this.image = this.croppedImageVersion;
    this.controlContainer.control.updateValueAndValidity();

    this.formControl.markAsTouched();
    this.showCropper = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  writeValue(obj: unknown): void {}
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn: unknown): void {}
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn: unknown): void {}

  private sendAnalytics(): void {
    this.gaService.gtag('event', GA_EVENT_IMAGE_UPLOAD, {
      profile_id: this.tokenService.getProfileId(),
      image_type: this.imageType,
    });
  }
}

interface ForbiddenTypeError {
  forbiddenType: {
    expected: string;
  };
}

interface ImageLimits {
  type: string;
}
