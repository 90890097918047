<button
  #button
  [type]="type"
  class="button button--{{ isDisabledLoginForm ? isDisabledLoginForm : role }} {{buttonClass}}"
  [ngClass]="{'small-on-tablet': isSmallOnTablet}"
  [disabled]="isDisabled"
  [class.action-button]="activeClass"
  [class.login-form-height]="isLoginForm"
>
  <span class="wrap">
    <ng-content select="[left-side]"></ng-content>
    <img
      *ngIf="iconUrl"
      class="button__icon button-reuse-icon"
      src="{{ iconUrl }}"
      alt="iconLike"
      [width]="iconWidth"
      [height]="iconHeight"
    />
    <div title="{{ text }}" class="active-span">{{ text }}</div>
    <ng-content select="[right-side]"></ng-content>
  </span>
</button>
