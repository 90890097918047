import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FAVORITES_URL } from '@kitch/data-access/constants';
import { AppPagesItem, FavoritesList, SimpleResponse } from '@kitch/data-access/models';
import { ShortFavoriteRecipeInfo, FavoriteRecipe } from '@kitch/data-access/models/favorite';
import { FavoriteSearchParams } from '@kitch/data-access/models/search-params/favorite';

import { makeUri } from '@kitch/util';

import { ApiService } from './api.service';

@Injectable()
export class FavoritesService {
  constructor(private apiService: ApiService) {}

  getAll(searchParams: FavoriteSearchParams): Observable<FavoritesList<FavoriteRecipe>> {
    return this.apiService.get(makeUri(FAVORITES_URL), searchParams);
  }

  addToFavorites(recipeId: string): Observable<ShortFavoriteRecipeInfo> {
    return this.apiService.post(FAVORITES_URL, { recipeId });
  }

  deleteFromFavorites(recipeId: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(FAVORITES_URL, recipeId));
  }

  getCheckedRecipesList(recipesList: ShortFavoriteRecipeInfo[]): Observable<AppPagesItem<ShortFavoriteRecipeInfo[]>> {
    return this.apiService.get(makeUri(FAVORITES_URL, 'check'), { recipesList: recipesList });
  }
}
