import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';
import { DASHBOARD_URL } from '../constants';
import { DashboardSchema } from '../models/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  getDashboardInfo(profileId: string): Observable<DashboardSchema> {
    return this.apiService.get(makeUri(DASHBOARD_URL, profileId));
  }
}
