import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-remote-login-modal',
  templateUrl: './remote-login-modal.component.html',
  styleUrls: ['./remote-login-modal.component.scss'],
})
export class RemoteLoginModalComponent implements OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() isOpen = false;

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.modal.open();
    } else {
      this.modal?.close();
    }
  }

  closePage(): void {
    this.modal.close();
    window.history.back();
  }

  reloadPage(): void {
    location.reload();
  }
}
