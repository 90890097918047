import { Component, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { WalmartGAEvents } from '@kitch/data-access/constants';
import { TokenService } from '@kitch/data-access/services';

interface WalmartGAAddIngredientsParams {
  clicked_on_page: string,
  modal?: boolean,
  profile_id: string,
  walmart_recipe_id: number
}

@Component({
  selector: 'app-walmart-button',
  templateUrl: './walmart-button.component.html',
  styleUrls: ['./walmart-button.component.scss'],
})
export class WalmartButtonComponent implements OnInit {
  @Input()
  readonly walmartRecipeId: number;

  @Input()
  readonly walmartRecipePortions: number;

  @Input()
  readonly title: string;

  @Input()
  readonly pageTypeButton: string;

  @Input()
  readonly isInModal = false;

  @Input()
  readonly hasShoppingCart = true;

  @Input()
  readonly isSmallOnTablet = false;

  isShoppingCardOpened = false;
  isLoading = false;
  isGuest: boolean;

  constructor(
    private tokenService: TokenService,
    private $gaService: GoogleAnalyticsService,
  ) { }

  ngOnInit() {
    this.isGuest = this.tokenService.isGuest();
  }

  openShoppingCart(): void {
    this.isShoppingCardOpened = true;
    this.isLoading = true;

    const gaParams: WalmartGAAddIngredientsParams = {
      profile_id: this.tokenService.getProfileId(),
      walmart_recipe_id: this.walmartRecipeId,
      clicked_on_page: this.pageTypeButton,
    };

    if (this.isInModal) {
      gaParams.modal = true;
    }

    this.$gaService.gtag('event', WalmartGAEvents.addIngredients, gaParams);
  }

  closeShoppingCart(): void {
    this.isShoppingCardOpened = false;
    this.isLoading = false;
  }

  trackClick(): void {
    const params = {
      profile_id: this.tokenService.getProfileId(),
    };

    this.$gaService.gtag('event', WalmartGAEvents.shopWithWalmartButtonClick, params);
  }
}
