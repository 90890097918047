import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { ClamsPlan } from '@kitch/data-access/models';
import { CheckoutInfo } from '@kitch/data-access/models/checkout-info';

export interface SelectedClamsPlan {
  clamsPlan: ClamsPlan;
  index: number;
}

@Component({
  selector: 'app-clams-plan',
  templateUrl: './clams-plan.component.html',
  styleUrls: ['./clams-plan.component.scss'],
})
export class ClamsPlanComponent implements OnInit {
  @Input() checkoutInfo: CheckoutInfo;
  @Input() clamsPlan: ClamsPlan;
  @Input() index: number;
  @Input() isSelected = false;

  @Output() clamsPlanSelect: EventEmitter<SelectedClamsPlan> = new EventEmitter<SelectedClamsPlan>();
  @Output() clamsPlanChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('click')
  onClick(): void {
    this.clamsPlanSelect.emit({
      clamsPlan: this.clamsPlan,
      index: this.index,
    });
  }

  changeQuantity(): void {
    this.clamsPlanChange.emit();
  }
}
