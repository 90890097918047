import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortNumber' })
export class ShortNumberPipe implements PipeTransform {
  transform(value: number): unknown {
    if (value < 1000) {
      return value;
    } else {
      return `${this.decimalAdjust(value / 1000, -1)}K`;
    }
  }

  // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Math/round
  private decimalAdjust(val, exp) {
    let value = val;

    if (typeof exp === 'undefined' || +exp === 0) {
      return Math.floor(value);
    }

    value = value.toString().split('e');
    value = Math.floor(+(`${value[0]}e${value[1] ? (+value[1] - exp) : -exp}`));
    value = value.toString().split('e');

    return +(`${value[0]}e${value[1] ? (+value[1] + exp) : exp}`);
  }
}
