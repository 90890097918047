const MOBILE_MEDIA_QUERY = 480;
const TABLET_MEDIA_QUERY = 768;

export class DeviceBreakpointTool {
  static isTablet(innerWidth: number): boolean {
    return innerWidth <= TABLET_MEDIA_QUERY && innerWidth > MOBILE_MEDIA_QUERY;
  }

  static isMobile(innerWidth: number): boolean {
    return innerWidth <= MOBILE_MEDIA_QUERY;
  }

  static isDesktop(innerWidth: number): boolean {
    return innerWidth > TABLET_MEDIA_QUERY;
  }
}
