import { Pipe, PipeTransform } from '@angular/core';
import { TransactionCurrency } from '@kitch/data-access/models';
import { getRoundedPrice } from '@kitch/util';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(amount: number, currency: TransactionCurrency, showCurrency = true): string {
    let clamsCurrencyLabel = '';
    let roundedAmount;

    if (amount != null && currency) {
      switch (currency) {
        case 'CLAMS':
          roundedAmount = getRoundedPrice(amount, 0);
          if (showCurrency) {
            clamsCurrencyLabel = Math.abs(roundedAmount) === 1 ? 'Clam' : 'Clams';
          }

          return `${roundedAmount} ${clamsCurrencyLabel}`;
        case 'USD':
          return `$${getRoundedPrice(amount, 2)}`;
        default:
          console.error('Unexpected currency: ', currency);

          return `${amount}`;
      }
    }
  }
}
