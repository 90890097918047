import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StoreFulfillmentType, StoreLoadStatus, WalmartStore, WalmartStoresObject } from '@kitch/data-access/models';
import { WalmartService } from '@kitch/data-access/services';
import { ZipCodeValidator } from '@kitch/util/validators';

@Component({
  selector: 'app-stores-list',
  templateUrl: 'stores-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['../shopping-cart.component.scss'],
})

export class StoresListComponent implements OnInit {
  private readonly storeSearchControlName = 'storeSearch';
  private readonly storeControlName = 'store';

  storesListForm: UntypedFormGroup;
  storeLoadStatus: StoreLoadStatus = StoreLoadStatus.HAS_STORES;
  StoreLoadStatus = StoreLoadStatus;
  StoreFulfillmentType = StoreFulfillmentType;

  @Input() stores: WalmartStore[];

  @Input() currentStore: WalmartStore;

  @Output()
  readonly closed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly choseStore: EventEmitter<WalmartStoresObject> = new EventEmitter<WalmartStoresObject>();

  constructor(
    private readonly walmartService: WalmartService,
    public fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initStoresListForm();
  }

  get isSaveDisabled(): boolean {
    return !this.storesListForm.get(this.storeControlName).valid;
  }

  closeStoreList(): void {
    this.closed.emit();
  }

  initStoresListForm(): void {
    this.storesListForm = this.fb.group({
      store: new UntypedFormControl(this.currentStore.fulfillmentStoreId, [Validators.required]),
      storeSearch: new UntypedFormControl(null, [ZipCodeValidator]),
    });

    this.subscribeOnZipCodeSearchInput();
  }

  onSearchEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.storesListForm.get(this.storeSearchControlName).markAsTouched();
    }
  }

  saveStore(): void {
    const chosenStore = this.stores.find((store) => {
      return store.fulfillmentStoreId === this.storesListForm.get(this.storeControlName).value;
    });

    this.choseStore.emit({
      stores: this.stores,
      currentStore: chosenStore,
    });
  }

  getAddress(store: WalmartStore): string {
    const { addressLineOne, city, state, postalCode } = store.serviceAddress;

    return `${addressLineOne}, ${city}, ${state}${postalCode}`;
  }

  private subscribeOnZipCodeSearchInput(): void {
    const zipCodeControl = this.storesListForm.get(this.storeSearchControlName);

    zipCodeControl.valueChanges.subscribe(zipCode => {
      if (zipCodeControl.valid) {
        this.storeLoadStatus = this.StoreLoadStatus.LOADING;
        this.storesListForm.get(this.storeControlName).patchValue(null);
        this.walmartService.getStores({ zipCode })
          .subscribe((stores: WalmartStore[]) => {
            if (stores.length) {
              this.stores = stores;
              this.storeLoadStatus = this.StoreLoadStatus.HAS_STORES;
              this.cdr.markForCheck();
            } else {
              // no stores for given ZIP-code
              this.storeLoadStatus = this.StoreLoadStatus.EMPTY;
              this.cdr.markForCheck();
            }
          },
          () => {
            // invalid ZIP-code
            this.storeLoadStatus = this.StoreLoadStatus.EMPTY;
            this.cdr.markForCheck();
          });
      }
    });
  }
}
