<ul class="questions-container">
  <li class="question__item question__item--first">
    <p class="access">
      <span class="access__text">Are you a Chef/Creator? Apply for&nbsp;
        <a
          class="link"
          data-testid="access-link"
          href="https://6hjii32gwyh.typeform.com/to/GHujEy5T?typeform-source=kittch.com"
          target="_blank" rel="noopener"
        >access</a>.
      </span>
    </p>
  </li>
  <li class="question__item">
    <p class="contact">
      Need help?&nbsp;
      <a
        class="link"
        data-testid="contact-us-link"
        href="mailto:support@kittch.com"
        rel="nofollow"
      >Contact Us</a>.
      By signing up, I accept<br>
      Kittch's&nbsp;
      <a
        class="link"
        data-testid="terms-of-use-link"
        [routerLink]="'/terms-of-use'"
        target="_blank"
        rel="nofollow"
      >Terms of Service&nbsp;</a>
      and&nbsp;
      <a
        class="link"
        data-testid="privacy-policy-link"
        [routerLink]="'/privacy-policy'"
        target="_blank"
        rel="nofollow"
      >Privacy Statement.</a>
    </p>
  </li>

  <li *ngIf="isPhoneShown" class="question__item question__item--captcha">
    <p class="contact">
      Protected by reCAPTCHA&nbsp;
      <a
        class="link"
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noopener"
      >Privacy</a>&nbsp;
      <a
        class="link"
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noopener"
      >Terms</a>.
    </p>
  </li>
</ul>
