<form [formGroup]="form" class="form">
  <ul class="form-items">
    <li class="form-item">
      <label class="form-label" for="formControlNameFirst">text Block 1</label>
      <div class="form-input-wrap form-input-wrap-editor">
        <app-wysiwyg-editor
          #firstInput
          [formControlName]="formControlNameFirst"
          [errorMessage]="isInvalidFirst"
          [isRequired]="isRequired"
          [maxLength]="maxLength"
          [errorName]="firstInputErrorName"
          [isEditable]="isEditable"
          id="formControlNameFirst"
          (textValue)="setValueFirst($event)"
          (valueWithHtml)="setFirstValueWithHtml($event)"
        ></app-wysiwyg-editor>
      </div>
    </li>
    <li class="form-item">
      <label class="form-label" for="formControlNameSecond">text Block 2</label>
      <div class="form-input-wrap form-input-wrap-editor">
        <app-wysiwyg-editor
          #secondInput
          [formControlName]="formControlNameSecond"
          [errorMessage]="false"
          [isRequired]="false"
          [maxLength]="maxLength"
          [errorName]="secondInputErrorName"
          [isEditable]="isEditable"
          id="formControlNameSecond"
          (textValue)="setValueSecond($event)"
          (valueWithHtml)="setSecondValueWithHtml($event)"
        ></app-wysiwyg-editor>
      </div>
    </li>
  </ul>
</form>
