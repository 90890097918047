import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { USER_LOGGED_FOR_TYPEFORM } from '@kitch/data-access/models';
import { AuthService } from '@kitch/data-access/services';
import { Socials } from '@kitch/user/shared/constants/social';

declare let google: any;

@Component({
  selector: 'app-social-buttons',
  templateUrl: './social-buttons.component.html',
  styleUrls: ['./social-buttons.component.scss'],
})
export class SocialButtonsComponent implements AfterViewInit {
  @ViewChild('googleBtn', { static: false }) googleBtn: ElementRef;

  readonly Socials = Socials;

  @Input()
  readonly isLogin: boolean;

  @Output()
  readonly metaBtnClick: EventEmitter<Socials> = new EventEmitter<Socials>();

  get actionText(): string {
    return this.isLogin ? 'Login' : 'Sign up';
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly authService: AuthService,
  ) {}

  ngAfterViewInit(): void {
    this.authService.initGoogleSSO();
    const screenWidth = window.innerWidth;

    if (isPlatformBrowser(this.platformId)) {
      google.accounts.id.renderButton(
        this.googleBtn.nativeElement,
        { size: 'large',
          type: 'standard',
          shape: 'pill',
          width: screenWidth > 550 ? 400 : 310,
          text: this.isLogin ? 'signin_with' : 'signup_with',
          locale: 'en_US',
          logo_alignment: 'left' },
      );
    }
  }

  loginWithMeta(socialName: Socials): void {
    sessionStorage.setItem(USER_LOGGED_FOR_TYPEFORM, 'true');
    this.metaBtnClick.emit(socialName);
  }
}
