import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, ObservableInput } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NOT_FOUND } from '@kitch/data-access/constants';
import { OldRoute } from '@kitch/data-access/models';
import { ShortProfileInfo } from '@kitch/data-access/models/profile';
import { ProfilesService, RecipesService, StreamsService } from '@kitch/data-access/services';

@Injectable()
export class OldRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private profileService: ProfilesService,
    private streamsService: StreamsService,
    private recipesService: RecipesService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> | boolean | UrlTree {
    switch (route.data.oldRoute) {
      case OldRoute.VIEW_CHANNEL:
      case OldRoute.VIEW_CHANNEL_RECIPES:
      case OldRoute.VIEW_CHANNEL_STREAMS:
        return this.redirectToChannelRoute(route.params.id, route.data.oldRoute);
      case OldRoute.VIEW_STREAM:
        return this.redirectToStreamPage(route.params.id);
      case OldRoute.VIEW_RECIPE:
        return this.redirectToRecipePage(route.params.id);
      default:
        return true;
    }
  }

  private redirectToChannelRoute(channelId: string, oldRoute: OldRoute) {
    return this.profileService.getUserProfile(channelId)
      .pipe(
        map((profiles) => profiles.results[0]),
        map((profile) => {
          if (profile) {
            this.router.navigate([this.getNewRoute(oldRoute, profile)]);
          } else {
            this.router.navigate(['/not-found'], { replaceUrl: true });
          }

          return false;
        }),
        catchError((error) => this.catchError(error)),
      );
  }

  private redirectToStreamPage(streamId: string) {
    return this.streamsService.getById(streamId)
      .pipe(
        map((stream) => {
          if (stream) {
            this.router.navigate([`/${stream.channel.chefProfile.chefSlug}/streams/${stream.slug}`]);
          } else {
            this.router.navigate(['/not-found'], { replaceUrl: true });
          }

          return false;
        }),
        catchError((error) => this.catchError(error)),
      );
  }

  private redirectToRecipePage(recipeId: string) {
    return this.recipesService.getById(recipeId)
      .pipe(
        map((recipe) => {
          if (recipe) {
            this.router.navigate([`/${recipe.profile.chefSlug}/recipes/${recipe.slug}`]);
          } else {
            this.router.navigate(['/not-found'], { replaceUrl: true });
          }

          return false;
        }),
        catchError((error) => this.catchError(error)),
      );
  }

  private getNewRoute(oldRoute: OldRoute, profile: ShortProfileInfo): string {
    switch (oldRoute) {
      case OldRoute.VIEW_CHANNEL:
        return `/${profile.chefSlug}`;
      case OldRoute.VIEW_CHANNEL_RECIPES:
        return `/${profile.chefSlug}/recipes`;
      case OldRoute.VIEW_CHANNEL_STREAMS:
        return `/${profile.chefSlug}/streams`;
    }
  }

  private catchError(error): ObservableInput<any> {
    const errorStatus = error.statusCode || error.status;

    if (errorStatus === NOT_FOUND) {
      this.router.navigate(['/not-found'], { replaceUrl: true });
    }

    throw error;
  }
}
