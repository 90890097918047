export enum ResourceType {
  CHANNEL = 'channel',
  RECIPE = 'recipe',
  VIDEO = 'video',
}

export interface TrackingData {
  resourceId: string,
  resourceType: ResourceType
}

export interface DefaultTrackingData extends TrackingData {
  profileId: string,
}

export interface PageViewData extends DefaultTrackingData {
  pageViews: number;
}

export interface PageTimeData extends DefaultTrackingData {
  pageTime: number;
}
