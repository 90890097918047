export const REF_CODE_KEY = 'REF_CODE';
export const REDIRECT_URL_KEY = 'REDIRECT_URL';
export const ACTION_AFTER_LOGIN_KEY = 'ACTION_AFTER_LOGIN_KEY';
export const STREAM_ID = 'STREAM_ID';
export const CREATED_STREAM_ID = 'CREATED_STREAM_ID';
export const SHOPPING_CART_KEY = 'WALMART_SHOPPING_CART';
export const OLD_USER_KEY = 'OLD_USER_KEY';
export const REWARDS_TYPE = 'REWARDS_TYPE';
export const TOP_BANNER_CLOSED = 'TOP_BANNER_CLOSED';
export const WALMART_POPUP_CLOSED = 'WALMART_POPUP_CLOSED';
export const SAVE_SPOT_STREAMS = 'SAVE_SPOT_STREAMS';
export const USER_LOGGED_FOR_TYPEFORM = 'USER_LOGGED_FOR_TYPEFORM';
