<section *ngIf="!isLoading" class="form-wrap">
  <div class="header">
    <h2 class="header__title">
      {{this.isCreateRecipe ? 'Add new Recipe' : 'Edit Recipe'}}
    </h2>
    <div class="header__buttons">
      <app-button
        class="save"
        [text]="'save'"
        [type]="'button'"
        (clicked)="saveRecipe()"
      ></app-button>
      <app-button
        class="publish"
        [text]="isRecipePublished ? 'unpublish' : 'publish'"
        [type]="'button'"
        (clicked)="saveRecipe(true)"
      ></app-button>
      <app-button
        class="cancel"
        [text]="'cancel'"
        [role]="'secondary'"
        [type]="'button'"
        (clicked)="cancelStream()"
      ></app-button>
    </div>
  </div>

  <div class="form">
    <form [formGroup]="recipeForm">
      <!-- EXTERNAL SECTION >> -->
      <ng-container *ngIf="isAdminForm">
        <!-- TOGGLE-SLIDE EXTERNAL RECIPE >> -->
        <div
          *ngIf="isCreateRecipe"
          class="entity-form__slide-toggle-container entity-form__slide-toggle-container--margin-bottom"
        >
          <app-slide-toggle
            class="entity-form__slide-toggle"
            [isSelected]="recipeIsExternal"
            (statusSlideInput)="setExternalStatus($event)"
          ></app-slide-toggle>
          <h3 class="entity-form__title">External recipe</h3>
        </div>
        <!-- << TOGGLE-SLIDE EXTERNAL RECIPE -->

        <ng-container *ngIf="recipeIsExternal">
          <!-- RECIPE SOURCE >> -->
          <div class="form__item">
            <label class="form-label" for="title">Recipe Source*</label>
            <div class="form-input-wrap">
              <input
                formControlName="primaryName"
                appWasInvolved
                class="form-input"
                id="primaryName"
                placeholder="Enter recipe source"
              />
              <app-errors-list formControlName="primaryName"></app-errors-list>
            </div>
          </div>
          <!-- << RECIPE SOURCE -->

          <!-- RECIPE CHEF/CREATOR >> -->
          <div class="form__item">
            <label class="form-label" for="title">Recipe Chef/Creator</label>
            <div class="form-input-wrap">
              <input
                formControlName="secondaryName"
                appWasInvolved
                class="form-input"
                id="secondaryName"
                placeholder="Enter chef/creator name"
              />
            </div>
          </div>
          <!-- << RECIPE CHEF/CREATOR -->
        </ng-container>

        <div class="entity-form__separator"></div>
      </ng-container>
      <!-- << EXTERNAL SECTION -->

      <div class="form-items">
        <!-- RECIPE INFORMATION >> -->
        <div class="entity-form">
          <h3
            class="entity-form__title entity-form__title--margin-bottom"
          >RECIPE INFORMATION</h3>

          <!-- CHOOSE CHEF >> -->
          <div *ngIf="isAdminForm && !recipeIsExternal"  class="form__item">
            <label class="form-label" for="title">Choose Chef*</label>
            <div class="form-input-wrap form-select-wrap">
              <div class="select-wrap select-wrap--chefs-list">
                <select
                  appWasInvolved
                  name="profileId"
                  class="select-wrap__select"
                  formControlName="profileId"
                >
                  <option [ngValue]="null" selected hidden>Select chef</option>
                  <option *ngFor="let chef of chefs" [ngValue]="chef.id">
                    {{ chef.chefName }}
                  </option>
                </select>
              </div>
              <app-errors-list formControlName="profileId"></app-errors-list>
            </div>
          </div>
          <!-- << CHOOSE CHEF -->

          <!-- RECIPE TITLE >> -->
          <div class="form__item">
            <label class="form-label" for="title">Recipe Title*</label>
            <div class="form-input-wrap">
              <input
                formControlName="recipeName"
                appWasInvolved
                class="form-input"
                id="title"
                placeholder="Text"
              />
              <app-errors-list formControlName="recipeName"></app-errors-list>
            </div>
            <div
              *ngIf="isCreateRecipe && !recipeForm.get('recipeName').hasError('forbiddenSlug') && recipeSlug"
              class="item__description"
            >Recipe url name will be: {{ recipeSlug }}</div>
          </div>
          <!-- << RECIPE TITLE -->

          <!-- DESCRIPTION >> -->
          <div class="form__item">
            <label class="form-label" for="description">Description*</label>
            <div class="form-input-wrap form-input-wrap-editor">
              <app-wysiwyg-editor
                [errorMessage]="isDescriptionInvalid"
                [extraToolBarElements]="['link']"
                formControlName="description"
                id="description"
              ></app-wysiwyg-editor>
            </div>
          </div>
          <!-- << DESCRIPTION -->

          <!-- << CHEF RECOMMENDED -->
          <div class="form__item" *ngIf="isAdminForm">
            <mat-checkbox
              class="checkbox-row"
              formControlName="isChefRecommended"
            >Chef Recommended</mat-checkbox>
          </div>
          <!-- << CHEF RECOMMENDED -->
        </div>
        <!-- << RECIPE INFORMATION -->

        <div class="entity-form__separator"></div>

        <!-- UPLOAD RECIPE IMAGE >> -->
        <div class="entity-form">
          <h3
            class="entity-form__title entity-form__title--margin-bottom"
          >upload recipe image*</h3>

          <div class="form__item">
            <app-image-chooser
              [imageAlt]="'Meal photo'"
              [hasDeleteBtn]='false'
              [imageType]="'recipe'"
              formControlName="mealPhoto"
              isAdmin="true"
              [showImageCreator]="true"
              [dishName]="recipeForm.get('recipeName').value"
            ></app-image-chooser>
            <app-errors-list formControlName="mealPhoto"></app-errors-list>
          </div>
        </div>
        <!-- << UPLOAD RECIPE IMAGE -->

        <div class="entity-form__separator"></div>

        <!-- RECIPE DETAILS >> -->
        <div class="entity-form">
          <h3
            class="entity-form__title entity-form__title--margin-bottom"
          >Recipe details*</h3>

          <div class="portions-time-container">
            <!-- SERVING SIZE >> -->
            <div class="portions portions--serves">
              <label class="form-label portions-title" for="title">Serves*</label>
              <div class="form-input-wrap portions-wrap">
                <input
                  formControlName="portions"
                  appWasInvolved
                  class="form-input portions-input"
                  id="portions"
                  type="number"
                  placeholder="Servings"
                  min="1"
                />
                <app-errors-list class="portions-error" formControlName="portions"></app-errors-list>
              </div>
            </div>
            <!-- << SERVING SIZE -->

            <!-- TOTAL TIME >> -->
            <div class="portions portions--time">
              <label class="form-label portions-title" for="title">Total Time*</label>
              <div class="form-input-wrap portions-wrap">
                <input
                  formControlName="totalTime"
                  appWasInvolved
                  class="form-input portions-input"
                  id="totalTime"
                  type="number"
                  min="0.5"
                  step="0.5"
                />
                <app-errors-list class="portions-error" formControlName="totalTime"></app-errors-list>
              </div>
            </div>
            <!-- << TOTAL TIME -->

            <!-- HRS / MIN  >> -->
            <div class="portions portions--hrs-min">
              <div class="select-wrap">
                <select
                  name="hrsMin"
                  class="select-wrap__select select-wrap__select--hrs-min"
                  formControlName="hrsMin"
                >
                  <option *ngFor="let timeOption of hrsMinOptions" [value]="timeOption">
                    {{ timeOption }}
                  </option>
                </select>
              </div>
            </div>
            <!-- << HRS / MIN  -->
          </div>

          <!-- DIFFICULTY & SPICE >> -->
          <div class="form-item form-item--spice-difficulty">
            <!-- DIFFICULTY >> -->
            <div class="form-input-wrap form-input-wrap--difficulty form-select-wrap">
              <label
                for="difficulty"
                class="difficulty-title"
              >
                Difficulty level*
              </label>
              <div class="select-wrap">
                <select
                  appWasInvolved
                  name="difficulty"
                  id="difficulty"
                  class="select-wrap__select"
                  formControlName="difficulty"
                >
                  <option
                    [ngValue]="null"
                    selected
                    disabled
                    hidden
                  >Select</option>
                  <option *ngFor="let difficulty of tags?.difficulty" [ngValue]="difficulty">
                    {{ difficulty.name }}
                  </option>
                </select>
              </div>
              <app-errors-list
                class="errors"
                errorName="difficulty level"
                inputType="SELECT"
                formControlName="difficulty"
              ></app-errors-list>
            </div>
            <!-- << DIFFICULTY -->

            <!-- SPICE >> -->
            <div class="form-input-wrap form-input-wrap--spice form-select-wrap">
              <label
                for="spice"
                class="spice-title"
              >
                Spice level
              </label>
              <div class="select-wrap">
                <select
                  appWasInvolved
                  name="spice"
                  id="spice"
                  class="select-wrap__select"
                  formControlName="spice"
                >
                  <option
                    [ngValue]="null"
                    selected
                    disabled
                    hidden
                  >Select</option>
                  <option *ngFor="let spice of tags?.spice" [ngValue]="spice">
                    {{ spice.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- << SPICE -->
          </div>
          <!-- << DIFFICULTY & SPICE -->
        </div>
        <!-- << RECIPE DETAILS -->

        <div class="entity-form__separator"></div>

        <!-- INGREDIENTS >> -->
        <div class="entity-form">
          <h3
            class="entity-form__title entity-form__title--margin-bottom"
          >Ingredients*</h3>

          <!-- TOGGLE-SLIDE INGREDIENTS FREE FORM >> -->
          <div
            class="entity-form__slide-toggle-container entity-form__slide-toggle-container--margin-bottom"
          >
            <app-slide-toggle
              class="entity-form__slide-toggle"
              [isSelected]="isIngredientFreeFormShown"
              (statusSlideInput)="setIngredientFreeFormVisibility($event)"
            ></app-slide-toggle>
            <span class="entity-form__slide-toggle-text">Enter ingredients in free form</span>
          </div>
          <!-- << TOGGLE-SLIDE INGREDIENTS FREE FORM -->

          <!-- INGREDIENTS FREE FORM >> -->
          <ng-container *ngIf="isIngredientFreeFormShown; else newIngredientsList" >
            <div  class="form__item">
              <div class="form-input-wrap form-input-wrap-editor">
                <app-wysiwyg-editor
                  [errorMessage]="isIngredientsOldInvalid"
                  [extraToolBarElements]="['link']"
                  formControlName="ingredients"
                ></app-wysiwyg-editor>
              </div>
            </div>
          </ng-container>
          <!-- << INGREDIENTS FREE FORM -->

          <!-- NEW STYLE INGREDIENTS >> -->
          <ng-template #newIngredientsList>
            <div class="form__item">
              <div formArrayName="listIngredients" class="ingredients-container">
                <app-errors-list class="ingredients-errors" [control]="listIngredientsControl"></app-errors-list>
                <ng-container *ngFor="let ingredient of listIngredientsControl.controls; let i = index">
                  <div class="ingredient" [formGroupName]="i">
                    <div class="form-input-wrap ingredient__product">
                      <p class="ingredient__title">Ingredient*</p>
                      <input
                        formControlName="product"
                        appWasInvolved
                        class="form-input"
                        id="product"
                        placeholder="Ingredient"
                      />
                      <app-errors-list formControlName="product"></app-errors-list>
                    </div>
                    <div class="form-input-wrap form-select-wrap ingredient__unit">
                      <p class="ingredient__title ingredient__title--left-indentation">Units*</p>
                      <div class="select-wrap">
                        <select
                          appWasInvolved
                          name="unit"
                          class="select-wrap__select"
                          formControlName="unit"
                        >
                          <option [ngValue]="null" selected hidden>Select</option>
                          <option *ngFor="let unit of ingredientUnits" [ngValue]="unit">
                            {{ unit | titlecase }}
                          </option>
                        </select>
                      </div>
                      <app-errors-list formControlName="unit"></app-errors-list>
                    </div>

                    <div class="form-input-wrap ingredient__unitValue">
                      <p class="ingredient__title ingredient__title--left-indentation">Amount*</p>
                      <input
                        formControlName="unitValue"
                        appWasInvolved
                        class="form-input"
                        id="unitValue"
                      />
                      <app-errors-list formControlName="unitValue"></app-errors-list>
                    </div>
                    <div class="form-input-wrap ingredient__description">
                      <p class="ingredient__title ingredient__title--left-indentation">Ingredient&nbsp;Desc</p>
                      <input
                        formControlName="ingredientDescription"
                        class="form-input"
                        placeholder="Chopped, minced, etc."
                      />
                    </div>
                    <button class="ingredient__delete-btn" (click)="deleteIngredient(i)">
                      <img
                        [src]="'assets/ui/images/svg/basket.svg'"
                        alt="delete"
                        width="18"
                        height="18"
                      />
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
            <app-button
              class="add-ingredient-btn"
              [text]="'Add ingredient'"
              [role]="'add-form-item'"
              [type]="'button'"
              [iconUrl]="'assets/ui/images/svg/plus.svg'"
              [iconHeight]="24"
              [iconWidth]="24"
              (clicked)="addIngredient()"
            ></app-button>
          </ng-template>
          <!-- << NEW STYLE INGREDIENTS -->
        </div>
        <!-- << INGREDIENTS -->

        <!-- SHOPPABLE TOGGLE >> -->
        <ng-container *ngIf="hasNewIngredients && isAdminForm">
          <div class="entity-form__separator"></div>

          <div class="entity-form">
            <div class="entity-form__slide-toggle-container">
              <app-slide-toggle
                class="entity-form__slide-toggle"
                [isSelected]="isShoppable"
                (statusSlideInput)="setShoppableStatus($event)"
              ></app-slide-toggle>
              <h3 class="entity-form__title">MAKE THIS RECIPE SHOPPABLE</h3>
            </div>
          </div>
        </ng-container>
        <!-- << SHOPPABLE TOGGLE -->

        <div class="entity-form__separator"></div>

        <!-- PREPARATION >> -->
        <div class="entity-form">
          <h3
            class="entity-form__title entity-form__title--margin-bottom"
          >preparation*</h3>

          <div
            *ngIf="isStepsInvalid && !recipeForm.get('listSteps').value?.length"
            class="form-error__text form-error__text--required"
          >This field is required</div>

          <div formArrayName="listSteps">
            <div
              *ngFor="let step of recipeForm | formArrayControls: 'listSteps'; index as index"
              class="form__item"
              [formGroupName]="index"
            >
              <label class="form-label" for="step-{{index}}">{{ step.value.type | titlecase }}</label>
              <div class="recipe-form-preparation__input-wrap">
                <div
                  *ngIf="step.value.type === 'step'; else headerOfStepTmpl"
                  class="form-input-wrap form-input-wrap-editor"
                >
                  <app-wysiwyg-editor
                    id="step-{{index}}"
                    formControlName="value"
                    [errorMessage]="isStepsInvalid && !this.step.valid"
                    [isRequired]="true"
                    [extraToolBarElements]="['link']"
                    [placeholder]="index ? 'Mix together onions, garlic…' : 'Pre-heat oven to 375C degrees'"
                  ></app-wysiwyg-editor>
                </div>

                <ng-template #headerOfStepTmpl>
                  <div class="form-input-wrap">
                    <input
                      id="step-{{index}}"
                      formControlName="value"
                      class="form-input"
                    />
                  </div>
                </ng-template>

                <button class="recipe-form-preparation__delete-btn" (click)="deleteStep(index)">
                  <img
                    class="delete-btn__icon"
                    src="assets/ui/images/svg/recipe-step-delete.svg"
                    alt="delete"
                    width="24"
                    height="24"
                  />
                </button>
              </div>
            </div>
          </div>

          <div class="recipe-form-preparation__buttons">
            <app-button
              class="recipe-form-preparation__add-step-btn"
              text="Add Step"
              [role]="'secondary-white--black-text'"
              [type]="'button'"
              [iconUrl]="'assets/ui/images/svg/plus-channel.svg'"
              [iconHeight]="16"
              [iconWidth]="16"
              (clicked)="addStep()"
            ></app-button>

            <app-button
              class="recipe-form-preparation__add-step-btn"
              text="Bulk Add"
              [role]="'add-form-item'"
              [type]="'button'"
              [iconUrl]="'assets/ui/images/svg/bulk-add.svg'"
              [iconHeight]="14"
              [iconWidth]="14"
              (clicked)="bulkAdd()"
            ></app-button>
          </div>
        </div>
        <!-- << PREPARATION -->

        <!-- ADD COLLABORATORS >> -->
        <ng-container *ngIf="!recipeIsExternal">
          <div class="entity-form__separator"></div>

          <div class="entity-form">
            <h3
              class="entity-form__title entity-form__title--margin-bottom"
            >
              Add Collaborators
              <span class="form__title--optional">(Optional)</span>
            </h3>

            <div class="form__item">
              <div class="form-select-wrap__container">
                <app-select-dropdown-with-avatar
                  placeholder="Search..."
                  maxSelectedItems="2"
                  [items]="allChefsAsCollaborators"
                  [selectedItems]="collaboratorIds"
                  (selectedOptions)="setSelectedCollaborators($event)"
                  (searchItems)="searchCollaborators($event)"
                  (changePage)="loadMoreCollaborators($event)"
                ></app-select-dropdown-with-avatar>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- << ADD COLLABORATORS -->

        <!-- ATTACH STREAM >> -->
        <ng-container *ngIf="!recipeIsExternal">
          <div class="entity-form__separator"></div>

          <div class="entity-form">
            <h3
              class="entity-form__title entity-form__title--margin-bottom"
            >
              Attach stream
              <span class="form__title--optional">(Optional)</span>
            </h3>

            <div>
              <div class="search-input-wrap">
                <input
                  #streamsSearch
                  class="search-input guests-search-input"
                  placeholder="Search streams"
                />
              </div>

              <div class="streams-list">
                <cdk-virtual-scroll-viewport
                  class="streams-list__scroll-viewport"
                  appendOnly
                  itemSize="81"
                >
                  <app-stream-list-item
                    *cdkVirtualFor="let stream of allStreams; templateCacheSize: 0"
                    [stream]="stream"
                    [isDisabled]="isMaxStreamsAttached && !stream.addedToRecipe"
                    (changeStatus)="changeStreamsList($event)"
                  ></app-stream-list-item>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- << ATTACH STREAM -->

        <div class="entity-form__separator"></div>

        <!-- ADD TAGS SECTION >> -->
        <div class="entity-form">
          <h3
            class="entity-form__title entity-form__title--margin-bottom"
          >Add tags</h3>

          <div *ngIf="isCreateRecipe" class="default-categories-notification">
            We’ve copied your categories from your channel page, but feel free to change them.
          </div>

          <!-- PRIMARY CUISINES >> -->
          <div class="form-item form-item--cuisines">
            <label class="form-label">Cuisines*</label>
            <div class="form-input-wrap form-select-wrap">
              <div class="form-select-wrap__container">
                <app-select-dropdown
                  [items]="cuisines.primary"
                  placeholder="e.g. Indian, American, Italian (up to 3)"
                  name="primaryCuisines"
                  formControlName="primaryCuisines"
                  [selectedItems]="primarySelectedCuisines"
                  (selectedOptions)="setSelectedCuisines($event)"
                ></app-select-dropdown>
              </div>
              <div *ngIf="recipeForm.get('primaryCuisines').value?.length === 3" class="form-select-wrap__label">
                Max number of 3 reached
              </div>
              <app-errors-list
                class="errors"
                errorName="cuisines"
                inputType="MULTISELECT"
                formControlName="primaryCuisines"
              >
              </app-errors-list>
            </div>
          </div>
          <!-- << PRIMARY CUISINES -->

          <!-- SUB CUISINES >> -->
          <div class="form-item form-item--cuisines">
            <label class="form-label">Sub-cuisine</label>
            <div class="form-input-wrap form-select-wrap">
              <div
                class="form-select-wrap__container"
                [ngClass]="{ 'form-select-wrap__container--disabled': !availableSubCuisines.length }"
              >
                <app-select-dropdown
                  [items]="availableSubCuisines"
                  placeholder="e.g. Jewish, British, Mexican-American (up to 3)"
                  name="subCuisines"
                  formControlName="subCuisines"
                  [selectedItems]="selectedSubCuisines"
                  (selectedOptions)="setSelectedSubCuisine($event)"
                  [isDisabled]="!availableSubCuisines.length"
                ></app-select-dropdown>
              </div>
              <div
                *ngIf="recipeForm.get('subCuisines')?.value?.length + newSubCuisines?.length === 3"
                class="form-select-wrap__label"
              >
                Max number of 3 reached
              </div>
              <div
                *ngIf="recipeForm.get('subCuisines')?.value?.length < 3"
                class="form-input-hint form-input-hint--select"
              >
                Optional
              </div>
            </div>
          </div>
          <!-- << SUB CUISINES -->

          <!-- TAGS >> -->
          <app-tags
            #tagsSection
            [tags]="tags"
            [tagTypes]="specialtyTagTypes"
            [selectedTagsIds]="selectedSpecialtyTagsIds"
          ></app-tags>
          <!-- << TAGS -->
        </div>
        <!-- << ADD TAGS SECTION -->

        <!-- DELETE RECIPE >> -->
        <ng-template [ngIf]="!isAdminForm && !isCreateRecipe">
          <div class="entity-form__separator"></div>

          <div class="entity-form">
            <h3
              class="entity-form__title entity-form__title--margin-bottom"
            >Delete this recipe</h3>

            <div class="form__item">
              <div class="item__description">Hold on! Once you delete a recipe,
                it will be removed from your profile and any search results.
                This action cannot be undone.</div>
              <button
                class="form__delete-btn"
                type="button"
                (click)="deleteRecipe()"
              >Delete Recipe</button>
            </div>
          </div>
        </ng-template>
        <!-- << DELETE RECIPE -->
      </div>
    </form>
  </div>
</section>
