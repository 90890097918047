<app-modal #modal>
  <ng-container header>
    <app-register-step-page
      [textButton]="'LOGIN & GET COOKIN’'"
      [isNotApprove]="false"
      (openLoginForm)="openLoginModal()">
      <ng-container text>
        You’re now part of the Kittch community! You can now view recipes, save streams, follow chefs, and interact with chefs and users at any time.
      </ng-container>
    </app-register-step-page>
  </ng-container>
</app-modal>
