import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SETTINGS_URL } from '@kitch/data-access/constants';
import { SimpleResponse } from '@kitch/data-access/models';
import { makeUri } from '@kitch/util';
import { NotificationStatus, TypeFormStatus, SettingsStatus } from '../models/settings';
import { ApiService } from './api.service';

const defaultSettingStatus: SettingsStatus = {
  isPromotedGeneralContent: false,
  newContentNotification: false,
  isTypeformFilled: false,
  isTypeformModalCanBeShow: false,
  numberTypeformViews: 0,
};

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private settingsStatus = new BehaviorSubject<SettingsStatus>(defaultSettingStatus);

  constructor(private apiService: ApiService) {}

  get settingStatus$(): Observable<SettingsStatus> {
    return this.settingsStatus.asObservable();
  }

  changeSettingsStatus(settingData: SettingsStatus): void {
    this.settingsStatus.next(settingData);
  }

  getSettingsStatus(profileId: string): Observable<SettingsStatus> {
    return this.apiService.get(makeUri(SETTINGS_URL, profileId));
  }

  updateStatusNotifications(id: string, notificationStatus: Partial<NotificationStatus>): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(SETTINGS_URL, id), notificationStatus);
  }

  updateTypeformStatus(id: string, notificationStatus: TypeFormStatus): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(SETTINGS_URL, id), notificationStatus);
  }
}
