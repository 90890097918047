<val-errors [control]="formControl">
  <ng-template valError="serverError" let-error="error">
    {{ error }}
  </ng-template>
  <ng-template valError="customError" let-error="error">
    {{ error }}
  </ng-template>
  <ng-template valError="required">

    <ng-container [ngSwitch]="inputType">
      <ng-container *ngSwitchCase="InputTypes.FILE">
        Please add a {{ errorName }}
      </ng-container>
      <ng-container *ngSwitchCase="InputTypes.TEXT">
        Please enter a {{ errorName }}
      </ng-container>
      <ng-container *ngSwitchCase="InputTypes.SELECT">
        Please choose a {{ errorName }}
      </ng-container>
      <ng-container *ngSwitchCase="InputTypes.MULTISELECT">
        Please choose {{ errorName }}
      </ng-container>
      <ng-container *ngSwitchDefault>This field is required</ng-container>
    </ng-container>

   </ng-template>
  <ng-template valError="forbiddenType" let-error="error">
    This file type is not available here. Please, use the following: {{ error.expected }}
  </ng-template>
  <ng-template valError="minlength" let-error="error">
    Minimal length is {{ error.requiredLength }}
  </ng-template>
  <ng-template valError="maxlength" let-error="error">
    <ng-container *ngIf="maxLengthError === MaxLengthErrors.DEFAULT">
      Maximal length is {{ error.requiredLength }}
    </ng-container>
    <ng-container *ngIf="maxLengthError === MaxLengthErrors.WITHOUT_NUMBERS">
      You reached the max length
    </ng-container>
  </ng-template>
  <ng-template valError="digits">Only numbers</ng-template>
  <ng-template valError="letters">Only letters</ng-template>
  <ng-template valError="email">Wrong email format</ng-template>
  <ng-template valError="pattern">Not allowed characters</ng-template>
  <ng-template valError="forbiddenSlug">Please choose another name</ng-template>
  <ng-template valError="notValidRefCode">This invite code is not valid.</ng-template>
  <ng-template valError="invalidSocialNetwork">Invalid social URL</ng-template>
</val-errors>
