import { Properties as CarouselProperties } from './interfaces';

export class Utils {
  get images(): any {
    return this.carouselProperties.images;
  }

  get overflowCellsLimit(): number {
    if (this.images && this.isImagesLessCellLimit) {
      return Math.floor((this.images.length - this.visibleCellsCount) / 2);
    } else {
      return this.carouselProperties.overflowCellsLimit;
    }
  }

  get isImagesLessCellLimit(): boolean {
    return this.carouselProperties.overflowCellsLimit * 2 + this.visibleCellsCount > this.images.length;
  }

  get visibleCellsCount(): number {
    return Math.ceil(this.visibleWidth / this.fullCellWidth);
  }

  get fullCellWidth(): number {
    return this.carouselProperties.cellWidth + this.carouselProperties.margin;
  }

  get visibleWidth(): number {
    return this.carouselProperties.visibleWidth || this.carouselProperties.cellsElement.parentElement.clientWidth;
  }

  constructor(private carouselProperties: CarouselProperties) {

  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getStartX(event: any) {
    const touches = event.touches;
    const carouselElementPosition = this.getCarouselElementPosition().left;
    let startX;

    if (touches) {
      startX = touches[0].clientX - carouselElementPosition;
    } else {
      startX = event.clientX - carouselElementPosition;
    }

    return startX;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getMoveX(event: any) {
    const touches = event.touches;
    const carouselElementPositionX = this.getCarouselElementPosition().left;

    if (touches) {
      return touches[0].clientX - carouselElementPositionX;
    } else {
      return event.clientX - carouselElementPositionX;
    }
  }

  getCarouselElementPosition(): DOMRect {
    return this.carouselProperties.hostElement.getBoundingClientRect();
  }
}
