import { Pipe, PipeTransform } from '@angular/core';
import { convertMinutesToTime } from '@kitch/util';

@Pipe({
  name: 'convertMinutesToTime',
})
export class ConvertMinutesToTimePipe implements PipeTransform {
  transform(value: number): string {
    return convertMinutesToTime(value);
  }
}
