import { Role } from '@kitch/data-access/models/user';

export enum Token {
  AUTH = 'TOKEN',
  LOGIN = 'LOGIN_TOKEN',
}

export interface DecodedToken {
  exp: number;
  id: string;
  isAdmin: boolean;
  iss: 'https://login.api.kitch.cc' | 'https://api.kitch.cc';
  profileId: string;
  role: Role;
}

export interface LoginRequest {
  phoneNumber: string;
}

export interface LoginResponse {
  otp: string;
  token: string;
}

export interface VerifyAuthCodeRequest {
  otp: string;
}

export interface VerifyAuthCodeResponse {
  token: string;
}

export interface RefreshTokenResponse {
  refreshToken: string;
  token: string;
}

export interface LogoutResponse {
  success: boolean;
}

export interface GuestTokenResponse {
  refreshToken: string;
  schema: string;
  token: string;
}
