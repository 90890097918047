import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from '@kitch/data-access/constants';
import { PaginationParams } from '@kitch/data-access/models/search-params';

import { deepCopy } from '@kitch/util';

@Injectable()
export class SearchService {
  private searchParams: PaginationParams;
  private searchParams$ = new Subject<PaginationParams>();
  private defaultParams: PaginationParams = {
    page: DEFAULT_PAGE,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  };

  constructor() {
    this.searchParams = { ...this.defaultParams };
  }

  getSearchParams(): Observable<PaginationParams> {
    return this.searchParams$.asObservable();
  }

  searchByParams(params: PaginationParams): void {
    this.searchParams = { ...this.defaultParams, ...params };
    this.emitSearchParams();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  searchByKey(key: string, value: any): void {
    if (value === 'false') {
      delete this.searchParams[key];
    } else {
      this.searchParams[key] = value;
    }

    this.setDefaultPage();
    this.emitSearchParams();
  }

  changePage(page: number, itemsPerPage: number): void {
    this.searchParams.page = page;
    this.searchParams.itemsPerPage = itemsPerPage;
    this.emitSearchParams();
  }

  resetFilters(): void {
    this.searchParams = { ...this.defaultParams };
    this.emitSearchParams();
  }

  private setDefaultPage(): void {
    this.searchParams.page = DEFAULT_PAGE;
  }

  private emitSearchParams(): void {
    this.searchParams$.next(deepCopy(this.searchParams));
  }
}
