import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ButtonRole } from '@kitch/ui/models/custom-button-form';

type ButtonType = 'button' | 'submit';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @ViewChild('button', { static: true }) button: ElementRef<HTMLButtonElement>;

  @Input() type: ButtonType = 'button';
  @Input() role: ButtonRole = 'primary';
  @Input() text = 'Submit';
  @Input() iconUrl: string;
  @Input() isDisabled = false;
  @Input() activeClass: boolean;
  @Input() buttonClass: string;
  @Input() isDisabledLoginForm: string;
  @Input() isLoginForm: boolean;
  @Input() isSmallOnTablet? = false;
  @Input() iconHeight: number;
  @Input() iconWidth: number;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('click')
  onClick(): void {
    if (!this.isDisabled) {
      this.clicked.emit();
      this.button.nativeElement.blur();
    }
  }
}
