<div [id]="editorId" class="NgxEditor__Wrapper">
  <ngx-editor-menu [editor]="editor" [toolbar]="toolBar"> </ngx-editor-menu>
  <ngx-editor
    [editor]="editor"
    [placeholder]="placeholder"
    (focusOut)="onBlur()"
    (focusIn)="onFocus()"
  ></ngx-editor>
</div>
<div *ngIf="!validField && focused || errorMessage" class="errors">
  <ng-container *ngIf="errorName">
    <div class="form-error__text ng-star-inserted">Please enter a {{ errorName }}</div>
  </ng-container>
  <ng-container *ngIf="!errorName">
    <div class="form-error__text ng-star-inserted">This field is required</div>
  </ng-container>
</div>

<span *ngIf="maxLength" class="value-count">
  {{ editor.view.dom.textContent.length || 0 }}/{{ maxLength }}
</span>
