import { Component, Inject, Input, OnInit } from '@angular/core';
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { CommonUserRole, REF_CODE_KEY } from '@kitch/data-access/models';

@Component({
  selector: 'app-guest-buttons',
  templateUrl: './guest-buttons.component.html',
  styleUrls: ['./guest-buttons.component.scss'],
})
export class GuestButtonsComponent implements OnInit {
  @Input() source: string;

  isLoginModalOpened = false;
  isRegisterModalOpened = false;
  userRole: CommonUserRole = CommonUserRole.USER;
  refCode: string;

  constructor(
     @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {
  }

  ngOnInit() {
    this.refCode = this.sessionStorage.getItem(REF_CODE_KEY);
  }

  openLoginModal(): void {
    this.isLoginModalOpened = true;
  }

  openRegisterModal(): void {
    this.isRegisterModalOpened = true;
  }

  onLoginModalClosed(): void {
    this.isLoginModalOpened = false;
  }

  onRegisterModalClosed(): void {
    this.isRegisterModalOpened = false;
  }
}

