<app-modal #modal [showCloseIcon]="false">
  <ng-container header>
    <div class="title">You have another browser open with the same stream</div>
  </ng-container>

  <ng-container body>
    <div class="content">
      <p class="content__text">Please close this page or reload for the best experience.</p>
      <div class="content__buttons">
        <app-button
          class="success-btn"
          [text]="'Leave stream'"
          [type]="'button'"
          [role]="'secondary'"
          (clicked)="closePage()"
        >
        </app-button>

        <app-button
          class="reject-btn"
          [text]="'reload'"
          [type]="'button'"
          (clicked)="reloadPage()"
        >
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>

