import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable, InjectionToken, NgZone, PLATFORM_ID } from '@angular/core';
import { ParamMap, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, take, tap } from 'rxjs/operators';

import {
  GUEST_LOGIN_URL,
  INSTAGRAM_LOGIN_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REF_CODE_VALIDATE_URL,
  REFRESH_TOKEN_URL,
  RESEND_CODE_URL,
  SSO_LOGIN_URL,
  SSO_URL,
  VERIFY_LOGIN_URL,
} from '@kitch/data-access/constants';
import { environment } from '@kitch/data-access/env/environment';
import {
  CommonUserRole,
  GuestTokenResponse,
  LoginRequest,
  LoginResponse,
  RefCodeValidatedResponse,
  RefreshTokenResponse,
  Role,
  SSOUser, USER_LOGGED_FOR_TYPEFORM,
  VerifyAuthCodeRequest,
  VerifyAuthCodeResponse,
} from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';
import { TokenService } from '@kitch/data-access/services/token.service';
import { WebsocketService } from '@kitch/data-access/services/websocket.service';
import { getSecondsFromDate, makeUri } from '@kitch/util';
import { BrowserDetectTool } from '@kitch/util/browser-detect.tool';
import { BrowserEnum } from '@kitch/ui/enums';
import { Socials } from '@kitch/user/shared/constants/social';

declare let google: any;
const LOGIN_PAGE_URL = new InjectionToken<string>('LoginPageUrl');
const USER_ROLE = new InjectionToken<CommonUserRole>('User Role');

enum GoogleUxModeEnum {
  Popup = 'popup',
  Redirect = 'redirect',
}

@UntilDestroy()
@Injectable()
export class AuthService {
  private refreshTokenTimeoutId: ReturnType<typeof setTimeout>;
  private socialAuthState: Subject<SSOUser> = new Subject<SSOUser>();
  private isGoogleInited = false;
  private ssrResponse;

  private readonly tokenIsRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get socialAuthState$(): Observable<SSOUser> {
    return this.socialAuthState.asObservable();
  }

  get isUserApplication(): boolean {
    return this.role === CommonUserRole.USER;
  }

  constructor(
    @Inject(LOGIN_PAGE_URL) private loginUrl: string,
    @Inject(USER_ROLE) private role: CommonUserRole,
    private apiService: ApiService,
    private httpClient: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private webSocket: WebsocketService,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformServer(platformId)) {
      this.ssrResponse = inject(RESPONSE);
    }
  }

  loginAsGuest(): Observable<GuestTokenResponse> {
    return this.apiService.post(GUEST_LOGIN_URL).pipe(
      tap((response: GuestTokenResponse) => {
        this.tokenService.setToken(response.token);
        this.setSsrCookies(response.refreshToken);
      }),
    );
  }

  login(phone: string): Observable<LoginResponse> {
    const payload: LoginRequest = { phoneNumber: phone };

    return this.apiService.post(LOGIN_URL, payload).pipe(
      tap((response: LoginResponse) => {
        this.tokenService.setLoginToken(response.token);
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleGoogleSignIn(response: any): void {
    sessionStorage.setItem(USER_LOGGED_FOR_TYPEFORM, 'true');
    console.log(response.credential);

    // This next is for decoding the idToken to an object if you want to see the details.
    const base64Url = response.credential.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
    }).join(''));

    const googleUser = JSON.parse(jsonPayload);
    const user: SSOUser = {
      email: googleUser.email,
      firstName: googleUser.given_name,
      lastName: googleUser.family_name,
      SSOProvider: Socials.GOOGLE,
      authToken: response.credential,
    };

    this.socialAuthState.next(user);
  }

  authorizeThroughSSO(user: SSOUser): Observable<VerifyAuthCodeResponse> {
    return this.apiService.post(SSO_URL, user, { inviteId: user.inviteId })
      .pipe(
        tap((response: VerifyAuthCodeResponse) => {
          this.persistTokens(response);
        }),
      );
  }

  verifyAuthCode(otp: string): Observable<VerifyAuthCodeResponse> {
    const payload: VerifyAuthCodeRequest = { otp };

    return this.apiService.post(VERIFY_LOGIN_URL, payload).pipe(
      tap((response: VerifyAuthCodeResponse) => {
        this.persistTokens(response);
      }),
    );
  }

  resendAuthCode(phone: string): Observable<LoginResponse> {
    const payload: LoginRequest = { phoneNumber: phone };

    return this.apiService.post(RESEND_CODE_URL, payload).pipe(
      tap((response: LoginResponse) => {
        this.tokenService.setLoginToken(response.token);
      }),
    );
  }

  refreshToken(): Observable<RefreshTokenResponse> {
    return this.tokenIsRefreshing$.pipe(
      filter((loading: boolean) => !loading),
      take(1),
      tap(() => {
        this.setTokenRefreshingStatus(true);
      }),
      concatMap(() => this.apiService.post(REFRESH_TOKEN_URL)),
      catchError((err) => {
        this.setTokenRefreshingStatus(false);

        return throwError(err);
      }),
      tap((response: RefreshTokenResponse) => {
        this.setSsrCookies(response.refreshToken);
        this.persistTokens(response);
        this.setTimeoutToRefreshToken();
        this.setTokenRefreshingStatus(false);
      }),
      untilDestroyed(this),
    );
  }

  setTimeoutToRefreshToken(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    clearInterval(this.refreshTokenTimeoutId);

    const oneMinute = 60;
    const expirationDateInSeconds: number = this.tokenService.getTokenExpirationDate();
    const nowDateInSeconds: number = getSecondsFromDate(new Date());
    const secondsToExpireToken: number = expirationDateInSeconds - nowDateInSeconds;
    const secondsToRefreshToken = secondsToExpireToken > oneMinute ? secondsToExpireToken - oneMinute : 0;

    if (secondsToExpireToken > 0) {
      this.ngZone.runOutsideAngular(() => {
        this.refreshTokenTimeoutId = setTimeout(() => {
          if (!document.hidden) {
            this.refreshToken().subscribe();
          }
        }, secondsToRefreshToken * 1000);
      });
    }
  }

  logout(): Observable<unknown> {
    return this.apiService.post(LOGOUT_URL).pipe(
      finalize(() => {
        this.stopSession();
      }),
    );
  }

  startSession(): void {
    // TODO: remove startSession method in the future if auto-logout logic is not needed
    // const secondsToExpire: number = this.tokenService.getTokenExpirationDate();
    // const nowDateInSeconds: number = getSecondsFromDate(new Date());
    // let secondsToLogout: number = secondsToExpire - nowDateInSeconds;
    //
    // clearInterval(this.checkSessionInterval);
    //
    // this.zone.runOutsideAngular(() => {
    //   this.checkSessionInterval = window.setInterval(() => {
    //     secondsToLogout = secondsToLogout - 1;
    //
    //     if (!this.isAuthenticated() || secondsToLogout < 0) {
    //       this.zone.run(() => this.stopSession());
    //     }
    //   }, 1000);
    // });
  }

  stopSession(error?: string, redirectUrl?: string, queryParams?: Params): void {
    this.webSocket.disconnect();
    clearInterval(this.refreshTokenTimeoutId);
    this.tokenService.deleteToken();
    this.tokenService.deleteLoginToken();

    if (this.role === 'USER') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(
        [this.loginUrl],
        { state: { error, redirectUrl }, queryParams: queryParams },
      );
    }
  }

  initGoogleSSO(): void {
    if (!this.isGoogleInited && isPlatformBrowser(this.platformId)) {
      const uxMode = this.getGoogleUxMode;
      const redirectUri = uxMode === GoogleUxModeEnum.Redirect ? this.getGoogleRedirectUri : undefined;

      google.accounts.id.initialize({
        client_id: environment.ssoGoogleClientId,
        ux_mode: uxMode,
        login_uri: redirectUri,
        callback: (response: any) => this.handleGoogleSignIn(response),
      });

      this.isGoogleInited = true;
    }
  }

  isValidRole(role: CommonUserRole): boolean {
    const loggedUserRole: Role = this.tokenService.getLoginRole();
    const isAdmin: boolean = this.tokenService.isLoginAdmin();

    switch (role) {
      case CommonUserRole.USER:
        return [Role.USER, Role.CHEF].includes(loggedUserRole);
      case CommonUserRole.ADMIN:
        return isAdmin;
      default:
        return false;
    }
  }

  checkRefCode(refCode: string): Observable<RefCodeValidatedResponse> {
    return this.apiService.get(makeUri(REF_CODE_VALIDATE_URL, refCode));
  }

  loginWithInstagramSSOId(instagramSSOLoginID: string, paramMap: ParamMap): Observable<VerifyAuthCodeResponse> {
    const INSTAGRAM_SSO_LOGIN_ID_KEY = 'instagram_sso_login_id';
    const payload = { [INSTAGRAM_SSO_LOGIN_ID_KEY]: instagramSSOLoginID };

    return this.apiService.post(INSTAGRAM_LOGIN_URL, payload).pipe(
      tap((response: VerifyAuthCodeResponse) => {
        this.persistTokens(response);
      }),
      catchError((error) => {
        const queryParams = {};

        paramMap.keys
          .filter(key => key !== INSTAGRAM_SSO_LOGIN_ID_KEY)
          .forEach(key => (queryParams[key] = paramMap.get(key)));

        this.router.navigate([], { queryParams });

        throw error;
      }),
    );
  }

  private isAuthenticated(): boolean {
    const token: string = this.tokenService.getToken();

    return !!token;
  }

  private persistTokens(
    response: VerifyAuthCodeResponse | LoginResponse | RefreshTokenResponse,
  ) {
    this.tokenService.setToken(response.token);
  }

  private get getGoogleUxMode(): GoogleUxModeEnum {
    const isInstagramBrowser = BrowserDetectTool.isCurrentBrowser(BrowserEnum.Instagram);

    return isInstagramBrowser ? GoogleUxModeEnum.Redirect : GoogleUxModeEnum.Popup;
  }

  private get getGoogleRedirectUri(): string {
    const googleRedirectUri = new URL(SSO_LOGIN_URL, `${environment.apiUrl}/`);

    googleRedirectUri.searchParams.set('redirect_uri', this.router.url);
    googleRedirectUri.searchParams.set('guest_token', this.tokenService.getToken());

    return googleRedirectUri.href;
  }

  private setTokenRefreshingStatus(loading: boolean): void {
    this.tokenIsRefreshing$.next(loading);
  }

  private setSsrCookies(refreshToken: string): void {
    if (isPlatformServer(this.platformId)) {
      const date = new Date();

      const cookieSettings: any = {
        path: '/',
        secure: true,
        sameSite: 'none',
        expires: new Date(date.setDate(date.getDate() + 30)),
      };

      switch (environment.name) {
        case 'staging':
          cookieSettings.domain = '.kitchstaging.com';
          break;
        case 'preprod':
          cookieSettings.domain = '.kitchprepmode.com';
          break;
        case 'prod':
          cookieSettings.domain = '.kittch.com';
          break;
      }

      this.ssrResponse.cookie('refreshToken', refreshToken, cookieSettings);
    }
  }
}
