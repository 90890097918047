<app-modal #modalRecordingError class="decline-modal" [showCloseIcon]="true" (closed)="continueStream()">

  <ng-container header>
    <h2 class="modal-title">
      Unfortunately your stream still isn’t recording. Do you want to cancel your stream or continue?
    </h2>
  </ng-container>
  <ng-container body>
    <div class="form__item form__item--center">
      <div class="form-button form-button--success">
        <app-button
          data-testid="stop-stream-modal-btn"
          [text]="'end stream'"
          (clicked)="endStream()">
        </app-button>
      </div>
      <div class="form-button form-button--close">
        <app-button
          [role]="'secondary'"
          [text]="'continue'"
          (clicked)="continueStream()">
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
