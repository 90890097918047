import { TagsService } from '@kitch/data-access/services';
import { ConfirmDialogService } from '@kitch/ui/services/confirm-dialog.service';

export * from './alert.service';
export * from './confirm-dialog.service';
export * from './previous-route.service';
export * from './search.service';
export * from './seo.service';
export * from './image-creator.service';
export * from './top-banner.service';

export const uiServices = [ConfirmDialogService, TagsService];
