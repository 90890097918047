import Fraction from 'fraction.js';
import { ListIngredients } from '@kitch/data-access/models';
import { HrsMin } from '@kitch/util/date.tool';

export const getIngredientsWithFractions = (ingredients: ListIngredients[]): string[] => {
  const ingredientsWithFractions = [];

  ingredients.forEach(ingredient => {
    const { product, unit, unitValue, ingredientDescription } = ingredient;
    const textDescription = ingredientDescription ? `, ${ingredientDescription}` : '';
    const ingredientStr = `${getFractionValue(unitValue)} ${unit} ${product}${textDescription}`;

    ingredientsWithFractions.push(ingredientStr);
  });

  return ingredientsWithFractions;
};

export const getFractionValue = (unitValue: string): string => {
  try {
    const fraction = new Fraction(unitValue);

    // return 1/2, 2/3, etc. as is
    if (!unitValue.includes('.') && fraction.valueOf() < 1) {
      return unitValue;
    }

    // handle repeating values 0.33 -> 33/100 -> 3/10
    const fractionString = fraction.simplify(0.1).toFraction(true);
    // 1/3: 1 - numerator, 3 - denominator
    const numerator = fraction.n;
    const denominator = fraction.d;

    if (numerator > denominator) {
      const [integer, decimal] = fractionString.split(' ');

      return `${integer} ${getFractionTemplate(decimal)}`;
    } else {
      return fractionString;
    }
  } catch {
    return unitValue;
  }
};

export const getFractionTemplate = (fraction: string): string => {
  const [numerator, denominator] = fraction.split('/');

  return `<span class="fraction fraction--top">${numerator}</span>&frasl;<span class="fraction">${denominator}</span>`;
};

export const getHtmlSteps = (steps: string[]): string => {
  let stepsHtml = '';

  if (steps.length === 1) {
    // new recipe
    stepsHtml = steps[0];
  } else {
    // old recipe
    steps.forEach(step => stepsHtml = `${stepsHtml}<p>${step}</p>`);
  }

  return stepsHtml;
};

export const getRecipeDisplayTime = (timeInMinutes: number): string => {
  const hrs = Math.floor(timeInMinutes / 60);
  const hrsEnd = hrs > 1 ? 's' : '';
  const hrsPart = hrs ? `${hrs} hr${hrsEnd} ` : '';
  const min = timeInMinutes % 60;
  const minPart = min ? `${min} min` : '';

  return `${hrsPart} ${minPart}`;
};

export const getTotalTimeInMinutes = (time: number, hrsMin: HrsMin): number => {
  const timeInMinutes = hrsMin === 'Hours' ? time * 60 : time;

  return Math.round(timeInMinutes);
};
