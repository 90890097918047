import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Role } from '@kitch/data-access/models';

import { UsersListItem } from '@kitch/admin/shared/models/user';

@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss'],
})
export class UserListItemComponent {
  @Input() user: UsersListItem;
  @Input() canBeAdded = true;

  @Output() changeStatus: EventEmitter<UserStatusChange> = new EventEmitter<UserStatusChange>();

  userRole = Role;

  onCheckboxClick(event: Event): void {
    this.changeStatus.emit({
      isSelected: (event.target as HTMLInputElement).checked,
      user: this.user,
    });
    this.user.addedToStream = (event.target as HTMLInputElement).checked;
  }

  setBgName(name: string): string {
    if (!name) return;

    return name.match(/\b(\w)/g).join('').toUpperCase();
  }
}

export interface UserStatusChange {
  isSelected: boolean;
  user: UsersListItem;
}
