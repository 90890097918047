// eslint-disable-next-line import/no-cycle
import { Stream } from '@kitch/data-access/models/stream';

export interface BasicCuisineSchema {
  id?: string;
  name: string;
  type: CuisineType;
}
export interface Cuisine extends BasicCuisineSchema{
  videos: [Stream]
}

export enum CuisineType {
  PRIMARY = 'PRIMARY',
  STREAM = 'STREAM',
  SUBCUISINE = 'SUBCUISINE'
}

export interface HardcodedCuisinesStructure {
  [key: string]: string[];
}

export interface CuisinesList {
  primary: BasicCuisineSchema[];
  stream?: BasicCuisineSchema[];
  subCuisine: BasicCuisineSchema[];
}
