import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[appAutoscalingText]',
})
export class AutoscalingTextDirective implements AfterViewInit, OnDestroy {
  @Input() textWrapper: HTMLDivElement;
  @Input() textElement: HTMLAnchorElement;
  @Input() textLength: number;

  fontSize = 31;

  private readonly maxFontSize = 31;

  @HostListener('window:resize', ['$event'])
  onResizeHandler(event: Event): void {
    const eventTarget = event.target as Window;
    const MOBILE_SCREEN_WIDTH = 750;
    const MOBILE_FONT_SIZE = 21;

    if (eventTarget.innerWidth > MOBILE_SCREEN_WIDTH) {
      this.autoscalingText();
    } else {
      this.updateFontSize(MOBILE_FONT_SIZE);
    }
  }

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.scaleText();
    this.observeTextElementLengthChange();
  }

  ngOnDestroy() {}

  private scaleText(): void {
    const charsThreshold = 8;

    // decrease the font size for every 8 characters
    this.updateFontSize(this.maxFontSize - Math.floor(this.textLength / charsThreshold));
  }

  private autoscalingText(): void {
    const MIN_FONT_SIZE = 17;
    const fontSize = (this.textWrapper.offsetWidth * this.textWrapper.offsetHeight *
      this.fontSize) / (this.textElement.offsetWidth * this.textElement.offsetHeight);

    if (fontSize <= MIN_FONT_SIZE) {
      this.updateFontSize(MIN_FONT_SIZE);

      return;
    }

    if (fontSize >= this.maxFontSize) {
      this.updateFontSize(this.maxFontSize);

      return;
    }

    this.updateFontSize(Math.floor(fontSize));
  }

  private updateFontSize(value: number): void {
    this.fontSize = value;
    this.textWrapper.style.fontSize = `${this.fontSize}px`;
    this.textWrapper.style.lineHeight = `${this.fontSize + 2}px`;
  }

  private observeTextElementLengthChange(): void {
    const getTextElementLength = () => this.textElement.textContent.length;
    const textElementLength$ = new BehaviorSubject<number>(getTextElementLength());
    const observer = new MutationObserver(() => {
      textElementLength$.next(getTextElementLength());
    });
    const mutationObserverConfig = {
      childList: true,
      characterData: true,
      subtree: true,
    };

    textElementLength$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.autoscalingText();
      });

    observer.observe(this.el.nativeElement, mutationObserverConfig);
  }
}
