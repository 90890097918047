import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-external-recipe',
  templateUrl: './avatar-external-recipe.component.html',
  styleUrls: ['./avatar-external-recipe.component.scss'],
})
export class AvatarExternalRecipeComponent {
  @Input() primaryName: string;
  @Input() secondaryName: string;
}
