import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { IMaskModule } from 'angular-imask';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { DisplayMode, ValdemortConfig, ValdemortModule } from 'ngx-valdemort';
import { uiComponents } from '@kitch/ui/components';
import { uiDirectives } from '@kitch/ui/directives';
import { uiPipes } from '@kitch/ui/pipes';
import { uiServices } from '@kitch/ui/services';
import { MaterialModule } from '@kitch/material';
import { formsSharedComponents } from './components/forms';
import { modalsSharedComponents } from './components/modals';
import { selectDropdowns } from './components/ng-select';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ValdemortModule,
    MaterialModule,
    NgxEditorModule.forRoot({
      locals: {
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
      },
    }),
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxIntlTelInputModule,
    FormsModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgSelectModule,
    IMaskModule,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    NgOptimizedImage,
  ],
  exports: [
    ...uiComponents,
    ...uiDirectives,
    ...uiPipes,
    ...modalsSharedComponents,
    ...formsSharedComponents,
    ...selectDropdowns,
    CommonModule,
    ValdemortModule,
    NgxEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxIntlTelInputModule,
    NgSelectModule,
    IMaskModule,
    NgOptimizedImage,
  ],
  declarations: [
    ...uiComponents,
    ...uiDirectives,
    ...uiPipes,
    ...modalsSharedComponents,
    ...formsSharedComponents,
    ...selectDropdowns,
  ],
  providers: [
    ...uiServices,
  ],
})
export class UiModule {
  constructor(ngxValdemortConfig: ValdemortConfig, iconLibrary: FaIconLibrary) {
    ngxValdemortConfig.errorsClasses = 'form-error';
    ngxValdemortConfig.errorClasses = 'form-error__text';
    ngxValdemortConfig.displayMode = DisplayMode.ONE;

    iconLibrary.addIcons(faCopy);
  }
}
