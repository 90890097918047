import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TERMS_URL, USERS_URL } from '@kitch/data-access/constants';
import { TermsGetResponse, TermsPatchRequest, TermsPatchResponse } from '@kitch/data-access/models/terms';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';

@Injectable({ providedIn: 'root' })
export class TermsService {
  constructor(private apiService: ApiService) {}

  getTermsStatus(userId: string): Observable<TermsGetResponse> {
    return this.apiService.get(makeUri(USERS_URL, userId, TERMS_URL));
  }

  patchTermsStatus(userId: string, body: TermsPatchRequest): Observable<TermsPatchResponse> {
    return this.apiService.patch(makeUri(USERS_URL, userId, TERMS_URL), body);
  }
}
