import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TIPS_URL } from '@kitch/data-access/constants';
import { AppPagesItem, SimpleResponse } from '@kitch/data-access/models';
import { TipSearchParams } from '@kitch/data-access/models/search-params';
import { Tip, TipToChef } from '@kitch/data-access/models/tip';
import { ApiService } from '@kitch/data-access/services/api.service';

import { makeUri } from '@kitch/util';

@Injectable()
export class TipsService {
  constructor(private apiService: ApiService) {}

  getAll(searchParams?: TipSearchParams): Observable<AppPagesItem<Tip>> {
    return this.apiService.get(TIPS_URL, searchParams);
  }

  // eslint-disable-next-line default-param-last
  tipToChef(chefProfileId: string, amount = 1, videoId?: string): Observable<SimpleResponse> {
    const tip: TipToChef = {
      profileIds: [chefProfileId],
      amount: amount,
    };

    if (videoId) {
      tip.videoId = videoId;
    }

    return this.apiService.post(makeUri(TIPS_URL, 'tip-to-chef'), tip);
  }
}
