import { AbstractControl, ValidatorFn } from '@angular/forms';

export function DigitsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }

    return /^\d+$/.test(control.value) ? null : { digits: true };
  };
}
