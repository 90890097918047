import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { TRANSACTIONS_URL } from '@kitch/data-access/constants';
import { AppPagesItem } from '@kitch/data-access/models';
import { TransactionSearchParams } from '@kitch/data-access/models/search-params/transaction';
import { Transaction } from '@kitch/data-access/models/transaction';
import { ApiService } from '@kitch/data-access/services/api.service';

import { makeUri } from '@kitch/util';

@Injectable()
export class TransactionsService {
  constructor(private apiService: ApiService) {}

  getTransactions(searchParams: TransactionSearchParams): Observable<AppPagesItem<Transaction>> {
    return this.apiService.get(makeUri(TRANSACTIONS_URL), searchParams);
  }

  getClamsTransactions(searchParams: TransactionSearchParams): Observable<AppPagesItem<Transaction>> {
    return this.apiService.get(makeUri(TRANSACTIONS_URL, 'clams'), searchParams);
  }
}
