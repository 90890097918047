import { Injectable } from '@angular/core';
import { deepCopy } from '@kitch/util';
import { TABS_MENU } from '@kitch/user/shared/constants/tabs';
import { Tab, TabId, TabTitle } from '@kitch/user/shared/models';

@Injectable()
export class LiveReplayTabsService {
  tabsMenu = deepCopy(TABS_MENU);

  isTabActive(tabId: TabId): boolean {
    const tab = this.tabsMenu.tabs.find(({ id }: Tab) => id === tabId);

    if (tab) {
      return this.tabsMenu.active === tab.index;
    } else {
      return false;
    }
  }

  addTab(idToAdd: TabId): void {
    const addTab = TABS_MENU.tabs.find(({ id }: Tab) => id === idToAdd);
    const addTabIndex = this.findTabIndexById(idToAdd);

    if (addTabIndex === -1) {
      this.tabsMenu.tabs.push(addTab);
      this.tabsMenu.tabs.sort((a, b) => a.index - b.index);
      this.tabsMenu.active = addTab.index;
    }
  }

  removeTab(idToRemove: TabId): void {
    const removeTabIndex = this.findTabIndexById(idToRemove);

    if (removeTabIndex !== -1) {
      this.tabsMenu.tabs.splice(removeTabIndex, 1);
      this.tabsMenu.active = this.tabsMenu.tabs[0]?.index;

      if (this.isRecipeTab(idToRemove)) {
        this.setUpRecipeTabsTitle();
      }
    }
  }

  setUpRecipeTabsDependingOnRecipeCount(recipeCount: number): void {
    this.getRecipeTabsId.slice(recipeCount).forEach((id) => this.removeTab(id));
  }

  private setUpRecipeTabsTitle(): void {
    const recipeTabs = this.tabsMenu.tabs.filter(({ id }: Tab) => this.isRecipeTab(id));

    if (recipeTabs.length === 1) {
      const recipeTab = recipeTabs[0];

      if (recipeTab.title !== TabTitle.RECIPE) {
        recipeTab.title = TabTitle.RECIPE;
      }
    }
  }

  private isRecipeTab(tabId: TabId): boolean {
    return this.getRecipeTabsId.includes(tabId);
  }

  private get getRecipeTabsId(): TabId[] {
    return [TabId.RECIPE_FIRST, TabId.RECIPE_SECOND, TabId.RECIPE_THIRD];
  }

  private findTabIndexById(tabId: TabId): number {
    return this.tabsMenu.tabs.findIndex(({ id }: Tab) => id === tabId);
  }
}
