import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VIEWERS_URL } from '@kitch/data-access/constants';
import {
  AppPagesItem,
  AudienceViewerUser,
  BasicAudienceSchema,
  SimpleResponse,
  StreamViewerType,
  ViewerCapped,
  WatchReplayDuration,
  WatchReplayUserInfo,
} from '@kitch/data-access/models';
import { TipSearchParams } from '@kitch/data-access/models/search-params';
import { ApiService } from '@kitch/data-access/services/api.service';
import { FetchApiService } from '@kitch/data-access/services/fetch-api.service';
import { makeUri } from '@kitch/util';

@Injectable({ providedIn: 'root' })
export class AudienceService {
  constructor(
    private apiService: ApiService,
    private fetchApiService: FetchApiService,
  ) {}

  getAll(searchParams?: TipSearchParams): Observable<AppPagesItem<AudienceViewerUser>> {
    return this.apiService.get(VIEWERS_URL, searchParams);
  }

  getViewerCapped(profileId: string): Observable<ViewerCapped> {
    return this.apiService.get(makeUri(VIEWERS_URL, 'capped', profileId), {
      profileId: profileId,
    });
  }

  sendInfoUser(
    profileId: string,
    streamType: StreamViewerType,
    videoId?: string,
    viewerName?: string,
    viewerPhoto?: string,
  ): Observable<SimpleResponse> {
    const info: BasicAudienceSchema = {
      profileId: profileId,
      viewerName: viewerName,
      viewerPhoto: viewerPhoto,
      streamType: streamType,
    };

    if (videoId) {
      info.videoId = videoId;
    }

    return this.apiService.post(makeUri(VIEWERS_URL, 'join'), info);
  }

  deleteUserLeftStream(profileId: string, videoId: string, videoMode: StreamViewerType): Observable<SimpleResponse> {
    const url = makeUri(VIEWERS_URL, profileId, 'left-stream', videoId, videoMode);

    return this.fetchApiService.makeRequest('DELETE', url);
  }

  sendViewedSeconds(info: WatchReplayUserInfo): Observable<SimpleResponse> {
    const url = makeUri(VIEWERS_URL, 'seconds');
    const payload: WatchReplayDuration = {
      ...info,
      streamType: StreamViewerType.REPLAY,
    };

    return this.fetchApiService.makeRequest('POST', url, payload);
  }

  // commented for ticket KB-2220
  // sendCollectEmail(collectEmailData: CollectEmailData): Observable<SimpleResponse> {
  //   return this.apiService.post(makeUri(USERS_URL, 'audience'), collectEmailData);
  // }
}
