import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Directive({ selector: '[appHorizontalScroll]' })
export class HorizontalScrollDirective {
  private _deltaY: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public coefficientOfScrollSpeed = 1.33;

  get deltaY$(): Observable<number> {
    return this._deltaY.asObservable();
  }

  set deltaY(state: number) {
    this._deltaY.next(state);
  }

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  @HostListener('wheel', ['$event']) onWheelScroll(e: WheelEvent): void {
    const { nativeElement } = this.el;

    if (nativeElement.scrollWidth > nativeElement.clientWidth) {
      e.preventDefault();
      this.deltaY = e.deltaY;
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollEl();
    }
  }

  private scrollEl(): void {
    this.deltaY$.subscribe((deltaY) => {
      this.el.nativeElement.scrollBy({
        left: +deltaY * this.coefficientOfScrollSpeed,
        top: 0,
      });
    });
  }
}
