import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CHEF_TABLE_VISITORS_URL } from '@kitch/data-access/constants';
import { ChefTableVisitor, ChefTableVisitorInfo, UpdateVisitorResponse } from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';

@Injectable({
  providedIn: 'root',
})
export class ChefTableService {
  constructor(
    private apiService: ApiService,
  ) { }

  getVisitors(videoId: string): Observable<ChefTableVisitor[]> {
    return this.apiService.get(makeUri(CHEF_TABLE_VISITORS_URL, videoId));
  }

  updateVisitor(visitor: ChefTableVisitorInfo): Observable<UpdateVisitorResponse> {
    return this.apiService.post(CHEF_TABLE_VISITORS_URL, visitor);
  }
}
