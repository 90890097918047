import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { UPLOAD_URL } from '@kitch/data-access/constants';
import { makeUri } from '@kitch/util';

import { ApiService } from './api.service';

export interface Upload {
  progress: number
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
}

@Injectable()
export class UploadService {
  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
  ) {
  }

  getUploadUrls(contentType: string, type: UploadFileType): Observable<UploadUrls> {
    return this.apiService.get(makeUri(UPLOAD_URL), { contentType, type });
  }

  upload(url: string, image: string | File): Observable<any> {
    return this.httpClient.put(url, image);
  }

  uploadVideo(url: string, video: string): Observable<Upload> {
    const initialState: Upload = { state: 'IN_PROGRESS', progress: 0 };

    return this.httpClient.put(
      url,
      video,
      { reportProgress: true, observe: 'events' },
    ).pipe(scan(this.calculateState, initialState));
  }

  private calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
    if (this.apiService.isHttpProgressEvent(event)) {
      return {
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : upload.progress,
        state: 'IN_PROGRESS',
      };
    }
    if (this.apiService.isHttpResponse(event)) {
      return {
        progress: 100,
        state: 'DONE',
      };
    }

    return upload;
  };
}

export interface UploadUrls {
  publicUrl: string;
  uploadSignedUrl: string;
}

type UploadFileType = 'profile' | 'channel' | 'recipe' | 'video' | 'video-product';
