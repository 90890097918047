<p class="text-content text-content--no-intend text-content--vertical-intend">
  <i>Effective as of April 20th, 2021</i>
</p>

<p class="text-content text-content--no-intend text-content--vertical-intend text-content--vertical-intend">
  Welcome to the Internet sites (“Sites”), applications and services (collectively with the Sites, the “Services”) provided by Kittch, Inc. (“Kittch,” “We” or “us”).
  These Terms of Service govern your access and use of the Services provided by Kittch, and any of its subsidiaries, affiliates, brands and entities that it controls.
  Please read these Terms carefully before accessing or using the Services.
</p>
<p class="text-content text-content--no-intend text-content--vertical-intend text-content--vertical-intend">
  Kittch’s Services offer a social platform for culinary creators to publish live and interactive content for users of the Services.
  Users of the Services include members who have registered for an account (“Members” or “You”), including but not limited to culinary creators who wish to publish content (“Creators”),
  and those who wish to access certain areas of the Services which require registration as a Member, as well as visitors who browse the Sites without registering (“Visitors”;
  Visitors and Members are collectively “Users”).
  To use many of the features of the Sites or Services, we require you to register as a Member and secure an account (“Account” or “Membership”) with us.
</p>
<p class="text-content text-content--no-intend text-content--vertical-intend text-content--vertical-intend">
  By joining, or each time you access and use the Services, you signify that you have read and understand, and agree to be bound by, these Terms.
  You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s
  permission to use the Services and have gotten your parent or guardian to agree to these Terms on your behalf;
  age restrictions for Membership are described below). If you do not agree to these terms, you are not permitted to use the Services.
  These Terms have the same force and effect as an agreement in writing.
</p>
<p class="text-content text-content--no-intend text-content--vertical-intend text-content--vertical-intend">
  If you wish to contact us in writing, have a complaint or are required to give us notice in writing, you can send this to us <a class="link-underline" href="mailto:support@kittch.com" target="_blank">here</a>.
  If we have to contact you or give you notice in writing, we will do so by email or post to any (email) address you provide to us.
</p>
<p class="text-content text-content--no-intend text-content--vertical-intend">
  <span class="bold">Important Notices:</span>
</p>
<ul class="level-3">
  <li class="text-content">
    The key terms you should consider are the limitations on liability contained in the sections titled Disclaimer of Warranties and Limitations of Liability,
    and a class action waiver and resolution of disputes by arbitration in the section titled
    <span class="link-underline" (click)="scrollToDisputesSection()">Disputes</span>.
  </li>
  <li class="text-content">
    Your access to and use of the Services is also governed by our Privacy Notice located <a class="link-underline" [routerLink]="'/privacy-policy'" target="_blank">here</a>.
  </li>
</ul>
<p class="text-content text-content--no-intend text-content--vertical-intend">
  <span class="bold">ARBITRATION NOTICE AND CLASS ACTION WAIVER:</span>
  EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE “DISPUTES” SECTION BELOW,
  YOU AGREE THAT DISPUTES UNDER THESE TERMS WILL BE RESOLVED BY BINDING,
  INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
</p>
<ol class="level-1">
  <li>
    <h3 class="section-title">Your Responsibilities</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You are responsible for obtaining and maintaining at your own cost all equipment and services needed for access to and use of the Services.
      When you register with us and each time you access the Services, you may be providing certain information about yourself.
      You agree that we may use any information that we obtain about you in accordance with the provisions of our Privacy Notice and that you have no ownership or proprietary interest in your account other than as set out in these Terms.
      If you elect to register with us, you agree to: (a) provide true, accurate, current, and complete information as prompted by the registration form; and (b) maintain and update such information to keep it true, accurate, current, and complete at all times.
      If any information you provide is or becomes untrue, inaccurate, or incomplete, we have the right to terminate your access to and use of your account and the Services.
      In addition, you agree not to use the Services to: (a) violate any local, state, national, or international law or regulation;
      (b) transmit any material that is abusive, harassing, tortious, defamatory, vulgar, pornographic, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable;
      (c) transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation;
      (d) transmit any material that contains adware, malware, spyware, software viruses, or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;
      (e) stalk, harass, or harm another individual, including revealing the real name of any fellow user that has chosen to use an alias on the Services;
      (f) impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity;
      (g) use any "robot," "spider," "rover," "scraper" or any other data-mining technology or automatic or manual process to monitor, cache, frame, mask, extract data from, copy or distribute any data from the Services, our network or databases;
      (h) use any features of the Services for anything other than their intended purpose;
      (i) interfere with or disrupt the Services or servers or our networks, or disobey any requirements, procedures, policies, or regulations of networks connected to the Services; or
      (j) assist, permit or encourage any person to perform any of the activities described above.
      We may immediately terminate your Membership and access to the Services as well as pursue any legal or equitable remedy in the event you violate any of the foregoing.
    </p>
  </li>

  <li>
    <h3 class="section-title">Membership and Participation on the Sites</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You must be thirteen (13) years of age or older to become a Member and  participate in any Services and/or be a Member and receive Membership benefits,
      and you must be eighteen (18) years of age or older to participate in our certain specified engagements.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Registration as a Member is required in order to access certain services, including, without limitation, viewing any content or live streams,
      listing reviews and blog and article comment posting.
      Your registration information will be handled by us in accordance with our
      <a class="link-underline" [routerLink]="'/privacy-policy'" target="_blank">Privacy Notice</a>, which you should review prior to registering with us.

    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      There are two types of Members – General Members and Creators.
      General Members will be able to sign up for certain classes, make purchases, access certain limited resources, view livestreams and pre-recorded content,
      engage with other Members, and post comments, questions and other public messages.
      Creators, who are selected by Kittch within its sole discretion,
      enjoy additional capabilities that allow them to upload content, stream video (live and pre-recorded)
      of culinary interest as well as offer related product (collectively,
      “Creator Content”), and offer access to their Creator Content on terms determined by Creators, e.g., payment for access to classes or events.
      Members may be both General Members and Creators.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You may be required to select a password and Member name for Membership registration.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We encourage you to keep us informed about any changes in your Membership, personal contact and email information.
      You may change or update certain information with respect to your Account by using the controls on your profile page.
      You may delete your own profile at any time by clicking here.
      If your email address is canceled, becomes inactive or inaccessible for an extended period of time, we may cancel your Membership and delete all or part of your Account,
      including your profile, to the extent allowed by law and in accordance with our security measures.
      We also reserve the right to cancel your Membership or prohibit your participation in any or all of the Sites’ activities if you violate any provision of this Agreement or our
      <a class="link-underline" [routerLink]="'/privacy-policy'" target="_blank">Privacy Notice</a>.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You do not have to be a Member to browse the Sites or participate in certain contests, sweepstakes and/or special events; however,
      you must meet the designated minimum age requirements (for example, twenty-one (21) years of age or older) for the specific event.
      We will establish specific rules and terms for participation in each contest, sweepstakes and/or special event and will post this information on our Sites.
      We will not knowingly collect personal information from visitors under sixteen (16) years of age for these activities.
      Upon discovery of the participation of a person under sixteen (16) years of age in such activities, his/her registration or participation will be
      canceled immediately and all personal information will be deleted from our files.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Creators are subject to all rules of Members, as well as certain additional terms.
      See <span class="link-underline" (click)="scrollToSupplementalSection()">[Supplemental Terms of Service for Creators]</span> below.
    </p>
  </li>

  <li>
    <h3 class="section-title">User Submissions and Interactive Areas</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Except for certain content uploaded or posted by our Users, including without limitation Creator Content, as between you and Kittch,
      all other aspects of the Services are owned or controlled by Kittch, including but not limited to text, graphics, photographs,
      logos and other imagery, videos, user interfaces, trademarks, code, and other content.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may provide interactive activities for the Service Member communities such as chat rooms,
      article and blog comment posting areas, reader photo upload, reader ratings and reviews,
      saving favorite restaurants or fashion looks, bulletin boards (also known as message boards), SMS text messaging and mobile alerts (collectively,
      “Interactive Areas”) for the enjoyment of our visitors.
      Members must be thirteen (13) years of age or older to participate in the Service’s Interactive Areas.
      Interactive Areas not conducted, operated and/or managed by Kittch may require a different registration process.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Excluding certain Creator Content, any User Submissions (as defined below) or postings by visitors to certain parts of the Sites,
      including, without limitation, Interactive Areas, will be public and posted in public areas on our Sites.
      Kittch and its employees, directors, investors, sponsors and vendors will not be responsible for the User Submissions or the action of any visitors
      or third parties with respect to any information, materials or content posted, uploaded or transmitted in these Interactive Areas.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Kittch does not claim ownership of any video, audio, information, data, text, software, music, sound, photographs, graphics, messages,
      tags or other materials you submit for display or distribution to others through the Services, including any such materials that you submit through Interactive Areas (collectively, "User Submissions").
      As between you and Kittch, you own all rights to your User Submissions.
      However, you grant (and confirm and promise to us that you have the right to grant) to us and our affiliates, representatives, sublicensees and assigns an irrevocable,
      perpetual, non-exclusive, sub-licensable, royalty-free and fully-paid, license (sublicensable through multiple tiers) throughout the universe in perpetuity to use,
      distribute, syndicate, license, reproduce, modify, adapt, publish, translate, publicly perform, create derivative works from and publicly display your
      User Submissions (in whole or in part) in any format or medium now known or later developed; provided, however, that our exercise of our rights under the foregoing license shall at all times be subject
      to the limitations upon disclosure of your User Submissions imposed on us under our Privacy Notice.
      You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attributions with respect to your User Submissions.
      We reserve the right to display advertisements in connection with User Submissions (including without limitation Creator Content) and to use User Submissions (including the names,
      voices and likenesses of the individuals featured in the User Submissions and the Creator Content) for advertising and promotional purposes for the Sites as well as for third party advertisers
      and sponsors of the Sites without any compensation to you (the “Sponsor Rights”).
      You represent and warrant that you have the right and will maintain the right to grant to Kittch the right to grant the Sponsor Rights with respect to your User Submissions and Creator Content.
      In exchange for allowing you access to and use of the Services, you agree that we may place such advertisements throughout our Services.
      We do not pre-screen all User Submissions, and you agree that you are solely responsible for all of your User Submissions.
      By participating in any of the aforementioned activities, all visitors and Members agree to follow the Services’ standards of conduct.
      Postings to public areas may or may not be reviewed by the Sites prior to appearing on the Sites.
      Nonetheless, Kittch reserves the right to change, delete or remove, in whole or in part, any postings in Interactive Areas and to terminate or suspend access to such areas for conduct that we believe,
      in our sole discretion, interferes with other peoples’ enjoyment of our Sites or Services.
      Kittch will also cooperate with local, state and/or federal authorities as well as international governing bodies to the extent of jurisdiction to comply with applicable law.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We are not required to back up, host, display, or distribute any User Submissions, and may remove or refuse any User Submissions at any time.
      We are not responsible for any loss, theft or damage of any kind to any User Submissions.
      You represent and warrant that your User Submissions and our use of such submissions do not and will not infringe the rights of any third party
      (including, without limitation, intellectual property rights, rights of privacy or publicity, or any other legal or moral rights).
      Your User Submissions must not violate our policies.
      You may not represent or imply to others that your User Submissions are in any way provided, sponsored or endorsed by us.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Please consider the risks of revealing personal information (such as name, phone number or street address) about yourself or others in Interactive Areas,
      including when connecting to the Sites through a third-party service.
      You, and not we, are responsible for any consequences of sharing personal information about yourself on public areas of the Service,
      such as your identity, home address or city, or the home address of others.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We own all right, title, and interests in any compilation, collective work or other derivative work created by us using or incorporating your
      User Submissions (but not your underlying, original content).
      When you use a feature on the Services that allows Users to share, transform, readapt, modify,
      or combine user content with other content, you grant us and our users an irrevocable, non-exclusive, royalty free,
      perpetual right and license in the universe to use, reproduce, modify, display, remix, perform, distribute, redistribute, adapt, promote,
      create derivative works from, syndicate and monetize (through advertising) your User Submissions in any medium and through any form of technology
      or distribution and to permit any derivative works to be licensed under these same license terms.
      The rights granted under this section will survive the termination of these Terms.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      All content and materials provided on the Services are intended for general information, general discussion, education, and entertainment purposes only.
      The Services contains facts, views, opinions and statements of third parties, visitors and other organizations, not necessarily reflective of our own,
      and we do not represent or endorse the accuracy or reliability of any advice, opinion, statement or other information displayed or distributed through our Services.
      You acknowledge that any reliance upon any such advice, opinions, statement or other information shall be at your sole risk and you agree that
      Kittch and its affiliated entities shall not be held responsible or liable, directly or indirectly,
      for any loss or damage caused or alleged to have been caused in any way whatsoever related to any advice, opinions, statements or other information displayed or distributed on our Services.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We do our best to discourage disruptive, hurtful or hateful communication.
      We also discourage disruptive statements that incite others to violate our standards.
      We encourage your participation in upholding our standards.
      You are responsible for all content that you post, email, transmit, upload or otherwise make available through our Sites.
      You agree not to use the Interactive Areas or the Sites to make available any content that:
    </p>
    <ul class="level-3">
      <li class="text-content">
        is unlawful, harmful to adults or minors, threatening, abusive, harassing, tortious, defamatory, vulgar,
        obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable;
      </li>
      <li class="text-content">
        infringes any patent, trademark, trade secret, copyright, right of privacy or publicity or other proprietary
        rights of any person;
      </li>
      <li class="text-content">
        contains advertising not placed by Kittch or solicits visitors to any third party site; or
      </li>
      <li class="text-content">
        is intended to interrupt, destroy or limit the functionality or integrity of any computer
        software, hardware or Materials on this website.
      </li>
    </ul>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The Services may allow you to post reviews of events, movies, restaurants and other businesses (“Reviews”).
      Such Reviews are governed by the terms of this Agreement, including, without limitation, your agreement regarding your use of Interactive Areas.
      Reviews do not reflect the views of Kittch, its affiliated entities, employees, investors or vendors.
      The Services do not assume responsibility or liability for any Reviews or for any claims, damages or
      losses resulting from the use of this service or the materials contained therein.
      Reviews that are submitted to the Services shall be owned exclusively and in perpetuity by Kittch and/or its successors.
      Such exclusive ownership means that Kittch and its affiliated entities shall have the unrestricted, perpetual and exclusive right to use,
      reproduce, modify, translate, transmit, distribute or otherwise exploit any and all materials and communications.
      There shall be no obligation to give credit or pay any consideration to you for any Reviews.
      Kittch reserves the right to delete or modify any review that we determine to violate the terms of this
      Agreement or general standards of good taste at any time and at our sole discretion.
      We strive to maintain a high level of integrity with our user-submitted Reviews,
      and any submission that is determined to be disingenuous in any way, and could diminish the overall quality of our Reviews, will be removed.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The Services may allow a visitor to post photograph(s) or video on-line (“Photo” and “Video”).
      The submission of a Photo or Video is governed by the terms of this Agreement, including without limitation,
      your agreement regarding your use of Interactive Areas.
      In submitting a Photo or Video and clicking the “I agree” box on the submission form,
      you represent and warrant that: (1) you are the person in the Photo/Video or are the owner of the
      Photo/Video and have the consent of all persons in the Photo/Video to post the Photo/Video to the Sites;
      (2) you are sixteen (16) years of age or over; (3) you have submitted the Photo/Video using your legal name and accurate personal information and consent to the Uses;
      (4) you are either the holder of the copyright in the Photo/Video or are an authorized licensee of the copyright in the Photo/Video and grant to the Sites,
      their licensees, successors and assigns, the right to publish and display the Photo/Video in connection with the Uses;
      and (5) you have the legal right and authority to agree to the use of Photo/Video and grant the Sites the right to use the Photo/Video in accordance with these Terms of Use.
      In addition, you expressly release Kittch and its licensees, successors and assigns from any privacy,
      defamation and any other claims you may have for the use of any Photo/Video submitted to the Services.
      If you see an objectionable Photo or have any questions about this Agreement, please <a class="link-underline" href="mailto:support@kittch.com" target="_blank">contact us</a>.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The Services strive to make their Interactive Areas enjoyable.
      Our chat venues welcome people of all races, religions, genders, national origins, sexual orientations and differing points of view.
      When in doubt about appropriate behavior in our Interactive Areas, please remember that, although the venue is electronic,
      those participating in it are real people. We ask that you treat others with respect.
      Any conduct by a participant in the Interactive Areas that violates this Agreement in any way may result in the suspension
      or termination of the visitor’s registration and access to the Services, at Kittch’s sole discretion, in addition to any other remedies.
      The Sites may provide interactive activities on a number of topics, but our staff or volunteer hosts participating in these activities
      do not offer professional advice of any kind and are speaking from their own experience or opinion as is helpful for the facilitation of the dialogue.
      Neither Kittch nor its hosts claim any professional expertise or authority.
      We may also post additional guidelines and/or a code of conduct for certain Interactive Areas or events.
      Any additional posted rules will be incorporated into this Agreement.
      To the extent there is a conflict between the rules of a specific event and this Agreement, the rules of the specific event will govern.
      If you see objectionable content or have any questions about this Agreement,
      please <a class="link-underline" href="mailto:support@kittch.com" target="_blank">contact us</a>.
    </p>
  </li>

  <li>
    <h3 class="section-title">Fees Payable by Members [“Clam Currency”]</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may at any time to charge fees for access to the Services or to any specific new feature
      or content that we may introduce from time to time. In no event will you be charged for access
      to any Services unless we obtain your prior agreement to pay such fees. If you do not consent
      to the payment of such fees, however, you may not have access to paid content,
      including certain Creator Content, or other features of the Services. Details regarding the Creator
      Content or other content or services you will receive in exchange for fees, as well as the payment
      terms and conditions that apply, will be disclosed to you prior to your agreement to pay such fees.
      You agree to pay such fees if you sign up for any fee-based Service, including access to Culinary Content.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may charge such fees via our “Clams Currency.” Clams are essentially credits for payment
      for access to our fee-based Services and Creator Content. The price per Clam is set by Kittch,
      and may be reset from time to time within its discretion. The then-current price will be set
      forth on the Site and Services <a class="link-underline" [routerLink]="'/profile/rewards'">here</a>.
      Clams do not expire for so long as the Sites and Services continue to operate, and are non-refundable.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Clams are redeemable for access to certain Creator Content on the Sites and Services in accordance with terms set by each Creator.
      For example, a Creator may schedule a live-stream event and charge two Clams for access.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If required by applicable law, the Sites and Services may charge sales tax
      on the purchase or redemption of Clams and/or the payment of any other fees hereunder.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Kittch reserves the right to revise the terms applicable to the use of Clams Currency and/or to terminate the Clams Currency system.
      In such event, Users will be provided with ___ advance notice of the termination of the Clams Currency system.
    </p>
  </li>

  <li #supplementalSection>
    <h3 class="section-title">Supplemental Terms for Creators</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Creators are selected solely by us within our discretion. Once so selected, a Creator will be required to register as a Member in order to participate in certain Creator Activities.
      As used herein, “Creator Activities” shall include creating content and posting it to the Services, including without limitation written and/or pre-produced audiovisual materials
      (e.g, recipes and pre-produced video showing the preparation thereof); producing or participating in live-streams; engaging with other users of the Services;
      and marketing and promoting products or services.  When you, as a Creator,  post or upload Creator Content to our Services, you grant to us the right to store,
      use, display, and provide access to such Creator Content as necessary to provide include the Creator Content in our Services, to reference the Creator Content in search results,
      to make your Creator Content available to Members, and to market and promote your Creator Content through our Services, through email, through ads on other sites or search engines,
      and through other marketing channels. For these purposes, you grant to us a nonexclusive, irrevocable, perpetual (except as otherwise provided herein), worldwide,
      sublicensable license to exercise any copyright, trademark rights, or other rights in and to your Creator Content which are necessary to provide our Services to you and our other Users.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      When a Member purchases a license to access Creator Content, a Creator 	provides such purchasers use and access (and permits Kittch the right to 	provide such access)
      to the Creator Content subject to the conditions determined by the Creator, so long as such access conditions are clearly disclosed and are otherwise consistent
      with these Terms of Service.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Kittch may make changes, in its discretion, to the Memberships it offers, including those applicable to Creators.
    </p>
  </li>

  <li>
    <h3 class="section-title">Creator Compensation</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Once selected as a Creator, Kittch will assign Clams Accounts to each Creator.
      Creators may be compensated in connection with their activities on Kittch in a number of ways, principally the following:
    </p>

    <ul>
      <li class="text-content list-latin">
        Creation and posting of audiovisual content, e.g., livestreams, pre-produced programs and the replays thereof.
        Creators set the terms and payment required to access their Creator Content.
      </li>
      <li class="text-content list-latin">
        Promotion and sale of Creator-owned or controlled or affiliated products,
        e.g., Creator mealkits or cookware.
      </li>
      <li class="text-content list-latin">
        Tips from Users, i.e., discretionary amounts contributed by Users as a manner of showing approval of Creator Content.
      </li>
    </ul>

    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Creators may withdraw the balance of their accounts, in dollars calculated at the exchange rate on the date earned,
      less the then-current exchange fee payable to Kittch, if any (posted <a class="link-underline" [routerLink]="'/profile/rewards'" target="_blank">here</a>).
      The minimum withdrawal is $100.00. Creators will be solely responsible for all taxes payable with respect to amounts paid to them hereunder.
    </p>
  </li>

  <li>
    <h3 class="section-title">Access to Creator Content</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The intellectual property rights in the materials on the Services may be owned by Kittch, Members, or Creators.
      Specifically, the intellectual property rights in Creator Content are owned by Creators, and Members who access
      Creator Content may do so under limited license for access as specified at the time of purchase.
      Members may not copy the Creator Content or use it for commercial or marketing purposes, nor may Members link to such Creator
      Content or make it available to any third party unless such third party purchases a license to the Creator Content.
      Any purchase of access will terminate upon the closure of you Account.
      Access to User Submissions and Kittch-owned content is generally available to all Members, subject to these Terms of Service.
    </p>
  </li>

  <li>
    <h3 class="section-title">Copyright Infringement and Trademark Rights</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We respect the intellectual property rights of others.
      Accordingly, we have a policy of removing User Submissions that violate copyright law, suspending access to the Services
      (or any portion thereof) to any user who uses the Services in violation of copyright law,
      and/or terminating in appropriate circumstances the account of any user who uses the Services in violation of copyright law.
      Pursuant to Title 17 of the United States Code, Section 512, the Digital Millennium Copyright Act of 1998 (“DMCA”),
      we have implemented procedures for receiving written notification of claimed copyright infringement and for processing such claims
      in accordance with such law. If you believe a user of the Services is infringing your copyright, please provide written notice to our agent
      listed below for notice of claims of copyright infringement.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Email: <a class="link-underline" href="mailto:support@kittch.com" target="_blank">support@kittch.com</a>
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Your written notice must: (a) contain your physical or electronic signature;
      (b) identify the copyrighted work alleged to have been infringed;
      (c) identify the allegedly infringing material in a sufficiently precise manner to allow us to locate that material;
      (d) contain adequate information by which we can contact you (including postal address, telephone number, and email address);
      (e) contain a statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner,
      the copyright owner's agent, or the law;
      (f) contain a statement that the information in the written notice is accurate; and
      (g) contain a statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
      Please do not send notices or inquiries unrelated to alleged copyright infringement to our designated copyright agent.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If you believe that your trademark is being used somewhere on the Services in a way that constitutes trademark infringement,
      the owner or an agent of the owner may notify us at
      <a class="link-underline" href="mailto:support@kittch.com" target="_blank">support@kittch.com</a>.
      We ask that any complaints provide the accurate identity of the owner,
      how we may contact you, and the specific nature of the complaint, including registration information, if any.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If you believe in good faith that someone has wrongfully filed a notice of copyright infringement against you,
      the DMCA permits you to send us a counter-notice. Notices and counter-notices must meet the then current statutory requirements imposed by the DMCA;
      see <a style="color: inherit;" href="https://www.loc.gov/copyright" target="_blank" rel="noopener">www.loc.gov/copyright</a>.
      Send counter-notices to the same addresses listed above and a statement that such person or entity consents to the jurisdiction of
      the Federal Court for the judicial district in which the content provider’s address is located,
      or, if the content provider’s address is located outside the United States, for any judicial district in which Company is located,
      and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If a counter-notice is received by the Designated Agent, Company may, in its discretion, send a copy of the counter-notice
      to the original complaining party informing that person that Company may replace the removed material or
      cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against
      the content provider accused of committing infringement, the removed material may be replaced or access to it restored
      in 10 to 14 business days or more after receipt of the counter-notice, at Company’s discretion.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If Kittch receives more than one Notice of Copyright Infringement concerning a user, the user may be deemed a “repeat copyright infringer.”
      Kittch reserve the right to terminate the accounts of “repeat copyright infringers.”
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Material on our Services may include inaccuracies or typographical errors.
      We have the right to make changes and update any information contained on our Sites without prior notice.
    </p>
  </li>

  <li>
    <h3 class="section-title">Termination</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may terminate your Membership or suspend your access to all or part of the Services, without notice,
      if you violate these Terms or you engage in any conduct that we, in our sole and absolute discretion,
      believe is in violation of any applicable law or regulation or is otherwise harmful to the interests of Kittch,
      any other user of the Services, or any third party. You agree that Kittch shall not be liable to you or any third party for removing your
      User Submissions or suspending or terminating your access to the Services (or any portion thereof).
      You may discontinue your participation in and access to the Services at any time.
      We reserve the right to investigate your use of the Services in the event we, in our sole and absolute discretion,
      believe you have violated these Terms. Upon termination, we have no obligation to retain, store, or provide you with any data,
      information or other content that you uploaded, stored, or transferred on or through the Services, other than as provided by law and in accordance with our
      <a class="link-underline" [routerLink]="'/privacy-policy'" target="_blank">Privacy Notice</a>.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You can request Kittch to disable your account at any time, for any reason, by emailing us
      <a class="link-underline" href="mailto:support@kittch.com" target="_blank">here</a> with the subject “Close My Account.”
      Please provide as much information as you can about your account, so we may identify the account and you correctly.
      If we do not receive sufficient information, we will be unable to disable or delete your account.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Provisions that, by their nature, should survive termination of these Terms shall survive termination.
      By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us,
      any limitations on our liability, any terms regarding ownership or intellectual property rights,
      and terms regarding disputes between us, including without limitation the arbitration agreement.
    </p>
  </li>

  <li>
    <h3 class="section-title">Modifications To Terms</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may, in our sole and absolute discretion, change these Terms from time to time.
      We may notify you of any changes by any reasonable means, including by posting a revised version of these
      Terms through the Services or by emailing you at the address you provided upon registering for an account.
      If you object to any such changes, your sole recourse shall be to stop using the Services.
      Your continued use of the Services following notice of any such changes shall indicate your acknowledgement
      of such changes and agreement to be bound by the terms and conditions of such changes.
    </p>
  </li>

  <li>
    <h3 class="section-title">Modifications To the Services</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We reserve the right to modify, suspend or discontinue all or any aspect of the Services with or without notice to you.
      We may periodically schedule system downtime for maintenance and other purposes.
      You also acknowledge that unplanned system outages may occur.
      The Services are provided over the Internet and the quality and availability of the Services may be affected by factors outside our reasonable control.
      We cannot accept any responsibility for any connectivity issues that you may experience when using the Services or for any loss of material, data,
      transactions or other information caused by system outages, whether planned or unplanned.
      You agree that we shall not be liable to you or any third party should we exercise the right to modify, suspend or discontinue the Services.
    </p>
  </li>

  <li>
    <h3 class="section-title">Password, Security and Confidentiality</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You are responsible for maintaining the confidentiality of your password to access the Services,
      and you are solely responsible for all activities that occur under your password.
      You agree to immediately change your password and notify us <a class="link-underline" href="mailto:support@kittch.com" target="_blank">here</a>
      if you suspect or become aware of any unauthorized use of your password or any other breach of security related to the Services.
      We reserve the right to require you to alter your password if we believe that your password is no longer secure.
      You agree that we will not be liable for any loss or damage arising from your failure to adequately
      safeguard your password or someone else’s use of your account.
    </p>
  </li>

  <li>
    <h3 class="section-title">Email</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Email is an important communication method for our online visitors.
      The person in whose name the email account is registered should generate all email sent to us.
      Email users shall not mask their identity by using a false name or another person’s name or account.
      We will use your email address and the content of any email for correspondence and visitor response purposes.
      Any non-personal information you provide to us by email, including, but not limited to, feedback, data, answers,
      questions, comments, suggestions, plans, ideas or the like, shall be deemed to be non-confidential,
      and we assume no obligation to protect such non-personal information contained in the email from disclosure.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The submission of non-personal information to us shall in no way prevent the purchase,
      manufacture or use of similar products, services, plans and ideas or the like by the Sites,
      their parents, affiliates, subsidiaries or operational providers for any purpose, and the Sites,
      their parents, affiliates, subsidiaries and operational providers shall be free to reproduce, use,
      disclose and distribute such information to others without liability or restriction.
      Any personal information transmitted with an email, such as the sender’s name, email or home addresses,
      will be protected in accordance with the policies set forth in our Privacy Notice.
    </p>
  </li>

  <li>
    <h3 class="section-title">Links</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may provide links to other Web sites or Internet resources for your convenience only,
      and such links do not signify or imply our endorsement of such other Web site or resource
      or its contents over which we have no control and which we do not monitor.
      You use those links at your own risk and should apply a reasonable level of caution and discretion in doing so.
      You agree that we shall have no responsibility or liability for any information,
      software, or materials found at any other web site or internet resource.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may also integrate with third parties who will interact with you under their terms of service.
      Please consult their terms of service with respect to your use of and access to such sites.
    </p>
  </li>

  <li>
    <h3 class="section-title">Applications</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      We may at some time offer software applications to help you gain access to our Services.
      In such circumstances, we grant you a personal, non-exclusive, non-transferable,
      limited license to install such software applications solely on the devices you will use to access the Services.
      You agree that we may provide you from time to time with automatic upgrades of these applications,
      which you will accept for installation. Please note that certain retail application stores that offer
      our applications may have separate sales terms that will be binding on you if you elect to download our applications from such merchants.
    </p>
    <ul class="level-3">
      <li class="text-content">
        <a
          class="link-underline"
          href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
          target="_blank"
          rel="noopener"
        >
          Apple App Store Terms
        </a>
      </li>
      <li class="text-content">
        <a
          class="link-underline"
          href="https://play.google.com/about/play-terms/index.html"
          target="_blank"
          rel="noopener"
        >
          Google Play Store Terms
        </a>
      </li>
    </ul>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Your use of the software must comply with all applicable import and export control
      laws and regulations of the United States and other countries.
    </p>
  </li>

  <li>
    <h3 class="section-title">Restrictions and Commercial Use</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Other than as set provided for in these Terms: you may not copy, make derivative works, resell,
      distribute, or make any commercial use of (other than to keep and share information for your own non-commercial purposes)
      any content, materials, or databases from our network or systems.
      You may not sell, sublicence or redistribute our software applications or incorporate them
      (or any portion of them) into another product. You may not reverse engineer, decompile or disassemble the software
      or otherwise attempt to derive the source code (except where expressly permitted by law)
      or the communications protocol for accessing the Services or networks.
      You may not remove proprietary notices in the software. You undertake not to use the Services for any purpose that
      is fraudulent or unlawful, nor to interfere with the operation of the Services.
      Your use of the Services must comply with our policies.
    </p>
  </li>

  <li>
    <h3 class="section-title">Disclaimer of Warranties</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK.
      WE PROVIDE THE SERVICES ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE EXPRESSLY DISCLAIM ALL WARRANTIES
      OF ANY KIND, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO THE KITTCH AND ITS SERVICES (INCLUDING, BUT NOT LIMITED
      TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE,
      AND NON-INFRINGEMENT).  KITTCH MAKES NO WARRANTY THAT IT WILL MEET YOUR REQUIREMENTS,
      OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
      OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. YOU ACKNOWLEDGE THAT ACCESS TO DATA
      (INCLUDING, BUT NOT LIMITED TO, DOCUMENTS, PHOTOGRAPHS, AND SOFTWARE FILES) STORED BY YOU
      OR OTHERS ON THE SERVICES IS NOT GUARANTEED AND THAT WE SHALL NOT BE RESPONSIBLE TO YOU FOR ANY LOSS
      OF DATA CAUSED BY THE SERVICES OR THEIR UNAVAILABILITY. WE MAKE NO WARRANTY AS TO THE RESULTS THAT
      MAY BE OBTAINED FROM THE USE OF THE SERVICES, OR AS TO THE ACCURACY OR RELIABILITY
      OF ANY INFORMATION OBTAINED THROUGH THE SERVICES, OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED.
      NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM KITTCH OR THROUGH THE SERVICES
      SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      ALTHOUGH THE INFORMATION PROVIDED TO VISITORS ON THE SERVICES IS OBTAINED OR COMPILED FROM SOURCES WE BELIEVE TO BE RELIABLE,
      THE SITES CANNOT AND DO NOT GUARANTEE THE ACCURACY, VALIDITY, TIMELINESS OR COMPLETENESS OF ANY INFORMATION
      OR DATA MADE AVAILABLE TO VISITORS OR ITS SUITABILITY FOR ANY PARTICULAR PURPOSE. NEITHER KITTCH NOR ANY OF THEIR AFFILIATED ENTITIES,
      MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OPERATIONAL OR PROMOTIONAL PROVIDERS, PROGRAM PRODUCERS
      OR SPONSORS ARE LIABLE OR SHALL HAVE RESPONSIBILITY OF ANY KIND TO ANY VISITOR FOR ANY LOSS OR DAMAGE THAT YOU INCUR IN THE EVENT OF:
      (I) ANY FAILURE OR INTERRUPTION OF THIS SITE; (II) ANY ACT OR OMISSION OF ANY THIRD PARTY INVOLVED IN MAKING THE SITES OR THE DATA
      CONTAINED HEREIN AVAILABLE TO YOU; (III) ANY OTHER CAUSE RELATING TO YOUR ACCESS OR USE, OR INABILITY TO ACCESS OR USE,
      ANY PORTION OF THE SITES OR MATERIALS ON THE SITES; (IV) YOUR INTERACTION OR SUBMISSIONS ON THE SITES,
      INCLUDING, BUT NOT LIMITED TO, RESUMÉ OR EMPLOYMENT SUBMISSIONS OR DIALOGUE BETWEEN HOSTS;
      OR (V) FROM YOUR FAILURE TO COMPLY WITH THIS AGREEMENT, WHETHER OR NOT THE CIRCUMSTANCES GIVING RISE TO SUCH CAUSE
      MAY HAVE BEEN WITHIN THE CONTROL OF THE SITES OR OF ANY VENDOR PROVIDING SOFTWARE, SERVICES OR SUPPORT.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      IN NO EVENT WILL KITTCH, ITS AFFILIATED ENTITIES, MEMBERS, OFFICERS OR EMPLOYEES BE LIABLE FOR ANY DIRECT,
      SPECIAL, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR ANY OTHER LOSS OR DAMAGES OF ANY KIND EVEN IF KITTCH
      OR ANY OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY THEREOF. PLEASE BE ADVISED THAT ONCE YOU LEAVE THE SITES,
      YOUR USE OF THE INTERNET WILL BE GOVERNED BY THE TERMS OF USE AGREEMENTS AND PRIVACY POLICIES,
      IF ANY, OF THE PARTICULAR SITE THAT YOU ARE ACCESSING, INCLUDING THOSE OF OUR OPERATIONAL PROVIDERS, ADVERTISERS, SPONSORS AND PROMOTIONAL PARTNERS.
      THE SITES, THEIR PARENTS, PARTNERS, AFFILIATES, SUBSIDIARIES, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE
      OR LIABLE FOR THE CONTENT, ACTIVITIES OR PRIVACY PRACTICES OF THE OTHER SITES, OR ANY LOSS OR DAMAGE THAT COULD RESULT FROM LEAVING THE SITE.
    </p>
  </li>

  <li>
    <h3 class="section-title">Exclusions and Limitations</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion
      of liability for incidental or consequential damages.
      Accordingly, some of the above limitations and disclaimers may not apply to you.
      To the extent that we may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities,
      the scope and duration of such warranty and the extent of our liability shall be the minimum permitted under such applicable law.
    </p>
  </li>

  <li>
    <h3 class="section-title">Indemnification</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You agree to indemnify, defend, and hold harmless us, our parents, subsidiaries, affiliates, officers, directors, employees, consultants,
      subcontractors and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorney fees)
      that such parties may incur as a result of or arising from your (or anyone using your accounts) violation of these Terms.
      We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you,
      and in such case, you agree to cooperate with our defense of such claim. You shall and hereby do waive California Civil Code Section 1542
      or any similar law of any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor
      or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that,
      if known by him or her, would have materially affected his or her settlement with the debtor or released party.”
    </p>
  </li>

  <li #disputesSection>
    <h3 class="section-title">Disputes with Kittch</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You and Kittch agree than any disputes arising out of or relating to these Terms or your use of the
      Services will be settled through individual  binding arbitration, conducted by the American Arbitration Association (“AAA”)
      and in accordance with their commercial arbitration rules.  To the extent permitted under AAA rules, the arbitrator may award costs,
      fees and expenses, including reasonable attorneys’ fees, to the prevailing party. A decision reached by arbitration will be final
      and binding and may be entered as a judgment by any court having jurisdiction.
      You understand that there is no judge or jury in arbitration, and that other procedural rights such as discovery
      and appeal are not available in an arbitration. You knowingly and voluntarily agree to waive your rights to a trial by judge or jury or to have your case heard in a court of law.
    </p>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Disputes will be settled on an individual basis. You agree to waive the right to bring or participate in a class, collective, consolidated
      or representative action or arbitration. Notwithstanding the foregoing, either party may seek injunctive
      or other equitable relief in a court of competent jurisdiction in New York County to protect such party’s
      intellectual property rights pending completion of the arbitration.
    </p>
  </li>

  <li>
    <h3 class="section-title">Trademarks & Patents</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The “KITTCH” name, design, and logos, as well as certain other names, logos, and materials displayed within
      the Services constitute trademarks, trade names, service marks or logos (“Marks”) of Kittch or other entities.
      You are not authorized to use any such Marks. Ownership of all such Marks and the goodwill associated therewith
      remains with us or those other entities.
    </p>
  </li>

  <li>
    <h3 class="section-title">Copyrights; Restrictions on Use</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      The content on the Sites and Services (the “Content”), including without limitation, video, text, photos,
      and graphics, is protected under United States and international copyright laws, is subject to other intellectual
      property and proprietary rights and laws, and is owned by us or our licensors.
      Other than with respect to your own User Submissions: (a) the Content may not be copied, modified, reproduced,
      republished, posted, transmitted, sold, offered for sale, or redistributed in any way without our prior written permission
      and that of our applicable licensors; and (b) you must abide by all copyright notices, information,
      or restrictions contained in or attached to any Content. We give you a personal, revocable, non-assignable,
      non-sublicensable and non-exclusive right to access and use the Services in the manner permitted by these Terms.
      You acknowledge that you have no right to have access to all or any part of the Services in source code form.
    </p>
  </li>

  <li>
    <h3 class="section-title">Electronic Notices</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You agree to transact with us electronically. Your affirmative act of registering, using or logging into
      the Services constitutes your acceptance signature to these Terms. We may provide notices to you electronically
      (1) via email if you have provided us with a valid email address or (2) by posting the notice on a website designated by us for this purpose.
      The delivery of any Notice is effective when sent or posted by us, regardless of whether you read the Notice or actually receive delivery.
      You can withdraw your consent to receive Notices electronically by discontinuing your use of the Service.
    </p>
  </li>

  <li>
    <h3 class="section-title">Governing Law and Jurisdiction</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      These Terms and the relationship between you and us shall be governed by the laws of the State of New York as applied
      to agreements made, entered into, and performed entirely in the State of New York, notwithstanding your actual place of residence.
      All lawsuits arising from or relating to these Terms or your use of the Services shall be brought in the courts located
      in New York County and you hereby irrevocably submit to the exclusive personal jurisdiction of such courts for such purpose.
    </p>
  </li>

  <li>
    <h3 class="section-title">Miscellaneous</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend"></p>
    <h4 class="section-title">Entire Agreement</h4>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      These Terms, together with the terms of any end user license agreement to which you agree when downloading any software that we make available through
      the Services and any additional terms to which you agree when using particular elements of the Services (for example, terms specific
      to a site within the network of Sites or relating to the payment of fees for certain Services content or services),
      constitute the entire and exclusive and final statement of the agreement between you and us with respect to the subject matter hereof,
      and govern your use of the Services, superseding any prior agreements or negotiations between you and us with respect to the subject matter hereof.
    </p>

    <h4 class="section-title">Transfer of Rights</h4>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      You may not transfer your rights or obligations under these
      Terms to anyone else without our prior written consent.
    </p>

    <h4 class="section-title">Waiver and Severability</h4>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.
      If any provision of these Terms is found by a court of competent jurisdiction to be invalid, you nevertheless agree that
      the court should endeavor to give effect to the intentions of us and you as reflected in the provision,
      and that the other provisions of these Terms remain in full force and effect. If we do not insist immediately
      that you do anything you are required to do under these Terms, or if we delay in taking steps against you in respect of your breaking these Terms,
      that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.
      For users outside of the European Union Only: You agree that regardless of any statute or law to the contrary,
      any claim or cause of action arising out of or related to use of the Services or these Terms must be filed within one
      (1) year after such claim or cause of action arose or be forever barred.
    </p>

    <h4 class="section-title">Our Relationship</h4>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Both parties are independent contractors of each other.
      No other person shall have any rights to enforce any of the provisions contained in these Terms.
    </p>
  </li>
</ol>

