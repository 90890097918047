<div class="wrap">
  <div class="wrap-inner">
    <picture class="meal-photo">
      <source srcset="{{ recipe.mealPhoto }}"/>
      <img
        class="meal-photo"
        src="assets/ui/images/png/no-image.png"
        alt="meal"
      />
    </picture>
    <div class="recipe-name" data-testid="recipe-name">{{ recipe.recipeName }}</div>
  </div>

  <label class="checkmark">
    <input
      type="checkbox"
      class="checkmark__input"
      [checked]="recipe.addedToStream"
      [disabled]="isDisabled"
      (change)="onCheckboxClick($event)"
      data-testid="checkbox"
    />
    <span class="checkmark__label"></span>
  </label>
</div>
