import { AccessIndicatorComponent } from '@kitch/ui/components/access-indicator/access-indicator.component';
import { AlertComponent } from '@kitch/ui/components/alert/alert.component';
import { AnimatedInputComponent } from '@kitch/ui/components/animated-input/animated-input.component';
import { AvatarWithOutPhotoComponent }
  from '@kitch/ui/components/avatar-with-out-photo/avatar-with-out-photo.component';
import { AvatarComponent } from '@kitch/ui/components/avatar/avatar.component';
import { ButtonComponent } from '@kitch/ui/components/button/button.component';
import { ChannelCardComponent } from '@kitch/ui/components/channel-card/channel-card.component';
import { ClamsPlanComponent } from '@kitch/ui/components/clams-plan/clams-plan.component';
import { ConfirmDialogComponent } from '@kitch/ui/components/confirm-dialog/confirm-dialog.component';
import { CreditCardTransactionComponent }
  from '@kitch/ui/components/credit-card-transaction/credit-card-transaction.component';
import { CreditCardComponent } from '@kitch/ui/components/credit-card/credit-card.component';
import {
  DownloadStreamButtonComponent,
} from '@kitch/ui/components/download-stream-button/download-stream-button.component';
import { EmailTextInputsComponent } from '@kitch/ui/components/email-text-inputs/email-text-inputs.component';
import { EntranceQuestionsComponent } from '@kitch/ui/components/entrance-questions/entrance-questions.component';
import { ErrorsListComponent } from '@kitch/ui/components/errors-list/errors-list.component';
import { GuestButtonsComponent } from '@kitch/ui/components/guest-buttons/guest-buttons.component';
import { ImageChooserComponent } from '@kitch/ui/components/image-chooser/image-chooser.component';
import { ImageCreatorComponent } from '@kitch/ui/components/image-creator/image-creator.component';
import { CarouselComponent } from '@kitch/ui/components/ivyсarousel/carousel.component';
import { RecipeListItemComponent } from '@kitch/ui/components/lists-item/recipe-list-item/recipe-list-item.component';
import { StreamListItemComponent } from '@kitch/ui/components/lists-item/stream-list-item/stream-list-item.component';
import { UserListItemComponent } from '@kitch/ui/components/lists-item/user-list-item/user-list-item.component';
import { LoginFormComponent } from '@kitch/ui/components/login-form/login-form.component';
import { LoginPageComponent } from '@kitch/ui/components/login-page/login-page.component';
import {
  LoginRegisterModalWrapperComponent,
} from '@kitch/ui/components/login-register-modal-wrapper/login-register-modal-wrapper.component';
import { LoginVerificationComponent } from '@kitch/ui/components/login-verification/login-verification.component';
import { MainStageChannelComponent } from '@kitch/ui/components/main-stage-channel/main-stage-channel.component';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { PageNotFoundComponent } from '@kitch/ui/components/page-not-found/page-not-found.component';
import { PreloaderComponent } from '@kitch/ui/components/preloader/preloader.component';
import { PrivacyPolicyComponent } from '@kitch/ui/components/privacy-pocily/privacy-policy.component';
import { RatingComponent } from '@kitch/ui/components/rating/rating.component';
import { RecipeCardComponent } from '@kitch/ui/components/recipe-card/recipe-card.component';
import { RecipeInlineCardComponent } from '@kitch/ui/components/recipe-inline-card/recipe-inline-card.component';
import { RegisterFormComponent } from '@kitch/ui/components/register-form/register-form.component';
import { RegisterStepPageComponent } from '@kitch/ui/components/register-step-page/register-step-page.component';
import { SearchInputComponent } from '@kitch/ui/components/search-input/search-input.component';
import { ShareButtonsComponent } from '@kitch/ui/components/share-buttons/share-buttons.component';
import { ShoppingCartButtonComponent } from '@kitch/ui/components/shopping-cart-button/shopping-cart-button.component';
import {
  ShoppingCartProductComponent,
} from '@kitch/ui/components/shopping-cart-product/shopping-cart-product.component';
import { ShoppingCartComponent } from '@kitch/ui/components/shopping-cart/shopping-cart.component';
import { StoresListComponent } from '@kitch/ui/components/shopping-cart/store-list/stores-list.component';
import { SlideToggleComponent } from '@kitch/ui/components/slide-toggle/slide-toggle.component';
import { SocialButtonsComponent } from '@kitch/ui/components/social-buttons/social-buttons.component';
import { StreamForRecipeCardComponent }
  from '@kitch/ui/components/stream-for-recipe-card/stream-for-recipe-card.component';
import { StreamPastCardComponent } from '@kitch/ui/components/stream-past-card/stream-past-card.component';
import { SubscriptionCardComponent } from '@kitch/ui/components/subscription-card/subscription-card.component';
import { SuccessComponent } from '@kitch/ui/components/success/success.component';
import { TabsComponent } from '@kitch/ui/components/tabs/tabs.component';
import { TermsCreatorsComponent } from '@kitch/ui/components/terms/terms-creators/terms-creators.component';
import { TermsComponent } from '@kitch/ui/components/terms/terms/terms.component';
import { ViewsCounterComponent } from '@kitch/ui/components/views-counter/views-counter.component';
import {
  WalmartButtonComponent,
} from '@kitch/ui/components/walmart-button/walmart-button.component';
import { WysiwygEditorComponent } from '@kitch/ui/components/wysiwyg-editor/wysiwyg-editor.component';
import { AuthRequiredBlockComponent } from './auth-required-block/auth-required-block.component';
import { AvatarExternalRecipeComponent } from './avatar-external-recipe/avatar-external-recipe.component';
import { LinkBackComponent } from './link-back/link-back.component';
import { MultipleAvatarsComponent } from './multiple-avatars/multiple-avatars.component';
import { RecipeFormComponent } from './recipe-form/recipe-form.component';

export * from '@kitch/ui/components/auth-required-block/auth-required-block.component';
export * from '@kitch/ui/components/alert/alert.component';
export * from '@kitch/ui/components/animated-input/animated-input.component';
export * from '@kitch/ui/components/avatar/avatar.component';
export * from '@kitch/ui/components/button/button.component';
export * from '@kitch/ui/components/confirm-dialog/confirm-dialog.component';
export * from '@kitch/ui/components/link-back/link-back.component';
export * from '@kitch/ui/components/credit-card/credit-card.component';
export * from '@kitch/ui/components/errors-list/errors-list.component';
export * from '@kitch/ui/components/image-chooser/image-chooser.component';
export * from '@kitch/ui/components/login-page/login-page.component';
export * from '@kitch/ui/components/login-verification/login-verification.component';
export * from '@kitch/ui/components/page-not-found/page-not-found.component';
export * from '@kitch/ui/components/success/success.component';
export * from '@kitch/ui/components/lists-item/recipe-list-item/recipe-list-item.component';
export * from '@kitch/ui/components/lists-item/stream-list-item/stream-list-item.component';
export * from '@kitch/ui/components/lists-item/user-list-item/user-list-item.component';
export * from '@kitch/ui/components/recipe-card/recipe-card.component';
export * from '@kitch/ui/components/ivyсarousel/carousel.component';
export * from '@kitch/ui/components/views-counter/views-counter.component';
export * from '@kitch/ui/components/channel-card/channel-card.component';
export * from '@kitch/ui/components/stream-past-card/stream-past-card.component';
export * from '@kitch/ui/components/main-stage-channel/main-stage-channel.component';
export * from '@kitch/ui/components/clams-plan/clams-plan.component';
export * from '@kitch/ui/components/privacy-pocily/privacy-policy.component';
export * from '@kitch/ui/components/modal/modal.component';
export * from '@kitch/ui/components/credit-card-transaction/credit-card-transaction.component';
export * from '@kitch/ui/components/slide-toggle/slide-toggle.component';
export * from '@kitch/ui/components/subscription-card/subscription-card.component';
export * from '@kitch/ui/components/terms/terms/terms.component';
export * from '@kitch/ui/components/multiple-avatars/multiple-avatars.component';
export * from '@kitch/ui/components/preloader/preloader.component';
export * from '@kitch/ui/components/share-buttons/share-buttons.component';
export * from '@kitch/ui/components/search-input/search-input.component';
export * from '@kitch/ui/components/stream-for-recipe-card/stream-for-recipe-card.component';
export * from '@kitch/ui/components/recipe-inline-card/recipe-inline-card.component';
export * from '@kitch/ui/components/recipe-form/recipe-form.component';
export * from '@kitch/ui/components/login-form/login-form.component';
export * from '@kitch/ui/components/register-form/register-form.component';
export * from '@kitch/ui/components/login-register-modal-wrapper/login-register-modal-wrapper.component';
export * from '@kitch/ui/components/shopping-cart/shopping-cart.component';
export * from '@kitch/ui/components/shopping-cart/store-list/stores-list.component';
export * from '@kitch/ui/components/shopping-cart-product/shopping-cart-product.component';
export * from '@kitch/ui/components/walmart-button/walmart-button.component';
export * from '@kitch/ui/components/register-step-page/register-step-page.component';
export * from '@kitch/ui/components/wysiwyg-editor/wysiwyg-editor.component';
export * from '@kitch/ui/components/email-text-inputs/email-text-inputs.component';
export * from '@kitch/ui/components/avatar-with-out-photo/avatar-with-out-photo.component';
export * from '@kitch/ui/components/shopping-cart-button/shopping-cart-button.component';
export * from '@kitch/ui/components/access-indicator/access-indicator.component';
export * from '@kitch/ui/components/social-buttons/social-buttons.component';
export * from '@kitch/ui/components/download-stream-button/download-stream-button.component';
export * from '@kitch/ui/components/tabs/tabs.component';
export * from '@kitch/ui/components/avatar-external-recipe/avatar-external-recipe.component';

export const uiComponents = [
  AlertComponent,
  AnimatedInputComponent,
  AvatarComponent,
  AvatarExternalRecipeComponent,
  ButtonComponent,
  ConfirmDialogComponent,
  CreditCardComponent,
  ErrorsListComponent,
  GuestButtonsComponent,
  ImageChooserComponent,
  LoginFormComponent,
  LoginPageComponent,
  EntranceQuestionsComponent,
  RegisterFormComponent,
  RegisterStepPageComponent,
  LoginVerificationComponent,
  PageNotFoundComponent,
  SuccessComponent,
  RecipeListItemComponent,
  UserListItemComponent,
  RecipeCardComponent,
  CarouselComponent,
  ViewsCounterComponent,
  ChannelCardComponent,
  StreamPastCardComponent,
  ClamsPlanComponent,
  PrivacyPolicyComponent,
  ModalComponent,
  CreditCardTransactionComponent,
  SlideToggleComponent,
  SubscriptionCardComponent,
  TermsComponent,
  TermsCreatorsComponent,
  MultipleAvatarsComponent,
  MainStageChannelComponent,
  PreloaderComponent,
  ShareButtonsComponent,
  SearchInputComponent,
  StreamForRecipeCardComponent,
  RecipeInlineCardComponent,
  LoginRegisterModalWrapperComponent,
  ShoppingCartComponent,
  ShoppingCartProductComponent,
  StoresListComponent,
  WalmartButtonComponent,
  WysiwygEditorComponent,
  EmailTextInputsComponent,
  AvatarWithOutPhotoComponent,
  AuthRequiredBlockComponent,
  ShoppingCartButtonComponent,
  AccessIndicatorComponent,
  LinkBackComponent,
  RecipeFormComponent,
  SocialButtonsComponent,
  DownloadStreamButtonComponent,
  TabsComponent,
  ImageCreatorComponent,
  RatingComponent,
  StreamListItemComponent,
];
