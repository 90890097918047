<app-button
  text="create image"
  role="secondary"
  [iconWidth]="24"
  [iconHeight]="24"
  iconUrl="assets/ui/images/svg/create-image.svg"
  type="button"
  (clicked)="openImageCreatorModal()"
></app-button>

<app-modal #imageCreatorModal class="image-creator-modal">
  <ng-container header>
    <h2 class="modal-title">{{ modalTitle }}</h2>
  </ng-container>

  <ng-container body>
    <div class="image-creator-modal__content" [ngSwitch]="true">
      <ng-container
        *ngSwitchCase="isLoading"
        [ngTemplateOutlet]="loadingTemplate"
      ></ng-container>

      <ng-container
        *ngSwitchCase="isUnavailableGenerating"
        [ngTemplateOutlet]="isUnavailableGeneratingTemplate"
      ></ng-container>

      <ng-container *ngSwitchDefault>
        <span class="image-creator-modal__label">
          <ng-container *ngIf="!showImagesChooser; else selectImageText">
            Type a prompt for the image you would like our Kittch AI to create!
            We'll give you options and you can choose your favorite.
          </ng-container>

          <ng-template #selectImageText>
            Select an image to save.
            If you don't like what you see, edit the prompt below and create more images.
          </ng-template>
        </span>

        <div class="image-creator-modal__images-chooser" *ngIf="showImagesChooser">
          <div
            *ngFor="let image of images; let i = index"
            class="image-creator-modal__image-container"
            [ngClass]="{'image-creator-modal__image-container--selected': selectedImage === image}"
          >
            <img
              [src]="image"
              alt="Generated image {{ i + 1 }}"
              width="220"
              height="150"
              (click)="selectImage(image)"
            >
          </div>
        </div>

        <textarea
          [(ngModel)]="prompt"
          class="image-creator-modal__textarea"
          [placeholder]="placeholderForPrompt"
        ></textarea>

        <span class="image-creator-modal__description-prompt">
          Please note: Any image generated is public domain and not creator IP
        </span>

        <div class="image-creator-modal__bottom-panel">
          <span class="image-creator-modal__support" *ngIf="showImagesChooser">
            If you’re having any issues or would like to provide us with feedback, please email us at
            <a
              href="mailto:support@kittch.com"
              target="_blank"
            >
              support@kittch.com
            </a>
          </span>

          <div class="content__buttons">
            <ng-container *ngIf="showImagesChooser; else createImageBtn">
              <app-button
                text="create images again"
                type="button"
                [isDisabled]="!prompt || prompt === previousPrompt"
                (clicked)="createImages(true)"
                role="secondary"
              ></app-button>

              <app-button
                text="save"
                type="button"
                [isDisabled]="!selectedImage"
                (clicked)="saveImage()"
              ></app-button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</app-modal>

<ng-template #createImageBtn>
  <app-button
    text="create images"
    type="button"
    [isDisabled]="!prompt"
    (clicked)="createImages()"
  ></app-button>
</ng-template>

<ng-template #loadingTemplate>
  <div class="image-creator-modal__loading">
    <div class="image-creator-modal__loading-container">
      <div
        *ngFor="let i of 4 | arrayCreator"
        [style.animation-delay]="i * 500 + 'ms'"
        class="image-creator-modal__loading-el"
      ></div>
    </div>

    <span class="image-creator-modal__loading-text">
      Hang tight!  We’re cooking up those images for you.
    </span>
  </div>
</ng-template>

<ng-template #isUnavailableGeneratingTemplate>
  <div class="image-creator-modal__unavailable">
    <span class="image-creator-modal__unavailable-title">
      images unavailable at this time
    </span>
    <span class="image-creator-modal__unavailable-text">
      Please upload an image for this stream
    </span>

    <input
      #uploadInput
      hidden
      type="file"
      [accept]="imageUploadContentTypes"
      (change)="onFileChanged($event)"
    />

    <app-button
      class="chooser-button"
      (clicked)="uploadInput.click()"
      [text]="'choose file'"
      [role]="'secondary'"
      [iconUrl]="'assets/ui/images/svg/file.svg'"
      [type]="'button'"
    ></app-button>
  </div>
</ng-template>
