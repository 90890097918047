<div class="shopping-cart">
  <header class="shopping-cart__header">
    <h5 class="shopping-cart__title">Select Store</h5>
    <button class="modal-close-btn" (click)="closeStoreList()">
      <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
      <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
    </button>
  </header>

  <main class="shopping-cart__main" [formGroup]="storesListForm">
    <div class="shopping-cart__zip-input-container">
      <img
        class="shopping-cart__zip-input-pin"
        src="assets/ui/images/svg/shopping-cart-location.svg"
        alt="pin"
        width="16"
        height="20"
      />
      <input
        class="shopping-cart__zip-input"
        type="text"
        placeholder="ZIP code"
        formControlName="storeSearch"
        (keyup)="onSearchEnter($event)"
      />
      <app-errors-list formControlName="storeSearch" class="shopping-cart__zip-error"></app-errors-list>
    </div>

    <ng-container [ngSwitch]="storeLoadStatus">
      <ul *ngSwitchCase="StoreLoadStatus.HAS_STORES" class="shopping-cart__store-list">
        <li class="shopping-cart__store-item" *ngFor="let store of stores">
          <input
            type="radio"
            [id]="store.fulfillmentStoreId"
            [value]="store.fulfillmentStoreId"
            name="store"
            formControlName="store"
          />
          <label class="shopping-cart__store-item-label" [for]="store.fulfillmentStoreId">
            <span class="shopping-cart__store-item-name">{{store.accessPointName}}</span>
            <span>{{getAddress(store)}}</span>
            <span
              *ngIf="store.fulfillmentType === StoreFulfillmentType.INSTORE_PICKUP"
              class="shopping-cart__instore"
            >
              <img
                class="shopping-cart__instore-img"
                src="assets/ui/images/svg/checked-blue.svg"
                alt="Check"
                width="20"
                height="20"
              />
              In-store pickup
            </span>
          </label>
        </li>
      </ul>
      <div class="preloader" *ngSwitchCase="StoreLoadStatus.LOADING">
        <app-preloader></app-preloader>
      </div>
      <div class="empty-state" *ngSwitchCase="StoreLoadStatus.EMPTY">
        <img
          class="empty-state__img"
          src="/assets/ui/images/svg/emty-state-picture.svg"
          alt="no stores"
          width="130"
          height="130"
        />
        <h5 class="empty-state__text">
          Unfortunately, no stores were found in your area. But you can go to
          <a
            href="https://www.walmart.com/"
            target="_blank"
            rel="noopener"
          >Walmart.com</a>
          and shop directly there.
        </h5>
      </div>
    </ng-container>
  </main>

  <footer class="shopping-cart__footer shopping-cart__footer--stores">
    <app-button
      [text]="'Save'"
      [type]="'button'"
      class="shopping-cart__footer-item shopping-cart__save-button"
      [isDisabled]="isSaveDisabled"
      (clicked)="saveStore()"
    ></app-button>
  </footer>
</div>

