import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberFormInput]',
})
export class NumberFormInputDirective {
  @HostListener('input', ['$event'])
  inputValue(): void {
    this.formattingNumberInput();
  }

  constructor(private ngControl: NgControl, private el: ElementRef) { }

  private formattingNumberInput(): void {
    const value = this.el.nativeElement.value;
    let formattedValue: string;

    if (isNaN(+value)) {
      formattedValue = value.replace(/[^\d.-]/g, '');
      const dotsLength = formattedValue.replace(/[^.]/g, '').length;

      if (dotsLength > 1) {
        const lastDotIndex = formattedValue.lastIndexOf('.');

        formattedValue = formattedValue.slice(0, lastDotIndex);
      }

      this.el.nativeElement.value = formattedValue;
      this.ngControl.control.setValue(formattedValue);
    }
  }
}
