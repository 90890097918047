// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const equals = (a, b): boolean => {
  if (a === b) return true;
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
    // eslint-disable-next-line eqeqeq
    return a == b;
  }
  if (a.prototype !== b.prototype) return false;

  const keys = Object.keys(a);

  if (keys.length !== Object.keys(b).length) return false;

  return keys.every(k => equals(a[k], b[k]));
};

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export type ObjectWithArraysProps = {
  [key: string]: any[]
}
