export enum SocialNetworkHostEnum {
  FACEBOOK = 'www.facebook.com',
  FACEBOOK_LINK = 'fb.watch',
  INSTAGRAM = 'www.instagram.com',
  LINKEDIN = 'www.linkedin.com',
  PINTEREST = 'www.pinterest.com',
  TIKTOK = 'www.tiktok.com',
  TIKTOK_LINK = 'vm.tiktok.com',
  TWITTER = 'twitter.com',
  YOUTUBE = 'www.youtube.com',
  YOUTUBE_LINK = 'youtu.be',
}

export enum SocialNetworkNameEnum {
  FACEBOOK = 'facebook',
  FACEBOOK_LINK = 'fb.watch',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  PINTEREST = 'pinterest',
  TIKTOK = 'tiktok',
  TIKTOK_LINK = 'vm.tiktok',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  YOUTUBE_LINK = 'youtu.be',
}

export enum SocialNetworkImagesEnum {
  FACEBOOK = 'assets/ui/images/svg/facebook-icon.svg',
  FACEBOOK_LINK = 'assets/ui/images/svg/facebook-icon.svg',
  INSTAGRAM = 'assets/ui/images/svg/instagram-icon.svg',
  LINKEDIN = 'assets/ui/images/svg/linkedin-icon.svg',
  PINTEREST = 'assets/ui/images/svg/pinterest-icon.svg',
  TIKTOK = 'assets/ui/images/svg/tiktok-icon.svg',
  TIKTOK_LINK = 'assets/ui/images/svg/tiktok-icon.svg',
  TWITTER = 'assets/ui/images/svg/twitter-icon.svg',
  YOUTUBE = 'assets/ui/images/svg/youtube-icon.svg',
  YOUTUBE_LINK = 'assets/ui/images/svg/youtube-icon.svg',
}

