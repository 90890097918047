import { Inject, Injectable } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Observable, Observer, Subject } from 'rxjs';
import { environment } from '@kitch/data-access/env/environment';
import { SocketEventMessage } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services/token.service';

@Injectable()
export class WebsocketService {
  private ws: ReconnectingWebSocket;
  private subject: Subject<SocketEventMessage>;

  constructor(
    private tokenService: TokenService,
    @Inject(LOCATION) private location: Location,
  ) {
  }

  connect(): Observable<SocketEventMessage> {
    if (!this.subject || this.ws?.readyState === WebSocket.CLOSED) {
      this.subject = this.create();
    }

    return this.subject.asObservable();
  }

  disconnect(): void {
    this.ws?.close();
  }

  sendMassage(message: SocketEventMessage): void {
    this.subject.next(message);
  }

  private create(): Subject<SocketEventMessage> {
    this.ws = new ReconnectingWebSocket(this.wsUrl, [], { debug: !environment.production });

    const observable = new Observable((obs: Observer<string>) => {
      this.ws.onmessage = (event: MessageEvent<string>) => obs.next(JSON.parse(event.data));
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = (e) => {
        if (e.wasClean) {
          obs.complete.bind(obs);
        } else {
          obs.error.bind(obs);
        }
      };

      return this.ws.close.bind(this.ws);
    });

    const observer = {
      next: (data: string) => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      },
    };

    return Subject.create(observer, observable);
  }

  private wsUrl = (): Promise<string> => {
    let intervalId;

    return new Promise((resolve) => {
      intervalId = setInterval(() => {
        const token = this.tokenService.getToken();

        if (token) {
          clearInterval(intervalId);
          resolve(`${environment.wsUrl}?auth=${token}`);
        }
      }, 1000);
    });
  };
}
