import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-user-already-signup-modal',
  templateUrl: './user-already-signup-modal.component.html',
  styleUrls: ['./user-already-signup-modal.component.scss'],
})
export class UserAlreadySignupModalComponent implements OnChanges {
  @ViewChild('userAlreadySignupModal', { static: false }) userAlreadySignupModal: ModalComponent;

  @Input() isOpen = false;

  @Output() isUserAlreadySignupOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() openLoginForm: EventEmitter<void> = new EventEmitter<void>();

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.userAlreadySignupModal.open();
    } else {
      this.userAlreadySignupModal?.close();
    }
  }

  redirectToLoginPage(): void {
    this.isUserAlreadySignupOpen.emit(false);
    this.openLoginForm.emit();
    this.userAlreadySignupModal.close();
  }
}
