import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { from, of, Subject, throwError } from 'rxjs';
import { concatMap, finalize, switchMap } from 'rxjs/operators';
import {
  GA_EVENT_OPEN_LOGIN_MODAL,
  GA_EVENT_SUBMIT_LOGIN_MODAL,
  NOT_FOUND,
  NOT_FOUND_USER_ERROR,
} from '@kitch/data-access/constants';
import {
  CommonUserRole,
  LoginResponse,
  ServerError,
  SSOUser,
  USER_LOGGED_FOR_TYPEFORM,
} from '@kitch/data-access/models';
import { AuthService, TokenService } from '@kitch/data-access/services';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { UserAction } from '@kitch/ui/components/modals/user-not-found-modal/user-not-found-modal.component';
import { TimezonePipe } from '@kitch/ui/pipes';
import { AlertService } from '@kitch/ui/services';
import { Socials } from '@kitch/user/shared/constants/social';

enum LoginStep {
  DEFAULT = 'default',
  VERIFICATION = 'verification',
}

@UntilDestroy()
@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss', '../modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent implements OnInit, OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  readonly error$: Subject<string> = new Subject<string>();

  @Input()
  readonly isOpen = false;

  @Input()
  readonly error: string;

  @Input() userRole: CommonUserRole;

  @Input()
  readonly pageView: string;

  @Input() source: string;

  @Output()
  readonly isModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  readonly openRegisterModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  readonly reloadPage: EventEmitter<void> = new EventEmitter<void>();

  private socialAuthService: SocialAuthService;

  readonly loginStepDefault: LoginStep = LoginStep.DEFAULT;
  readonly loginStepVerification: LoginStep = LoginStep.VERIFICATION;

  phoneNumber: string;
  otpCode: string;
  savedProfileId: string;
  usingSSO: Socials;

  isNotFoundModal = false;
  isUserLoggingIn: boolean;

  loginStep: LoginStep = this.loginStepDefault;
  loading = false;
  isPhoneShown = false;

  constructor(
    private readonly alertService: AlertService,
    private readonly authService: AuthService,
    private readonly tokenService: TokenService,
    private readonly cdr: ChangeDetectorRef,
    protected readonly $gaService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.socialAuthService = inject(SocialAuthService);
    }
  }

  ngOnInit(): void {
    this.savedProfileId = this.tokenService.getProfileId();
    this.authService.socialAuthState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((user: SSOUser) => {
        if (this.isOpen) {
          this.usingSSO = user.SSOProvider;

          this.authService.authorizeThroughSSO(user)
            .subscribe(() => {
              this.onLoginVerified(this.usingSSO);
            });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen?.currentValue) {
      this.openModal();
      this.$gaService.gtag('event', GA_EVENT_OPEN_LOGIN_MODAL, {
        time: new TimezonePipe().transform(new Date()),
        trigger_source: this.source,
      });
    }
  }

  login({ phoneNumber }: { phoneNumber: string }): void {
    this.phoneNumber = phoneNumber;
    this.authService.login(this.phoneNumber).pipe(
      switchMap((response) => {
        return this.validateRole()
          ? of(response)
          : throwError(() => of({ message: 'User not found', statusCode: 404 }));
      }),
      finalize(() => this.isUserLoggingIn = false),
      untilDestroyed(this),
    ).subscribe({
      next: (response: LoginResponse) => this.onLoginResolve(response),
      error: (error: ServerError) => this.onLoginReject(error),
    });
    this.$gaService.pageView(this.pageView, 'Login Submit', undefined, { user_phone: phoneNumber });
  }

  onResendAuthCode(): void {
    this.authService.resendAuthCode(this.phoneNumber)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ({ otp }: LoginResponse) => {
          this.alertService.success('SMS code has been sent to your phone');
          this.setOtpCode(otp);
          this.cdr.detectChanges();
        },
        error: ({ message }: ServerError) => this.error$.next(message),
      });
  }

  onVerifyAuthCode(optCode: string): void {
    this.loading = true;
    this.cdr.detectChanges();
    this.authService
      .verifyAuthCode(optCode)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => {
          this.onLoginVerified('phone');
        },
        error: ({ message }: ServerError) => this.error$.next(message),
      });
  }

  setNotFoundModal(value: boolean): void {
    this.isNotFoundModal = value;
    this.cdr.detectChanges();
  }

  handleUserNotFoundModalAction(action: UserAction): void {
    switch (action) {
      case 'login':
        this.openModal();
        break;
      case 'signup':
        this.emitOpenRegisterModal();
        break;
    }
  }

  onModalClosed(): void {
    this.resetProperties();
    this.isModal.emit(false);
  }

  emitOpenRegisterModal(): void {
    this.closeModal();
    this.openRegisterModal.emit(true);
  }

  private resetProperties(): void {
    this.loginStep = this.loginStepDefault;
    this.otpCode = '';
    this.phoneNumber = '';
    this.cdr.detectChanges();
  }

  private setOtpCode(value: string): void {
    if (value) {
      this.otpCode = value;
    }
  }

  private onLoginResolve(response: LoginResponse): void {
    this.loginStep = this.loginStepVerification;
    sessionStorage.setItem(USER_LOGGED_FOR_TYPEFORM, 'true');
    this.setOtpCode(response.otp);
    this.cdr.detectChanges();
  }

  private onLoginReject({ message, statusCode }: ServerError): void {
    if ([NOT_FOUND].includes(statusCode) && [NOT_FOUND_USER_ERROR].includes(message)) {
      this.closeModal();
      this.setNotFoundModal(true);
    }
  }

  private onLoginVerified(method: string) {
    const { pathname } = location;
    const newProfileId = this.tokenService.getProfileId();

    this.$gaService.gtag('event', GA_EVENT_SUBMIT_LOGIN_MODAL, {
      time: new TimezonePipe().transform(new Date()),
      trigger_source: this.source,
      method: method,
    });

    this.closeModal();
    if (pathname.includes(this.savedProfileId)) {
      location.replace(pathname.replace(this.savedProfileId, newProfileId));
    } else {
      this.reloadPage.emit();
    }
  }

  private openModal(): void {
    this.modal?.open();
    this.cdr.markForCheck();
  }

  private closeModal(): void {
    this.isModal.emit(false);
    this.modal?.close();
  }

  private validateRole() {
    return this.authService.isValidRole(this.userRole);
  }

  togglePhoneSection(): void {
    this.isPhoneShown = !this.isPhoneShown;
  }

  onMetaBtnClick(socialName: Socials): void {
    from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))
      .pipe(
        concatMap((socialUser) => {
          const user = {
            SSOProvider: Socials.META,
            email: socialUser.email,
            firstName: socialUser.firstName,
            lastName: socialUser.lastName,
            authToken: socialUser.authToken,
          };

          return this.authService.authorizeThroughSSO(user);
        }),
        untilDestroyed(this),
      )
      .subscribe((() => {
        this.onLoginVerified(socialName);
      }));
  }
}
