export enum SocketEventType {
  UPDATE_ALL_STREAMS_PAGE_LIST = 'UPDATE_ALL_STREAMS_PAGE_LIST',
  UPDATE_LIVE_STREAMS_LIST = 'UPDATE_LIVE_STREAMS_LIST',
  UPDATE_STREAM = 'UPDATE_STREAM'
}

export interface SocketEventMessage {
  payload?: SocketEventPayload;
  type: SocketEventType;
}

export interface SocketEventPayload {
  streamId?: string;
}
