import { BrowserEnum } from '@kitch/ui/enums';

export class BrowserDetectTool {
  static get getCurrentBrowser(): BrowserEnum {
    return Object.keys(BrowserEnum)
      .find((browser: string) => BrowserDetectTool.isCurrentBrowser(BrowserEnum[browser])) as BrowserEnum;
  }

  static isCurrentBrowser(browser: BrowserEnum): boolean {
    const { userAgent } = navigator;

    return userAgent.toLowerCase().indexOf(browser) > -1;
  }
}
