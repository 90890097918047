import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { makeUri } from '@kitch/util';

import { OPENWEB_URL } from '../constants';

import { ApiService } from './api.service';

@Injectable()
export class OpenwebService {
  constructor(private apiService: ApiService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getSSO(codeA: any): Observable<any> {
    return this.apiService.post(makeUri(OPENWEB_URL, 'sso'), { codeA });
  }
}
