import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FILTERS_URL, STREAMS_URL } from '@kitch/data-access/constants';
import {
  PRODUCTION_EARTH_WEEK_CHEF,
  PRODUCTION_FIRE_CHEF,
  PRODUCTION_MUSHROOM_CHEF,
  STAGE_EARTH_WEEK_CHEF,
  STAGE_FIRE_CHEF,
  STAGE_MUSHROOM_CHEF,
} from '@kitch/data-access/env/constants';
import { environment } from '@kitch/data-access/env/environment';
import {
  AppPagesItem,
  BasicStream,
  IdObject,
  SimpleResponse,
  Stream,
  StreamGuest,
  StreamSchedule,
  StreamConnectionStatus,
  CheckStreamStatus,
  StreamChefProfile,
  UploadVideoParams,
  StreamSlugResponse,
  SlugAvailableResponse,
  FavoritesSearchParams,
  FavoritesList,
  STREAM_ID,
  ACTION_AFTER_LOGIN_KEY,
  AppPagesItemSeries,
  DownloadStream,
  Recipe,
  MostPopularFilters,
  StagedChannel,
} from '@kitch/data-access/models';
import {
  StreamRecordingResponse,
  StreamStartRecordingParams,
  StreamStopRecordingParams,
  StreamsSearchParams,
  UpdateRecordingLayoutParams,
  StreamRecordingStatusResponse,
  UpdatePipCameraPositionParams,
  GetHardcodedSeriesResponse,
} from '@kitch/data-access/models/search-params';
import { ApiService } from '@kitch/data-access/services/api.service';

import { FetchApiService } from '@kitch/data-access/services/fetch-api.service';
import { makeUri } from '@kitch/util';
import { Product } from '@kitch/ui/models';
import { CustomButton } from '@kitch/ui/models/custom-button-form';

export enum Days {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum Months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

@Injectable({ providedIn: 'root' })
export class StreamsService {
  constructor(
    private apiService: ApiService,
    private fetchApiService: FetchApiService,
  ) {}

  getAll(searchParams?: StreamsSearchParams): Observable<AppPagesItem<Stream>> {
    return this.apiService.get(STREAMS_URL, searchParams);
  }

  getAllStreams(searchParams?: StreamsSearchParams): Observable<AppPagesItem<Stream>> {
    return this.apiService.get(makeUri(STREAMS_URL, 'all-streams'), searchParams);
  }

  getTopPicks(searchParams?: StreamsSearchParams): Observable<AppPagesItem<Stream>> {
    return this.apiService.get(makeUri(STREAMS_URL, 'top-picks'), searchParams);
  }

  getById(id: string, profileId?: string): Observable<Stream> {
    return this.apiService.get(makeUri(STREAMS_URL, id), { profileId: profileId });
  }

  getIdBySlug(slug: string): Observable<StreamSlugResponse> {
    return this.apiService.get(makeUri(STREAMS_URL, 'id-by-slug', slug));
  }

  checkSlugAvailable(slug: string): Observable<SlugAvailableResponse> {
    return this.apiService.get(makeUri(STREAMS_URL, 'slug-status', slug));
  }

  getFavoritedStreams(params: FavoritesSearchParams): Observable<FavoritesList<Stream>> {
    return this.apiService.get(makeUri(STREAMS_URL, 'favorited'), params);
  }

  getDownloadStream(id: string): Observable<DownloadStream> {
    return this.apiService.get(makeUri(STREAMS_URL, 'files-to-download', id));
  }

  getStatusById(id: string): Observable<CheckStreamStatus> {
    return this.apiService.get(makeUri(STREAMS_URL, id, 'status'));
  }

  create(stream: BasicStream): Observable<IdObject> {
    return this.apiService.post(STREAMS_URL, stream);
  }

  update(stream: Partial<BasicStream>, id: string, isForced = false): Observable<SimpleResponse> {
    const url = makeUri(STREAMS_URL, id);

    return isForced ? this.fetchApiService.makeRequest('PATCH', url, stream) : this.apiService.patch(url, stream);
  }

  updateViewersCount(count: number, id: string): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(STREAMS_URL, id, 'view-count'), { viewCount: count });
  }

  delete(id: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(STREAMS_URL, id));
  }

  cancel(id: string): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, id, 'cancel'));
  }

  getRecipes(id: string): Observable<AppPagesItem<Recipe>> {
    return this.apiService.get(makeUri(STREAMS_URL, id, 'recipes'));
  }

  getProducts(id: string): Observable<Product[]> {
    return this.apiService.get(makeUri(STREAMS_URL, id, 'products'));
  }

  getButtons(id: string): Observable<CustomButton[]> {
    return this.apiService.get(makeUri(STREAMS_URL, id, 'buttons'));
  }

  getScheduleDate(id: string): Observable<StreamSchedule> {
    return this.apiService.get(makeUri(STREAMS_URL, id, 'schedule'));
  }

  createScheduleDate(id: string, data: StreamSchedule): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, id, 'schedule'), data);
  }

  deleteScheduleDate(id: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(STREAMS_URL, id, 'schedule'));
  }

  updateConnectionStatus(id: string, connectionStatus: StreamConnectionStatus): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, id, 'connection-status'), { connectionStatus });
  }

  getGuests(id: string): Observable<AppPagesItem<StreamGuest>> {
    return this.apiService.get(makeUri(STREAMS_URL, id, 'guests'));
  }

  getCollaborators(id: string): Observable<AppPagesItem<StreamChefProfile>> {
    return this.apiService.get(makeUri(STREAMS_URL, id, 'collaborators'));
  }

  startRecording(params: StreamStartRecordingParams): Observable<StreamRecordingResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, params.videoId, 'recording', 'start'), params);
  }

  stopRecording(params: StreamStopRecordingParams): Observable<StreamRecordingResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, params.videoId, 'recording', 'stop'), params);
  }

  getRecordingStatus(videoId: string): Observable<StreamRecordingStatusResponse> {
    return this.apiService.get(makeUri(STREAMS_URL, videoId, 'recording', 'query'));
  }

  updateRecordingLayout(videoId: string, payload: UpdateRecordingLayoutParams): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, videoId, 'recording', 'updateLayout'), payload);
  }

  updateCoHostsList(videoId: string, payload: UpdateRecordingLayoutParams): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, videoId, 'recording', 'updateCohosts'), payload);
  }

  updatePipCameraPosition(videoId: string, payload: UpdatePipCameraPositionParams): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(STREAMS_URL, videoId, 'recording', 'change-pip-position'), payload);
  }

  uploadVideo(id: string, body: UploadVideoParams): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, id, 'video'), body);
  }

  like(id: string): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, id, 'like'));
  }

  unlike(id: string): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STREAMS_URL, id, 'unlike'));
  }

  showPurchaseModalLoggedInGuest(showPurchaseModal: string): Observable<Stream> {
    const streamId = window.sessionStorage.getItem(STREAM_ID);

    if (window.sessionStorage.getItem(ACTION_AFTER_LOGIN_KEY) === showPurchaseModal && streamId) {
      window.sessionStorage.removeItem(ACTION_AFTER_LOGIN_KEY);
      window.sessionStorage.removeItem(STREAM_ID);

      return this.getById(streamId);
    } else {
      return of(null);
    }
  }

  getHardcodedSeries(): Observable<GetHardcodedSeriesResponse> {
    return this.apiService.get(makeUri(STREAMS_URL, 'hardcoded-series'));
  }

  getHardcodedSeriesDetails(): Observable<AppPagesItemSeries<Stream>> {
    return this.apiService.get(makeUri(STREAMS_URL, 'hardcoded-series-details'));
  }

  getFilters(): Observable<MostPopularFilters> {
    return this.apiService.get(makeUri(STREAMS_URL, FILTERS_URL));
  }
}

export const getAllCollaborators = <T extends (StreamChefProfile | StagedChannel)>(
  chef: T,
  collaborators: Array<T>,
): Array<T> => {
  return [chef, ...collaborators].reduce((accumulator, obj) => {
    if (!accumulator.find(item => item.id === obj.id)) {
      accumulator.push(obj);
    }

    return accumulator;
  }, []);
};

export const getMushroomMonthChef = (stream: Stream): boolean => {
  const idMushroomChef = environment.production ? PRODUCTION_MUSHROOM_CHEF : STAGE_MUSHROOM_CHEF;

  return stream.collaborators.some(
    (item) => item.id === idMushroomChef && new Date(stream.scheduledVideo.date).getMonth() + 1 === 4,
  );
};

export const getEarthWeekChef = (stream: Stream): boolean => {
  const idEarthWeekChef = environment.production ? PRODUCTION_EARTH_WEEK_CHEF : STAGE_EARTH_WEEK_CHEF;

  return stream.collaborators.some((item) => item.id === idEarthWeekChef);
};

export const getFireChef = (stream: Stream): boolean => {
  const idFireChef = environment.production ? PRODUCTION_FIRE_CHEF : STAGE_FIRE_CHEF;

  return stream.collaborators.some((item) => item.id === idFireChef);
};

export const getIsPaymentRequire = (stream: Stream, profileId: string, isAdmin: boolean): boolean => {
  const isOwner = profileId === stream.channel?.chefProfile.id || profileId === stream.profile?.channel?.id;
  const isCollaborator = !!stream.collaborators?.find(person => person.id === profileId);
  const isUserPrivileged = isAdmin || isOwner || isCollaborator;

  return stream.paid && !stream.subscribed && !isUserPrivileged;
};
