<div class="recipe__external-info">
  <div class="recipe__external-icons">
    <img
      class="recipe__external-icon"
      src="assets/ui/images/svg/external-recipe.svg"
      alt="external recipe"
      width="25"
      height="25"
    />

    <div class="recipe__external-ellipse-icon"></div>

    <span class="recipe__external-text">External recipe</span>
  </div>

  <div class="recipe__external-names">
    <span class="recipe__external-primary-name">{{ primaryName }}</span>

    <span
      *ngIf="secondaryName"
      class="recipe__external-secondary-name"
    >
      {{ secondaryName }}
    </span>
  </div>
</div>
