import { AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { RefCodeValidatedResponse } from '@kitch/data-access/models';

type ApiCallback = (refCode: string) => Observable<RefCodeValidatedResponse>;

export function refCodeValidatorFn(apiCallback: ApiCallback): AsyncValidatorFn {
  return (control) => {
    return of(control.value).pipe(
      delay(400),
      distinctUntilChanged(),
      switchMap((value) => {
        if (value) {
          return apiCallback(value).pipe(
            map((resp: RefCodeValidatedResponse) => (resp.validated ? null : { notValidRefCode: true })),
            tap(() => control.markAsTouched()),
          );
        } else {
          return of(null);
        }
      }),
    );
  };
}
