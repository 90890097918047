import { environment } from '@kitch/data-access/env/environment';

export const CAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/api.js?render=';

export const loadCaptchaScript = (): void => {
  const isCaptchaScriptLoaded = document.querySelector(`script[src*="${CAPTCHA_SCRIPT_URL}"]`);

  if (isCaptchaScriptLoaded) {
    return;
  }

  const node = document.createElement('script');

  node.src = CAPTCHA_SCRIPT_URL + environment.captchaSiteKey;
  node.async = true;
  document.body.appendChild(node);
};
