export interface ParsedImage {
  base64: string;
  file: File;
  image: HTMLImageElement;
}

export class ImageTool {
  static parseImage(file: File): Promise<ParsedImage> {
    return new Promise((resolve, reject) => {
      const result: Partial<ParsedImage> = {};

      result.file = file;

      if (!file) {
        reject('No File was selected');
      }

      const fr = new FileReader();

      fr.addEventListener('load', ({ target }: any) => {
        result.base64 = target.result;

        const img = new Image();

        img.onload = () => {
          result.image = img;
          resolve(result as ParsedImage);
        };

        img.src = target.result;
      });

      fr.readAsDataURL(file);
    });
  }

  static async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();

    return new File([blob], fileName, { type: blob.type });
  }
}
