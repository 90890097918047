import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, fromEvent, Observable, of } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { DEFAULT_PAGE, MAX_ITEMS_PER_PAGE } from '@kitch/data-access/constants';

import {
  AppPagesItem,
  BasicCuisineSchema,
  Categories,
  CuisineType,
  EMPTY_SPECIALTY_TAGS_IDS,
  FormType,
  IngredientUnit,
  NewRecipe,
  Recipe,
  Role,
  RoutingType,
  SpecialtyTagsIds,
  Tag,
  Tags,
  TagsTypeLowercase,
  Stream,
  HardcodedCuisinesStructure,
  CuisinesList,
  RecipeStep,
  RecipeStepType,
} from '@kitch/data-access/models';
import { Profile, ProfileRole } from '@kitch/data-access/models/profile';
import {
  CuisineSearchParams,
  StreamsSearchParams,
  UsersSearchParams,
} from '@kitch/data-access/models/search-params';
import {
  CuisinesService,
  ProfilesService,
  RecipesService,
  StreamsService,
  TagsService,
  UploadService,
} from '@kitch/data-access/services';
import { FormTool, getTotalTimeInMinutes, HrsMin, RepeaterTool } from '@kitch/util';
import { getValidSlug } from '@kitch/util/url.tool';
import { DigitsValidator, FractionValidator, slugValidatorFn } from '@kitch/util/validators';
import { TagsComponent } from '@kitch/ui/components/forms';
import { StreamStatusChange } from '@kitch/ui/components/lists-item/stream-list-item/stream-list-item.component';
import { AddPreparationStepsModalComponent } from '@kitch/ui/components/modals';
import { ingredientUnits, walmartErrorPhrase } from '@kitch/ui/constants';
import { AlertService } from '@kitch/ui/services';
import { GuestUser, UsersListItem } from '@kitch/admin/shared/models/user';
import { UsersService } from '@kitch/admin/shared/services/users.service';
import { ModalService } from '@kitch/user/core/modal.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';

export interface RecipeForm {
  description: FormControl<string | null>;
  difficulty: FormControl<Tag|null>,
  external: FormControl<boolean>;
  hrsMin: FormControl<HrsMin>;
  ingredients: FormControl<string | null>;
  isChefRecommended: FormControl<boolean>,
  listIngredients: FormControl<unknown[] | null>;
  listSteps: FormControl<RecipeStep[]>;
  mealPhoto: FormControl<string | File | null>;
  portions: FormControl<number | null>;
  primaryCuisines: FormControl<string[]>;
  primaryName?: FormControl<string>;
  profileId?: FormControl<string|null>;
  recipeName: FormControl<string | null>;
  secondaryName?: FormControl<string>;
  shoppable?: FormControl<boolean|null>;
  spice: FormControl<Tag|null>,
  subCuisines: FormControl<string[]>;
  totalTime: FormControl<number | null>;
}

@UntilDestroy()
@Component({
  selector: 'app-recipe-form',
  templateUrl: './recipe-form.component.html',
  styleUrls: ['./recipe-form.component.scss'],
})
export class RecipeFormComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('tagsSection', { static: false }) tagsSection: TagsComponent;
  @ViewChild('streamsSearch', { static: false }) streamsSearch: ElementRef;

  readonly hrsMinOptions: HrsMin[] = ['Hours', 'Minutes'];

  recipeForm: FormGroup<RecipeForm>;
  isLoading = false;
  isSaving = false;

  recipeId: string;
  profileId: string;
  chefSlug: string;
  recipeSlug: string;
  isRecipePublished = false;
  isCreateRecipe: boolean;
  isAdminForm: boolean;

  isDescriptionInvalid: boolean;
  isIngredientsOldInvalid: boolean;
  isStepsInvalid: boolean;
  hasNewIngredients: boolean;
  toShowWalmartError = false;
  isRecipeShoppable = false;

  imageUrl: string;

  chefs: Profile[] = [];
  allChefsAsCollaborators: GuestUser[] = [];
  collaborators: GuestUser[] = [];
  collaboratorIds: string[] = [];
  collaboratorsPage = 1;
  totalCollaborators: number;
  totalPageCollaborators: number;

  tags: Tags;
  specialtyTagTypes: TagsTypeLowercase[] = [];
  selectedSpecialtyTagsIds: SpecialtyTagsIds = EMPTY_SPECIALTY_TAGS_IDS;
  primarySelectedCuisines: string[] = [];
  selectedSubCuisines: string[] = [];
  hasChannelCuisines = false;
  cuisines: CuisinesList = {
    primary: [],
    subCuisine: [],
  };

  availableSubCuisines: BasicCuisineSchema[] = [];
  hardcodedCuisines: HardcodedCuisinesStructure;
  newSubCuisines: string[] = [];

  ingredientUnits: Readonly<IngredientUnit[]> = ingredientUnits;
  isMaxStreamsAttached: boolean;
  allStreams: Stream[] = [];
  selectedStreamsIds: string[] = [];
  recipeIsExternal = false;

  constructor(
    private alertService: AlertService,
    private untypedFormBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private recipesService: RecipesService,
    private uploadService: UploadService,
    private userProfileService: UserProfileService,
    private profilesService: ProfilesService,
    private usersService: UsersService,
    private tagsService: TagsService,
    private cuisinesService: CuisinesService,
    private streamService: StreamsService,
    private modalService: ModalService,
  ) {
    this.toShowWalmartError = this.router.getCurrentNavigation()?.extras?.state?.toShowWalmartError;
  }

  get listIngredientsControl(): UntypedFormArray {
    return this.recipeForm.get('listIngredients') as UntypedFormArray;
  }

  get ingredientsControl(): AbstractControl<string | null> {
    return this.recipeForm.get('ingredients');
  }

  get difficultyControl(): AbstractControl {
    return this.recipeForm.get('difficulty');
  }

  get spiceControl(): AbstractControl {
    return this.recipeForm.get('spice');
  }

  get isShoppable(): boolean {
    return Boolean(this.recipeForm.get('shoppable')?.value);
  }

  get ownerProfileId(): string {
    return this.profileId || this.recipeForm?.get('profileId').value;
  }

  get isIngredientFreeFormShown(): boolean {
    return !this.hasNewIngredients;
  }

  get totalTime(): number {
    return this.recipeForm.get('totalTime').value;
  }

  get hrsMin(): HrsMin {
    return this.recipeForm.get('hrsMin').value;
  }

  ngOnInit(): void {
    this.recipeId = this.route.snapshot.params.id;
    this.isCreateRecipe = this.route.snapshot.data.routing_type === RoutingType.CREATE;
    this.isAdminForm = this.route.snapshot.data.form_type === FormType.ADMIN;

    if (!this.isAdminForm) {
      this.userProfileService.userProfile$
        .pipe(
          tap((user) => {
            this.profileId = user.id;
            this.chefSlug = user.slug;
          }),
          concatMap(() => this.getStreams()),
          untilDestroyed(this),
        )
        .subscribe();
    }

    if (this.isAdminForm) {
      this.getChefsProfile().subscribe();
    }

    if (this.isCreateRecipe) {
      this.hasNewIngredients = true;
      this.createRecipeForm();
      this.addIngredientsControls();
      this.addStepsControls();
      this.subscribeOnTitleChanges();
      this.getTags().subscribe();
      this.getHardcodedCuisines().pipe(
        switchMap(() => this.getCuisinesForCurrentChef()),
        untilDestroyed(this),
      ).subscribe();
      this.getCuisines()
        .pipe(untilDestroyed(this))
        .subscribe();
      this.recipeForm.get('recipeName').setAsyncValidators(
        slugValidatorFn((slug) => this.recipesService.checkSlugAvailable(slug)),
      );
    } else {
      this.getRecipe();
    }
    this.getCollaborators().subscribe(() => this.removeDuplicatedCollaborators());
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {}

  saveRecipe(shouldPublish?: boolean): void {
    this.updateControlsValueForNonExternalRecipe();
    this.updateIngredientsValidity();
    if (!this.recipeForm.valid) {
      this.recipeForm.markAllAsTouched();
      this.isDescriptionInvalid = this.recipeForm.get('description').invalid;
      this.isIngredientsOldInvalid = this.ingredientsControl.invalid;
      this.isStepsInvalid = this.listStepsControl.invalid || !this.listStepsControl.length;

      return;
    }
    this.isSaving = true;

    let uploadPhoto$ = of({});

    if (this.recipeForm.get('mealPhoto').dirty) {
      const mealPhotoFile = this.recipeForm.get('mealPhoto').value as File;
      const imageType: string = mealPhotoFile.type;

      uploadPhoto$ = this.uploadService.getUploadUrls(imageType, 'recipe')
        .pipe(
          tap(({ publicUrl }) => this.imageUrl = publicUrl),
          concatMap(({ uploadSignedUrl }) =>
            this.uploadService.upload(uploadSignedUrl, this.recipeForm.get('mealPhoto').value)),
        );
    }

    const submitForm$ = () => {
      return this.isCreateRecipe
        ? this.recipesService.create(this.getRecipeFormData())
        : this.recipesService.update(this.getRecipeFormData(), this.recipeId);
    };

    uploadPhoto$.pipe(
      concatMap(submitForm$),
      finalize(() => (this.isSaving = false)),
    )
      .subscribe((res) => {
        const recipeId = this.isCreateRecipe ? res.id : this.recipeId;

        const { walmartRecipeId } = res;

        if (this.isShoppable && walmartRecipeId === null) {
          this.handleFailedWalmartPublishing(recipeId);

          return;
        }

        if (shouldPublish) {
          this.recipesService
            .changeStatus(recipeId, !this.isRecipePublished)
            .subscribe();
        }
        this.isAdminForm ?
          this.router.navigate(['/recipes']) :
          this.router.navigate([`/${this.chefSlug}/recipes/${this.recipeSlug}`]);
      });
  }

  cancelStream(): void {
    this.isAdminForm ?
      this.router.navigate(['/recipes']) :
      this.router.navigate(['/profile/recipe-manager']);
  }

  changeStatus(event: MatSlideToggleChange): void {
    const alertMessage = event.checked
      ? 'Recipe is published'
      : 'Recipe is unpublished';

    this.recipesService
      .changeStatus(this.recipeId, event.checked)
      .subscribe(() => this.alertService.success(alertMessage));
  }

  deleteRecipe(): void {
    this.recipesService.delete(this.recipeId).pipe(
      finalize(() => this.cancelStream()),
    ).subscribe();
  }

  addIngredient(): void {
    const ingredient = this.untypedFormBuilder.group({
      product: new UntypedFormControl(null, [Validators.required]),
      unit: new UntypedFormControl(null, [Validators.required]),
      unitValue: new UntypedFormControl(1, [Validators.required, FractionValidator()]),
      ingredientDescription: new UntypedFormControl(null),
    });

    this.listIngredientsControl.push(ingredient);
    this.toggleListIngredientsError(false);
  }

  deleteIngredient(index: number): void {
    this.listIngredientsControl.removeAt(index);
    this.toggleListIngredientsError(false);
  }

  setShoppableStatus(newShoppableStatus: boolean): void {
    this.recipeForm.get('shoppable').patchValue(newShoppableStatus);
    this.toggleListIngredientsError(false);
  }

  setSelectedCollaborators(userIds: string[]): void {
    this.collaboratorIds = userIds;
    this.collaborators = userIds
      .map(id => [...this.collaborators, ...this.allChefsAsCollaborators].find(collaborator => collaborator.id === id));
    this.changeDetector.detectChanges();
  }

  searchCollaborators(term: string): void {
    this.collaboratorsPage = 1;
    this.getCollaborators(term).subscribe();
  }

  loadMoreCollaborators(page: number): void {
    if (page < this.totalPageCollaborators) {
      this.collaboratorsPage = page + 1;
      this.getCollaborators().subscribe();
    }
  }

  setIngredientFreeFormVisibility(status: boolean): void {
    this.hasNewIngredients = !status;
    this.toggleIngredientsValidators();
  }

  setSelectedCuisines(cuisines: string[]): void {
    this.recipeForm.get('primaryCuisines').setValue(cuisines);

    setTimeout(() => {
      this.filterAvailableSubCuisines(cuisines);
      this.filterSelectedSubCuisines();
    });
  }

  setSelectedSubCuisine(subCuisines: string[]): void {
    const subCuisinesSelected = subCuisines.filter((item) => item);

    this.recipeForm.get('subCuisines').setValue(subCuisinesSelected);
  }

  addStep(type: RecipeStepType = 'step', value = ''): void {
    const stepControl = this.untypedFormBuilder.group({
      type: new FormControl<RecipeStepType>(type, [Validators.required]),
      value: new FormControl<string>(value, [Validators.required]),
    });

    this.listStepsControl.push(stepControl);
  }

  deleteStep(index: number): void {
    this.listStepsControl.removeAt(index);
  }

  bulkAdd(): void {
    const addPreparationStepsModal = this.modalService.open(AddPreparationStepsModalComponent, { width: '620px' });

    addPreparationStepsModal.closed
      .pipe(take(1))
      .subscribe((steps: RecipeStep[]) => {
        if (steps) {
          if (this.listStepsControl.controls.length === 1) {
            const { value } = this.listStepsControl.at(0).value;

            if (!value || value === '<br>') {
              this.deleteStep(0);
            }
          }

          steps.forEach(({ type, value }) => {
            this.addStep(type, value);
          });
        }
      });
  }

  private get listStepsControl(): UntypedFormArray {
    return this.recipeForm.get('listSteps') as UntypedFormArray;
  }

  private filterAvailableSubCuisines(cuisineIds: string[]): void {
    const subCuisineIds: string[] = [];

    cuisineIds.forEach(cuisineId => {
      if (this.hardcodedCuisines[cuisineId]) {
        subCuisineIds.push(...this.hardcodedCuisines[cuisineId]);
      }
    });

    const uniqueSubCuisineIds: string[] = Array.from(new Set(subCuisineIds));

    this.availableSubCuisines = this.cuisines.subCuisine
      .filter(subCuisine => uniqueSubCuisineIds.includes(subCuisine.id));
  }

  private filterSelectedSubCuisines(): void {
    const availableSubCuisineIds = this.availableSubCuisines.map(subCuisine => subCuisine.id);
    const filteredSubCuisineIds = this.recipeForm.get('subCuisines').value
      .filter(subCuisine => availableSubCuisineIds.includes(subCuisine));

    this.setSelectedSubCuisine(filteredSubCuisineIds);
    this.selectedSubCuisines = filteredSubCuisineIds;
  }

  changeStreamsList(streamStatus: StreamStatusChange): void {
    const { streamId } = streamStatus;

    this.selectedStreamsIds = [streamId];
    this.allStreams.forEach((stream) => stream.addedToRecipe = streamId === stream.id);
    this.updateTagsFromStream(streamId);
    this.updatedSelectedCuisinesAndSubCuisines(streamId);
  }

  setExternalStatus(status: boolean): void {
    this.recipeForm.get('external').setValue(status);
    this.recipeIsExternal = status;

    if (status) {
      this.setRequiredValidatorForControl('primaryName');
      this.clearValidatorForControl('profileId');
      this.recipeForm.get('profileId').setValue(null);
    } else {
      this.setRequiredValidatorForControl('profileId');
      this.clearValidatorForControl('primaryName');
    }
    this.resetCuisinesValue();
  }

  private removeDuplicatedCollaborators(): void {
    this.collaborators.forEach((selectedUser) => {
      this.allChefsAsCollaborators = this.allChefsAsCollaborators.filter((user) => user.id !== selectedUser.id);
    });
    this.allChefsAsCollaborators = [...this.collaborators, ...this.allChefsAsCollaborators];
  }

  private toggleListIngredientsError(toSet: boolean): void {
    const error = toSet ? { customError: walmartErrorPhrase } : null;

    this.listIngredientsControl.setErrors(error);
    this.listIngredientsControl.markAsTouched();
  }

  private createRecipeForm(): void {
    this.recipeForm = this.untypedFormBuilder.group({
      recipeName: new FormControl<string|null>(null, [Validators.required, Validators.maxLength(320)]),
      description: new FormControl<string|null>(null, [Validators.required]),
      ingredients: new FormControl<string|null>(null),
      listSteps: new UntypedFormArray([]),
      mealPhoto: new FormControl<string | File|null>(null, [Validators.required]),
      portions: new FormControl<number|null>(null, [Validators.required, Validators.min(1), DigitsValidator()]),
      listIngredients: new UntypedFormArray([]),
      totalTime: new FormControl<number|null>(null, [Validators.required]),
      hrsMin: new FormControl<HrsMin>('Minutes'),
      spice: new FormControl<Tag|null>(null),
      difficulty: new FormControl<Tag|null>(null, [Validators.required]),
      isChefRecommended: new FormControl<boolean>(false),
      primaryCuisines: new FormControl<string[]>([], [Validators.required, Validators.maxLength(3)]),
      subCuisines: new FormControl<string[]>([], [Validators.maxLength(3)]),
      external: new FormControl<boolean>(false),
    });

    if (this.isAdminForm) {
      this.recipeForm.addControl('profileId', new FormControl<string|null>(null, [Validators.required]));
      this.recipeForm.addControl('shoppable', new FormControl<boolean|null>(null));
      this.recipeForm.addControl('isChefRecommended', new FormControl<boolean>(false));
      this.recipeForm.addControl('primaryName', new FormControl<string>(''));
      this.recipeForm.addControl('secondaryName', new FormControl<string>(''));

      this.getDataOnChangingProfileId();
    }

    this.toggleIngredientsValidators();
    setTimeout(() => this.subscribeOnSearchStreamsInput());
  }

  private getRecipe(): void {
    this.isLoading = true;
    forkJoin([
      this.recipesService.getById(this.recipeId),
      this.getTags(),
      this.getCuisines(),
      this.getCollaborators(),
      this.getStreams(),
      this.getHardcodedCuisines(),
    ])
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          setTimeout(() => this.toggleListIngredientsError(this.toShowWalmartError), 0);
        }))
      .subscribe(([recipe, _tags, cuisines]) => {
        this.isRecipePublished = recipe.published;
        this.isRecipeShoppable = recipe.shoppable;
        this.recipeSlug = recipe.slug;
        this.hasNewIngredients = recipe.listIngredients?.length > 0 ||
          (!recipe.listIngredients?.length && !recipe.ingredients?.length);
        this.collaborators = (recipe.collaborators || []).map(collaborator => {
          return {
            id: collaborator.id,
            photo: collaborator.photo,
            name: collaborator.chefName,
          };
        });
        this.collaboratorIds = this.collaborators.map(collaborator => collaborator.id);
        this.recipeIsExternal = recipe.external;

        this.removeDuplicatedCollaborators();
        this.createRecipeForm();
        this.addIngredientsControls(recipe.listIngredients?.length);
        this.addStepsControls(recipe.listSteps?.length);
        this.fillForm(recipe, cuisines);
        if (this.recipeIsExternal) {
          this.setRequiredValidatorForControl('primaryName');
        }
      });
  }

  private getCuisinesForCurrentChef(): Observable<Categories> {
    if (!this.ownerProfileId) return of(null);

    return this.profilesService.getUserCategories(this.ownerProfileId).pipe(
      tap((cuisines) => {
        this.primarySelectedCuisines = cuisines.cuisines.map((cuisine) => cuisine.id);
        this.selectedSubCuisines = cuisines.subCuisines.map((subCuisine) => subCuisine.id);
        this.recipeForm.get('primaryCuisines').patchValue(this.primarySelectedCuisines);
        this.recipeForm.get('subCuisines').patchValue(this.selectedSubCuisines);
      }),
      finalize(() => (this.hasChannelCuisines = true)),
    );
  }

  private getCuisines(): Observable<BasicCuisineSchema[]> {
    const params: CuisineSearchParams = {
      page: 1,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
    };

    return this.cuisinesService.getAllShort(params).pipe(
      map((cuisines) => cuisines.results),
      tap((cuisines) => {
        this.cuisines.primary = cuisines.filter((cuisine) => cuisine.type === CuisineType.PRIMARY);
        this.cuisines.subCuisine = cuisines.filter((cuisine) => cuisine.type === CuisineType.SUBCUISINE);
      }),
    );
  }

  private getStreams(query?: string): Observable<AppPagesItem<Stream>> {
    if (!this.ownerProfileId) return of(null);

    const params: StreamsSearchParams = {
      page: DEFAULT_PAGE,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      profileId: this.ownerProfileId,
    };

    if (query) {
      params.query = query;
    }

    return this.streamService.getAll(params).pipe(
      tap((streams) => {
        this.allStreams = streams.results;
        this.markChosenStream();
      }),
    );
  }

  private addIngredientsControls(length?: number): void {
    const ingredientsLength = length || 4;

    for (let i = 0; i < ingredientsLength; i++) {
      this.addIngredient();
    }

    this.toggleListIngredientsError(false);
  }

  private addStepsControls(length?: number): void {
    RepeaterTool.repeat(length || 2).forEach(() => {
      this.addStep();
    });
  }

  private fillForm(recipe: Recipe, cuisines: BasicCuisineSchema[]): void {
    const { external, primaryName, secondaryName, listSteps } = recipe;
    const recipeForm = {
      recipeName: recipe.recipeName,
      description: recipe.description,
      ingredients: recipe.ingredients && recipe.ingredients[0],
      mealPhoto: recipe.mealPhoto,
      listIngredients: recipe.listIngredients,
      listSteps,
      totalTime: recipe.totalTimeInMinutes,
      portions: recipe.portions,
      profileId: recipe.profile.id,
      shoppable: recipe.shoppable,
      isChefRecommended: recipe.isChefRecommended,
      external,
      primaryName,
      secondaryName,
    };

    this.recipeForm.patchValue(recipeForm, { emitEvent: false });
    this.selectedSpecialtyTagsIds = this.tagsService.getSpecialtyTagsIds(recipe.tags);
    this.selectedStreamsIds = recipe.streamIds;
    this.markChosenStream();
    this.setSpice(recipe);
    this.setDifficulty(recipe);

    const selectedCuisines = recipe.cuisines?.map((cuisine) => cuisine.id) || [];

    selectedCuisines.forEach((cuisineId) => {
      const selectedCuisine = cuisines.find((cuisine) => cuisine.id === cuisineId);

      if (selectedCuisine) {
        switch (selectedCuisine.type) {
          case CuisineType.PRIMARY:
            this.primarySelectedCuisines = [...this.primarySelectedCuisines, selectedCuisine.id];
            this.recipeForm.get('primaryCuisines').patchValue(this.primarySelectedCuisines);
            break;
          case CuisineType.SUBCUISINE:
            this.selectedSubCuisines = [...this.selectedSubCuisines, selectedCuisine.id];
            this.recipeForm.get('subCuisines').patchValue(this.selectedSubCuisines);
            break;
        }
      }
    });

    if (recipe.listIngredients.length) {
      FormTool.markControlAsDeepDirty(this.listIngredientsControl);
    }

    if (recipe.profile.id && this.isAdminForm) {
      this.recipeForm.get('profileId').markAsDirty();
    }

    setTimeout(() => this.tagsSection.setTag(recipe.tags[TagsTypeLowercase.style][0], TagsTypeLowercase.style));
  }

  private getRecipeFormData(): NewRecipe {
    const totalTimeInMinutes =
      getTotalTimeInMinutes(this.totalTime, this.hrsMin);
    const isQuick = totalTimeInMinutes <= 30;
    const { recipeIsExternal } = this;
    const collaboratorIds = recipeIsExternal ? [] : this.collaborators.map(({ id }: GuestUser) => id);

    const newRecipe = {
      ...this.recipeForm.value,
      ingredients: this.ingredientsControl.value ? [this.ingredientsControl.value] : [],
      isQuick,
      mealPhoto: this.imageUrl,
      profileId: this.ownerProfileId,
      slug: this.recipeSlug,
      tags: this.getTagsForBE(),
      totalTimeInMinutes,
      collaboratorIds,
      cuisines: {
        cuisines: this.recipeForm.get('primaryCuisines').value,
        subCuisines: this.recipeForm.get('subCuisines').value,
      },
      streamIds: recipeIsExternal ? [] : this.selectedStreamsIds,
    };

    if (this.hasNewIngredients) {
      newRecipe.ingredients = [];
    } else {
      newRecipe.listIngredients = [];
    }

    if (this.isAdminForm) {
      newRecipe.shoppable = this.recipeForm.get('shoppable').value || false;
    } else {
      newRecipe.shoppable = this.isRecipeShoppable;
    }

    if (recipeIsExternal) {
      delete newRecipe.profileId;
    }

    return newRecipe as NewRecipe;
  }

  private getCollaborators(query?: string): Observable<AppPagesItem<UsersListItem>> {
    const params: UsersSearchParams = {
      approved: true,
      page: this.collaboratorsPage,
      itemsPerPage: 25,
      role: [Role.CHEF],
      orderDirection: 'asc',
    };

    if (query) {
      params.query = query;
    }

    return this.usersService.getUsers(params).pipe(
      tap((users) => {
        const collaborators = users.results
          .map((user) => {
            return {
              id: user.profile.id,
              photo: user.profile.photo,
              name: user.profile.fullName,
            };
          })
          .filter(user => user.id !== this.ownerProfileId);

        this.totalCollaborators = users.total;
        this.totalPageCollaborators = users.pageCount;

        if (this.collaboratorsPage > 1) {
          this.allChefsAsCollaborators = [...this.allChefsAsCollaborators, ...collaborators];
        } else {
          this.allChefsAsCollaborators = collaborators;
        }
      }),
    );
  }

  private getChefsProfile(): Observable<AppPagesItem<Profile>> {
    const params = {
      role: [ProfileRole.CHEF],
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      orderDirection: 'asc',
    };

    return this.profilesService.getAll(params).pipe(
      tap((users) => {
        this.chefs = users.results;
      }),
    );
  }

  getTags(): Observable<Tags> {
    return this.tagsService.getAll()
      .pipe(
        tap((tags) => {
          this.tags = tags;
          this.specialtyTagTypes = this.tagsService.getSpecialtyTagsTypes(tags);
        }),
        untilDestroyed(this),
      );
  }

  private getHardcodedCuisines(): Observable<HardcodedCuisinesStructure> {
    return this.cuisinesService.getHardcodedCuisines()
      .pipe(
        tap((hardcodedCuisines) => this.hardcodedCuisines = hardcodedCuisines),
        untilDestroyed(this),
      );
  }

  private getTagsForBE(): Tags {
    const difficulty: Tag[] = this.difficultyControl.value ? [this.difficultyControl.value] : [];
    const spice: Tag[] = this.spiceControl.value ? [this.spiceControl.value] : [];

    return {
      difficulty,
      spice,
      ...this.tagsSection.getTags(),
    } as Tags;
  }

  private subscribeOnTitleChanges(): void {
    this.recipeForm.get('recipeName').valueChanges
      .subscribe((title) => this.recipeSlug = getValidSlug(title));
  }

  private handleFailedWalmartPublishing(recipeId: string): void {
    if (this.isCreateRecipe) {
      // redirect to edit page
      this.router.navigate([`/recipes/edit/${recipeId}`], { state: { toShowWalmartError: true } });
    } else {
      // show error if we already there
      if (this.hasNewIngredients) {
        this.toggleListIngredientsError(true);
      }
      if (this.isAdminForm) {
        this.setShoppableStatus(false);
      }
    }
  }

  private toggleIngredientsValidators(): void {
    if (this.hasNewIngredients) {
      this.listIngredientsControl.setValidators([Validators.required]);
      this.ingredientsControl.setValidators(null);
      this.ingredientsControl.setErrors(null);
    } else {
      this.ingredientsControl.setValidators([Validators.required]);
      this.resetErrorsOfListIngredients();
      this.listIngredientsControl.setValidators(null);
    }
  }

  private updateIngredientsValidity(): void {
    if (this.hasNewIngredients) {
      this.ingredientsControl.setErrors(null);
    } else {
      this.resetErrorsOfListIngredients();
    }
  }

  private resetErrorsOfListIngredients(): void {
    for (const ingredientControl of this.listIngredientsControl.controls) {
      ingredientControl.get('product').setErrors(null);
      ingredientControl.get('unit').setErrors(null);
      ingredientControl.get('unitValue').setErrors(null);
    }
  }

  private setDifficulty(recipe: Recipe): void {
    const recipeDifficulty = recipe.tags.difficulty[0];

    if (recipeDifficulty) {
      const difficultyTag = this.tags.difficulty
        .find(difficulty => difficulty.id === recipeDifficulty.id);

      this.difficultyControl.patchValue(difficultyTag);
      this.difficultyControl.markAsDirty();
    }
  }

  private setSpice(recipe: Recipe): void {
    const recipeSpice = recipe.tags.spice[0];

    if (recipeSpice) {
      const spiceTag = this.tags.spice
        .find(spice => spice.id === recipeSpice.id);

      this.spiceControl.patchValue(spiceTag);
      this.spiceControl.markAsDirty();
    }
  }

  private updateTagsFromStream(streamId: string): void {
    const stream = this.allStreams.find(stream => stream.id === streamId);
    const streamSpecialtyTagsIds = this.tagsService.getSpecialtyTagsIds(stream.tags);
    const selectedTags = this.tagsSection.getTags();
    const selectedSpecialtyTagsIds = this.tagsService.getSpecialtyTagsIds(selectedTags);
    const streamStyleTag = stream.tags[TagsTypeLowercase.style][0];

    this.selectedSpecialtyTagsIds =
      this.tagsService.mergeSpecialtyTagsIds(selectedSpecialtyTagsIds, streamSpecialtyTagsIds);

    if (streamStyleTag) {
      this.tagsSection.setTag(streamStyleTag, TagsTypeLowercase.style);
    }
  }

  private updatedSelectedCuisinesAndSubCuisines(recipeId: string): void {
    const stream = this.allStreams.find(recipe => recipe.id === recipeId);

    if (!stream.videoCuisines.length) return;

    const primaryCuisines = stream.videoCuisines
      .filter(cuisine => cuisine.type === CuisineType.PRIMARY)
      .map(cuisine => cuisine.id);
    const subCuisines = stream.videoCuisines
      .filter(cuisine => cuisine.type === CuisineType.SUBCUISINE)
      .map(cuisine => cuisine.id);
    const primarySelectedCuisines = Array.from(
      new Set([...this.primarySelectedCuisines, ...primaryCuisines]),
    )
      .slice(0, 3);
    const selectedSubCuisines = Array.from(
      new Set([...this.selectedSubCuisines, ...subCuisines]),
    )
      .slice(0, 3);

    this.setSelectedCuisines(primarySelectedCuisines);
    this.setSelectedSubCuisine(selectedSubCuisines);
    this.primarySelectedCuisines = primarySelectedCuisines;
    this.selectedSubCuisines = selectedSubCuisines;
  }

  private subscribeOnSearchStreamsInput(): void {
    if (this.recipeIsExternal) {
      return;
    }

    fromEvent(this.streamsSearch.nativeElement, 'input')
      .pipe(
        map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((query) => this.getStreams(query)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private markChosenStream() {
    const streamIds = this.selectedStreamsIds;

    this.allStreams = this.allStreams.map((stream) => {
      stream.addedToRecipe = streamIds.includes(stream.id);

      return stream;
    });
  }

  private setRequiredValidatorForControl(controlName: keyof RecipeForm) {
    this.recipeForm.get(controlName).setValidators([Validators.required]);
  }

  private clearValidatorForControl(controlName: keyof RecipeForm): void {
    this.recipeForm.get(controlName).clearValidators();
  }

  private updateControlsValueForNonExternalRecipe(): void {
    if (this.isAdminForm && !this.recipeIsExternal) {
      this.recipeForm.get('primaryName').setValue('');
      this.recipeForm.get('secondaryName').setValue('');
    }
  }

  private getDataOnChangingProfileId(): void {
    this.recipeForm.get('profileId').valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(() => this.getCollaborators()),
        switchMap(() => this.getCuisinesForCurrentChef()),
        switchMap(() => this.getStreams()),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.collaboratorIds = this.collaboratorIds.filter(id => id !== this.ownerProfileId);
      });
  }

  private resetCuisinesValue(): void {
    this.primarySelectedCuisines = [];
    this.selectedSubCuisines = [];
    this.recipeForm.get('primaryCuisines').setValue([]);
    this.recipeForm.get('subCuisines').setValue([]);
  }
}
