import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SIGN_UP_URL } from '@kitch/data-access/constants';
import { NewUser, User } from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services';

@Injectable()
export class RegisterService {
  constructor(private apiService: ApiService) {}

  signUp(user: User): Observable<NewUser> {
    return this.apiService.post(SIGN_UP_URL, user, { inviteId: user.inviteId });
  }
}
