import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShoppingCartData } from '@kitch/data-access/models';

@Injectable({
  providedIn: 'root',
})
export class ProductsQuantityService {
  private productsQuantity = new BehaviorSubject<number>(0);
  private productsPrice = new BehaviorSubject<number>(0);
  persistedShoppingCartData: ShoppingCartData;

  constructor() {
    this.initEmptyShoppingCartData();
  }

  get productsQuantity$(): Observable<number> {
    return this.productsQuantity.asObservable();
  }

  get productsPrice$(): Observable<number> {
    return this.productsPrice.asObservable();
  }

  changeProductsQuantity(quantity: number): void {
    this.productsQuantity.next(quantity);
  }

  changeProductsPrice(price: number): void {
    this.productsPrice.next(price);
  }

  updateProductsQuantity(persistedShoppingCartData: ShoppingCartData): void {
    let quantity = 0;
    let price = 0;

    persistedShoppingCartData?.recipes.forEach(recipe => {
      recipe.products.forEach(product => {
        quantity += product ? product.quantity : 0;
        price += product ? product.quantity * product.offerPrice : 0;
      });
    });
    this.changeProductsQuantity(quantity);
    this.changeProductsPrice(price);
  }

  initEmptyShoppingCartData(): void {
    this.persistedShoppingCartData = {
      currentStore: null,
      ingredientsNumber: null,
      recipes: [],
      recipesNumber: null,
      stores: [],
    };
  }
}
