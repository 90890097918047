import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { StreamPaidValidator } from '@kitch/util/validators';

export interface AdminCheckboxForm {
  featured: FormControl<boolean>,
  isChefRecommended: FormControl<boolean>,
  staged: FormControl<boolean>
  stagedOrder: FormControl<number>
}

@Component({
  selector: 'app-admin-checkbox-form',
  templateUrl: './admin-checkbox-form.component.html',
  styleUrls: ['./admin-checkbox-form.component.scss'],
})
export class AdminCheckboxFormComponent implements OnInit {
  adminCheckboxForm: FormGroup<AdminCheckboxForm>;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createCheckboxForm();
  }

  get checkboxFormIsValid(): boolean {
    const { staged, stagedOrder } = this.checkboxControls;

    return !staged.value || (staged.value && !!stagedOrder.value);
  }

  get checkboxControls(): AdminCheckboxForm {
    return {
      isChefRecommended: this.adminCheckboxForm.get('isChefRecommended') as FormControl<boolean>,
      featured: this.adminCheckboxForm.get('featured') as FormControl<boolean>,
      staged: this.adminCheckboxForm.get('staged') as FormControl<boolean>,
      stagedOrder: this.adminCheckboxForm.get('stagedOrder') as FormControl<number>,
    };
  }

  setCheckboxValueToForm(
    isChefRecommended: boolean, featured: boolean, staged: boolean, stagedOrder: null | number,
  ): void {
    this.checkboxControls.isChefRecommended.patchValue(isChefRecommended);
    this.checkboxControls.featured.patchValue(featured);
    this.checkboxControls.staged.patchValue(staged);
    this.checkboxControls.stagedOrder.patchValue(stagedOrder);
  }

  removeAllCheckboxes(): void {
    this.setCheckboxValueToForm(false, false, false, null);
  }

  private createCheckboxForm(): void {
    this.adminCheckboxForm = this.formBuilder.group<AdminCheckboxForm>({
      isChefRecommended: new FormControl<boolean>(false),
      featured: new FormControl<boolean>(false),
      staged: new FormControl<boolean>(false),
      stagedOrder: new FormControl<number>(null, [StreamPaidValidator]),
    });
  }
}
