import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { CardGAEvents } from '@kitch/data-access/constants';
import { Stream } from '@kitch/data-access/models';
import { getAllCollaborators, TokenService } from '@kitch/data-access/services';

import { getSecondsFromDate } from '@kitch/util';

@Component({
  selector: 'app-stream-past-card',
  templateUrl: './stream-past-card.component.html',
  styleUrls: ['./stream-past-card.component.scss'],
})
export class StreamPastCardComponent implements OnInit {
  @Input() isActiveSlider = false;
  @Input() stream: Stream;
  @HostBinding('class.carousel-cell') private carouselCell = false;

  streamDuration: number;

  constructor(
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) {
  }

  ngOnInit(): void {
    this.carouselCell = this.isActiveSlider;
    this.streamDuration = getSecondsFromDate(new Date(this.stream.stoppedAt)) -
      getSecondsFromDate(new Date(this.stream.startedAt));
    if (this.stream.collaborators?.length) {
      this.stream.collaborators = getAllCollaborators(this.stream.channel.chefProfile, this.stream.collaborators);
    }
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', CardGAEvents.past, {
      profile_id: this.tokenService.getProfileId(),
      name: this.stream.title,
    });
  }
}
