import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { CardGAEvents } from '@kitch/data-access/constants';
import { Profile } from '@kitch/data-access/models/profile';
import { TokenService } from '@kitch/data-access/services';
import { AvatarSize } from '@kitch/ui/types';

@Component({
  selector: 'app-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.scss'],
})
export class ChannelCardComponent implements OnInit {
  @Input() isActiveSlider = false;
  @Input() isClub = false;
  @Input() needOpenInNewTab = false;
  @Input() isCollectionChannel = false;
  @Input() profile: Profile;
  @Input() avatarSize: AvatarSize = 'extra-small';
  @HostBinding('class.carousel-cell') private carouselCell = false;

  constructor(
    private tokenService: TokenService,
    private $gaService: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.carouselCell = this.isActiveSlider;
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', this.getCardGAEvent, {
      profile_id: this.tokenService.getProfileId(),
      name: this.profile.channel?.name || '',
    });
  }

  private get getCardGAEvent(): CardGAEvents {
    switch (true) {
      case this.isCollectionChannel:
        return CardGAEvents.collectionsChannel;
      case this.isClub:
        return CardGAEvents.club;
      default:
        return CardGAEvents.channel;
    }
  }
}
