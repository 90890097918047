import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { TERMS_THANKS_MODAL_CLOSE_DELAY } from '@kitch/user/shared/constants/modal';

@Component({
  selector: 'app-creators-terms-thanks-modal',
  templateUrl: './creators-terms-thanks-modal.component.html',
  styleUrls: ['../modals.scss', './creators-terms-thanks-modal.component.scss'],
})
export class CreatorsTermsThanksModalComponent implements OnInit {
  constructor(private dialogRef: DialogRef<string>) {}

  ngOnInit() {
    setTimeout(() => this.close(), TERMS_THANKS_MODAL_CLOSE_DELAY);
  }

  close(): void {
    this.dialogRef.close();
  }
}
