import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SHARE_BUTTONS, ShareParams, ShareService } from 'ngx-sharebuttons';
import { GA_EVENT_SHARE_BUTTON_CLICK } from '@kitch/data-access/constants';
import { Role } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { MediaShare } from '@kitch/ui/models';
import { ButtonRole } from '@kitch/ui/models/custom-button-form';
import { SeoService } from '@kitch/ui/services';
import { UserProfileService } from '@kitch/user/core/user-profile.service';

@UntilDestroy()
@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
})

export class ShareButtonsComponent extends MediaShare implements OnInit {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() buttonTitle = '';
  @Input() url: string = window.location.href;
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() tags: string;
  @Input() role: ButtonRole;
  @Input() isProduct = false;
  @Input() isSmallOnTablet = false;
  @Input() buttonClass: string;

  socialMedia = [
    'facebook',
    'whatsapp',
    'sms',
    'email',
    'link',
  ];

  showCanvaLink: boolean;
  savedDescription: string;

  private linkInDescription = {
    description: (params: ShareParams) => {
      return `Check this out on Kittch: ${params.url}\n#kittch #foodobsessed`;
    },
  };

  constructor(
    private share: ShareService,
    protected $gaService: GoogleAnalyticsService,
    protected tokenService: TokenService,
    private seoService: SeoService,
    private userProfile: UserProfileService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super(
      $gaService,
      tokenService,
    );
  }

  ngOnInit(): void {
    this.userProfile.userProfile$
      .pipe(untilDestroyed(this))
      .subscribe(profile => {
        // guest and product card don't have a refcode
        if (profile.refCode && !this.isProduct) {
          this.addRefCodeToUrl(profile.refCode);
        }
      });
    this.showCanvaLink = this.tokenService.getRole() === Role.CHEF;
    if (isPlatformBrowser(this.platformId)) {
      this.addCustomCopyButtons();
    }
  }

  openModal(): void {
    this.addCustomCopyButtons();
    this.savedDescription = this.seoService.getDescription();
    this.seoService.updateTags({
      title: this.title,
      description: this.description,
      image: this.image,
    });
    this.modal.open();
    this.$gaService.gtag('event', GA_EVENT_SHARE_BUTTON_CLICK, { profile_id: this.tokenService.getProfileId() });
  }

  modalClosed(): void {
    this.seoService.updateTags({ description: this.savedDescription });
  }

  private addCustomCopyButtons(): void {
    this.share.addButton('link', {
      ...SHARE_BUTTONS.copy,
      icon: ['fas', 'copy'],
      data: { ...SHARE_BUTTONS.copy.data, icon: ['fas', 'copy'] },
      func: (args) => this.copyToClipboard(args, args.params.url, 'link'),
    });
    this.share.addButton('email', {
      ...SHARE_BUTTONS.email,
      paramsFunc: this.linkInDescription,
    });
  }

  private addRefCodeToUrl(refCode: string): void {
    const url = new URL(this.url);
    const urlParams = new URLSearchParams(url.search);

    urlParams.set('refCode', refCode);
    this.url = `${url.origin + url.pathname}?${urlParams.toString()}`;
  }
}
