<div
  class="wrapper"
  [ngClass]="{
    'cropper-invisible': showCropper,
    'image-creator-visible': showImageCreator
  }"
>
  <div
    *ngIf="showDescription"
    class="description"
  >
    We recommend a landscape picture with a ratio of {{ imageAspectRatioText }}.
  </div>
  <span *ngIf="image" [ngClass]="useLikeAvatar ? 'image-chooser--avatar' : 'image-chooser'" class="admin-image-chooser">
    <span *ngIf="useLikeAvatar" class="image-chooser__image-wrapper">
      <label for="{{ 'imageUpload-' + formControlName }}">
        <span class="overlay"></span>
      </label>
      <img alt="{{ imageAlt }}" #img class="image-chooser__image" (error)="onImageLoadError()" [src]="image" />
    </span>

    <img *ngIf="!useLikeAvatar && isUser" alt="{{ imageAlt }}" #img class="image-chooser__image" (error)="onImageLoadError()" [src]="image" />

    <ng-container *ngIf="isUser" [ngTemplateOutlet]="imageNameEl"></ng-container>

    <label *ngIf="isAdmin" class="image-chooser__wrap-label" for="{{ 'imageUpload-' + formControlName }}">
      <img *ngIf="!useLikeAvatar" alt="{{ imageAlt }}" #img class="image-chooser__image" (error)="onImageLoadError()" [src]="image" />

      <ng-container
        [ngTemplateOutlet]="showCropperButton && showImageCreator ? cropButtonEl : imageNameEl"
      ></ng-container>
    </label>

    <button
      *ngIf="image && hasDeleteBtn"
      type="button"
      class="delete-btn"
      (click)="onDeleteImage($event)"
    >
      <div class="delete-btn__cross delete-btn__cross--left"></div>
      <div class="delete-btn__cross delete-btn__cross--right"></div>
    </button>
  </span>

  <div class="file-buttons-wrapper">
    <app-button
      class="chooser-button"
      data-testId="choose-file-btn"
      (clicked)="uploadInput.click()"
      [text]="'choose file'"
      [role]="'secondary'"
      [iconUrl]="'assets/ui/images/svg/file.svg'"
      [type]="'button'"
    ></app-button>

    <app-image-creator
      *ngIf="showImageCreator"
      (saveGeneratedImage)="saveGeneratedImage($event)"
      (imageFileChanged)="onFileChanged($event)"
      [imageHeight]="imageMaxHeigth"
      [imageWidth]="imageMaxWidth"
      [imageType]="imageType"
      [dishName]="dishName"
    ></app-image-creator>

    <ng-container
      *ngIf="showCropperButton && !showImageCreator"
      [ngTemplateOutlet]="cropButtonEl"
    ></ng-container>
  </div>

  <input
    id="{{ 'imageUpload-' + formControlName }}"
    #uploadInput
    hidden
    type="file"
    [accept]="imageLimits.type"
    (click)="onFileClick()"
    (change)="onFileChanged($event)"
  />

</div>

<div class="cropper-wrapper" [ngClass]="showCropper ? '' : 'cropper-invisible'">
  <div class="cropper-header">
    <h4>crop image</h4>
    <div class="crop-actions">
      <app-button
        data-testId="image-save-btn"
        (clicked)="cropperSaveButtonClick()"
        [text]="'Save'"
        [role]="'secondary'"
        [type]="'button'"
      >
      </app-button>
      <app-button
        (clicked)="cropperCancelButtonClick()"
        [text]="'Cancel'"
        [role]="'secondary'"
        [type]="'button'"
      >
      </app-button>
    </div>
  </div>
  <div class="cropper-container">
    <image-cropper
      class="cropper-margin"
      [imageChangedEvent]="imageChangedEvent"
      [aspectRatio]="imageAspectRatio"
      [maintainAspectRatio]="imageType !== 'badge'"
      [resizeToWidth]="imageMaxWidth"
      [resizeToHeight]="imageMaxHeigth"
      [imageBase64]="imageBase64"
      format="imageType === 'badge' ? png : jpeg"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
</div>

<ng-template #cropButtonEl>
  <app-button
    class="crop-buttons"
    (clicked)="displayCropper()"
    [text]="'crop'"
    [role]="'secondary'"
    [iconUrl]="'assets/ui/images/svg/crop.svg'"
    [type]="'button'"
  ></app-button>
</ng-template>

<ng-template #imageNameEl>
    <span class="image-chooser__name">
      {{ imageName }}
    </span>
</ng-template>
