import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import jwt_decode from 'jwt-decode';

import { CommonUserRole, DecodedToken, Role, Token } from '@kitch/data-access/models';

import { getSecondsFromDate } from '@kitch/util';

const USER_ROLE = new InjectionToken<CommonUserRole>('User Role');

@Injectable()
export class TokenService implements OnDestroy {
  private decodedAuthToken: DecodedToken;
  private authToken: string;
  constructor(
    @Inject(USER_ROLE) private role: CommonUserRole,
  ) {
  }

  ngOnDestroy() {
    this.deleteToken();
  }

  setToken(token: string): void {
    this.authToken = token;
    this.decodedAuthToken = jwt_decode<DecodedToken>(token);
  }

  getToken(): string | null {
    return this.authToken;
  }

  deleteToken(): void {
    this.authToken = null;
    this.decodedAuthToken = null;
  }

  setLoginToken(token: string): void {
    sessionStorage.setItem(this.tokenKey(Token.LOGIN), token);
  }

  getLoginToken(): string | null {
    return sessionStorage.getItem(this.tokenKey(Token.LOGIN));
  }

  deleteLoginToken(): void {
    sessionStorage.removeItem(this.tokenKey(Token.LOGIN));
  }

  getIdFromToken(): string {
    return this.decodedAuthToken?.id || '';
  }

  getTokenExpirationDate(): number {
    return this.getAuthTokenExpirationDate() || 0;
  }

  getAuthTokenExpirationDate(): number | null {
    return this.decodedAuthToken?.exp;
  }

  getRole(): Role | undefined {
    return this.decodedAuthToken?.role;
  }

  getLoginRole(): Role | null {
    const token: string = this.getLoginToken();

    if (token) {
      return jwt_decode<DecodedToken>(token).role;
    }
  }

  isAdmin(): boolean {
    return this.decodedAuthToken?.isAdmin === true;
  }

  isLoginAdmin(): boolean {
    const token: string | null = this.getLoginToken();

    if (token) {
      return jwt_decode<DecodedToken>(token).isAdmin === true;
    } else {
      return false;
    }
  }

  isGuest(): boolean {
    return this.decodedAuthToken?.role === Role.GUEST;
  }

  getProfileId(): string {
    return this.decodedAuthToken?.profileId || '';
  }

  isTokenNotExpired(): boolean {
    if (this.isGuest()) {
      return true;
    } else {
      const expireDate = this.getTokenExpirationDate();
      const nowDate = getSecondsFromDate(new Date());

      return expireDate - nowDate > 0;
    }
  }

  isAuthenticated(): boolean | null {
    return this.decodedAuthToken?.iss === 'https://api.kitch.cc';
  }

  private tokenKey(token: Token) {
    return `${this.role}_${token}`;
  }
}
