import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GA_EVENT_USER_FAVORITE_RECIPE_CLICK, GA_EVENT_USER_VIEW_RECIPE_CLICK } from '@kitch/data-access/constants';
import { MyRecipe, StreamChefProfile } from '@kitch/data-access/models';
import { getAllCollaborators, TokenService } from '@kitch/data-access/services';
import { FavoritesService } from '@kitch/data-access/services/favorites.service';

@Component({
  selector: 'app-recipe-card',
  templateUrl: './recipe-card.component.html',
  styleUrls: ['./recipe-card.component.scss'],
})
export class RecipeCardComponent implements OnInit {
  favoriteStatus: boolean;
  iconUrl: string;
  isShoppable = false;

  @Input() recipe: MyRecipe;
  @Input() name: string;
  @Input() isEditCard = false;
  @Input() enablePublishStatus = false;
  @Input() isActiveSlider = false;
  @Input() isMyStuff = false;
  @Input() openInNewTab = true;

  @HostBinding('class.carousel-cell') private carouselCell = false;

  constructor(
    private favoritesService: FavoritesService,
    protected $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) {}

  ngOnInit(): void {
    this.carouselCell = this.isActiveSlider;
    this.favoriteStatus = this.recipe.favorited;
    this.getFavoriteButtonImg(this.favoriteStatus);
    if (this.recipe.collaborators?.length) {
      if (!this.recipe.collaborators.find(el => el.id === this.recipe.profile.id)) {
        this.recipe.collaborators =
          getAllCollaborators<StreamChefProfile>(this.recipe.profile, this.recipe.collaborators);
      }
    }
    this.isShoppable = this.isMyStuff && !!this.recipe.walmartRecipeId;
  }

  onFavoriteButtonClick(): void {
    this.favoriteStatus = !this.favoriteStatus;
    this.getFavoriteButtonImg(this.favoriteStatus);
    this.favoriteStatus
      ? this.favoritesService.addToFavorites(this.recipe.id).subscribe()
      : this.favoritesService.deleteFromFavorites(this.recipe.id).subscribe();

    if (this.favoriteStatus) {
      this.$gaService.gtag(
        'event',
        GA_EVENT_USER_FAVORITE_RECIPE_CLICK,
        {
          recipe_id: this.recipe.id,
          recipe_name: this.recipe.recipeName,
          recipe_slug: this.recipe.slug,
          profile_id: this.tokenService.getProfileId(),
        },
      );
    }
  }

  getFavoriteButtonImg(favoriteStatus: boolean): void {
    this.iconUrl = favoriteStatus ?
      'assets/ui/images/svg/bookmark--checked.svg' :
      'assets/ui/images/svg/black-bookmark.svg';
  }

  getRecipeLink(): string {
    return `${window.location.origin}/${this.recipe.profile.chefSlug}/recipes/${this.recipe.slug}`;
  }

  onViewClick(event: Event): void {
    const target: HTMLElement = event.target as HTMLElement;
    const shareButton = target.closest('app-share-buttons');
    const favoriteButton = target.closest('app-button#favorite');
    const walmartButton = target.closest('app-walmart-button');

    if (shareButton || favoriteButton || walmartButton) {
      event.preventDefault();

      return;
    }
    this.$gaService.gtag('event', GA_EVENT_USER_VIEW_RECIPE_CLICK, {
      recipe_id: this.recipe.id,
      recipe_name: this.recipe.recipeName,
      recipe_slug: this.recipe.slug,
      profile_id: this.tokenService.getProfileId(),
    });
  }
}
