<div
  class="app-tabs"
  [ngClass]="'app-tabs--active-tab-' + tabsMenu.active">
  <div class="app-tabs__title-list-container">
    <ul
      *ngIf="tabsMenu.tabs?.length > 1"
      class="app-tabs__title-list"
      appHorizontalScroll
    >
      <li
        *ngFor="let tab of tabsMenu.tabs"
        class="app-tabs__title-item"
        [ngClass]="{ 'hidden-on-desktop': tab.id === tabId.CHAT || tab.id === tabId.INFO }"
      >
        <button
          class="app-tabs__title-btn"
          [ngClass]="{ 'app-tabs__title-btn--active' : tabsMenu.active === tab.index }"
          (click)="switchTab(tab.index)"
        >
          {{tab.title}}
        </button>
      </li>
    </ul>
  </div>


  <div class="app-tabs__content">
    <div class="tab-content hidden-on-desktop" [ngClass]="{ 'tab-content--is-hidden' : tabsMenu.active !== 0 }">
      <ng-content select="[tab-0]"></ng-content>
    </div>
    <div class="tab-content hidden-on-desktop" [ngClass]="{ 'tab-content--is-hidden' : tabsMenu.active !== 1 }">
      <ng-content select="[tab-1]"></ng-content>
    </div>
    <div class="tab-content" [ngClass]="{ 'tab-content--is-hidden' : tabsMenu.active !== 2 }">
      <ng-content select="[tab-2]"></ng-content>
    </div>
    <div class="tab-content" [ngClass]="{ 'tab-content--is-hidden' : tabsMenu.active !== 3 }">
      <ng-content select="[tab-3]"></ng-content>
    </div>
    <div class="tab-content" [ngClass]="{ 'tab-content--is-hidden' : tabsMenu.active !== 4 }">
      <ng-content select="[tab-4]"></ng-content>
    </div>
    <div class="tab-content" [ngClass]="{ 'tab-content--is-hidden' : tabsMenu.active !== 5 }">
      <ng-content select="[tab-5]"></ng-content>
    </div>
    <div class="tab-content" [ngClass]="{ 'tab-content--is-hidden' : tabsMenu.active !== 6 }">
      <ng-content select="[tab-6]"></ng-content>
    </div>
  </div>
</div>
