<app-modal #stopStreamModal [showCloseIcon]="false">
  <ng-container header>
    <h2 class="modal-title">Are you sure you want to end your livestream?</h2>
  </ng-container>
  <ng-container body>
    <div class="form__item form__item--center">
      <div class="form-button form-button--success">
        <app-button
          data-testid="stop-stream-modal-btn"
          [text]="'yes'"
          (clicked)="stopStreamAndCloseModal()">
        </app-button>
      </div>
      <div class="form-button form-button--close">
        <app-button
          [role]="'secondary'"
          [text]="'No, keep streaming'"
          (clicked)="closeModal()">
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
