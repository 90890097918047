export interface BasicAudienceSchema {
    profileId?: string;
    streamType: StreamViewerType;
    videoId?: string;
    viewerName?: string;
    viewerPhoto?: string;
}

export interface AudienceViewerUser {
    createdAt: string;
    profileId: string;
    raisedHand?: boolean;
    videoId?: string;
    viewerName?: string;
    viewerPhoto?: string;
}
export interface AudienceUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ViewerCapped {
  capped: boolean;
}

export interface WatchReplayUserInfo {
  profileId: string;
  seconds: number;
  videoId: string;
  viewerName: string;
  viewerPhoto: string;
}

export interface WatchReplayDuration extends WatchReplayUserInfo {
  streamType: StreamViewerType;
}

export enum StreamViewerType {
  LIVESTREAM = 'LIVESTREAM',
  REPLAY = 'REPLAY',
}
