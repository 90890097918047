<div class="avatar--wrap">
  <ng-container *ngIf="url; else nameAvatar">
    <picture
      data-testid="avatar-image"
      class="avatar img-channel avatar--{{ size }}"
      [ngClass]="{ 'avatar--bg-color': isLive }"
    >
      <img
        class="avatar"
        width="32"
        height="32"
        [ngSrc]="url"
        alt="{{ altText }}"
      />
    </picture>
  </ng-container>

  <ng-template #nameAvatar>
    <div class="avatar img-channel avatar--{{ size }} avatar--name" data-testid="avatar-image">
      <div class="avatar__name">{{ upperCaseName }}</div>
    </div>
  </ng-template>

  <div class="info-details">
    <div
      *ngIf="name"
      data-testid="avatar-details-name"
      title="{{ name }}"
      class="info-details__name {{ avatarInputTextColor }}"
    >{{ name }}</div>
    <span class="info-details__you" *ngIf="isYou">(You)</span>
    <div
      *ngIf="title && !isClub"
      data-testid="avatar-details-title"
      class="info-details__title"
    >{{ title }}</div>
  </div>
</div>


