import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LOCATION } from '@ng-web-apis/common';

export interface MetaData {
  description?: string,
  image?: string,
  title?: string,
  type?: string,
  url?: string
}

@Injectable()
export class SeoService {
  // tool for meta tags: https://megatags.co/#generate-tags
  private readonly defaultTitle: string;
  private readonly defaultDescription: string;
  private readonly defaultImage: string;
  private readonly defaultType: string;
  private noIndexMetaTagWasAdded: boolean;

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCATION) private location: Location,
  ) {
    this.defaultTitle = 'Kittch';
    this.defaultDescription = 'Kittch is the first live-streaming community of culinary creators. ' +
      'Come see what’s cooking.';
    this.defaultImage = `${this.location.host}/assets/ui/images/png/social-logo.png`;
    this.defaultType = 'website';
  }

  updateTags(metadata: MetaData): void {
    const {
      title = this.defaultTitle,
      description = this.defaultDescription,
      image = this.defaultImage,
      type = this.defaultType,
      url = this.location.href,
    } = metadata;
    const shortDescription = description.slice(0, 320);

    // Main meta tags
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: shortDescription });
    this.meta.updateTag({ name: 'thumbnail', content: image });

    // Open Graph general (Facebook, LinkedIn, Apple Pinterest & Google+)
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:type', content: type });
    this.meta.updateTag({ property: 'og:description', content: shortDescription });

    // Twitter
    // title, image and description twitter gets from og tags
    // site and card are static values and moved to index.html
  }

  setTagsToDefault(): void {
    this.updateTags({
      title: this.defaultTitle,
      description: this.defaultDescription,
      image: this.defaultImage,
    });
  }

  getDescription(): string {
    return this.meta.getTag('name = "description"')?.content ||
      this.defaultDescription;
  }

  getCurrentTags(): Required<MetaData> {
    return {
      title: this.meta.getTag('property="og:title"')?.content || this.defaultTitle,
      description: this.meta.getTag('property="og:description"')?.content || this.defaultDescription,
      image: this.meta.getTag('property="og:image"')?.content || this.defaultImage,
      type: this.meta.getTag('property="og:type"')?.content || this.defaultType,
      url: this.meta.getTag('property="og:url"')?.content || this.location.href,
    };
  }

  setNoIndexMetaTag(): void {
    if (!this.noIndexMetaTagIsExist) {
      const metaElement = this.document.createElement('meta');
      const baseEl = this.document.head.querySelector('base');

      metaElement.name = 'robots';
      metaElement.content = 'noindex';
      baseEl.after(metaElement);
      this.noIndexMetaTagWasAdded = true;
    }
  }

  removeNoIndexMetaTag(): void {
    if (this.noIndexMetaTagIsExist && this.noIndexMetaTagWasAdded) {
      this.meta.removeTag('content="noindex"');
      this.noIndexMetaTagWasAdded = false;
    }
  }

  private get noIndexMetaTagIsExist(): boolean {
    return !!this.meta.getTag('content="noindex"');
  }
}

