import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SAVE_SPOT_URL } from '@kitch/data-access/constants';
import { SAVE_SPOT_STREAMS, SimpleResponse } from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';
import { TokenService } from '@kitch/data-access/services/token.service';

export interface SaveSpotParams {
  email: string,
  timezoneOffset: number,
  videoId: string
}

interface StoredData {
  savedProfiles: Record<string, string>;
  savedStreamsIds: string[];
}

@Injectable({ providedIn: 'root' })
export class SaveSpotService {
  constructor(
    private tokenService: TokenService,
    private apiService: ApiService,
  ) { }

  isSpotSavedLocally(streamId: string): boolean {
    const { savedStreamsIds } = this.getLocallyStoredData();

    return savedStreamsIds.includes(streamId);
  }

  saveSpotLocally(streamId: string): void {
    const { savedProfiles, savedStreamsIds } = this.getLocallyStoredData();

    savedStreamsIds.push(streamId);
    savedProfiles[this.tokenService.getProfileId()] = savedStreamsIds.join(',');
    localStorage.setItem(SAVE_SPOT_STREAMS, JSON.stringify(savedProfiles));
  }

  saveSpot(params: SaveSpotParams): Observable<SimpleResponse> {
    return this.apiService.post<SimpleResponse>(SAVE_SPOT_URL, params);
  }

  private getLocallyStoredData(): StoredData {
    const savedProfiles = JSON.parse(localStorage.getItem(SAVE_SPOT_STREAMS) || '{}');
    const savedStreamsIds = (savedProfiles[this.tokenService.getProfileId()] || '').split(',');

    return { savedProfiles, savedStreamsIds };
  }
}
