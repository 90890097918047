<div class="top-channel">
  <a class="top-channel__tile-link"
     [routerLink]="'/' + channel.chefSlug"
     (click)="sendGAEvent()"
  >
    <img class="top-channel__tile-image"
      [ngSrc]="channel.channel.tileImage"
      [priority]="true"
      [fill]="true"
      alt="thumbnail"
    />
  </a>

  <div class="top-channel__content">
    <div>
      <div class="top-channel__content-title">
        featured {{ channel.channel.isClub ? 'course' : 'channel' }}
      </div>
      <div class="top-channel__author top-channel__author-channel">
        <app-avatar
          [size]="'small'"
          [name]="channel.chefName"
          [title]="channel.channel.name"
        ></app-avatar>
      </div>
    </div>
    <a
      class="top-channel__action-button link"
      [routerLink]="'/' + channel.chefSlug"
      (click)="sendGAEvent()"
    >
      {{ channel.channel.isClub ? 'Explore course' : 'View channel' }}
    </a>
  </div>
</div>
