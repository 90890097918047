import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import clamp from 'clamp-js';

type Options = {
  animate?: boolean,
  clamp?: number,
  splitOnChars?: string[]
  truncationChar?: string,
  truncationHTML?: string,
  useNativeClamp?: boolean
}

@Directive({ selector: '[appTrimContent]' })
export class TrimContentDirective implements AfterViewInit {
  @Input() options: Options;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    clamp(this.el.nativeElement, this.options);
  }
}
