import { ChangeDetectorRef, Component, EventEmitter, forwardRef,
  Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation,
  AfterViewChecked }
  from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { ToolbarItem } from 'ngx-editor/lib/types';

let nextId = 0;

@UntilDestroy()
@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WysiwygEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => WysiwygEditorComponent),
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class WysiwygEditorComponent implements OnInit, OnChanges,
  OnDestroy, ControlValueAccessor, Validator, AfterViewChecked {
  @Input() formControlName: string;
  @Input() isEditable = true;
  @Input() isRequired = true;
  @Input() errorMessage = false;
  @Input() errorName: string;
  @Input() maxLength: number;
  @Input() extraToolBarElements?: ToolbarItem[];
  @Input() placeholder = 'Type here...';
  @Output() textValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueWithHtml: EventEmitter<string> = new EventEmitter<string>();

  editor: Editor;
  toolBar: Toolbar = [
    ['bold', 'italic', 'underline'],
  ];

  validField = false;
  focused = false;

  readonly editorId = `app-editor-${nextId++}`;

  get isEmptyContent(): boolean {
    return this.editor.view.dom.innerHTML.includes(`data-placeholder="${this.placeholder}"`);
  }

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.validField = !this.isRequired;
    this.initEditor();

    setTimeout(() => {
      const wrapEditor = document.getElementById(this.editorId);

      if (!this.isEditable) {
        const inputField = wrapEditor.querySelector('.NgxEditor__Content');

        inputField.setAttribute('contenteditable', 'false');
      }
    });

    this.observeEditorChanges();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(ch) {
    this.errorMessage = ch?.errorMessage?.currentValue;
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  writeValue(value: string): void {
    this.editor.setContent(value);
  }

  onBlur(): void {
    this.focused = true;

    if (this.isRequired || this.errorMessage) {
      this.validField = !this.isEmptyContent;
      this.errorMessage = !this.validField;
    } else {
      this.validField = true;
    }
    this.propagateOnTouched();
  }

  onFocus(): void {
    if (this.isRequired) {
      this.validField = !this.isEmptyContent;
    } else {
      this.validField = true;
    }
    this.errorMessage = false;
    this.propagateOnTouched();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn) {
    this.propagateOnChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn) {
    this.propagateOnTouched = fn;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    if (this.validField) {
      return null;
    }

    return { [this.formControlName]: true };
  }

  private initEditor(): void {
    this.editor = new Editor();

    if (this.extraToolBarElements?.length) {
      this.toolBar.push(this.extraToolBarElements);
    }
  }

  private addHttpsToHrefs(htmlString: string): string {
    const regex = /(<a[^>]+href=")(http:\/\/|https:\/\/)?([^"]+)("[^>]*>)/g;

    return htmlString.replace(regex, (match, p1, p2, p3, p4) => {
      if (p2 === 'http://' || p2 === 'https://') {
        return match;
      } else {
        return `${p1}https://${p3}${p4}`;
      }
    });
  }

  private observeEditorChanges(): void {
    this.editor.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((content) => {
        const htmlContent = toHTML(content);

        if (this.maxLength && this.editor.view.dom.textContent.length >= this.maxLength) {
          const contentText = this.editor.view.dom.textContent?.slice(0, this.maxLength);

          this.editor.setContent(contentText);
        }
        this.validField = !(this.isRequired && content.content.length === 1 && !content.content[0].content);

        let formattedContent = this.extraToolBarElements?.includes('link') ?
          this.addHttpsToHrefs(htmlContent)
          : htmlContent;

        formattedContent = this.changeEmptyLineToBreak(formattedContent);

        this.propagateOnChange(formattedContent);
        this.textValue.emit(this.editor.view.dom.textContent);
        this.valueWithHtml.emit(formattedContent);
      });
  }

  private changeEmptyLineToBreak(htmlString: string): string {
    const regex = /<p><\/p>/g;

    return htmlString.replace(regex, '<br>');
  }

  private propagateOnChange: any = () => {};
  private propagateOnTouched: any = () => {};
}
