import { AbstractControl, ValidatorFn } from '@angular/forms';

export function EmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }

    return /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(control.value) ? null : { email: true };
  };
}
