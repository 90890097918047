import { DOCUMENT } from '@angular/common';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// source with slight differ - https://github.com/angular/angular/issues/15776#issuecomment-436922524

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CanonicalLinkService implements OnDestroy {
  private routeListener: Subscription;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window,
    private readonly router: Router,
  ) { }

  ngOnDestroy(): void {
    this.routeListener.unsubscribe();
  }

  startRouteListener(): void {
    this.routeListener = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      untilDestroyed(this),
    ).subscribe(
      () => {
        let url = '';
        const urlTree = this.router.parseUrl(this.router.url);

        if (urlTree.root.hasChildren()) {
          const segments = urlTree.root.children.primary.segments;

          if (segments?.length > 0) {
            url = segments.map(segment => segment.path).join('/');
          }
        }

        this.createOrUpdateCanonicalLinkTag({
          rel: 'canonical',
          href: `https://${this.window.location.host}/${url}`,
        });
      },
    );
  }

  createOrUpdateCanonicalLinkTag(tag: LinkDefinition): void {
    const selector = this.parseSelector(tag);
    const linkElement = this.document.head.querySelector(selector)
      || this.document.head.appendChild(this.document.createElement('link'));

    if (linkElement) {
      Object.keys(tag).forEach((prop: string) => {
        linkElement[prop] = tag[prop];
      });
    }
  }

  private parseSelector(tag: LinkDefinition): string {
    const attr: string = tag.rel ? 'rel' : 'hreflang';

    return `link[${attr}="${tag[attr]}"]`;
  }
}
