import {
  Component,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { InputTypes, MaxLengthErrors } from '@kitch/data-access/constants';

@Component({
  selector: 'app-errors-list',
  templateUrl: './errors-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ErrorsListComponent,
      multi: true,
    },
  ],
})

export class ErrorsListComponent implements ControlValueAccessor, OnInit {
  @Input() formControlName: string;
  @Input() control: AbstractControl;
  @Input() errorName = '';
  @Input() inputType: InputTypes;
  @Input() maxLengthError: MaxLengthErrors = MaxLengthErrors.DEFAULT;

  InputTypes = InputTypes;
  MaxLengthErrors = MaxLengthErrors;

  formControl: AbstractControl;

  @Input() rangeLength: number;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    if (!this.control) {
      if (this.controlContainer) {
        if (this.formControlName) {
          this.formControl = this.controlContainer.control.get(
            this.formControlName,
          );
        } else {
          console.warn(
            'Missing FormControlName directive from host element of the component',
          );
        }
      } else {
        console.warn('Can\'t find parent FormGroup directive');
      }
    } else {
      this.formControl = this.control;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  writeValue(obj: any): void {}
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn: any): void {}
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn: any): void {}
}
