<a
  [routerLink]="'/' + profile.chefSlug"
  (click)="sendGAEvent()"
  target="{{ needOpenInNewTab ? '_blank' : '_self' }}"
>
  <div class="channel__wrap">
    <div class="channel__thumbnail">
      <div class="overlay"></div>
      <picture>
        <img
          class="avatar"
          height="500"
          width="500"
          [ngSrc]="profile.channel?.tileImage"
          alt="{{ profile.channel?.name }}"
        />
      </picture>
      <h3 *ngIf="isClub" class="channel__title">{{ profile.chefName }}</h3>
    </div>
    <div *ngIf="!isClub" class="channel__author">
      <app-avatar
        [url]="profile.photo"
        [size]="avatarSize"
        [name]="profile.chefName"
        [title]="profile.channel && !isCollectionChannel ? profile.channel.name : ''"
        [avatarInputTextColor]="'green'"
        [isLive]="profile.isLive"
        [isClub]="!!profile.channel.requiredClubSubscriptionPlan"
      ></app-avatar>
    </div>
  </div>
</a>
