import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CUISINES_URL } from '@kitch/data-access/constants';
import {
  AppPagesItem,
  BasicCuisineSchema,
  Cuisine,
  HardcodedCuisinesStructure,
} from '@kitch/data-access/models';
import { CuisineSearchParams } from '@kitch/data-access/models/search-params/cuisine';
import { ApiService } from '@kitch/data-access/services/api.service';

import { makeUri } from '@kitch/util';

@Injectable()
export class CuisinesService {
  constructor(private apiService: ApiService) {}

  create(params: BasicCuisineSchema): Observable<AppPagesItem<Cuisine>> {
    return this.apiService.post(CUISINES_URL, params);
  }

  getAll(queryParams?: CuisineSearchParams): Observable<AppPagesItem<Cuisine>> {
    return this.apiService.get(CUISINES_URL, queryParams);
  }

  getAllShort(queryParams?: CuisineSearchParams): Observable<AppPagesItem<BasicCuisineSchema>> {
    return this.apiService.get(makeUri(CUISINES_URL, 'short-info'), queryParams);
  }

  getHardcodedCuisines(): Observable<HardcodedCuisinesStructure> {
    return this.apiService.get(makeUri(CUISINES_URL, 'get-hardcoded-cuisines-structure'));
  }
}
