import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { UploadService, UploadUrls } from '@kitch/data-access/services';
import { UrlTool } from '@kitch/util';
import { RequiredFieldsValidator } from '@kitch/util/validators/required-fields.validator';
import {
  ProductFormControlType, ProductForm,
  ProductsFormArrayType,
  ProductsForm, Product,
} from '@kitch/ui/models';

@UntilDestroy()
@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
})
export class ProductFormComponent implements OnInit, OnDestroy {
  @Input() isShowSlideToggle = false;
  isCreateNewProduct = false;

  productForm: FormGroup<ProductsForm>;
  isShowAddProductButtons = false;

  constructor(
    private uploadService: UploadService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.createCustomProductsForm();
  }

  ngOnDestroy() {}

  get productsFormIsValid(): boolean {
    return this.productForm.valid;
  }

  get productsControlValueForSave(): Product[] {
    if (!this.productControls.length) {
      return [];
    } else {
      return [...this.productControls]
        .map(({ value }: ProductFormControlType) => value as Product)
        .filter(({ url, label }) => !!url && !!label)
        .map((control) => {
          const { url, label, id } = control;

          if (this.isCreateNewProduct || (url && label && id === '')) {
            delete control.id;
          }

          control.url = UrlTool.checkUrlProtocol(url);

          return control;
        });
    }
  }

  get productsFormArray(): ProductsFormArrayType {
    return this.productForm.get('products') as ProductsFormArrayType;
  }

  get productControls(): ProductFormControlType[] {
    return this.productsFormArray.controls;
  }

  setProductsValueToForm(streamProducts: Product[]): void {
    const products = streamProducts.map((value) => {
      if (value.price === 0) {
        value.price = null;
      }

      return value;
    });

    this.isShowAddProductButtons = !!products.length;

    products.forEach(() => {
      this.addProduct();
    });

    this.productForm.get('products').patchValue(products);

    if (!this.productControls.length && !this.isShowSlideToggle) {
      this.addProduct();
    }
  }

  uploadProductPhotos(): Observable<Product>[] {
    return this.productControls.map((control) => {
      if (control.value.image instanceof File) {
        return this.uploadService.getUploadUrls(control.value.image.type, 'video-product')
          .pipe(
            concatMap((res: UploadUrls) => {
              const imageFile = control.value.image;

              control.value.image = res.publicUrl;

              return this.uploadService.upload(res.uploadSignedUrl, imageFile);
            }),
            untilDestroyed(this),
          );
      } else {
        return of({});
      }
    });
  }

  showAddProductsButtons(show?: boolean): void {
    this.isShowAddProductButtons = show;
    if (show && !this.productControls.length) {
      this.addProduct();
    } else {
      this.productsFormArray.controls = [];
    }
  }

  addProduct(): void {
    const control = this.formBuilder.group<ProductForm>({
      id: new FormControl<string>(''),
      label: new FormControl<string>(''),
      url: new FormControl<string>(''),
      image: new FormControl<string | File>(''),
      price: new FormControl<number | null>(null),
    }, { validators: [RequiredFieldsValidator()] });

    this.productsFormArray.push(control);
  }

  removeProduct(id: number): void {
    this.productsFormArray.removeAt(id);
  }

  removeAllProducts(): void {
    this.productsFormArray.clear();
  }

  removeProductImage(indexImage: number): void {
    this.productControls
      .filter((control, index) => index === indexImage)
      .forEach((control) => control.get('image').reset());
  }

  private createCustomProductsForm(): void {
    this.productForm = this.formBuilder.group<ProductsForm>({
      products: this.formBuilder.array<ProductFormControlType>([]),
    });
  }
}
