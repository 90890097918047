<div [ngSwitch]="loginStep" class="login-wrap">
  <section class="login">
    <h1 class="login__logo">
      <div class="sign-in-image">
        <img class="" src="assets/ui/images/gif/kittch_teaser_2022_update.gif" alt="Kittch">
      </div>
    </h1>

    <ng-container *ngSwitchCase="'default'">

      <form class="login__form" [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <div class="login__form-container">
          <h2 class="login__title">welcome to kittch</h2>
          <ul>
            <li class="form-fields">
              <div class="form__item c-form-item">
                <div class="form__field">
                  <app-animated-input formControlName="phoneNumber" label='Mobile Number' autocomplete="username"
                    type="tel" isLoginForm="true" (dialCode)="setDialCode($event)"></app-animated-input>
                  <app-errors-list formControlName="phoneNumber"></app-errors-list>
                  <app-errors-list></app-errors-list>
                </div>
              </div>

              <div class="form__item form__item form__item--button">
                <app-button
                  [text]="'sign in'"
                  [type]="'submit'"
                  isLoginForm="true"
                  [isDisabled]="!loginForm.valid || isUserLoggingIn"
                  [isDisabledLoginForm]="!loginForm.valid || isUserLoggingIn && isSubmitted ? 'disabled' : ''">
                </app-button>
              </div>
            </li>

          </ul>
        </div>
      </form>

      <ul class="questions-container">
        <li class="question__item">
          <p class="join-team">
            <span class="join-team__text">Work at Kittch –
              <a class="join-team__link" href="http://kittch.workable.com/" target="_blank" rel="noopener">Join our team!</a>
            </span>
          </p>
        </li>
        <li class="question__item">
          <p class="access">
            <span class="access__text">Are you a Chef/Creator? Apply for access
              <a class="access__link"
                data-testid="access-link"
                href="https://6hjii32gwyh.typeform.com/to/GHujEy5T?typeform-source=kittch.com"
                target="_blank" rel="noopener">here</a>.</span>

          </p>
        </li>
        <li class="question__item">
          <p class="contact">
            <span class="contact__text">Need help?</span>
            <a class="contact__link" href="mailto:support@kittch.com" rel="nofollow"> Contact Us</a>.
          </p>
          <p class="privacy-policy">
            <span class="privacy-policy__text">View our</span>
            <a class="privacy-policy__link" (click)="setStatusPrivacyPolicyModal(true)"> Privacy Policy</a>
          </p>
        </li>
      </ul>
    </ng-container>

    <app-privacy-policy-modal [isOpen]="isPrivacyPolicyModalOpen"
      (isPrivacyPolicyModalOpen)="setStatusPrivacyPolicyModal($event)">
    </app-privacy-policy-modal>

    <app-modal #userApprovalModal [backgroundColor]="'#a6e6db'">
      <ng-container body>
        <div class="user-exists-modal">
          <p>
            Thanks for signing up.<br />
            You will have access within 24 hours<br />
            or be contacted with instructions.
          </p>
        </div>
        <div class="user-exists-actions form__item form__item--center">
          <div class="form-button form-button--close">
            <app-button [text]="'Close'" (clicked)="userApprovalModal.close()"></app-button>
          </div>
        </div>
      </ng-container>
    </app-modal>

    <app-user-not-found-modal
      [isOpen]="isUserNotFoundModalOpen"
      (userAction)="handleUserNotFoundModalAction($event)"
      (isUserNotFoundModalOpen)="setStatusModal($event)">
    </app-user-not-found-modal>


    <div class="verification" *ngSwitchCase="'verification'">
      <div class="login__form-container login__verification">
        <app-login-verification [code]="code" [loading]="loading" [error]="error$.asObservable()"
          (verifyAuthCode)="onVerifyAuthCode($event)" (resendAuthCode)="onResendAuthCode()">
        </app-login-verification>
      </div>
      <ul>
        <li class="question__item">
          <p class="resend-code">
            <span class="resend-code__text">Didn’t receive a code?</span>
            <button type="button" class="resend-code__button" (click)="onResendAuthCode()">RESEND</button>
          </p>
        </li>
        <li class="question__item">
          <p class="privacy-policy">
            <span class="privacy-policy__text">View our&nbsp;</span>
            <a class="privacy-policy__link" (click)="setStatusPrivacyPolicyModal(true)">Privacy Policy</a>
          </p>
        </li>
      </ul>
    </div>
  </section>
</div>
