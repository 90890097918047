export const LOGIN_URL = 'auth/login';
export const VERIFY_LOGIN_URL = 'auth/verify';
export const RESEND_CODE_URL = 'auth/resend';
export const REFRESH_TOKEN_URL = 'auth/refresh';
export const LOGOUT_URL = 'auth/logout';
export const GUEST_LOGIN_URL = 'auth/guest/login';
export const SIGN_UP_URL = 'auth/registration';
export const REF_CODE_VALIDATE_URL = 'auth/referral';
export const SSO_URL = 'login/sso';
export const SSO_LOGIN_URL = 'login/sso-login-uri';
export const INSTAGRAM_LOGIN_URL = 'login/instagram-user';

export const UPLOAD_URL = 'upload';

export const CHANNELS_URL = 'channels';
export const RECIPES_URL = 'recipes';
export const STREAMS_URL = 'streams';
export const STAGED_URL = 'staged';
export const DASHBOARD_URL = 'dashboard';
export const CUISINES_URL = 'cuisines';
export const TAGS_URL = 'tags';
export const FAVORITES_URL = 'favorites';
export const FAVORITE_PRODUCT_URL = 'favorite-product';
export const PROFILES_URL = 'profiles';
export const STRIPE_URL = 'stripe';
export const SUBSCRIPTIONS_URL = 'subscriptions';
export const PRODUCTS_URL = 'products';
export const USERS_URL = 'users';
export const RESTAURANTS_URL = 'restaurants';
export const TIPS_URL = 'tips';
export const TERMS_URL = 'terms-of-use';
export const FOLLOWS_URL = 'follows';
export const WITHDRAWALS_URL = 'withdrawals';
export const SETTINGS_URL = 'settings';
export const SPECIALTY_URL = 'specialities';
export const TRANSACTIONS_URL = 'transactions';
export const VIEWERS_URL = 'viewers';
export const WALMART_URL = 'walmart';
export const DISCOUNTS_URL = 'discounts';
export const CHEF_TABLE_VISITORS_URL = 'chef-table-visitors';
export const SAVE_SPOT_URL = 'spots/save-my-spot';
export const AI_IMAGE_GENERATE_URL = 'ai/image/generate';
export const EXPERIMENT_OPTION_URL = 'experiment/option';

export const FILTERS_URL = 'filters';

export const OPENWEB_URL = 'openweb';
export const SEARCH_URL = 'search';
export const SEARCH_PAGINATION_URL = 'search-pagination';
export const ANALYTICS_URL = 'analytics';
