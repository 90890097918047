import { Subject } from 'rxjs';
// import { logger } from '@kitch/util/logger.tool';

export type VideoProfileId = typeof AgoraRTCTool['profiles'][number]['id'];

interface VideoProfileConfig extends VideoEncoderConfiguration {
  bitrateMax: number;
  bitrateMin?: number;
  frameRate: number;
  height: number;
  width: number;
}

export class AgoraRTCTool {
  // Auto Adjust Resolutions

  // This utility will adjust the camera encoding profile dynamically in order to adapt to slower networks
  // and also to support devices which insufficient CPU/GPU resources required to encode and
  // decode higher resolution video streams
  // This utility is intended mainly for iOS devices where both Safari and Chrome do not automatically
  // lower the encoding resolution when the outgoing bitrate is reduced or the encoder is struggling to reach
  // the desired FPS

  // It is useful on other browsers as well to avoid the bit rate dropping low too quickly in the presence
  // of packet loss.

  /* To use this module, simply include this JS file e.g.
     <script src='./sdk/AgoraRTCUtil.js'></script>

     and call the following after client.publish(..)

     AgoraRTCTool.startAutoAdjustResolution(this.clients[this.myPublishClient],'360p_11');

     It is recommended that you start with the following settings in your app which correspond to the 360p_11
     profile from the list below

          [this.localTracks.audioTrack, this.localTracks.videoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(
          { microphoneId: this.micId }, { cameraId: this.cameraId, encoderConfig:
          { width:640, height: 360, frameRate: 24, bitrateMin: 400, bitrateMax: 1000} });

    // eslint-disable-next-line max-len
    To avoid losing the camera feed on iOS when switching resolution you should explicitly select the camera and mic in
     the SDK e.g.
        await agoraApp.localTracks.videoTrack.setDevice(currentCam.deviceId);
        await agoraApp.localTracks.audioTrack.setDevice(currentMic.deviceId);

*/
  private static readonly profiles = [
    {
      id: '360p',
      moveDownThreshold: 0,
      moveUpThreshold: 930,
      config: {
        height: 360,
        width: 480,
        frameRate: 30,
        bitrateMin: 100,
        bitrateMax: 980,
      },
    },
    {
      id: '480p',
      moveDownThreshold: 930,
      moveUpThreshold: 1710,
      config: {
        height: 480,
        width: 848,
        frameRate: 30,
        bitrateMin: 930 * 0.9,
        bitrateMax: 1860,
      },
    },
    {
      id: '720p',
      moveDownThreshold: 1710,
      moveUpThreshold: 3150,
      config: {
        height: 720,
        width: 1280,
        frameRate: 30,
        bitrateMin: 1710 * 0.9,
        bitrateMax: 3420,
      },
    },
    {
      id: '1080p',
      moveDownThreshold: 3150,
      moveUpThreshold: Number.MAX_SAFE_INTEGER,
      config: {
        height: 1080,
        width: 1920,
        frameRate: 30,
        bitrateMin: 3150 * 0.9,
        bitrateMax: 6300,
      },
    },
  ] as const;

  private readonly AdjustFrequency = 500; // ms between checks
  private readonly ResultCountToStepUp = 6; // number of consecutive positive results before step up occurs
  private readonly ResultCountToStepDown = 10; // number of consecutive negative results before step down occurs
  private readonly MinFPSPercent = 90; // below this percentage FPS will a trigger step down
  private readonly MinVideoKbps = 100; // below this and the video is likely off or static
  private readonly _videoProfileChanging$ = new Subject<VideoProfileId>();

  private _autoAdjustInterval: ReturnType<typeof setTimeout>;
  private _publishClient: IAgoraRTCClient;
  private _currentProfileIndex = 0;
  private _fpsLowObserved = 0;
  private _brLowObserved = 0;
  private _brHighObserved = 0;

  readonly videoProfileChanging$ = this._videoProfileChanging$.asObservable();

  // // Fire Inbound Audio Levels for Remote Streams
  // // Bandwidth and Call Stats Utils
  // // fire events if necessary
  //
  // private _rtc_clients = [];
  // private _rtc_num_clients = 0;
  // private _monitorInboundAudioLevelsInterval;
  //
  // // Voice Activity Detection
  // // fire events if necessary
  //
  // private _vad_audioTrack = null;
  // private _voiceActivityDetectionFrequency = 150;
  //
  // private _vad_MaxAudioSamples = 400;
  // private _vad_MaxBackgroundNoiseLevel = 30;
  // private _vad_SilenceOffset = 10;
  // private _vad_audioSamplesArr = [];
  // private _vad_audioSamplesArrSorted = [];
  // private _vad_exceedCount = 0;
  // private _vad_exceedCountThreshold = 2;
  // private _voiceActivityDetectionInterval;
  //
  // private readonly AgoraRTCUtilEvents = new AgoraRTCUtilEvents();

  private static getProfileByIndex(profileIndex: number) {
    return AgoraRTCTool.profiles[profileIndex];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getProfileIndexById(profileId: VideoProfileId) {
    return AgoraRTCTool.profiles.findIndex((p) => p.id === profileId);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getProfileConfigById(profileId: VideoProfileId) {
    const config = AgoraRTCTool.profiles.find((p) => p.id === profileId)?.config;

    if (!config) {
      throw new Error(`Failed to get profile config by id("${profileId}")`);
    }

    return { ...config };
  }

  constructor({ initialVideoProfileId }: { initialVideoProfileId: VideoProfileId }) {
    this._currentProfileIndex = AgoraRTCTool.getProfileIndexById(initialVideoProfileId);
  }

  private async changeProfileUp() {
    await this.changeProfile(this._currentProfileIndex + 1); // increase profile
  }

  private async changeProfileDown() {
    await this.changeProfile(this._currentProfileIndex - 1); // reduce profile
  }

  private async autoAdjustResolution() {
    // real time video stats
    const videoStats = this._publishClient.getLocalVideoStats();

    const profile = AgoraRTCTool.getProfileByIndex(this._currentProfileIndex);
    const sendBitrateKbps = Math.floor(videoStats.sendBitrate / 1000);

    // check encoding FPS not too low
    if (
      videoStats.sendFrameRate &&
      videoStats.sendFrameRate > 0 &&
      videoStats.sendFrameRate < (profile.config.frameRate * this.MinFPSPercent) / 100
    ) {
      this._fpsLowObserved++;
    } else {
      this._fpsLowObserved = 0;
    }

    // check outbound bitrate not too low for this resolution
    if (
      videoStats.sendResolutionWidth > 0 &&
      sendBitrateKbps < profile.moveDownThreshold &&
      sendBitrateKbps > this.MinVideoKbps
    ) {
      this._brLowObserved++;
    } else {
      this._brLowObserved = 0;
    }

    // see if performing well enough to increase profile
    if (
      videoStats.sendResolutionWidth > 0 &&
      (videoStats.sendResolutionWidth === profile.config.width ||
        videoStats.sendResolutionWidth === profile.config.height) &&
      sendBitrateKbps > profile.moveUpThreshold
    ) {
      this._brHighObserved++;
    } else {
      this._brHighObserved = 0;
    }

    // log details
    // eslint-disable-next-line max-len
    //logger.info('AutoAdjustAlgo profile:'+_currentProfileIndex+', width:'+videoStats.sendResolutionWidth+', height:'+videoStats.sendResolutionHeight+', fps:' + videoStats.sendFrameRate + ', br_kbps:' + sendBitrateKbps + ', bad_fps:' + _fpsLowObserved + ', bad_br:' + _brLowObserved + ', good_br:' + _brHighObserved+' ios='+isIOS());
    // +', sendPacketsLost:'+videoStats.sendPacketsLost does not work on Safari

    // after 5 seconds of low bandwidth out
    if (this._brLowObserved > this.ResultCountToStepDown) {
      await this.changeProfileDown(); // reduce profile
    } else if (this._fpsLowObserved > this.ResultCountToStepDown) {
      await this.changeProfileDown(); // reduce profile
    }

    // after about 5 seconds of very good
    if (
      this._fpsLowObserved === 0 &&
      this._brLowObserved === 0 &&
      this._brHighObserved > this.ResultCountToStepUp &&
      this._currentProfileIndex < AgoraRTCTool.profiles.length - 1
    ) {
      await this.changeProfileUp(); // increase profile
    }
  }

  private clearCounters() {
    this._brLowObserved = 0;
    this._brHighObserved = 0;
    this._fpsLowObserved = 0;
  }

  private async changeProfile(profileIndex) {
    if (profileIndex < 0 || profileIndex >= AgoraRTCTool.profiles.length) return;

    // logger.info(
    //   `debug: Changing video profile to (${profileIndex})"${AgoraRTCTool.getProfileByIndex(profileIndex).id}"`,
    // );

    this._currentProfileIndex = profileIndex;

    this.clearCounters();

    this._videoProfileChanging$.next(AgoraRTCTool.getProfileByIndex(profileIndex).id);

    const profile = AgoraRTCTool.getProfileByIndex(profileIndex);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const videoTrack: ICameraVideoTrack = this._publishClient?._highStream?.videoTrack;

    if (!videoTrack) {
      return;
    }

    await videoTrack.setEncoderConfiguration(profile.config);
  }

  get activeProfileId(): VideoProfileId {
    return AgoraRTCTool.getProfileByIndex(this._currentProfileIndex).id;
  }

  get activeProfileConfig(): VideoProfileConfig {
    return { ...AgoraRTCTool.getProfileByIndex(this._currentProfileIndex).config };
  }

  get lowVideoConfig(): VideoProfileConfig {
    return {
      height: 360,
      width: 640,
      frameRate: 15,
      bitrateMax: 800,
    };
  }

  // get highVideoConfig(): VideoProfileConfig {
  //   return {
  //     height: 1080,
  //     width: 1920,
  //     frameRate: 30,
  //     bitrateMax: 6300,
  //   };
  // }

  /*
    mobile
    168х124 (1-2) 240х240
    109х80 (3) 120х120
    80х59 (4+) 120х120

    desktop
    232х232 (1) 240х240
    224х168 (2) 240х240
    106х78 (3+) 120х120
  */

  get userVideoConfig(): VideoProfileConfig {
    return {
      width: 240,
      height: 240,
      frameRate: 15,
      bitrateMax: 280,
    };
  }

  get secondCameraVideoConfig(): VideoProfileConfig {
    return {
      width: 320,
      height: 180,
      frameRate: 15,
      bitrateMax: 280,
    };
  }

  // get coHostCameraVideoConfig(): VideoProfileConfig {
  //   return {
  //     width: 640,
  //     height: 360,
  //     frameRate: 30,
  //     bitrateMax: 1200,
  //   };
  // }

  isIOS(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  async startAutoAdjustResolution(client: IAgoraRTCClient, initialVideoProfileId?: VideoProfileId): Promise<void> {
    // logger.info('debug: startAutoAdjustResolution');

    if (this._autoAdjustInterval) {
      clearInterval(this._autoAdjustInterval);
      this._autoAdjustInterval = null;
    }
    this.clearCounters();

    this._publishClient = client;

    if (initialVideoProfileId) {
      this._currentProfileIndex = AgoraRTCTool.getProfileIndexById(initialVideoProfileId);
    }

    await this.changeProfile(this._currentProfileIndex);

    this._autoAdjustInterval = setInterval(async () => {
      // const localVideoStats = this._publishClient.getLocalVideoStats();
      // eslint-disable-next-line max-len
      // logger.info(`debug: video ${localVideoStats.sendFrameRate}fps ${localVideoStats.sendResolutionHeight}h ${localVideoStats.sendResolutionWidth}w ${localVideoStats.sendBitrate / 1000}kbr`, localVideoStats);

      await this.autoAdjustResolution();
    }, this.AdjustFrequency);
  }

  stopAutoAdjustResolution(): void {
    // logger.info('debug: stopAutoAdjustResolution');
    if (!this._autoAdjustInterval) {
      return;
    }
    clearInterval(this._autoAdjustInterval);
    this._autoAdjustInterval = null;
    this.clearCounters();
  }

  // private monitorInboundAudioLevels() {
  //   for (let i = 0; i < this._rtc_num_clients; i++) {
  //     const client = this._rtc_clients[i];
  //     if (!client._users.length) {
  //       continue;
  //     }
  //
  //     if (client._remoteStream) {
  //       for (let u = 0; u < client._users.length; u++) {
  //         const uid = client._users[u].uid;
  //         const rc = client._remoteStream.get(uid);
  //         if (rc) {
  //           if (rc.pc && rc.pc.pc) {
  //             rc.pc.pc.getStats(null).then((stats) => {
  //               stats.forEach((report) => {
  //                 if (report.type === 'inbound-rtp' && report.kind === 'audio') {
  //                   if (report['audioLevel']) {
  //                     let audioLevel = report['audioLevel'];
  //                     if (audioLevel > 1.0) {
  //                       audioLevel = audioLevel / 100000.0;
  //                     }
  //                     logger.info('sweet audioLevel ' + audioLevel);
  //                     // Safari has much bigger numbers
  //                     // need to divide by around 10000
  //                     this.AgoraRTCUtilEvents.emit('InboundAudioExceedsThreshold', audioLevel);
  //                   }
  //
  // eslint-disable-next-line max-len
  //                   // Object.keys(report).forEach(statName => { logger.info(`UTILS inbound-rtp ${report.kind} for ${uid} ${statName} ${report[statName]}`); });
  //                 } else {
  // eslint-disable-next-line max-len
  //                   // Object.keys(report).forEach(statName => { logger.info(`${report.type} ${report.kind} ${uid}  ${statName}: ${report[statName]}`); });
  //                 }
  //               });
  //             });
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  //
  // private getInputLevel(track) {
  //   const analyser = track._source.analyserNode;
  //   const bufferLength = analyser.frequencyBinCount;
  //   const data = new Uint8Array(bufferLength);
  //   analyser.getByteFrequencyData(data);
  //   let values = 0;
  //   const length = data.length;
  //   for (let i = 0; i < length; i++) {
  //     values += data[i];
  //   }
  //   const average = Math.floor(values / length);
  //   return average;
  // }
  //
  // private voiceActivityDetection() {
  //   if (!this._vad_audioTrack) return;
  //
  //   const audioLevel = this.getInputLevel(this._vad_audioTrack);
  //   if (audioLevel <= this._vad_MaxBackgroundNoiseLevel) {
  //     if (this._vad_audioSamplesArr.length >= this._vad_MaxAudioSamples) {
  //       const removed = this._vad_audioSamplesArr.shift();
  //       const removedIndex = this._vad_audioSamplesArrSorted.indexOf(removed);
  //       if (removedIndex > -1) {
  //         this._vad_audioSamplesArrSorted.splice(removedIndex, 1);
  //       }
  //     }
  //     this._vad_audioSamplesArr.push(audioLevel);
  //     this._vad_audioSamplesArrSorted.push(audioLevel);
  //     this._vad_audioSamplesArrSorted.sort((a, b) => a - b);
  //   }
  //   const background = Math.floor(
  //     (3 * this._vad_audioSamplesArrSorted[Math.floor(this._vad_audioSamplesArrSorted.length / 2)]) / 2
  //   );
  //   if (audioLevel > background + this._vad_SilenceOffset) {
  //     this._vad_exceedCount++;
  //   } else {
  //     this._vad_exceedCount = 0;
  //   }
  //
  //   if (this._vad_exceedCount > this._vad_exceedCountThreshold) {
  //     this.AgoraRTCUtilEvents.emit('VoiceActivityDetected', this._vad_exceedCount);
  //     this._vad_exceedCount = 0;
  //   }
  // }
  //
  // setRTCClients(clientArray, numClients) {
  //   this._rtc_clients = clientArray;
  //   this._rtc_num_clients = numClients;
  // }
  //
  // setRTCClient(client) {
  //   this._rtc_clients[0] = client;
  //   this._rtc_num_clients = 1;
  // }
  //
  // startInboundVolumeMonitor(inboundVolumeMonitorFrequency) {
  //   this._monitorInboundAudioLevelsInterval = setInterval(() => {
  //     this.monitorInboundAudioLevels();
  //   }, inboundVolumeMonitorFrequency);
  // }
  //
  // stopInboundVolumeMonitor() {
  //   clearInterval(this._monitorInboundAudioLevelsInterval);
  //   this._monitorInboundAudioLevelsInterval = null;
  // }
  //
  // startVoiceActivityDetection(vad_audioTrack) {
  //   this._vad_audioTrack = vad_audioTrack;
  //   if (this._voiceActivityDetectionInterval) {
  //     return;
  //   }
  //   this._voiceActivityDetectionInterval = setInterval(() => {
  //     this.voiceActivityDetection();
  //   }, this._voiceActivityDetectionFrequency);
  // }
  //
  // stopVoiceActivityDetection() {
  //   clearInterval(this._voiceActivityDetectionInterval);
  //   this._voiceActivityDetectionInterval = null;
  // }
}

// export class AgoraRTCUtilEvents {
//   private events = {};
//
//   on(eventName, fn) {
//     this.events[eventName] = this.events[eventName] || [];
//     this.events[eventName].push(fn);
//   }
//
//   off(eventName, fn) {
//     if (this.events[eventName]) {
//       for (let i = 0; i < this.events[eventName].length; i++) {
//         if (this.events[eventName][i] === fn) {
//           this.events[eventName].splice(i, 1);
//           break;
//         }
//       }
//     }
//   }
//
//   emit(eventName, data) {
//     if (this.events[eventName]) {
//       this.events[eventName].forEach(function(fn) {
//         fn(data);
//       });
//     }
//   }
// }
