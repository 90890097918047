import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { WalmartGAEvents } from '@kitch/data-access/constants';
import { ProductStatus, WalmartProduct } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';

const productOutOfStockImgUrl = 'assets/ui/images/svg/shopping-cart-no-product.svg';

@Component({
  selector: 'app-shopping-cart-product',
  templateUrl: './shopping-cart-product.component.html',
  styleUrls: ['./shopping-cart-product.component.scss'],
})
export class ShoppingCartProductComponent implements OnInit {
  @Input()
  readonly product: WalmartProduct;

  @Input()
  readonly isShown = false;

  @Output()
  readonly quantityChanged: EventEmitter<void> = new EventEmitter<void>();

  readonly ProductStatus = ProductStatus;

  productImgUrl: SafeUrl;

  constructor(
    private readonly tokenService: TokenService,
    private readonly sanitizer: DomSanitizer,
    private readonly $gaService: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    if (this.isShown) {
      this.productImgUrl =
        this.product?.thumbnailImageUrl
          ? this.sanitizer.sanitize(SecurityContext.URL, this.product.thumbnailImageUrl)
          : productOutOfStockImgUrl;
    }
  }

  changeQuantity(newQuantity: number): void {
    this.$gaService.gtag('event', WalmartGAEvents.changeProductQuantity, {
      profile_id: this.tokenService.getProfileId(),
      prev_quantity: this.product.quantity,
      new_quantity: newQuantity,
      product_id: this.product.id,
      product_title: this.product.title,
    });

    this.product.quantity = newQuantity;
    this.quantityChanged.emit();
  }
}
