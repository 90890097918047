import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DurationVideoService {
  private duration: Subject<number> = new Subject<number>();

  constructor() {
  }

  get durationVideo$(): Observable<number> {
    return this.duration.asObservable();
  }

  updateDurationVideo(duration: number): void {
    this.duration.next(duration);
  }
}
