<div class="search-wrap">
  <ng-container *ngTemplateOutlet="input"></ng-container>
  <img
    *ngIf="!searchValue.length"
    class="search-icon"
    src="assets/ui/images/svg/search.svg"
    alt="Search"
    width="22"
    height="22"
  />
  <ng-container *ngTemplateOutlet="clearButton"></ng-container>
</div>

<div *ngIf="isOpen" class="mobile-layout">
  <div class="search-wrap search-wrap--mobile">
    <img
      class="back-icon"
      (click)="back()"
      src="assets/ui/images/svg/arrow-left.svg"
      alt="Back"
      width="24"
      height="24"
    />
    <ng-container *ngTemplateOutlet="input"></ng-container>
    <ng-container *ngTemplateOutlet="clearButton"></ng-container>
  </div>
  <div class="separator"></div>

</div>



<ng-template #input>
  <input
    #searchInput
    [(ngModel)]="searchValue"
    (keyup)="onSubmit($event)"
    (focus)="onFocus()"
    class="search-input"
    placeholder="Search for streams, chefs, recipes, and more"
  />
</ng-template>

<ng-template #clearButton>
  <img
    *ngIf="searchValue.length"
    (click)="clearInput()"
    class="search-icon search-icon--clear"
    src="assets/ui/images/svg/close-black.svg"
    width="24"
    height="24"
    alt="Clear"
  />
</ng-template>
