import { Directive, HostBinding, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[appWasInvolved]' })
export class WasInvolvedDirective implements OnInit {
  @HostBinding('class.input--is-invalid') invalid = false;

  @HostListener('blur')
  @HostListener('input')
  handleChange(): void {
    setTimeout(() => this.invalid = this.control.touched && this.control.invalid);
  }

  constructor(private control: NgControl) {}

  ngOnInit(): void {
    this.control.statusChanges.subscribe(() => this.handleChange());
  }
}
