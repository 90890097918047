<div
  class="recipe-card__wrap"
  [ngClass]="isShoppable ? 'recipe-card__wrap--white' : 'recipe-card__wrap--blue'"
>
  <!-- IMAGE >> -->
  <div class="recipe-card__img">
    <img
      height="200"
      width="352"
      [ngSrc]="recipe.mealPhoto"
      alt="{{ recipe.recipeName }}"
    />
    <div
      class="recipe-card__publish-status"
      *ngIf="enablePublishStatus"
      [ngClass]="{ 'published': recipe.published }"
      data-testid="publish-status"
    >{{ recipe.published ? 'Published' : 'Unpublished' }}</div>
  </div>
  <!-- << IMAGE -->

  <a
    class="recipe-card__link"
    target="{{ openInNewTab ? '_blank' : '_self' }}"
    [routerLink]="'/' + recipe.profile.chefSlug + '/recipes/' + recipe.slug"
    (click)="onViewClick($event)"
  ></a>

  <div class="recipe-card__content">
    <!-- RECIPE TITLE >> -->
    <span
      class="recipe-card__name"
      data-testid="recipe-name"
    >{{ recipe.recipeName }}</span>
    <!-- << RECIPE TITLE -->

    <!-- EXTERNAL INFO >> -->
    <ng-container *ngIf="recipe.external; else avatarBlock">
      <app-avatar-external-recipe
        [primaryName]="recipe.primaryName"
        [secondaryName]="recipe.secondaryName"
      ></app-avatar-external-recipe>
    </ng-container>
    <!-- << EXTERNAL INFO -->

    <!-- AVATAR BLOCK >> -->
    <ng-template #avatarBlock>
    <div class="recipe-card__author">
      <a *ngIf="!recipe.collaborators?.length" [routerLink]="'/' + recipe.profile.chefSlug">
        <app-avatar
          [url]="recipe.profile?.photo"
          [size]="'extra-small'"
          [name]="recipe.profile?.chefName"
          [title]="recipe.profile?.channel.name"
          [avatarInputTextColor]="'green'"
          [isLive]="recipe.profile?.isLive"
          [isClub]="!!recipe.requiredClubSubscriptionPlan"
        ></app-avatar>
      </a>
      <app-multiple-avatars
        *ngIf="recipe.collaborators?.length"
        [chefs]="recipe.collaborators"
        [backgroundColor]="'green'"
      ></app-multiple-avatars>
    </div>
    </ng-template>
    <!-- << AVATAR BLOCK -->

    <div class="recipe-card__rating">
      <app-rating
        *ngIf="recipe.ratingQuantity"
        [quantity]="recipe.ratingQuantity"
        [rating]="recipe.ratingAverage"
        source="recipe_card"
      ></app-rating>
    </div>

    <div class="recipe-card__buttons">
      <ng-container *ngIf="isEditCard">
        <div class="recipe-card__wrap-inner">
          <a
            class="recipe-card__action-button recipe-card__action-button--edit-recipe link"
            [routerLink]="'/recipes/edit/' + recipe.id"
          >
            <img
              class="recipe-card__button-icon"
              [ngSrc]="'assets/ui/images/svg/edit.svg'"
              width="21"
              height="20"
              alt="iconEdit"
            />
            <span data-testid="edit">edit</span>
          </a>
          <ng-container *ngTemplateOutlet="shareButtons"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!isEditCard">
        <app-walmart-button *ngIf="isShoppable; else favoriteButton"
          class="recipe-card__walmart-button"
          [title]="'Shop ingredients'"
          [walmartRecipeId]="recipe.walmartRecipeId"
          [walmartRecipePortions]="recipe.portions"
          [pageTypeButton]="'MY_STAFF'"
        ></app-walmart-button>

        <ng-template #favoriteButton>
          <div class="recipe-card__favorite">
            <app-button
              appNeedAuthorize
              [source]="'like recipe'"
              [text]="''"
              [iconUrl]="iconUrl"
              [iconWidth]="20"
              [iconHeight]="29"
              [role]="'secondary'"
              (clicked)="onFavoriteButtonClick()"
              [activeClass]="favoriteStatus"
              [buttonClass]="'liked'"
              id="favorite"
            ></app-button>
          </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="shareButtons"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #shareButtons>
  <app-share-buttons
    [title]="recipe.recipeName"
    [description]="recipe.description"
    [image]="recipe.mealPhoto"
    [url]="getRecipeLink()"
    [role]="'secondary'"
  ></app-share-buttons>
</ng-template>
