<div class="wrap">
  <div class="wrap-inner">
    <app-avatar-with-out-photo
      [photo]="user.profile.photo"
      [role]="user.role"
      [fullName]="user.profile?.displayName"
      hasLink="false"
    >
    </app-avatar-with-out-photo>
    <div class="user-name">
      {{ user.profile?.displayName }}
      <ng-container *ngIf="user.role === userRole.USER">({{ user.profile?.fullName }})</ng-container>
    </div>
  </div>

  <label class="checkmark">
    <input
      type="checkbox"
      class="checkmark__input"
      [checked]="user.addedToStream"
      [disabled]="!canBeAdded && !user.addedToStream"
      (change)="onCheckboxClick($event)"
    />
    <span class="checkmark__label"></span>
  </label>
</div>
