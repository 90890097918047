import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DISCOUNTS_URL } from '@kitch/data-access/constants';
import { Discount } from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';

@Injectable()
export class DiscountsService {
  constructor(private apiService: ApiService) {}

  getByCode(code: string): Observable<Discount> {
    return this.apiService.get(makeUri(DISCOUNTS_URL, code));
  }
}
