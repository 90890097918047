export const disableScroll = (window: Window): void => {
  const scrollY = window.scrollY;
  const body = window.document.body;

  body.style.position = 'fixed';
  body.style.top = `-${scrollY}px`;
};

export const enableScroll = (window: Window): void => {
  const body = window.document.body;
  const scrollY = body.style.top;

  body.style.position = '';
  body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
};
