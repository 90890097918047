import { DialogRef } from '@angular/cdk/dialog';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RecipeStep } from '@kitch/data-access/models';
import { HtmlStepParserTool } from '@kitch/util';

@Component({
  selector: 'app-add-preparation-steps-modal',
  templateUrl: './add-preparation-steps-modal.component.html',
  styleUrls: ['./add-preparation-steps-modal.component.scss', '../modals.scss'],
})
export class AddPreparationStepsModalComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private dialogRef: DialogRef<RecipeStep[]>,
    private formBuilder: UntypedFormBuilder,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  close(steps: RecipeStep[] = null): void {
    this.dialogRef.close(steps);
  }

  addSteps(): void {
    const htmlStepParserTool = new HtmlStepParserTool(this.document);
    const steps = htmlStepParserTool.parse(this.form.get('steps').value);

    this.close(steps);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      steps: new FormControl<string>('', Validators.required),
    });
  }
}
