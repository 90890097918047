import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { LiveReplayTabsService } from '@kitch/user/core/live-replay-tabs.service';
import { TabId, TabsMenu } from '@kitch/user/shared/models';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Output() switchedTab: EventEmitter<void> = new EventEmitter<void>();

  tabsMenu: TabsMenu;
  readonly tabId = TabId;

  constructor(
    private liveReplayTabsService: LiveReplayTabsService,
  ) { }

  ngOnInit() {
    // copy whole object by link
    this.tabsMenu = this.liveReplayTabsService.tabsMenu;
  }

  switchTab(tabIndex: number): void {
    if (this.liveReplayTabsService.tabsMenu.active !== tabIndex) {
      this.liveReplayTabsService.tabsMenu.active = tabIndex;
      this.switchedTab.emit();
    }
  }
}
