<div class="social">
  <div class="social__google-container">
    <app-button
      class="social__btn"
      [text]="actionText +' with Google'"
      [type]="'button'"
      [role]="'secondary'"
      [iconHeight]="30"
      [iconWidth]="29"
      [iconUrl]="'assets/ui/images/svg/google-icon.svg'"
    ></app-button>
    <div #googleBtn class="social__google-btn"></div>
  </div>


  <app-button
    class="social__btn social__btn--fb"
    [text]="actionText + ' with Facebook'"
    [type]="'button'"
    [role]="'secondary'"
    [iconHeight]="27"
    [iconWidth]="27"
    [iconUrl]="'assets/ui/images/svg/facebook-blue-icon.svg'"
    (clicked)="loginWithMeta(Socials.META)"
  ></app-button>
</div>
