<app-shopping-cart
  data-testid="shopping-cart"
  [isShown]="isShoppingCardOpened"
  (closed)="closeShoppingCart()"
></app-shopping-cart>

<app-button
  data-testid="button"
  [text]="''"
  [type]="'button'"
  [role]="'shopping-cart'"
  [iconUrl]="'assets/ui/images/svg/cart-marketplace.svg'"
  [buttonClass]="'shopping-cart'"
  (clicked)="openShoppingCart()"
></app-button>
