import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { USERS_URL } from '@kitch/data-access/constants';
import { SimpleResponse, User, UserAccount } from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';

import { makeUri } from '@kitch/util';

@Injectable()
export class UserService {
  constructor(private apiService: ApiService) {}

  getUserById(userId: string): Observable<UserAccount> {
    return this.apiService.get(makeUri(USERS_URL, userId));
  }

  updateUserById(userId: string, body: User): Observable<UserAccount> {
    return this.apiService.patch(makeUri(USERS_URL, userId), body);
  }

  deleteUserById(userId: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(USERS_URL, userId));
  }
}
