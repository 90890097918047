<div class="wrap">
  <div class="wrap-inner">
    <picture class="meal-photo">
      <source srcset="{{ stream.thumbnailUrl }}"/>
      <img
        class="meal-photo"
        src="assets/ui/images/png/no-image.png"
        alt="meal"
      />
    </picture>
    <div class="stream-name" data-testid="stream-name">{{ stream.title }}</div>
  </div>

  <input
    type="radio"
    class="checkmark__input"
    [id]="stream.id"
    [checked]="stream.addedToRecipe"
    [disabled]="isDisabled"
    (change)="onStreamSelect($event)"
    data-testid="radio"
  />
  <label class="checkmark" [for]="stream.id">&nbsp;</label>
</div>
