import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { environment } from '@kitch/data-access/env/environment';
import { HttpMethod } from '@kitch/data-access/models';
import { AuthService } from '@kitch/data-access/services/auth.service';
import { TokenService } from '@kitch/data-access/services/token.service';
import { makeUri } from '@kitch/util';

@Injectable()
export class FetchApiService {
  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
  ) {}

  makeRequest<T>(method: HttpMethod, uri: string, body = {}): Observable<T> {
    const request = () => fromFetch(makeUri(environment.apiUrl, uri), {
      keepalive: true, // guaranty request will be sent
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.tokenService.getToken()}`,
      },
      body: JSON.stringify(body),
    });

    return request().pipe(
      switchMap(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401) {
            return this.authService.refreshToken().pipe(concatMap(request));
          }

          return of({ error: true, message: `Error ${response.status}` });
        }
      }),
      catchError(err => {
        return of({ error: true, message: err.message });
      }),
    );
  }
}
