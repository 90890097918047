<div class="loader-wrap">
  <header #header class="header">
    <div>
      <h1 class="title">{{ formLabels.title }}</h1>
      <p class="description">{{ formLabels.description }}</p>
    </div>
    <div class="buttons--mobile">
      <ng-container *ngTemplateOutlet="controlButtons"></ng-container>
    </div>
  </header>
  <section
    class="wrap"
    [ngClass]="{ 'entity-form-hidden': isLoading || isSaving }"
  >

    <div class="entity-form__separator entity-form__separator-top"></div>

    <div class="stream-info entity-form">
      <h2 class="stream-info__title">{{ formLabels.formTitle }}</h2>

      <form [formGroup]="mainInfoForm">
        <div class="stream-info__content stream-info__content--main">
          <ul class="form-items">

            <!-- SELECT CHEF >> -->
            <li *ngIf="isCreateStream && isAdminForm" class="form-item">
              <label class="form-label">Chefs*</label>
              <div class="form-input-wrap form-select-wrap">
                <div class="select-wrap">
                  <select
                    appWasInvolved
                    name="chefs"
                    class="select-wrap__select"
                    formControlName="chefs"
                    (change)="onChefChange($event)"
                  >
                    <option [value]="null" selected disabled>Choose a chef</option>
                    <option *ngFor="let chef of chefs" [value]="chef.id">
                      {{ chef.profile.chefName }}
                    </option>
                  </select>
                </div>
                <app-errors-list
                  class="errors"
                  errorName="stream type"
                  inputType="SELECT"
                  formControlName="chefs"
                ></app-errors-list>
              </div>
            </li>
            <!-- << SELECT CHEF -->

            <!-- TITLE >> -->
            <li class="form-item">
              <label class="form-label" for="title">{{ formLabels.streamTitle }}*</label>
              <div class="form-input-wrap form-input-wrap--auto-width">
                <textarea
                  formControlName="title"
                  appWasInvolved
                  class="form-input form-input--textarea"
                  id="title"
                  placeholder="Enter a title"
                  cols="100"
                  maxlength="{{ maxTitleLength + 1 }}"
                  (input)="titleMarkAsTouched()"
                ></textarea>
                <div class="info-title-value">
                  <app-errors-list
                    class="errors"
                    formControlName="title"
                    errorName="title"
                    inputType="TEXT"
                    maxLengthError="WITHOUT_NUMBERS">
                  </app-errors-list>
                  <span class="value-count">
                    {{ mainInfoForm.get('title').value?.length || 0 }}/{{ maxTitleLength }}
                  </span>
                </div>
                <div
                  *ngIf="isCreateStream && !mainInfoForm.get('title').hasError('forbiddenSlug') && streamSlug"
                  class="info-title-value"
                >Stream url name will be: {{ streamSlug }}</div>
              </div>
            </li>
            <!-- << TITLE -->

            <!-- ADMIN CHECKBOX >> -->
              <app-admin-checkbox-form
                [ngClass]="{ 'entity-form-hidden': !isAdminForm }"
                #adminCheckboxForm
              ></app-admin-checkbox-form>
            <!-- << ADMIN CHECKBOX -->

            <!-- DESCRIPTION >> -->
            <li class="form-item">
              <label class="form-label" for="description">{{ formLabels.streamDescription }}*</label>
              <div class="form-input-wrap form-input-wrap-editor">
                <app-wysiwyg-editor
                  [errorMessage]="isDescription"
                  [isRequired]="true"
                  [extraToolBarElements]="['link']"
                  formControlName="description"
                  errorName="description"
                  id="description"
                ></app-wysiwyg-editor>
              </div>
            </li>
            <!-- << DESCRIPTION -->

            <!-- VIDEO >> -->
            <li *ngIf="(isUploadVideo || isPastStream) && mainInfoForm.get('videoUrl')" class="form-item">
              <label class="form-label">video file*</label>

              <div class="form-input-wrap stream-info__content--thumbnail">
                <ng-container *ngIf="isAdminForm && streamUrl">
                  <h4 class="form-item__heading">Link to full video</h4>
                  <a class="form-item__link" href="{{ streamUrl }}">Full video</a>
                </ng-container>

                <form [formGroup]="mainInfoForm">
                  <app-video-chooser
                    #videoChooser
                    [uploadStatus]="uploadStatus"
                    (deleteVideo)="deleteVideo($event)"
                    formControlName="videoUrl"
                  ></app-video-chooser>
                  <app-errors-list
                    class="errors"
                    formControlName="videoUrl"
                    errorName="'video file'"
                    inputType="FILE"
                  ></app-errors-list>
                </form>

                <div
                  *ngIf="(mainCameraVideoUrl || secondCameraVideoUrl) && isAdminForm"
                  class="stream-info__additional-video"
                >
                  <h4 class="form-item__heading">Links to additional videos</h4>

                  <ng-container *ngIf="mainCameraVideoUrl">
                    <a
                      class="form-item__link"
                      href="{{ mainCameraVideoUrl }}"
                    >Separate video from the main camera</a>
                    <video
                      id="video-player-main"
                      class="form-item__video-chooser"
                      crossorigin="anonymous"
                      controls
                      [src]="mainCameraVideoUrl"
                    ></video>
                  </ng-container>

                  <ng-container *ngIf="secondCameraVideoUrl">
                    <a
                      class="form-item__link"
                      href="{{ secondCameraVideoUrl }}"
                    >Picture in picture video</a>
                    <video
                      id="video-player-pip"
                      class="form-item__video-chooser"
                      crossorigin="anonymous"
                      controls
                      [src]="secondCameraVideoUrl"
                    ></video>
                  </ng-container>
                </div>
              </div>
            </li>
            <!-- << VIDEO -->

            <!-- THUMBNAIL >> -->
            <li class="form-item">
              <label class="form-label">{{ formLabels.streamThumbnail }}*</label>

              <div class="form-input-wrap stream-info__content--thumbnail">
                <form [formGroup]="mainInfoForm">
                  <app-image-chooser
                    #imageChooser
                    [imageAlt]="formLabels.streamThumbnail"
                    [hasDeleteBtn]="false"
                    (deleteImage)="deleteImage($event)"
                    [imageType]="'stream'"
                    formControlName="thumbnailUrl"
                    isAdmin="true"
                    [showImageCreator]="true"
                    [dishName]="mainInfoForm.get('title').value"
                  ></app-image-chooser>
                  <app-errors-list
                    class="errors"
                    formControlName="thumbnailUrl"
                    [errorName]="formLabels.streamThumbnail"
                    inputType="FILE"
                  ></app-errors-list>
                </form>
              </div>
            </li>
            <!-- << THUMBNAIL -->

            <!-- DATE & TIME >> -->
            <ng-container *ngIf="isScheduledStream">
              <li class="form-item">
                <label class="form-label" for="schedule-picker">Date and time*</label>
                <div class="form-input-dateTimePicker">
                  <!--DATE >>-->
                  <div class="form-input-wrap form-datepicker-wrap">
                    <input
                      #datePickerInput
                      id="schedule-picker"
                      class="form-input schedule-picker"
                      formControlName="date"
                      [placeholder]="'Select Date'"
                      [owlDateTimeFilter]="isPossibleToPickDate"
                      [owlDateTimeTrigger]="dt"
                      [owlDateTime]="dt"
                      (dateTimeChange)="setDateFormat($event.value)"
                      (click)="setCurrentDateIfEmpty()"
                    />
                    <owl-date-time #dt [pickerType]="'calendar'"></owl-date-time>
                    <app-errors-list
                      class="errors"
                      errorName="date"
                      formControlName="date"
                      inputType="TEXT"
                    ></app-errors-list>
                  </div>
                  <!--<< DATE-->
                  <!-- TIME >>-->
                  <div class="form-input-wrap form-select-wrap">
                    <div class="select-wrap">
                      <select appWasInvolved name="time" class="select-wrap__select" formControlName="time">
                        <option [ngValue]="null" selected hidden>Select Time</option>
                        <option
                          *ngFor="let time of times"
                          [ngValue]="time.hour + ':' + time.minute + ' ' + time.format"
                          [disabled]="time.disabled"
                        >
                          {{ time.name }}
                        </option>
                      </select>
                    </div>
                    <app-errors-list
                      class="errors"
                      errorName="time"
                      formControlName="time"
                      inputType="TEXT"
                    ></app-errors-list>
                  </div>
                  <!--<< TIME -->
                </div>
              </li>
            </ng-container>
            <!-- << DATE & TIME -->

            <!-- ACCESS LEVEL >> -->
            <!--hidden section for ticket KB-1212-->
            <li *ngIf="false && !isUploadVideo" class="form-item">
              <label class="form-label">2-way video access*</label>
              <ul class="form-radio-items">
                <li *ngFor="let level of accessLevels" class="form-radio-item">
                  <input
                    type="radio"
                    id="{{ level.key }}"
                    name="accessLevel"
                    formControlName="accessLevel"
                    [value]="level.key"
                  />
                  <label for="{{ level.key }}">{{ level.value }}</label>
                  <div class="form-radio-hint">{{ level.extra }}</div>
                </li>
              </ul>
            </li>
            <!-- << ACCESS LEVEL -->

            <!-- STREAM CATEGORIES >> -->
            <li class="form-item">
              <label class="form-label">Stream Category*</label>
              <div class="form-input-wrap form-select-wrap">
                <div class="select-wrap">
                  <select
                    appWasInvolved
                    name="videoCuisines"
                    class="select-wrap__select"
                    formControlName="streamCuisines"
                  >
                    <option [ngValue]="null" selected hidden>Choose a stream category</option>
                    <option *ngFor="let cuisine of cuisines.stream" [ngValue]="cuisine.id">
                      {{ cuisine.name }}
                    </option>
                  </select>
                </div>
                <app-errors-list
                  class="errors"
                  errorName="stream type"
                  inputType="SELECT"
                  formControlName="streamCuisines"
                ></app-errors-list>
              </div>
            </li>
            <!-- << STREAM CATEGORIES -->

            <div class="entity-form__separator"></div>

            <!-- RECIPES >> -->
            <div class="stream-info entity-form">
              <div class="entity-form__slide-toggle-container">
                <app-slide-toggle
                  class="entity-form__slide-toggle"
                  [isSelected]="isSelectedRecipes"
                  (statusSlideInput)="setStatusRecipes($event)"
                ></app-slide-toggle>
                <h3 class="entity-form__title">Add recipes</h3>
              </div>

              <p class="entity-form__subtitle">
                Choose up to 3 recipes that will appear on your stream page.
              </p>

              <div [ngClass]="{ 'entity-form-hidden': !isSelectedRecipes }">
                <div class="search-input-wrap recipes-search-input-wrap">
                  <input #recipesSearch class="search-input recipes-search-input" placeholder="Search Recipes" />
                </div>

                <div class="stream-info__content stream-info__content--recipes">
                  <cdk-virtual-scroll-viewport itemSize="30" appendOnly>
                    <app-recipe-list-item
                      *cdkVirtualFor="let recipe of allRecipes; templateCacheSize: 0"
                      [recipe]="recipe"
                      [isDisabled]="isMaxRecipesAttachedToStream && !recipe.addedToStream"
                      (changeStatus)="changeRecipesList($event)"
                    ></app-recipe-list-item>
                  </cdk-virtual-scroll-viewport>
                </div>
              </div>
            </div>
            <!-- << RECIPES -->

            <div class="entity-form__separator"></div>

            <h3 class="entity-form__title entity-form__title--margin-bottom">Add tags</h3>

            <!-- STREAM CUISINES >> -->
            <div *ngIf="isCreateStream" class="default-categories-notification">
              We’ve copied your categories from your channel page, but feel free to change them.
            </div>
            <!-- PRIMARY CUISINES >> -->
            <li class="form-item">
              <label class="form-label">Cuisines*</label>
              <div class="form-input-wrap form-select-wrap">
                <div class="form-select-wrap__container">
                  <app-select-dropdown
                    [items]="cuisines.primary"
                    placeholder="e.g. Indian, American, Italian (up to 3)"
                    name="primaryCuisines"
                    formControlName="primaryCuisines"
                    [selectedItems]="pimarySelectedCuisines"
                    (selectedOptions)="setSelectedCuisines($event)"
                  ></app-select-dropdown>
                </div>
                <div *ngIf="mainInfoForm.get('primaryCuisines').value?.length === 3" class="form-select-wrap__label">
                  Max number of 3 reached
                </div>
                <app-errors-list
                  class="errors"
                  errorName="cuisines"
                  inputType="MULTISELECT"
                  formControlName="primaryCuisines"
                ></app-errors-list>
              </div>
            </li>
            <!-- << PRIMARY CUISINES -->

            <!-- SUB CUISINES >> -->
            <li class="form-item">
              <label class="form-label">Sub-cuisine</label>
              <div class="form-input-wrap form-select-wrap">
                <div
                  class="form-select-wrap__container"
                  [ngClass]="{ 'form-select-wrap__container--disabled': !availableSubCuisines.length }"
                >
                  <app-select-dropdown
                    [items]="availableSubCuisines"
                    placeholder="e.g. Jewish, British, Mexican-American (up to 3)"
                    name="subCuisines"
                    formControlName="subCuisines"
                    [selectedItems]="selectedSubCuisines"
                    (selectedOptions)="setSelectedSubCuisine($event)"
                    [isDisabled]="!availableSubCuisines.length"
                  ></app-select-dropdown>
                </div>
                <div
                  *ngIf="mainInfoForm.get('subCuisines')?.value?.length + newSubCuisines?.length === 3"
                  class="form-select-wrap__label"
                >
                  Max number of 3 reached
                </div>
                <div
                  *ngIf="mainInfoForm.get('subCuisines')?.value?.length < 3"
                  class="form-input-hint form-input-hint--select"
                >
                  Optional
                </div>
              </div>
            </li>
            <!-- << SUB CUISINES -->
            <!-- << STREAM CUISINES -->

            <!-- TAGS >> -->
            <li>
              <app-tags
                #tagsSection
                [tags]="tags"
                [tagTypes]="specialtyTagTypes"
                [selectedTagsIds]="selectedSpecialtyTagsIds"
              ></app-tags>
            </li>
            <!-- << TAGS -->


            <!--hidden section for ticket KB-1212-->
            <ng-container *ngIf="false && isScheduledStream">
              <!-- NOTIFY FOLLOWERS >> -->
              <li class="form-item">
                <label class="form-label">Notify followers*</label>
                <ul class="form-radio-items">
                  <li *ngFor="let notify of notifyFollowers" class="form-radio-item">
                    <input
                      type="radio"
                      id="notifications-{{ notify.key }}"
                      name="notifyFollowers"
                      formControlName="notifyFollowers"
                      [value]="notify.key"
                    />
                    <label for="notifications-{{ notify.key }}">{{ notify.value }}</label>
                    <div class="form-radio-hint">{{ notify.extra }}</div>
                  </li>
                </ul>
              </li>
              <!-- << NOTIFY FOLLOWERS -->
            </ng-container>
          </ul>
        </div>
      </form>
    </div>

    <!-- COLLABORATORS >> -->
    <div
      [ngClass]="{ 'entity-form-hidden': !(isStreamOwner && !isAdminForm || isAdminForm && !isUploadVideo) }"
    >
      <div class="entity-form__separator"></div>

      <div class="stream-info entity-form">
        <div class="entity-form__slide-toggle-container">
          <app-slide-toggle
            class="entity-form__slide-toggle"
            [isSelected]="isSelectedCollaborators"
            (statusSlideInput)="setStatusCollaborators($event)"
          ></app-slide-toggle>
          <h3 class="entity-form__title">Add Collaborators</h3>
        </div>

        <p class="entity-form__subtitle">
          Their name and profile picture will appear on the stream thumbnail and livestream page. You may add up to 2 collaborators.
        </p>

        <div [ngClass]="{ 'entity-form-hidden': !isSelectedCollaborators }">
          <div class="search-input-wrap guests-search-input-wrap">
            <input
              #collaboratorsSearch
              class="search-input guests-search-input"
              placeholder="Add up to three collaborators"
            />
          </div>

          <div class="stream-info__content stream-info__content--guests">
            <cdk-virtual-scroll-viewport itemSize="30" appendOnly>
              <app-user-list-item
                *cdkVirtualFor="let chef of collaborators"
                [user]="chef"
                [canBeAdded]="isDisabledCollab"
                (changeStatus)="changeCollaboratorsList($event)"
              ></app-user-list-item>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    </div>
    <!-- << COLLABORATORS -->

    <!-- VIDEO TIMESTAMP >> -->
    <div [ngClass]="{ 'entity-form-hidden': !isUploadVideo && !isPastStream }">
      <div class="entity-form__separator"></div>

      <app-video-timestamp-form #videoTimestampForm></app-video-timestamp-form>
    </div>
    <!-- << VIDEO TIMESTAMP -->

    <div class="entity-form__separator"></div>

    <!-- CUSTOM BUTTONS >> -->
    <app-custom-button-form
      #customButtonForm
      [isCreateStream]="isCreateStream"
    ></app-custom-button-form>
    <!-- << CUSTOM BUTTONS -->

    <div class="entity-form__separator"></div>

    <!-- ADD PRODUCTS BLOCK >> -->
    <app-product-form
      #productForm
      [isShowSlideToggle]="true"
    ></app-product-form>
    <!-- << ADD PRODUCTS BLOCK -->

    <div class="entity-form__separator"></div>

    <!-- PAID STREAM BLOCK >> -->
    <div class="stream-info entity-form">
      <div class="entity-form__slide-toggle-container">
        <app-slide-toggle
          class="entity-form__slide-toggle"
          [isSelected]="isStreamPaid"
          (statusSlideInput)="setPaidStatus($event)"
        ></app-slide-toggle>
        <h3 class="entity-form__title">Paid <span>{{ isShow ? 'show' : 'stream'}}</span></h3>
      </div>

      <p *ngIf="isStreamPaid" class="entity-form__subtitle">
        Users must pay (in either clams or $$) to view your
        <span>{{ isShow ? 'show.' : 'stream. You set the price.'}}</span> 1 clam = $1.
        Please note a separate sales tax may be automatically added to the price you set.
      </p>

      <div class="stream-info__content" *ngIf="isStreamPaid">
        <form [formGroup]="mainInfoForm" class="form">
          <ul class="form-items">
            <li *ngIf="!isPastNotPaidStream" class="form-item">
              <label for="streamPrice" class="form-label">Base price</label>
              <div class="form-input-wrap form-input-wrap--auto-width">
                <input
                  type="number"
                  class="form-input form-input--price"
                  id="streamPrice"
                  formControlName="streamPrice"
                  placeholder="Enter amount"
                />
                <img
                  class="form-input-calm"
                  src="assets/ui/images/svg/dollar-large.svg"
                  width="24"
                  height="25"
                  alt="Dollars"
                />
                <app-errors-list
                  formControlName="streamPrice"
                  class="errors"
                  errorName="amount"
                ></app-errors-list>
              </div>
            </li>
            <li class="form-item" *ngIf="!isShow">
              <label for="replayOption" class="form-label">Replay options</label>
              <div class="form-input-wrap form-select-wrap">
                <div class="select-wrap replay-option-wrap">
                  <select
                    appWasInvolved
                    name="replayOption"
                    class="select-wrap__select"
                    formControlName="replayOption"
                    id="replayOption"
                  >
                    <option
                      *ngFor="let option of replayOptions"
                      [disabled]="option.disabled"
                      [ngValue]="option.type"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <span
                  class="replay-option-description"
                  *ngFor="let option of replayOptions">
                 {{ option.type ===  mainInfoForm.get('replayOption').value ? option.description : '' }}
                </span>
              </div>
            </li>
            <li *ngIf="isShowReplayOptionInput" class="form-item">
              <label for="replayPrice" class="form-label">Replay Price</label>
              <div class="form-input-wrap form-input-wrap--auto-width">
                <input
                  type="number"
                  class="form-input form-input--price"
                  id="replayPrice"
                  formControlName="replayPrice"
                  placeholder="Enter amount"
                >
                <img
                  class="form-input-calm"
                  src="assets/ui/images/svg/dollar-large.svg"
                  alt="Clams"
                  width="24"
                  height="25"
                />
                <app-errors-list
                  formControlName="replayPrice"
                  class="errors"
                  errorName="replay price"
                ></app-errors-list>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
    <!-- << PAID STREAM BLOCK -->

    <div class="entity-form__separator"></div>

    <!-- << EMAIL COMMUNICATIONS -->
    <div *ngIf="isStreamPaid" class="stream-info email-block entity-form">
      <div class="entity-form__slide-toggle-container">
        <app-slide-toggle
          class="entity-form__slide-toggle"
          [isSelected]="isEmailNotifications"
          [isClickable]="!isPastStream"
          (statusSlideInput)="setEmailStatus($event)"
        ></app-slide-toggle>
        <h3 class="entity-form__title">
          {{ isUploadVideo ? 'Show' : 'Live stream'}} Email Communications
        </h3>
      </div>

      <p class="entity-form__subtitle">
        A purchase confirmation email with your stream link will automatically be sent to users,
        but you can send optional emails to users with more information about your {{ isUploadVideo ? 'show' : 'stream'}}.
        Check out the template
        <a
          class="link-google-drive"
          href="https://drive.google.com/drive/folders/1HF_l83arTKEgBnvWhLaclYESXZzMk_1P?usp=sharing"
          rel=noopener
          target="_blank"
        >here</a>,
        before you add your text.
      </p>

      <div *ngIf="isEmailNotifications" class="stream-info__content">
        <!-- << PRE-EMAIL COMUNICATIOS -->
        <div class="pre-post-email-wrapper">
          <ng-container *ngIf="!isUploadVideo">
            <div class="slide__switch-container email-switch-container">
              <span class="stream-info__title slide__title">
                Pre-stream email
              </span>
              <app-slide-toggle
                class="email-toggle"
                [size]="'small'"
                [isClickable]="!isPastStream"
                [isSelected]="isPreEmailNotifications"
                (statusSlideInput)="setPreEmailStatus($event)"
              ></app-slide-toggle>
            </div>
            <div *ngIf="isPreEmailNotifications">
              <app-email-text-inputs
                [isRequired]="isPreEmailNotifications"
                formControlNameFirst="preBlockFirst"
                formControlNameSecond="preBlockSecond"
                [isSubmiting]="isSubmiting"
                [maxLength]="maxEmailNotificationsLength"
                [values]="preBlocksText"
                [firstInputErrorName]="'first pre-email input'"
                [isEditable]="!isPastStream"
                (isSubmitingForm)="setStatusSubmiting($event)"
                (valueFirstInput)="setValuePreBlockFirst($event)"
                (valueSecondInput)="setValuePreBlockSecond($event)"
              ></app-email-text-inputs>
            </div>
          </ng-container>
          <!-- << PRE-EMAIL COMUNICATIOS -->

          <!-- << POST-EMAIL COMUNICATIOS -->
          <div class="pre-post-email-wrapper">
            <div *ngIf="!isUploadVideo" class="slide__switch-container email-switch-container">
              <span class="stream-info__title slide__title">
                Post-stream email
              </span>
              <app-slide-toggle
                class="email-toggle"
                [size]="'small'"
                [isClickable]="!isPastStream"
                [isSelected]="isPostEmailNotifications"
                (statusSlideInput)="setPostEmailStatus($event)"
              ></app-slide-toggle>
            </div>
            <div *ngIf="isPostEmailNotifications || isUploadVideo">
              <app-email-text-inputs
                [isRequired]="isPostEmailNotifications || (isUploadVideo && isEmailNotifications)"
                formControlNameFirst="postBlockFirst"
                formControlNameSecond="postBlockSecond"
                [isSubmiting]="isSubmiting"
                [maxLength]="maxEmailNotificationsLength"
                [values]="postBlocksText"
                [isEditable]="!isPastStream"
                [firstInputErrorName]="'first post-email input'"
                (isSubmitingForm)="setStatusSubmiting($event)"
                (valueFirstInput)="setValuePostBlockFirst($event)"
                (valueSecondInput)="setValuePostBlockSecond($event)"
              ></app-email-text-inputs>
            </div>
          </div>
          <!-- << POST-EMAIL COMUNICATIOS -->
        </div>
      </div>
    </div>

    <!-- << REPLAY COMMUNICATIONS -->
    <div
      *ngIf="isShowReplayOptionInput && isStreamPaid"
      class="stream-info email-block entity-form"
    >
      <h2 class="stream-info__title">
        <div class="slide__switch-container">
          <app-slide-toggle
            [isSelected]="isReplayPrice"
            (statusSlideInput)="setReplayPriceStatus($event)"
          >
          </app-slide-toggle>
          <span class="stream-info__title slide__title">
            Replay Email Communications
          </span>
        </div>
      </h2>
      <p class="stream-info__description">
        A purchase confirmation email with your stream link will automatically be sent to users, but you can send an optional email to users with more information about the replay.
        Check out the template
        <a
          class="link-google-drive"
          href="https://drive.google.com/drive/folders/1HF_l83arTKEgBnvWhLaclYESXZzMk_1P?usp=sharing"
          rel=noopener
          target="_blank"
        >here</a>,
        before you add your text.
      </p>
      <div *ngIf="isReplayPrice" class="stream-info__content">
        <app-email-text-inputs
          [isRequired]="isReplayPrice"
          formControlNameFirst="firstReplayEmail"
          formControlNameSecond="secondReplayEmail"
          [isSubmiting]="isSubmiting"
          [values]="replayBlocksText"
          [maxLength]="maxEmailNotificationsLength"
          [firstInputErrorName]="'first replay email communications input'"
          (isSubmitingForm)="setStatusSubmiting($event)"
          (valueFirstInput)="setFirstValueReplayCommunication($event)"
          (valueSecondInput)="setSecondValueReplayCommunication($event)"
        ></app-email-text-inputs>
      </div>
    </div>
    <!-- << REPLAY AND EMAIL COMMUNICATIONS -->

    <div *ngIf="isStreamPaid" class="entity-form__separator"></div>

    <!-- << TOGGLE-SLIDE-SPONSOR -->
    <div class="entity-form">
      <div class="entity-form__slide-toggle-container">
        <app-slide-toggle
          class="entity-form__slide-toggle"
          [isSelected]="isSelectedSponsor"
          (statusSlideInput)="setStatusSponsored($event)"
        ></app-slide-toggle>
        <h3 class="entity-form__title">sponsored</h3>
      </div>

      <p class="entity-form__subtitle">Indicates whether your stream is sponsored by a Kittch brand partner or not.</p>
    </div>
    <!-- << TOGGLE-SLIDE-SPONSOR -->

    <div class="entity-form__separator"></div>

    <!-- PRIVATE BLOCK >> -->
    <div class="stream-info guests-section entity-form">
      <div class="entity-form__slide-toggle-container">
        <app-slide-toggle
          class="entity-form__slide-toggle"
          [isSelected]="isStreamPrivate"
          (statusSlideInput)="setPrivateStatus($event)"
        ></app-slide-toggle>
        <h3 class="entity-form__title">make it a private stream</h3>
      </div>

      <!-- GUESTS >> -->
      <form
        *ngIf="isStreamPrivate"
        class="guests-section__form"
        [formGroup]="mainInfoForm"
      >
        <input
          class="guests-section__hide-input"
          type="radio"
          id="guests"
          value="guests"
          name="privateOption"
          formControlName="privateOption"
        />
        <label for="guests" class="guests-section__hide-label">Add guests</label>
        <div class="stream-info__description">
          Your stream will only be visible and accessible to guests you select.
        </div>

        <div class="form-select-wrap__container private-users">
          <app-select-dropdown-with-avatar
            [items]="allUsers"
            placeholder="Search People"
            maxSelectedItems="1000"
            [selectedItems]="streamGuestIds"
            (selectedOptions)="setSelectedPrivateUsers($event)"
            (searchItems)="setSearchTerm($event)"
            (changePage)="setPagePrivateUsers($event)"
          ></app-select-dropdown-with-avatar>
        </div>
        <!-- << GUESTS -->
        <p class="guests-section__or-block">
          or
        </p>
        <!-- HIDE BLOCK >> -->
        <div class="guests-section__hide-block">
          <div>
            <input
              class="guests-section__hide-input"
              type="radio"
              id="hidden"
              value="hidden"
              name="privateOption"
              formControlName="privateOption"
            />
            <label for="hidden" class="guests-section__hide-label">Hide Stream</label>
          </div>
          <p class="guests-section__hide-text">
            No user will see the thumbnail anywhere on&nbsp;Kittch.
            They must have the URL to access the stream.
          </p>
        </div>
        <!-- HIDE BLOCK >> -->
      </form>
    </div>
    <!-- PRIVATE BLOCK >> -->
  </section>

  <div class="buttons--desktop">
    <ng-container *ngTemplateOutlet="controlButtons"></ng-container>
    <div *ngIf="errorMessages.length > 0" class="error-messages">
      <div class="errors-title">Errors found</div>
      <ul>
        <li
          *ngFor="let message of errorMessages"
          class="error-item"
        >{{message}}</li>
      </ul>
    </div>
  </div>

  <div *ngIf="isLoading || isSaving" class="loader-inner">
    <div class="loader">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

<ng-template #controlButtons>
  <app-button
    [text]="formLabels.saveButton"
    [type]="'button'"
    (clicked)="saveMainStreamInfo()"
  ></app-button>
  <app-button
    [text]="'cancel'"
    [role]="'secondary'"
    [type]="'button'"
    (clicked)="cancelStream()"
  ></app-button>
</ng-template>
