import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortName' })
export class ShortNamePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.match(/\b(\w)/g).join('').toUpperCase();
    } else {
      return '';
    }
  }
}
