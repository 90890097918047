import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_MAX_WORDS_AMOUNT = 4;

@Pipe({
  name: 'limitWords',
})
export class LimitWordsPipe implements PipeTransform {
  transform(string: string, limit = DEFAULT_MAX_WORDS_AMOUNT): string {
    const words = string.split(' ');

    return words.length > limit
      ? `${words.slice(0, limit).join(' ')}...`
      : string;
  }
}
