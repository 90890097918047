import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TokenService } from '@kitch/data-access/services';
import { DeviceBreakpointTool } from '@kitch/util';

@UntilDestroy()
@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements AfterViewInit, OnChanges {
  @Input() rating: number;
  @Input() selectedRating: number;
  @Input() quantity: number;
  @Input() editable = false;
  @Input() source: 'recipe_card';
  @Input() openWebId: string;

  @Output() selectRating: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('ratingModal') private readonly ratingModal: TemplateRef<ElementRef<HTMLDivElement>>;

  fullRatingIconCount: number;
  showHalfRatingIcon: boolean;
  showingRatingModal = false;
  hoverRating = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private tokenService: TokenService,
  ) {}

  ngAfterViewInit() {
    this.startListeningClickOutsideModal();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rating) {
      this.setRatingIcons(changes.rating.currentValue);
    }
  }

  hoverOnRatingIconContainer(): void {
    if (this.isDesktop) {
      this.showRatingModal();
    }
  }

  clickOnRatingIconContainer(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.showRatingModal();
  }

  showRatingModal(): void {
    if (this.editable && !this.isGuest) {
      this.showingRatingModal = true;
    }
  }

  closeRatingModal(): void {
    this.showingRatingModal = false;
  }

  onIconHover(rating: number): void {
    this.hoverRating = rating;
  }

  onStopHover(): void {
    this.hoverRating = 0;
  }

  select(rating: number): void {
    this.selectRating.emit(rating);
  }

  leaveComment(): void {
    const openWebEl = this.document.querySelector(`[data-open-web-id="${this.openWebId}"]`);

    openWebEl?.scrollIntoView({
      behavior: 'smooth',
    });

    this.closeRatingModal();
  }

  private get isDesktop(): boolean {
    return DeviceBreakpointTool.isDesktop(this.document.body.clientWidth);
  }

  private get isGuest(): boolean {
    return this.tokenService.isGuest();
  }

  private startListeningClickOutsideModal(): void {
    fromEvent(this.document, 'click')
      .pipe(
        filter(() => this.showingRatingModal),
        untilDestroyed(this),
      )
      .subscribe((event: PointerEvent) => {
        if (!(event.target as Element).closest('.rating__modal')) {
          this.closeRatingModal();
        }
      });
  }

  private setRatingIcons(rating: number): void {
    this.fullRatingIconCount = Math.floor(rating);

    const decimalPart = +(rating - this.fullRatingIconCount).toFixed(1);

    this.showHalfRatingIcon = decimalPart >= 0.3 && decimalPart <= 0.7;
    if (decimalPart > 0.7) {
      this.fullRatingIconCount += 1;
    }
  }
}
