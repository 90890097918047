import { isPlatformServer } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Observable } from 'rxjs';

import {
  GUEST_LOGIN_URL,
  INSTAGRAM_LOGIN_URL, LOGOUT_URL,
  REFRESH_TOKEN_URL,
  RESEND_CODE_URL,
  SSO_URL,
  VERIFY_LOGIN_URL,
} from '@kitch/data-access/constants';
import { environment } from '@kitch/data-access/env/environment';
import { TokenService } from '@kitch/data-access/services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public tokenService: TokenService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let request = httpRequest.clone();

    if (this.isEnabledCookies(httpRequest.url)) {
      if (isPlatformServer(this.platformId)) {
        const req = inject(REQUEST);

        request = request.clone({
          setHeaders: {
            Cookie: this.getCookieString(req.cookies),
          },
          withCredentials: true,
        });
      } else {
        request = request.clone({
          withCredentials: true,
        });
      }
    }

    request = request.clone({
      withCredentials: this.isEnabledCookies(httpRequest.url),
    });

    if (
      !request.url.includes(environment.awsUrl) &&
      (this.tokenService.getToken() || this.tokenService.getLoginToken())
    ) {
      const token = request.url.includes(VERIFY_LOGIN_URL) || request.url.includes(RESEND_CODE_URL) ?
        this.tokenService.getLoginToken() :
        this.tokenService.getToken();

      if (token) {
        return next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${token}` } }));
      } else {
        return next.handle(request);
      }
    }

    return next.handle(request);
  }

  private isEnabledCookies(url: string): boolean {
    return url.includes(REFRESH_TOKEN_URL) ||
      url.includes(VERIFY_LOGIN_URL) ||
      url.includes(SSO_URL) ||
      url.includes(INSTAGRAM_LOGIN_URL) ||
      url.includes(GUEST_LOGIN_URL) ||
      url.includes(LOGOUT_URL);
  }

  private getCookieString(cookie): string {
    return Object.keys(cookie).reduce((accumulator, cookieName) => {
      return `${accumulator}${cookieName}=${cookie[cookieName]};`;
    }, '');
  }
}
