import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'timezone' })
export class TimezonePipe implements PipeTransform {
  private datePipe: DatePipe = new DatePipe('en');

  transform(
    value: string | number | Date,
    format = 'MMM d, h:mma',
  ): string {
    return `${this.datePipe.transform(value, format)} ${DateTime.local().offsetNameShort}`;
  }
}
