import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-reload-page-modal',
  templateUrl: './reload-page-modal.component.html',
  styleUrls: ['./reload-page-modal.component.scss'],
})
export class ReloadPageModalComponent implements OnChanges {
  @ViewChild('modalReloadPage', { static: false }) modal: ModalComponent;

  @Input() isOpen = false;
  @Input() message: string;
  @Output() needReloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.modal.open();
    } else {
      this.modal?.close();
    }
  }

  reloadPage(): void {
    this.needReloadPage.emit(true);
  }
}
