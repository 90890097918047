<h1 class="page-title">TERMS OF USE FOR CREATORS</h1>
<ol class="level-1">
  <li>
    <h3 class="section-title">Terms of Use Generally:</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      These Terms of Use for Creators (“Creator Terms”) apply to those users (“Creators”) who wish to upload content to,
      and participate in, the Kittch platform (the “Platform”) and shall incorporate by reference the Terms of Use
      applicable to all who log on to Kittch.com (the “General Terms”), which are posted at
      <a class="link-underline" [routerLink]="'/terms-of-use'" target="_blank">kittch.com/terms-of-use.</a>
      By accepting these Creator Terms, Creator acknowledges that General Terms are also applicable to Creator
      and Creator’s participation on the Platform.
      Any proper terms not otherwise defined herein shall have the meanings otherwise ascribed to them in the Terms.
      In the event of a conflict between the General Terms and these Creator Terms, the Creator Terms shall control.
    </p>
  </li>

  <li>
    <h3 class="section-title">Grant of Rights:</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      By accepting these Creator Terms, Creator grants certain rights to Kittch and agrees to adhere to Kittch’s
      <span class="link-underline" (click)="scrollToProgrammingStandards()">programming standards</span>.
      Specifically, Creator grants to Company the non-exclusive, irrevocable,
      royalty-free, sublicensable, worldwide right and license to store, use, display, modify, exhibit, distribute,
      create derivative works from and provide access to the Creator Content on the Platform to Company’s Members
      and other users.  Creator further grants Company the right to reference the Creator Content in search results,
      to market and promote the Creator Content through the Platform as well as through other available platforms
      and channels, in all media throughout the world during the Term.  For these purposes, Creator grants to Company
      the right to exercise any and all intellectual property rights in and to the Creator Content which are necessary
      to provide the Creator Content to Members and other users of Company’s platforms.  Creator further grants
      to Company the right to use Creator’s name, voice and approved likeness, as well as the right to use
      any trademark or trade name used by Creator in the Creator Content, in the marketing, promotion and publicity
      of the Creator Content, the Platform and Company.  Without limitation of the foregoing, the rights granted
      to Kittch include the right to run advertising or promotions on the Platform, including in connection with
      the Creator Content, such as pre-roll or post-roll spots.
      (See <span class="link-underline" (click)="scrollToSponsorships()">Sponsorships</span> for further information.)
      All rights not granted hereunder shall be retained by Creator.
    </p>
  </li>

  <li>
    <h3 class="section-title">Creator Content:</h3>
    <ul>
      <li class="text-content list-latin">
        Creator shall control all aspects of production of all the Creator Content,
        and shall be responsible for all expenses incurred in connection therewith.
      </li>
      <li class="text-content list-latin">
        The scripts, design, substance, length and look and feel of the Creator Content shall be within
        the sole control of Creator, provided that the Creator Content must conform to Company’s
        <span class="link-underline" (click)="scrollToProgrammingStandards()">Standards</span>.
        Company shall have the right to reject, or remove from the Platform, any Creator Content which in its
        reasonable discretion violates Company’s Standards and Practices or which does not conform to Company’s
        technical requirements, or for any other reason within its sole discretion.

      </li>
      <li class="text-content list-latin">
        Creator may take down from the Platform any item of Creator Content at any time.
      </li>
      <li class="text-content list-latin">
        Creator shall be solely responsible for securing all necessary rights and permissions from all individuals
        featured in the Creator Content, including the right to feature the name, voice and likeness of such third
        parties in the marketing and promotion of the Creator Content, Company, and other content featured on the
        Platform.  In addition, Creator shall be solely responsible for securing all rights permissions needed
        to include any third-party intellectual property (e.g., music, audiovisual materials, trademarks, trade names,
        photographs, artwork, etc.) in the Creator Content.
      </li>
      <li class="text-content list-latin">
        If Creator receives any consideration (in cash or in kind) for the mention or inclusion of any product or
        service in the Creator Content, Creator shall a) inform Company of such arrangement by notice to
        <a href="mailto:creatorsupport@kittch.com" class="link-underline">creatorsupport@kittch.com</a>,
        and b) without limitation of any other provision hereof, comply with all applicable Federal and State laws,
        rules and regulations, including all applicable regulations promulgated by the Federal Trade Commission,
        as well as rules in the EU which may be applicable to sponsored content.
      </li>
    </ul>
  </li>

  <li>
    <h3 class="section-title">Term:</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Creator’s grant of rights and agreements under these Creator Terms with respect to all Creator Content shall
      continue until either Creator or Kittch removes the Creator Content in question.  Thus, Creator Content may
      remain on the Platform and accessible to users unless and until either party determines to take it down.
      In addition, Kittch may modify or terminate these Creator Terms or the General Terms within its discretion,
      upon reasonable advance notice to Creators.
      <br>
      Creators may stop posting Creator Content at any time, and may delete their account from the
      Platform by going to their channel profile and selecting “delete profile.”
      <br>
      Kittch may suspend or terminate your account on the Platform or remove any Creator Content within its sole
      discretion.   Kittch will notify Creator in the event of any such suspension or termination.
    </p>
  </li>
  <li>
    <h3 class="section-title">Creator Compensation:</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Creators may be compensated for their activities on the Platform as follows:
    </p>
    <ul>
      <li class="text-content list-latin">
        Payment by Members to access Creator Content, including for live streams and replays (“Access Fees”).
        Creators set the terms and fees required for Members to access their Creator Content and will publish such
        terms on the Platform and in their marketing and promotion of the Creator Content. Payment may be set in Clams
        or dollars and will be paid to Company (“Clams” are more specifically described in the
        <a class="link-underline" [routerLink]="'/terms-of-use'" target="_blank">Terms of Use</a>).
      </li>
      <li class="text-content list-latin">
        Tips from Members, i.e., discretionary amounts contributed by Members as a manner of showing approval of
        Creator Content (“Tips”). Tips may also be paid in Clams or dollars.
        <br><br>
        Tips and Access Fees are collectively deemed “Principal Revenue Streams.” Upon becoming a Creator and accepting
        these Creator Terms, Company will set up a Clams Account for each Creator.  Once a Member makes a purchase
        (i.e., pays Access Fees or Tips) and such purchase is fulfilled (e.g., the Live Stream occurs or the Creator
        Content is made available to the User), then (i) if such purchase was made in Clams, such Clams are deposited
        directly to Creator’s Clams Account; (ii) if such purchase was made in dollars, then such amount is retained by
        Kittch.  In either case, once the purchase has been fulfilled, the Adjusted Gross Proceeds (as defined below)
        are credited to the Creator.
        <br><br>
        No interest is paid on any accounts or proceeds maintained by Company for Creators, and, to the extent any such
        payment is in dollars, Company shall have the right to, and shall, maintain all such payments in a general
        Kittch bank accounts and shall not be required to set up a separate bank accounts for each Creator or for the
        benefit of any specific Creator; in other words, Creators shall be general creditors of Kittch.
        <br><br>
        Creators may withdraw the Adjusted Gross Proceeds (as hereinafter defined) within their accounts at any time,
        provided that the minimum withdrawal by Creator is $100.00 (lesser balances shall be held until the balance of
        $100.00 or the Clams equivalent thereof is accrued). All such withdrawals are paid on a “net 45” basis, i.e.,
        paid within 45 days of notification thereof. To the extent required by law, Kittch shall withhold amounts due
        in connection with sales or use taxes, and any other taxes or governmental fees or assessments, as applicable.
        <br><br>
        The balance of Creator’s Clams Account is calculated at the exchange rate of one clam to one dollar. Company
        reserves the right to change the exchange rate at any time upon 10 business days prior written notice, provided
        that the new rate shall apply only to Clams earned and deposited to Creator’s account after the effective date
        of the change.
        <br><br>
        As used herein, “Adjusted Gross Proceeds” means gross amounts paid to and actually received by Company into
        Creator’s Clams Account or in dollars for Creator’s Access Fees and Tips (“Gross Proceeds”), less the following:
        (i) a reasonable allowance for bad debts and returns or customer credits; (ii) all sales, use or other
        applicable taxes and governmental assessments (excluding income taxes) required to be paid in connection with
        these transactions; and (iii) an Administration and Platform Fee retained by Company.  As of April 1, 2023, the
        “Administration and Platform Fee” is 25% of the Gross Proceeds.
        <br><br>
        Sales or other (non-income) taxes, to the extent payable, will be added by Company to the price charged to
        Users.  Creator is solely responsible for payment of all taxes and governmental assessments payable with respect
        to amounts paid to Creator hereunder.
        <br><br>
        Creators may also secure additional compensation through the following:
        <br><br>
        <strong class="marker-list">x</strong>. Promotion and sale through the Platform of Creator-owned or or
        -affiliated products, e.g., Creator meal kits or cookware (“Merchandising Revenues”).
        <br><br>
        <strong class="marker-list" #sponsorships>y</strong>. Sponsorship of Creator Content, if and to the extent secured by
        Creator (“Sponsorship Revenue”).
        <br><br>
        Merchandising Revenues and Sponsorship Revenues are deemed “Supplemental Revenue Streams.” Creator shall be
        required to secure Company’s prior written consent with respect to any sponsorships or merchandising sales to be
        included in the Creator Content.  Creators should contact Kittch at
        <a href="mailto:creatorsupport@kittch.com" class="link-underline">creatorsupport@kittch.com</a>
        if they wish to engage in any merchandising or sponsorship activities on the Platform.
        <br><br>
        Creator acknowledges that Company makes no representation, warranty or guaranty concerning viewership, sales,
        revenues or payments to be earned as a result of the creation and posting of Creator Content on the Platform,
        and in accepting these Creator Terms and posting Creator Content, Creator is not relying on any statement or
        representation with respect thereto.
      </li>
    </ul>
  </li>

  <li>
    <h3 class="section-title">Creator Warranties and Indemnification:</h3>
    <ul>
      <li class="text-content list-latin">
        By accepting these Creator Terms and posting Creator Content, Creator represents that he/she/they have secured
        all rights necessary to post such Creator Content and grant the rights granted herein; the Creator Content is
        original to Creator and does not and will not infringe on any rights of any third party; Creator is not subject
        to any conflicting obligations or any restrictions which would prevent his/her/their performance hereunder.
        Creator further represents that, except as otherwise disclosed in writing to Company, Creator has not accepted
        and will not accept any consideration in any form for inclusion or promotion in any Creator Content of any
        product or service, and will not promote or attempt to promote any product or service in the Creator Content in
        which Creator has a financial stake without advance disclosure to Company; and the Creator Content will be
        produced in accordance with all applicable Federal, State and local laws, rules and regulations, including
        without limitation any health codes and safety guidelines.
      </li>
      <li class="text-content list-latin">
        Creator shall indemnify and hold Company and its officers, directors, shareholders and employees (collectively
        for purposes of this section, “Affiliates”) harmless from and against all claims, damages, costs, liabilities
        and expenses, including reasonable legal fees and costs. (collectively, “Claims”) arising in connection with a)
        any claim that the Creator Content violates any rights of any third party; b) the breach by Creator of any
        representation, warranty or other provision of this Agreement; c) any gross negligence or willful misconduct in
        connection with Creator’s activities hereunder; or d) any third party claim arising in connection with the
        production of the Creator Content.  Company shall provide prompt written notice of any such claim of which it
        becomes aware, and Creator shall promptly defend or settle such claim, provided that Creator will not enter into
        any settlement which adversely affects Company without Company’s prior written consent, which may be withheld in
        its sole discretion.
      </li>
    </ul>
  </li>

  <li>
    <h3 class="section-title">Limitation of Liability:</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      NEITHER PARTY BE LIABLE TO THE OTHER PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE, OR
      CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATED TO THE OPERATION OF THE PLATFORM OR CREATOR’S PARTICIPATION
      THEREIN. KITTCH’S TOTAL LAIBILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE PLATFORM IS LIMITED TO THE
      GREATER OF A) THE AMOUNT PAID TO YOU OVER THE PRIOR 12 MONTHS ARISING FROM YOUR POSTING OF CREATOR CONTENT ON THE
      PLATFORM, OR B) $500.00.
    </p>
  </li>

  <li>
    <h3 class="section-title">
      Additional Restrictions: The following restrictions apply to Creator’s use of the Platform.
    </h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Creator shall not:
    </p>
    <ul>
      <li class="text-content list-latin">
        access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or otherwise
        use any part of the Kittch Platform or its service or any content on the Platform except in accordance with
        these Creator Terms or with prior written permission from Kittch and, if applicable, the respective rights
        holders;
      </li>
      <li class="text-content list-latin">
        circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Service (or attempt
        to do any of these things), including security-related features or features that (a) prevent or restrict the
        copying or other use of other Creator Content or (b) limit the use of the Platform or the Creator Content;
      </li>
      <li class="text-content list-latin">
        access the Platform using any automated means (such as robots, botnets or scrapers);
      </li>
      <li class="text-content list-latin">
        collect or harvest any information that might identify a person (for example, usernames or faces), unless
        permitted by that person;
      </li>
      <li class="text-content list-latin">
        use the Platform or the Creator Content to distribute unsolicited promotional or commercial content or other
        unwanted or mass solicitations;
      </li>
      <li class="text-content list-latin">
        cause or encourage any inaccurate measurements of genuine user engagement with the Platform including by paying
        people or providing them with incentives to increase a video’s views, likes, or dislikes, or to increase a
        channel’s subscribers, or otherwise manipulate metrics in any manner;
      </li>
      <li class="text-content list-latin">
        misuse any reporting, flagging, complaint, dispute, or appeals process, including by making groundless,
        vexatious, or frivolous submissions;
      </li>
      <li class="text-content list-latin">
        run contests on or through the Creator Content other than with Kittch’s prior written consent.
      </li>
    </ul>
  </li>

  <li>
    <h3 class="section-title">Confidentiality:</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      If Creator has access to, reviews or becomes aware of information about Company that would be deemed confidential
      or proprietary, including without limitation financial information, viewership statistics, business or marketing
      plans, designs, products, services, fees or budgets (collectively, “Confidential Information”), Creator shall be
      required to maintain the confidentiality of the Confidential Information in perpetuity, except as may be required
      by law or unless such Confidential Information becomes public through no action or inaction of Creator, and will
      not use the Confidential Information for any purpose other than to fulfill its obligations under this Agreement.
      Company shall be entitled to seek injunctive relief against Creator or any third party in the event it becomes
      aware of any violation of this section.
    </p>
  </li>

  <li>
    <h3 class="section-title">Independent Contractors:</h3>
    <p class="text-content text-content--no-intend text-content--vertical-intend">
      Creator shall be treated as an independent contractor of the Company, and not as partner, joint venturer,
      employer, employee or agent of Company and shall not bind nor attempt to bind the Company to any contract.
      Creator shall be solely responsible for all taxes, withholdings, and other statutory or contractual obligations
      of any sort, including, but not limited to, Workers' Compensation Insurance, in connection with the production,
      posting and monetizing of the Creator Content, and including taxes payable with respect to amounts paid to Creator
      by Company.  Creator shall defend, indemnify and hold Company harmless from any and all claims, damages,
      liabilities, losses, attorneys' fees and expenses on account of an alleged failure by Creator to satisfy any such
      obligations or any other obligation arising under these Creator Terms.
    </p>
  </li>

  <li>
    <h3 class="section-title">Miscellaneous Legal Terms</h3>
    <ul>
      <li class="text-content list-latin">
        This agreement shall be governed by the laws of the state of New York. In the event of any dispute, controversy
        or claim arising out of or relating to this Agreement, or the breach, termination or validity of it, this
        Agreement shall, at the option of either party, be settled by binding confidential arbitration in accordance
        with the rules of the American Arbitration Association, without regard to any conflict of laws provisions.
        Judgment upon the award rendered by the arbitrator may be entered by any court with jurisdiction. The arbitrator
        shall be empowered to award to the prevailing party all reasonable costs and expenses directly related to the
        arbitration, including but not limited to reasonable outside attorneys’ fees.
      </li>
      <li class="text-content list-latin">
        No party shall have the right to grant sublicenses hereunder or to otherwise assign, alienate, transfer,
        encumber, or hypothecate any of its rights or obligations hereunder.
      </li>
      <li class="text-content list-latin">
        The failure of either party to fully enforce its rights hereunder shall not be construed as a continuing waiver
        or relinquishment thereof.  Any cause of action arising out of these Creator Terms or Creator’s use of the
        Platform or creation of Creator Content must commence within one year after the cause of action accrues;
        otherwise, such cause of action is permanently barred.
      </li>
      <li class="text-content list-latin">
        Notices to the Company from Creator or its affiliates or representatives shall be sent to
        <a href="mailto:creatorsupport@kittch.com" class="link-underline">creatorsupport@kittch.com</a>.
        Notices from Company shall be sent from a  kittch email address.
      </li>
    </ul>
  </li>
</ol>

<h2 class="page-title" #programmingStandards>KITTCH CONTENT STANDARDS</h2>

<p class="text-content text-content--no-intend text-content--vertical-intend">
  Kittch is a platform company that exists to help consumers decide what and how to eat. Our platform empowers creators
  like yourself to post content that teaches, inspires, and helps consumers with their culinary needs. However, all
  content is subject to certain social guidelines and is subject to editing or removal if it fails to adhere to our
  requirements.
</p>

<p class="text-content text-content--no-intend">
  Kittch requires that all content posted adheres to the following guidelines:
</p>

<ol class="level-1">
  <li class="mt-0">
    <h3 class="section-title">Accurate Content</h3>
    <ol class="decimal-sub-list">
      <li class="text-content empty-list-style">
        <strong class="marker-list">1.1</strong>
        Tag Accuracy - Titles, tags, etc must always accurately represent the corresponding content that is distributed.
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">1.2</strong>
        Explicit Content - Creators  must tag individual episodes with an “explicit” tag if they contain explicit
        content (i.e., profanity or content that may not be suitable for children).
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">1.3</strong>
        Awareness of Cultural Sensitivities - Please be aware of  cultural sensitivities as you post content!
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">1.4</strong>
        Creator shall not make health claims related to or as part of the subject matter of the content without
        Company's prior written approval
      </li>
    </ol>
  </li>

  <li>
    <h3 class="section-title">The following content is prohibited:</h3>
    <ol class="decimal-sub-list">
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.1</strong>
        Infringing Content - content that infringes on trademarks, copyrights, privacy rights, publicity rights, or
        contract rights of others without the permission of the rightsholder.
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.2</strong>
        Illegal Content - content that violates applicable federal, state, and local laws, including content that
        promotes illegal drugs, illegal activity, or use of illegal weapons.
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.3</strong>
        Dangerous or Harmful Content - content that is abusive, threatening, obscene, defamatory or libelous, or
        depicts, encourages, or promotes bullying, excessive violence (including gore, guts, or dismemberment), hatred
        (towards a person or group of people based on age, disability, health or medical condition, race, religion,
        gender identity or expression, sex, ethnicity, nationality, sexual orientation, veteran status, or other
        characteristics associated with marginalization or systemic discrimination), physical or mental harm, racism,
        bigotry or excessive drug or alcohol abuse.
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.4</strong>
        Sexual/Pornographic Content - content that contains or exploits or sexually harasses others or encourages or
        links to sexually explicit websites, sexual abuse (rape, incest, beastiality), or sexual acts with minors.
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.5</strong>
        Deceptive, False or Misleading Content - content that misleads listeners by mimicking or impersonation, or that
        is dishonest, fraudulent, or interferes with the integrity of Kittch
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.6</strong>
        Solicitation Content - content that solicits passwords, personal identifiable information, funds, or involves
        the transmission of unsolicited mass mailing, instant messaging, “spamming” or “spimming” or involves the
        transmission of “junk mail” or “chain letters” or activity that involves bots, viruses, worms, or other computer
        files or programs that interrupt, or limit or destroy the functionality of any computer software or hardware,
        or otherwise permit the unauthorized use of or access to a computer or a computer network.
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.7</strong>
        Content or Sweepstakes Content - content that involves any form of user tracking, commercial activities and/or
        sales (i.e. contests, sweepstakes, barter, advertising, or pyramid schemes) without prior written consent from
        Kittch
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.8</strong>
        Content receiving consideration for promotion of products or services  without prior disclosure to Kittch
      </li>
      <li class="text-content empty-list-style">
        <strong class="marker-list">2.9</strong>
        Regulated Good or Services – content that promotes regulated goods and services, including alcohol, cannabis,
        gambling, pharmaceuticals, health/medical devices, tobacco, fireworks, or firearms/weapons, or other goods and
        services that are heavily regulated in the United States or in other countries in without Kittch’s consent
      </li>
    </ol>
  </li>
</ol>

<p class="text-content text-content--no-intend text-content--vertical-intend">
  All content is further subject to Kittch’s Terms of use.  Creators  that repeatedly distribute content that violates
  these guidelines will be suspended and/or terminated at the discretion of Kittch. Kittch reserves the right to update
  these guidelines for any reason and at any time.
</p>
