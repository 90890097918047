import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { CardGAEvents } from '@kitch/data-access/constants';
import { StagedChannel, StreamStatus } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';

@Component({
  selector: 'app-main-stage-channel.',
  templateUrl: './main-stage-channel.component.html',
  styleUrls: ['./main-stage-channel.component.scss'],
})
export class MainStageChannelComponent implements OnInit {
  @Input() isActiveSlider = false;
  @Input() channel: StagedChannel;
  @Input() useBgColorAvatar = true;

  @HostBinding('class.carousel-cell') private carouselCell = false;

  streamStatus = StreamStatus;

  constructor(
    protected $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) {}

  ngOnInit(): void {
    this.carouselCell = this.isActiveSlider;
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', CardGAEvents.mainStageChannel, {
      profile_id: this.tokenService.getProfileId(),
      name: this.channel.channel.name,
    });
  }
}
