import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { catchError } from 'rxjs/operators';
import { GA_EVENT_AI_IMAGE_GENERATE } from '@kitch/data-access/constants';
import { TokenService } from '@kitch/data-access/services';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { IMAGE_UPLOAD_CONTENT_TYPES } from '@kitch/ui/constants';
import { ImageCreatorParams } from '@kitch/ui/models/image-creator.model';
import { ImageCreatorService } from '@kitch/ui/services';

@UntilDestroy()
@Component({
  selector: 'app-image-creator',
  templateUrl: './image-creator.component.html',
  styleUrls: ['./image-creator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ImageCreatorService],
})
export class ImageCreatorComponent implements OnInit, OnDestroy {
  @ViewChild('imageCreatorModal', { static: true })
  private readonly imageCreatorModal: ModalComponent;

  @Input() imageWidth = 220;
  @Input() imageHeight = 150;
  @Input() quantity = 4;
  @Input() imageType: string;
  @Input() dishName: string;

  @Output() saveGeneratedImage = new EventEmitter<string>();
  @Output() imageFileChanged = new EventEmitter<Event>();

  readonly imageUploadContentTypes = IMAGE_UPLOAD_CONTENT_TYPES;
  prompt = '';
  previousPrompt = '';
  isLoading = false;
  showImagesChooser = false;
  images: string[] = [];
  selectedImage: string;
  modalTitle: string;
  placeholderForPrompt: string;
  isUnavailableGenerating = false;

  constructor(
    private imageCreatorService: ImageCreatorService,
    private gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) {}

  ngOnInit(): void {
    this.subscribeToModalClosing();
    this.setDefaultModalTitle();
  }

  ngOnDestroy(): void {}

  openImageCreatorModal(): void {
    this.imageCreatorModal.open();
    this.setDefaultPlaceholderForPrompt();
  }

  createImages(isRecreate = false): void {
    if (isRecreate) {
      this.selectedImage = null;
    }

    const { prompt, quantity, imageWidth, imageHeight } = this;
    const params: ImageCreatorParams = {
      width: imageWidth,
      height: imageHeight,
      quantity,
      prompt,
    };

    this.isLoading = true;
    this.showImagesChooser = false;
    this.modalTitle = 'creating images';
    this.previousPrompt = prompt;

    this.imageCreatorService.createImages(params)
      .pipe(
        catchError((error) => {
          this.unavailableGenerating();
          this.isLoading = false;

          throw error;
        }),
        untilDestroyed(this),
      )
      .subscribe(({ images, quantity }) => {
        if (quantity) {
          this.modalTitle = 'select an image';
          this.images = images;
          this.showImagesChooser = true;
        } else {
          this.unavailableGenerating();
        }

        this.isLoading = false;
      });
  }

  selectImage(image: string): void {
    this.selectedImage = this.selectedImage === image ? null : image;
  }

  saveImage(): void {
    this.saveGeneratedImage.emit(this.selectedImage);
    this.sendAnalytics();
    this.imageCreatorModal.close();
  }

  onFileChanged(event: Event): void {
    this.imageFileChanged.emit(event);
    this.imageCreatorModal.close();
  }

  private subscribeToModalClosing(): void {
    this.imageCreatorModal.closed
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetComponentPropertiesToDefault();
      });
  }

  private resetComponentPropertiesToDefault(): void {
    this.prompt = '';
    this.previousPrompt = '';
    this.images = [];
    this.selectedImage = null;
    this.showImagesChooser = false;
    this.isLoading = false;
    this.isUnavailableGenerating = false;
    this.setDefaultModalTitle();
  }

  private setDefaultModalTitle(): void {
    this.modalTitle = 'create images';
  }

  private sendAnalytics(): void {
    this.gaService.gtag('event', GA_EVENT_AI_IMAGE_GENERATE, {
      profile_id: this.tokenService.getProfileId(),
      image_type: this.imageType,
    });
  }

  private setDefaultPlaceholderForPrompt(): void {
    const dishName = this.dishName ? ` ${this.dishName}` : '';

    this.placeholderForPrompt = `Close up of${dishName} dish`;
    this.prompt = this.placeholderForPrompt;
  }

  private unavailableGenerating(): void {
    this.modalTitle = '';
    this.isUnavailableGenerating = true;
  }
}
