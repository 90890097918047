import { AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { SlugAvailableResponse } from '@kitch/data-access/models';
import { getValidSlug } from '@kitch/util/url.tool';

type ApiCallback = (slug: string) => Observable<SlugAvailableResponse>;

export function slugValidatorFn(apiCallback: ApiCallback): AsyncValidatorFn {
  return (control) => {
    return of(control.value).pipe(
      delay(400),
      map((value) => getValidSlug(value)),
      distinctUntilChanged(),
      switchMap((value) => {
        if (value) {
          return apiCallback(value).pipe(
            map((resp: SlugAvailableResponse) => (resp.available ? null : { forbiddenSlug: true })),
            tap(() => control.markAsTouched()),
          );
        } else {
          return of({ forbiddenSlug: true });
        }
      }),
    );
  };
}
