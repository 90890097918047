import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WITHDRAWALS_URL } from '@kitch/data-access/constants';
import {
  AppPagesItem,
  SimpleResponse,
  Withdrawal,
  WithdrawalsRequest,
  WithdrawCreate,
} from '@kitch/data-access/models';
import { WithdrawalsSearchParams } from '@kitch/data-access/models/search-params';
import { ApiService } from '@kitch/data-access/services/api.service';

import { makeUri } from '@kitch/util';

@Injectable()
export class WithdrawalsService {
  constructor(private apiService: ApiService) {}

  createRequest(profileId: string): Observable<WithdrawCreate> {
    return this.apiService.post(WITHDRAWALS_URL, { profileId });
  }

  getAll(searchParams?: WithdrawalsSearchParams): Observable<AppPagesItem<Withdrawal>> {
    return this.apiService.get(WITHDRAWALS_URL, searchParams);
  }

  updateStatus(id: string, status: WithdrawalsRequest): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(WITHDRAWALS_URL, id, 'status'), { status });
  }
}
