import { AgoraStreamToken } from '@kitch/data-access/models';

export interface AgoraStreamInfo {
  canUserJoinTable: boolean;
  isLoggedInRtm: boolean;
  isStreamOwner: boolean;
  profileId: string;
  streamToken: AgoraStreamToken;
  tipsBalance: number;
}

export interface UserInfo {
  audioEnabled: boolean;
  avatar: string;
  displayName: string;
  raisedHand: boolean;
  videoEnabled: boolean;
}

export interface StreamTracks {
  audioTrack: IMicrophoneAudioTrack;
  videoTrack: ICameraVideoTrack;
}

export interface DevicesFormValue {
  camera: string;
  microphone: string;
  secondCamera?: string;
}

export interface TipChefEvent {
  amount: number;
  callback: (amount: number) => void
}

export interface ToggleUserAudioEvent {
  status: boolean,
  uid: string
}

export enum PipCameraPositions {
  TOP_LEFT = 1,
  TOP_RIGHT = 2,
  BOTTOM_LEFT = 3,
  BOTTOM_RIGHT = 4,
}

export interface Tab {
  id: TabId;
  index: number;
  title: TabTitle;
}

export enum TabTitle {
  CHAT = 'Chat',
  CHEFS_TABLE = 'Chef\'s Table',
  INFO = 'Info',
  RECIPE = 'Recipe',
  RECIPE_FIRST = 'Recipe 1',
  RECIPE_SECOND = 'Recipe 2',
  RECIPE_THIRD = 'Recipe 3',
  SHOP = 'Shop',
}

export enum TabId {
  CHAT = 1,
  CHEFS_TABLE,
  INFO,
  RECIPE_FIRST,
  RECIPE_SECOND,
  RECIPE_THIRD,
  SHOP,
}

export interface TabsMenu {
  active: number;
  tabs: Tab[]
}
