import { Component, OnInit, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GA_EVENT_USER_FAVORITE_RECIPE_CLICK, GA_EVENT_USER_VIEW_RECIPE_CLICK } from '@kitch/data-access/constants';

import { MyRecipe } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';
import { FavoritesService } from '@kitch/data-access/services/favorites.service';

@Component({
  selector: 'app-recipe-inline-card',
  templateUrl: './recipe-inline-card.component.html',
  styleUrls: ['./recipe-inline-card.component.scss'],
})
export class RecipeInlineCardComponent implements OnInit {
  favoriteStatus: boolean;
  iconUrl: string;

  @Input() recipe: MyRecipe;

  constructor(
    private favoritesService: FavoritesService,
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) { }

  ngOnInit(): void {
    this.favoriteStatus = this.recipe.favorited;
    this.getFavoriteButtonImg(this.favoriteStatus);
  }

  onFavoriteButtonClick(): void {
    this.favoriteStatus = !this.favoriteStatus;
    this.getFavoriteButtonImg(this.favoriteStatus);
    this.favoriteStatus
      ? this.favoritesService.addToFavorites(this.recipe.id).subscribe()
      : this.favoritesService.deleteFromFavorites(this.recipe.id).subscribe();

    if (this.favoriteStatus) {
      this.sendGoogleAnalitycs(GA_EVENT_USER_FAVORITE_RECIPE_CLICK);
    }
  }

  getFavoriteButtonImg(favoriteStatus: boolean): void {
    this.iconUrl = favoriteStatus ?
      'assets/ui/images/svg/bookmark--inline-checked.svg' :
      'assets/ui/images/svg/black-bookmark.svg';
  }

  onButtonClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onViewClick(): void {
    this.sendGoogleAnalitycs(GA_EVENT_USER_VIEW_RECIPE_CLICK);
  }

  sendGoogleAnalitycs(eventName: string): void {
    this.$gaService.gtag('event', eventName, {
      recipe_id: this.recipe.id,
      recipe_name: this.recipe.recipeName,
      recipe_slug: this.recipe.slug,
      profile_id: this.tokenService.getProfileId(),
    });
  }
}
