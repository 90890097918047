import { ValidatorFn } from '@angular/forms';
import { ProductFormControlType } from '@kitch/ui/models';

export function RequiredFieldsValidator(): ValidatorFn {
  return (control: ProductFormControlType): { [key: string]: unknown } => {
    const { url, label, image, price } = control.value;
    const someControlIsFilled = !!(url || label || image || price);
    const requiredError = { required: true };

    if (someControlIsFilled) {
      control.get('label').setErrors(label ? null : requiredError);
      control.get('url').setErrors(url ? null : requiredError);
    } else {
      control.get('label').setErrors(null);
      control.get('url').setErrors(null);
    }

    if (control.get('label').valid && control.get('url').valid) {
      return null;
    }

    return requiredError;
  };
}
