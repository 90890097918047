import { Parser } from 'htmlparser2';

export const makeUri = (...chunks: Array<string | number>): string => {
  return chunks.join('/');
};

export const removeHTMLTags = (strToSanitize: string): string => {
  let parsedString = '';

  const parser = new Parser({
    ontext: (text) => parsedString += text,
  });

  parser.write(strToSanitize);
  parser.end();

  return parsedString;
};

