import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, of, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppPagesItem, Balance } from '@kitch/data-access/models';
import { FullProfileInfo, ShortProfileInfo } from '@kitch/data-access/models/profile';
import { ProfilesService, TokenService } from '@kitch/data-access/services';

export interface UserProfile {
  avatar: string;
  balance: number;
  id: string;
  name: string;
  refCode?: string;
  slug: string;
}

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  private defaultProfileInfo: UserProfile = {
    balance: 0,
    avatar: '',
    name: '',
    refCode: '',
    id: '',
    slug: '',
  };

  private fullUserProfile: ReplaySubject<FullProfileInfo> = new ReplaySubject<FullProfileInfo>();
  private userProfile: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(this.defaultProfileInfo);

  constructor(
    private profilesService: ProfilesService,
    private tokenService: TokenService,
  ) {
  }

  get userProfile$(): Observable<UserProfile> {
    return this.userProfile.asObservable();
  }

  get fullUserProfile$(): Observable<FullProfileInfo> {
    return this.fullUserProfile.asObservable();
  }

  updateUserProfile(profileInfo: Partial<UserProfile>): void {
    this.userProfile.next({ ...this.userProfile.value, ...profileInfo });
  }

  updateUserBalance(): void {
    this.getUserBalance().subscribe((balance) => {
      this.updateUserProfile(balance);
    });
  }

  loadUserProfileInfo(): Observable<[ShortProfileInfo, Balance]> {
    return forkJoin([
      this.getUserProfile(),
      this.getUserBalance(),
    ])
      .pipe(tap(([profile, balance]) => {
        this.userProfile.next({
          balance: balance.balance,
          avatar: profile.photo,
          name: profile.displayName,
          refCode: profile.refCode,
          id: profile.id,
          slug: profile.chefSlug,
        });
      }));
  }

  getFullUserProfile(): Observable<FullProfileInfo> {
    const profileId = this.tokenService.getProfileId();

    return this.profilesService.getUserProfileFullDetail(profileId)
      .pipe(tap(profile => this.fullUserProfile.next(profile)));
  }

  private getUserProfile(): Observable<ShortProfileInfo> {
    const profileId = this.tokenService.getProfileId();

    return this.profilesService.getUserProfile(profileId)
      .pipe(map((profiles: AppPagesItem<ShortProfileInfo>) => profiles.results[0]));
  }

  private getUserBalance(): Observable<Balance> {
    if (this.tokenService.isGuest()) {
      return of({ balance: 0 });
    } else {
      const profileId = this.tokenService.getProfileId();

      return this.profilesService.getUserBalance(profileId);
    }
  }
}
