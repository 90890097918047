<app-modal #fullTableModal [showCloseIcon]="true" (closed)="closeModal()">
  <ng-container header>
    <h2 class="modal-title">Whoops, the table is full!</h2>
  </ng-container>
  <ng-container body>
    <div class="full-table-modal-description">
      <p>
        You can use Ask a Chef for any questions or try again later.
      </p>
    </div>
    <div class="full-table-modal-actions form__item form__item--center">
      <div class="form-button form-button--close">
        <app-button
          [text]="'Close'"
          (clicked)="closeModal()">
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
