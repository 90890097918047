import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent {
  @Input() isSelected: boolean;
  @Input() isClickable = true;
  @Input() size = 'default';
  @Output() statusSlideInput: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  changeStatusCheckbox(): void {
    if (this.isClickable) {
      this.statusSlideInput.emit(!this.isSelected);
    }
  }
}
