import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { CreditCard } from '@kitch/data-access/models';

type CreditCardType = 'wallet' | 'modal';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit {
  @Input() card: CreditCard;
  @Input() selectedCardId: string;
  @Input() type: CreditCardType = 'modal';

  @Output() cardSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() cardDelete: EventEmitter<CreditCard> = new EventEmitter<CreditCard>();

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('click')
  onClick(): void {
    this.cardSelect.emit(this.card.id);
  }

  deleteCard(event: Event): void {
    event.stopPropagation();
    this.cardDelete.emit(this.card);
  }
}
