import { captureException } from '@sentry/angular-ivy';
import { UrlTool } from '@kitch/util/url.tool';
import {
  SocialNetworkNameEnum,
  SocialNetworkHostEnum,
  SocialNetworkImagesEnum,
} from '@kitch/ui/enums';

export const SOCIAL_NETWORK_MAP: Record<SocialNetworkNameEnum, SocialNetworkHostEnum> = {
  [SocialNetworkNameEnum.INSTAGRAM]: SocialNetworkHostEnum.INSTAGRAM,
  [SocialNetworkNameEnum.FACEBOOK]: SocialNetworkHostEnum.FACEBOOK,
  [SocialNetworkNameEnum.FACEBOOK_LINK]: SocialNetworkHostEnum.FACEBOOK_LINK,
  [SocialNetworkNameEnum.TWITTER]: SocialNetworkHostEnum.TWITTER,
  [SocialNetworkNameEnum.TIKTOK]: SocialNetworkHostEnum.TIKTOK,
  [SocialNetworkNameEnum.TIKTOK_LINK]: SocialNetworkHostEnum.TIKTOK_LINK,
  [SocialNetworkNameEnum.PINTEREST]: SocialNetworkHostEnum.PINTEREST,
  [SocialNetworkNameEnum.LINKEDIN]: SocialNetworkHostEnum.LINKEDIN,
  [SocialNetworkNameEnum.YOUTUBE]: SocialNetworkHostEnum.YOUTUBE,
  [SocialNetworkNameEnum.YOUTUBE_LINK]: SocialNetworkHostEnum.YOUTUBE_LINK,
};

export const SOCIAL_IMAGES_MAP: Record<SocialNetworkNameEnum, SocialNetworkImagesEnum> = {
  [SocialNetworkNameEnum.INSTAGRAM]: SocialNetworkImagesEnum.INSTAGRAM,
  [SocialNetworkNameEnum.FACEBOOK]: SocialNetworkImagesEnum.FACEBOOK,
  [SocialNetworkNameEnum.FACEBOOK_LINK]: SocialNetworkImagesEnum.FACEBOOK_LINK,
  [SocialNetworkNameEnum.TWITTER]: SocialNetworkImagesEnum.TWITTER,
  [SocialNetworkNameEnum.TIKTOK]: SocialNetworkImagesEnum.TIKTOK,
  [SocialNetworkNameEnum.TIKTOK_LINK]: SocialNetworkImagesEnum.TIKTOK_LINK,
  [SocialNetworkNameEnum.PINTEREST]: SocialNetworkImagesEnum.PINTEREST,
  [SocialNetworkNameEnum.LINKEDIN]: SocialNetworkImagesEnum.LINKEDIN,
  [SocialNetworkNameEnum.YOUTUBE]: SocialNetworkImagesEnum.YOUTUBE,
  [SocialNetworkNameEnum.YOUTUBE_LINK]: SocialNetworkImagesEnum.YOUTUBE_LINK,
};

export class SocialNetworkUrlTool {
  static isSocialNetwork(url: string): boolean {
    return !!SocialNetworkUrlTool.getSocialNetworkName(url);
  }

  static getSocialNetworkName(testUrl: string): SocialNetworkNameEnum | undefined {
    try {
      const testUrlWithProtocol = UrlTool.checkUrlProtocol(testUrl);
      const url = new URL(testUrlWithProtocol);

      return Object.keys(SOCIAL_NETWORK_MAP).find((networkName: SocialNetworkNameEnum) => {
        const networkHost = SOCIAL_NETWORK_MAP[networkName];

        return networkHost === url.host;
      }) as SocialNetworkNameEnum;
    } catch (e) {
      console.log('invalid URL', e);
      captureException(e);

      return undefined;
    }
  }

  static getSocialNetworkImage(url: string): string | null {
    const socialNetworkName = SocialNetworkUrlTool.getSocialNetworkName(url);

    return socialNetworkName ?
      SOCIAL_IMAGES_MAP[socialNetworkName] : null;
  }
}
