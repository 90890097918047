<article class="cdk-modal">
  <header class="cdk-modal__header">
    <img
      class="cdk-modal__header-img"
      data-testid="image"
      [src]="'assets/ui/images/svg/kittch-flame-modal-top-icon.svg'"
      alt="Chef's hat"
    >

    <button
      (click)="close()"
      class="modal-close-btn"
      data-testid="modal-close-btn"
      aria-label="close"
      type="button"
    >
      <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
      <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
    </button>


    <h2 class="creators-terms-thanks-modal__header-title">
      Thank you!<br>
      You’re good to go!
    </h2>
  </header>
</article>
