import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private sidebarStateMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private sidebarDesktopState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isPossibleToHideDesktopSidebarState: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  get sidebarMobileState$(): Observable<boolean> {
    return this.sidebarStateMobile.asObservable();
  }

  get sidebarDesktopState$(): Observable<boolean> {
    return this.sidebarDesktopState.asObservable();
  }

  get isPossibleToHideDesktopSidebarState$(): Observable<boolean> {
    return this.isPossibleToHideDesktopSidebarState.asObservable();
  }

  changeMobileState(state: boolean): void {
    this.sidebarStateMobile.next(state);
  }

  // current state (shown/hidden) of desktop sidebar
  changeDesktopState(state: boolean): void {
    this.sidebarDesktopState.next(state);
  }

  // if the desktop sidebar toggling at all
  changePossibilityToHideDesktopSidebar(state: boolean): void {
    this.isPossibleToHideDesktopSidebarState.next(state);
  }
}
