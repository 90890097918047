<div class="page-wrap">
  <img
    class="logo-img"
    src="assets/ui/images/svg/logo-1.svg"
    width="152"
    height="49"
    alt="Kittch"
  />
  <div class="success__text">
    Thank you for registering, we will notify you as soon as your account is
    approved. See you in the kitchen.
  </div>
</div>
