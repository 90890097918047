import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { FILTERS_URL, PROFILES_URL } from '@kitch/data-access/constants';
import {
  AppPagesItem,
  CreditCard,
  SimpleResponse,
  Balance,
  Categories,
  CardTransaction,
  Role,
  SlugAvailableResponse,
  Followers,
  MostPopularFilters,
} from '@kitch/data-access/models';
import { Notification } from '@kitch/data-access/models/notification';
import {
  FullProfileInfo,
  Profile,
  ProfileSlugResponse,
  ShortProfileInfo,
} from '@kitch/data-access/models/profile';
import { ProfileSearchParams } from '@kitch/data-access/models/search-params/profile';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';
import { Product } from '@kitch/ui/models';
import { CustomButton, NewCustomButton, ShortInfoCustomButton } from '@kitch/ui/models/custom-button-form';

@Injectable()
export class ProfilesService {
  constructor(private apiService: ApiService) {}

  getAll(searchParams?: ProfileSearchParams): Observable<AppPagesItem<Profile>> {
    return this.apiService.get(PROFILES_URL, searchParams);
  }

  getUserProfileFullDetail(profileId: string): Observable<FullProfileInfo> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId));
  }

  getUserProfile(profileIds: string | string[]): Observable<AppPagesItem<ShortProfileInfo>> {
    return this.apiService.get(makeUri(PROFILES_URL, 'short-info'), { ids: coerceArray(profileIds) });
  }

  getUserProfileIdBySlug(slug: string): Observable<ProfileSlugResponse> {
    return this.apiService.get(makeUri(PROFILES_URL, 'id-by-chef-slug', slug));
  }

  getPurchasedClubs(profileId: string): Observable<AppPagesItem<Followers>> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'purchased-clubs'));
  }

  checkSlugAvailable(slug: string): Observable<SlugAvailableResponse> {
    return this.apiService.get(makeUri(PROFILES_URL, 'chef-slug-status', slug));
  }

  getUserCards(profileId: string): Observable<CreditCard[]> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'cards'));
  }

  changeProfileBalance(id: string, balance: number, notes: string): Observable<SimpleResponse> {
    return this.apiService.post(
      makeUri(PROFILES_URL, id, 'balance'),
      { balance, notes },
    );
  }

  connectRestaurant(id: string, restaurantId: string): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(PROFILES_URL, id, 'restaurants'), { restaurantId });
  }

  getProducts(id: string): Observable<Product[]> {
    return this.apiService.get(makeUri(PROFILES_URL, id, 'products'));
  }

  getAllProducts(): Observable<Product[]> {
    return this.apiService.get(makeUri(PROFILES_URL, 'products'));
  }

  getButtons(id: string): Observable<CustomButton[]> {
    return this.apiService.get(makeUri(PROFILES_URL, id, 'buttons'));
  }

  deleteButton(id: string, buttonId: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(PROFILES_URL, id, 'button', buttonId));
  }

  addButtons(id: string, buttons: NewCustomButton[]): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(PROFILES_URL, id, 'buttons'), { buttons });
  }

  getUserBalance(profileId: string): Observable<Balance> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'balance'));
  }

  getUserCategories(profileId: string): Observable<Categories> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'categories'));
  }

  getUserRestaurants(profileId: string): Observable<AppPagesItem<CustomButton>> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'restaurants'));
  }

  updateUserRestaurants(profileId: string, restaurants: ShortInfoCustomButton[]): Observable<CustomButton[]> {
    return this.apiService.put(makeUri(PROFILES_URL, profileId, 'restaurants'), restaurants);
  }

  getUserButtons(profileId: string): Observable<CustomButton[]> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'buttons'));
  }

  getUserNotifications(profileId: string): Observable<Notification[]> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'notifications'));
  }

  getUserCardTransactions(profileId: string): Observable<AppPagesItem<CardTransaction>> {
    return this.apiService.get(makeUri(PROFILES_URL, profileId, 'transactions'));
  }

  updateReferrer(profileId: string, referrer: string | null): Observable<SimpleResponse> {
    const payload = referrer ? { referrer } : null;

    return this.apiService.patch(makeUri(PROFILES_URL, profileId, 'referral'), payload);
  }

  getProfileLink(role: Role, id: string, slug?: string): string {
    switch (role) {
      case Role.USER: return `/user-profile/${id}/recipes/`;
      case Role.CHEF: return `/${slug}`;
      default: return `/${slug}`;
    }
  }

  get getCuratedCollections$(): Observable<Profile[]> {
    return this.apiService.get(makeUri(PROFILES_URL, 'custom-category')).pipe(pluck('results'));
  }

  getFilters(): Observable<MostPopularFilters> {
    return this.apiService.get(makeUri(PROFILES_URL, FILTERS_URL));
  }
}
