import { AbstractControl, ValidatorFn } from '@angular/forms';
import Fraction from 'fraction.js';

export function FractionValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }

    try {
      const fraction = new Fraction(control.value);

      if (fraction.valueOf() <= 0) {
        return { customError: 'Wrong value' };
      }
    } catch (e) {
      return { customError: 'Wrong value' };
    }

    return null;
  };
}
