import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  @Input() inModal = false;

  constructor() { }

  ngOnInit(): void {
  }

  scrollTo(id: string): void {
    document.getElementById(id).scrollIntoView();
  }
}
