import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.scss'],
})
export class PrivacyPolicyModalComponent implements OnInit, OnChanges {
  @ViewChild('privacyPolicyModal', { static: false }) privacyPolicyModal: ModalComponent;

  @Input() isOpen = false;

  @Output() isPrivacyPolicyModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.privacyPolicyModal.open();
    }
  }

  closeModal(): void {
    this.isPrivacyPolicyModalOpen.emit(false);
    this.privacyPolicyModal.close();
  }

  setClosedModal(): void {
    this.isPrivacyPolicyModalOpen.emit(false);
  }
}
