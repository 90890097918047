<app-modal #modal (closed)="closeModal()" [showCloseIcon]="!isStreamOwner">
  <ng-container header>
    <div data-testid="modal-title" class="modal-title">Select camera and microphone</div>
  </ng-container>

  <ng-container body>
    <form [formGroup]="devicesForm">
      <label class="form-label" for="microphone">Microphone</label>
      <div class="form-input-wrap">
        <div class="select-wrap">
          <select id="microphone" name="microphone" class="select-wrap__select" formControlName="microphone">
            <option [ngValue]="null" selected hidden>Select microphone</option>
            <option *ngFor="let microphone of microphones" [ngValue]="microphone.deviceId">
              {{ microphone.label }}
            </option>
          </select>
        </div>
        <app-errors-list formControlName="microphone"></app-errors-list>
      </div>

      <label class="form-label" for="camera">Camera</label>
      <div class="form-input-wrap">
        <div class="select-wrap">
          <select id="camera" name="camera" class="select-wrap__select" formControlName="camera">
            <option [ngValue]="null" selected hidden>Select camera</option>
            <option *ngFor="let camera of cameras" [ngValue]="camera.deviceId">
              {{ camera.label }}
            </option>
          </select>
        </div>
        <app-errors-list formControlName="camera"></app-errors-list>
      </div>

      <ng-container *ngIf="isStreamOwner">
        <label class="form-label" for="camera">Second Camera (Optional)</label>
        <div class="form-input-wrap">
          <div class="select-wrap">
            <select id="secondCamera" name="secondCamera" class="select-wrap__select" formControlName="secondCamera">
              <option [ngValue]="null" selected hidden>Select second camera</option>
              <option *ngFor="let camera of cameras" [ngValue]="camera.deviceId">
                {{ camera.label }}
              </option>
            </select>
          </div>
          <app-errors-list formControlName="secondCamera"></app-errors-list>
        </div>
      </ng-container>
    </form>

    <div class="form-button-wrap">
      <div class="form-button form-button--submit">
        <app-button
          data-testid="device-form-submit-btn"
          [type]="'button'"
          [isDisabled]="isSubmitDisabled"
          (clicked)="submit()"
        ></app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
