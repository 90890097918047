import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { STAGED_URL, STREAMS_URL } from '@kitch/data-access/constants';
import {
  AppPagesItem,
  Stream,
} from '@kitch/data-access/models';
import { StreamsSearchParams } from '@kitch/data-access/models/search-params';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';

@Injectable({ providedIn: 'root' })
export class StagedService {
  constructor(private apiService: ApiService) {}

  getAllStaged(searchParams?: StreamsSearchParams): Observable<AppPagesItem<Stream>> {
    return this.apiService.get(STAGED_URL, searchParams);
  }

  getStagedItem(): Observable<AppPagesItem<Stream>> {
    return this.apiService.get(makeUri(STREAMS_URL, 'homepage-video'));
  }
}
