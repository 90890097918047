import { ValidatorFn } from '@angular/forms';
import { MaxLengthValidatorFields } from '@kitch/data-access/models/validator-fields';

export function MaxLengthFieldsValidator(controlFields: MaxLengthValidatorFields[]): ValidatorFn {
  return (control): { [key: string]: unknown } => {
    const maxLengthError = { maxlength: true };

    controlFields.forEach((controlField: MaxLengthValidatorFields) => {
      if (control.get(controlField.name).value.length > controlField.maxLength) {
        control.get(controlField.name).setErrors(maxLengthError);
      }
    });

    if (controlFields.every((controlField: MaxLengthValidatorFields) => control.get(controlField.name).valid)) {
      return null;
    }

    return maxLengthError;
  };
}
