import { Call } from '@angular/compiler';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor,
  UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export interface EmailInputs {
  isInvalid?: boolean;
  textContent: string;
  textWithHtml: string;
}

export interface EmailValues {
  first: string;
  second: string;
}

@Component({
  selector: 'app-email-text-inputs',
  templateUrl: './email-text-inputs.component.html',
  styleUrls: ['./email-text-inputs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailTextInputsComponent),
      multi: true,
    },
  ],
})
export class EmailTextInputsComponent implements ControlValueAccessor, OnInit, OnChanges {
  @ViewChild('firstInput', { static: true }) firstInput;
  @ViewChild('secondInput', { static: true }) secondInput;

  @Input() maxLength: number;
  @Input() isRequired = false;
  @Input() isSubmiting = false;
  @Input() isEditable = true;
  @Input() values: EmailValues;
  @Input() formControlNameFirst: string;
  @Input() formControlNameSecond: string;
  @Input() firstInputErrorName = 'first text block';
  @Input() secondInputErrorName = 'second text block';

  @Output() valueFirstInput: EventEmitter<EmailInputs> = new EventEmitter<EmailInputs>();
  @Output() valueSecondInput: EventEmitter<EmailInputs> = new EventEmitter<EmailInputs>();
  @Output() isSubmitingForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  firstValue: string;
  secondValue: string;
  form: UntypedFormGroup;
  isInvalidFirst = false;
  isInvalidSecond = false;

  constructor(
    private renderer: Renderer2,
    public fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({});

    if (this.formControlNameFirst) {
      this.form.addControl(this.formControlNameFirst, new UntypedFormControl(null,
        [Validators.maxLength(this.maxLength)]));
      if (this.isRequired) {
        this.form.get(this.formControlNameFirst).setValidators([Validators.required]);
        setTimeout(() => {
          const emailValues = {
            textContent: this.firstValue,
            textWithHtml: this.form.get(this.formControlNameFirst).value,
            isInvalid: this.form?.get(this.formControlNameFirst).status === 'INVALID',
          };

          this.valueFirstInput.emit(emailValues);
        });
      }
    }
    if (this.formControlNameSecond) {
      this.form.addControl(this.formControlNameSecond, new UntypedFormControl(null,
        [Validators.maxLength(this.maxLength)]));
      if (this.isRequired) {
        this.form.get(this.formControlNameFirst).setValidators([Validators.required]);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes?.values?.currentValue?.first && this.form) {
        this.form.get(this.formControlNameFirst)?.patchValue(changes.values?.currentValue.first);
      }

      if (changes?.values?.currentValue?.second && this.form) {
        this.form.get(this.formControlNameSecond)?.patchValue(changes.values?.currentValue.second);
      }

      if (changes?.isSubmiting?.currentValue && this.form) {
        this.isInvalidFirst = this.form?.get(this.formControlNameFirst).status === 'INVALID';
        this.form?.markAllAsTouched();
        this.isSubmitingForm.emit(false);
      }
    }, 1);
  }

  setValueFirst(value: string): void {
    this.firstValue = value;
    this.isInvalidFirst = this.form.get(this.formControlNameFirst).status === 'INVALID';
  }

  setValueSecond(value: string): void {
    this.secondValue = value;
  }

  setFirstValueWithHtml(value: string): void {
    const emailValues = {
      textContent: this.firstValue,
      textWithHtml: value,
      isInvalid: this.isInvalidFirst,
    };

    this.valueFirstInput.emit(emailValues);
  }

  setSecondValueWithHtml(value: string): void {
    const emailValues = {
      textContent: this.secondValue,
      textWithHtml: value,
    };

    this.valueSecondInput.emit(emailValues);
  }

  onBlur(): void {
    this.propagateOnTouched();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  writeValue(value: string) {
    this.renderer.setProperty(this.firstInput.nativeElement, 'value', value);
    this.renderer.setProperty(this.secondInput.nativeElement, 'value', value);
  }

  registerOnChange(fn: unknown): void {
    this.propagateOnChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn: Call): void {
    this.propagateOnTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private propagateOnChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private propagateOnTouched: any = () => {};
}
