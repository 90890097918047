import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WALMART_URL } from '@kitch/data-access/constants';
import {
  CheckoutParams,
  CheckoutUrlResponse,
  CheckoutProduct,
  ShoppingCartData,
  WalmartIngredient,
  WalmartProduct,
  WalmartRecipe,
  WalmartRecipesProducts,
  WalmartStore,
  WalmartStoreGetParams,
  WalmartWeeklyRecipe,
  WalmartStoresObject,
  ShoppingCartRecipe,
} from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';

@Injectable({
  providedIn: 'root',
})
export class WalmartService {
  constructor(private readonly apiService: ApiService) { }

  getWeeklyRecipe(): Observable<WalmartWeeklyRecipe> {
    return this.apiService.get(makeUri(WALMART_URL, 'weekly-recipe'));
  }

  getStores(params: WalmartStoreGetParams): Observable<WalmartStore[]> {
    return this.apiService.post<WalmartStore[]>(makeUri(WALMART_URL, 'store-locator'), params);
  }

  getRecipesProducts(recipeId: number, storeIdentifier: string, portions: number): Observable<WalmartRecipesProducts> {
    return this.apiService.post<{ payload: WalmartRecipesProducts }>(makeUri(WALMART_URL, 'recipe-products'), {
      calculation: 'price',
      portions,
      recipeId,
      storeIdentifier,
    }).pipe(
      map(({ payload }: { payload: WalmartRecipesProducts }) => {
        return {
          products: sanitizeProducts(payload.products),
          recipes: payload.recipes,
        };
      }));
  }

  mapToShoppingCartData(
    recipesProducts: WalmartRecipesProducts,
    walmartStoresObject: WalmartStoresObject,
  ): ShoppingCartData {
    const { stores } = walmartStoresObject;
    const currentStore = walmartStoresObject.currentStore || stores[0];

    const recipesNumber = recipesProducts.recipes.length;

    const ingredientsNumber = recipesProducts.recipes.reduce((acc: number, { ingredients }: WalmartRecipe) => {
      return acc + ingredients.length;
    }, 0);

    const recipes = mapSuitableRecipes(recipesProducts);

    return {
      currentStore,
      ingredientsNumber,
      recipes,
      recipesNumber,
      stores,
    };
  }

  getProductsFromRecipes(shoppingCartData: ShoppingCartData): CheckoutProduct[] {
    const products = shoppingCartData.recipes.map((recipe) => {
      return recipe.products.map(product => {
        const { offerId, quantity } = product;

        return {
          offerId,
          quantity,
        };
      });
    });

    // TODO remove ignore after update version of compiler tsconfig.base.json:compilerOptions.lib >= 'es2019'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return products.flat();
  }

  getRedirectLink(params: CheckoutParams): Observable<CheckoutUrlResponse> {
    return this.apiService.post<CheckoutUrlResponse>(makeUri(WALMART_URL, 'checkout-link'), params);
  }
}

export const mapSuitableRecipes = (recipesProducts: WalmartRecipesProducts): ShoppingCartRecipe[] => {
  const { products } = recipesProducts;

  return recipesProducts.recipes.map((recipe: WalmartRecipe) => {
    const { ingredients, title, recipeId, servingSize } = recipe;

    return {
      portions: servingSize,
      products: ingredients.map(({ ingredientId }: WalmartIngredient) => {
        const [product] = products.find(([{ recipeIngredientIds }]: WalmartProduct[]) => {
          return recipeIngredientIds.includes(ingredientId);
        }) ?? [null];

        return product;
      }),
      title,
      recipeId,
    };
  });
};

export const sanitizeProducts = (products: WalmartProduct[][]): WalmartProduct[][] => {
  return products.map(([product]) => {
    product.ppu = product.ppu || {
      amount: 0,
      currency: '',
      unit: '',
    };

    product.recipeIngredientIds = product.recipeIngredientIds || [];

    return [product];
  });
};
