export interface WalmartStore {
  accessPointId: string;
  accessPointName: string;
  distance: { measurementValue: number; unitOfMeasure: string; }
  fulfillmentStoreId: string;
  fulfillmentType: StoreFulfillmentType;
  serviceAddress: {
    addressLineOne: string;
    city: string;
    country: string;
    geoPoint: { latitude: string, longitude: string };
    postalCode: string;
    state: string;
    stateOrProvinceName: string;
  }
}

export interface WalmartStoresObject {
  currentStore: WalmartStore,
  stores: WalmartStore[];
}

export interface WalmartStoreGetParams {
  profileId?: string;
  zipCode?: string;
}

export interface WalmartIngredient {
  ingredientId: number;
  product: string;
  text: string;
  unit: string;
  unitValue: string;
}

export interface WalmartRecipe {
  description: string;
  ingredients: WalmartIngredient[];
  recipeId: number;
  servingSize: number;
  title: string;
}

export interface WalmartRecipesProducts {
  products: WalmartProduct[][];
  recipes: WalmartRecipe[];
}

export interface WalmartWeeklyRecipe {
  portions: number;
  walmartRecipeId: number;
}

export interface WalmartProduct {
  brand: string;
  categories: { id: number; name: string; }[];
  department: string;
  description: string;
  id: number;
  imageUrl: string;
  maxQuantity: number;
  offerId: string;
  offerPrice: number;
  ppu: {
    amount: number;
    currency: string;
    unit: string;
  }
  productPageURL: string;
  quantity: number;
  recipeIngredientIds: number[];
  salesUnitOfMeasure: string;
  similarProductsId: string;
  status: ProductStatus;
  thumbnailImageUrl: string;
  title: string;
  unitOfOrder: string;
  unitPriceDisplayCondition: string;
}

export interface CheckoutProduct {
  offerId: string;
  quantity: number;
}

export interface CheckoutParams {
  ap: string,
  offers: CheckoutProduct[],
  storeId: string
}

export interface CheckoutUrlResponse {
  checkoutUrl: string
}

export interface ShoppingCartData {
  currentStore: WalmartStore,
  ingredientsNumber: number;
  recipes: ShoppingCartRecipe[];
  recipesNumber: number;
  stores: WalmartStore[];
}

export interface ShoppingCartRecipe {
  portions: number;
  products: WalmartProduct[];
  recipeId: number;
  title: string;
}

export enum StoreLoadStatus {
  EMPTY = 'EMPTY',
  HAS_STORES = 'HAS_STORES',
  LOADING = 'LOADING',
}

export enum ShoppingCartStatus {
  EMPTY = 'EMPTY',
  HAS_PRODUCTS = 'HAS_PRODUCTS',
  LOADING = 'LOADING',
  PRODUCTS_NOT_FOUND = 'PRODUCTS_NOT_FOUND',
  STORE_NOT_FOUND = 'STORE_NOT_FOUND',
}

export enum StoreFulfillmentType {
  INSTORE_PICKUP = 'INSTORE_PICKUP',
}

export enum ProductStatus {
  IN_STOCK = 'in_stock',
  NO_MATCH = 'no_match',
  OUT_OF_STOCK = 'out_of_stock'
}
