import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import IMask from 'imask';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';

let nextId = 0;

@Component({
  selector: 'app-animated-input',
  templateUrl: './animated-input.component.html',
  styleUrls: ['./animated-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnimatedInputComponent),
      multi: true,
    },
  ],
})
export class AnimatedInputComponent implements ControlValueAccessor, AfterViewInit {
  private readonly UNLIMITED_INPUT_LENGTH = -1;
  private readonly DEFAULT_LENGTH = 500;
  private readonly MAX_LENGTH_WITH_MASK = 12;

  @ViewChild('label', { static: true }) label;
  @ViewChild('labelSignupForm', { static: true }) labelSignupForm;

  @ViewChild('input', { static: true }) input;
  @ViewChild('inputSignup', { static: true }) inputSignup;
  @ViewChild('inputLogin', { static: true }) inputLogin;

  @Input() minLength: number = this.UNLIMITED_INPUT_LENGTH;

  @Input() maxLength: number = this.UNLIMITED_INPUT_LENGTH;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('label')
  readonly labelText = '';

  @Input() type = 'text';

  @Input() formControlName: string;

  @Input() id?: string;

  @Input() mask: IMask.AnyMaskedOptions = {
    mask: /.*/,
    maxLength: Math.max(this.DEFAULT_LENGTH, this.maxLength),
  };

  @Input() autocomplete: string;

  @Input() isLoginForm = false;

  @Input() isHalf: boolean;

  @Input() isSignupForm = false;

  @Output() dialCode: EventEmitter<string> = new EventEmitter<string>();

  readonly inputId = `app-animated-input-${nextId++}`;
  readonly inputSignupId = 'app-animated-input-2';

  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  PhoneNumberFormat = PhoneNumberFormat;

  ngxInput: ElementRef | any;
  selectedCountryISO: string;

  constructor(
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.input.nativeElement.name = this.formControlName;
    if (this.isLoginForm) {
      this.maxLength = this.MAX_LENGTH_WITH_MASK;
      const defaultCode = '1';

      this.dialCode.emit(defaultCode);

      this.ngxInput = document.querySelector('ngx-intl-tel-input .iti .input-login');
      if (this.ngxInput) {
        this.renderer.setAttribute(this.ngxInput, 'autocomplete', this.autocomplete);
        this.renderer.setAttribute(this.ngxInput, 'id', 'phone-number-1');
      }
      this.inputLogin.name = this.formControlName;
    }
    this.inputSignup.nativeElement.name = this.formControlName;
    if (this.input.nativeElement.value.length !== 0) {
      this.renderer.addClass(this.label.nativeElement, 'label--is-focus');
    }

    if (this.inputSignup.nativeElement.value.length !== 0) {
      this.renderer.addClass(this.labelSignupForm.nativeElement, 'label--is-delete');
    }

    if (this.autocomplete != null) {
      if (this.input) {
        this.input.nativeElement.autocomplete = this.autocomplete;
      }

      if (this.inputSignup) {
        this.inputSignup.nativeElement.autocomplete = this.autocomplete;
      }
    }
    this.changeDetector.detectChanges();
  }

  onChange(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (this.maxLength !== this.UNLIMITED_INPUT_LENGTH && target.value.length > this.maxLength) {
      target.value = target.value.substr(0, this.maxLength);
    }
    if (this.isLoginForm) {
      target.value = this.getDigits(target.value);
    }
    this.propagateOnChange(target.value);
  }

  onCountryChange(): void {
    if (!this.isLoginForm || this.inputLogin.selectedCountry.iso2 === this.selectedCountryISO) return;

    this.selectedCountryISO = this.inputLogin.selectedCountry.iso2;
    if (!this.ngxInput) return;

    // fix Vatican City dialCode
    if (this.selectedCountryISO === 'va') {
      this.inputLogin.selectedCountry.dialCode = '379';
    }

    this.dialCode.emit(this.inputLogin.selectedCountry.dialCode);

    // const cutPhoneNumber = this.inputLogin.value.substring(0, this.maxLength);
    // this.inputLogin.writeValue(cutPhoneNumber);
    // this.propagateOnChange(cutPhoneNumber);
  }

  onBlur(): void {
    this.propagateOnTouched();
    if (this.input.nativeElement.value.length === 0) {
      this.renderer.removeClass(this.label.nativeElement, 'label--is-focus');
    }

    if (this.inputSignup.nativeElement.value.length === 0) {
      this.renderer.removeClass(this.labelSignupForm.nativeElement, 'label--is-delete');
    }
  }

  onFocus(): void {
    if (this.input) {
      this.renderer.addClass(this.label.nativeElement, 'label--is-focus');
    }

    if (this.inputSignup) {
      this.renderer.addClass(this.labelSignupForm.nativeElement, 'label--is-delete');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  writeValue(value: any) {
    if (value) {
      if (this.input) {
        this.renderer.addClass(this.label.nativeElement, 'label--is-focus');
      }

      if (this.inputSignup) {
        this.renderer.addClass(this.labelSignupForm.nativeElement, 'label--is-delete');
      }
    }

    this.renderer.setProperty(this.input.nativeElement, 'value', value);
    this.renderer.setProperty(this.inputSignup?.nativeElement, 'value', value);

    // to clear up register phone field after success registration
    if (this.inputLogin) {
      this.inputLogin.value = value;
      this.inputLogin.phoneNumber = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn) {
    this.propagateOnChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn) {
    this.propagateOnTouched = fn;
    if (this.inputLogin) {
      this.inputLogin.onTouched = fn;
      this.propagateOnTouched = this.inputLogin.onTouched;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.input) {
      this.renderer.setProperty(this.input.nativeElement, 'disabled', isDisabled);
    }

    if (this.inputSignup) {
      this.renderer.setProperty(this.inputSignup.nativeElement, 'disabled', isDisabled);
    }

    if (this.inputLogin) {
      this.renderer.setProperty(this.inputLogin, 'disabled', isDisabled);
    }
  }

  private getDigits(input: string): string {
    const onlyDigitsRegex = /\d+/g;
    const digits = input.match(onlyDigitsRegex);

    if (!digits) {
      return '';
    }

    return digits.join('');
  }

  private propagateOnChange: any = () => {};
  private propagateOnTouched: any = () => {};
}
