<div class="guest-buttons">
  <app-button
    data-testid="open-login-modal-btn"
    class="guest-buttons__button"
    [text]="'LOG IN'"
    [type]="'button'"
    [role]="'secondary'"
    (clicked)="openLoginModal()"
  >
  </app-button>
  <app-button
    data-testid="sign-up-btn"
    class="guest-buttons__button"
    [text]="'SIGN UP'"
    [type]="'button'"
    (clicked)="openRegisterModal()"
  >
  </app-button>
</div>

<app-login-register-modal-wrapper
  [userRole]="userRole"
  [refCode]="refCode"
  [isLoginModal]="isLoginModalOpened"
  [isRegisterModal]="isRegisterModalOpened"
  [source]="source"
  (loginModalClosed)="onLoginModalClosed()"
  (registerModalClosed)="onRegisterModalClosed()"
></app-login-register-modal-wrapper>
