import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RECIPES_URL } from '@kitch/data-access/constants';
import {
  AppPagesItem,
  NewRecipe,
  Recipe,
  RecipeRatingResponse,
  RecipeSlugResponse,
  SimpleResponse,
  SlugAvailableResponse,
} from '@kitch/data-access/models';
import { RecipesSearchParams } from '@kitch/data-access/models/search-params';

import { makeUri } from '@kitch/util';

import { ApiService } from './api.service';

@Injectable()
export class RecipesService {
  constructor(private apiService: ApiService) {}

  getAll(searchParams?: RecipesSearchParams): Observable<AppPagesItem<Recipe>> {
    return this.apiService.get(makeUri(RECIPES_URL), searchParams);
  }

  getAllForChef(searchParams: RecipesSearchParams): Observable<AppPagesItem<Recipe>> {
    return this.apiService.get(makeUri(RECIPES_URL, 'my'), searchParams);
  }

  getAllByProfile(searchParams: RecipesSearchParams): Observable<AppPagesItem<Recipe>> {
    return this.apiService.get(makeUri(RECIPES_URL, 'chef'), searchParams);
  }

  getByProfileId(searchParams: RecipesSearchParams): Observable<AppPagesItem<Recipe>> {
    return this.apiService.get(makeUri(RECIPES_URL, 'chef'), searchParams);
  }

  getTopPicks(): Observable<AppPagesItem<Recipe>> {
    return this.apiService.get(makeUri(RECIPES_URL, 'top-picks'));
  }

  getById(id: string): Observable<Recipe> {
    return this.apiService.get(makeUri(RECIPES_URL, id));
  }

  getIdBySlug(slug: string): Observable<RecipeSlugResponse> {
    return this.apiService.get(makeUri(RECIPES_URL, 'id-by-slug', slug));
  }

  checkSlugAvailable(slug: string): Observable<SlugAvailableResponse> {
    return this.apiService.get(makeUri(RECIPES_URL, 'slug-status', slug));
  }

  create(recipe: NewRecipe): Observable<Recipe> {
    return this.apiService.post(RECIPES_URL, recipe);
  }

  update(recipe: NewRecipe, id: string): Observable<Recipe> {
    return this.apiService.patch(makeUri(RECIPES_URL, id), recipe);
  }

  delete(id: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(RECIPES_URL, id));
  }

  changeStatus(id: string, isPublished: boolean): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(RECIPES_URL, id, 'status'), { published: isPublished });
  }

  rate(id: string, rating: number): Observable<RecipeRatingResponse> {
    return this.apiService.post(makeUri(RECIPES_URL, id, 'rating'), { rating });
  }

  reRate(id: string, rating: number): Observable<RecipeRatingResponse> {
    return this.apiService.patch(makeUri(RECIPES_URL, id, 'rating'), { rating });
  }
}
