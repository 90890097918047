// eslint-disable-next-line import/no-cycle
import { BasicCuisineSchema } from '@kitch/data-access/models/cuisine';
import { ClubData } from '@kitch/data-access/models/purchase';
import { Tags } from '@kitch/data-access/models/tags';
import { TransactionCurrency } from '@kitch/data-access/models/transaction';
import { Product } from '@kitch/ui/models';
import { CustomButton } from '@kitch/ui/models/custom-button-form';
import { VideoTimestamp } from '@kitch/ui/models/video-timestamp.form';
import { PipCameraPositions } from '@kitch/user/shared/models';

export enum StreamStatus {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  PAST = 'PAST',
  PREPMODE = 'PREPMODE',
  SCHEDULED = 'SCHEDULED'
}

export enum StreamAccessLevel {
  FREE = 'FREE',
  PAID = 'PAID',
  PRIVATE = 'PRIVATE',
}

export enum StreamUserRole {
  PUBLISHER = 'PUBLISHER',
  SUBSCRIBER = 'SUBSCRIBER',
}

export enum StreamOrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum StreamOrderField {
  DATE = 'updatedAt',
  ID = 'id',
  PAST_DATE = 'stoppedAt',
  START_DATE = 'startAt',
}

export enum StreamConnectionStatus {
  CONNECTED = 'CONNECTED',
  LOST = 'LOST',
}

export enum StageType {
  CHANNEL = 'CHANNEL',
  STREAM = 'STREAM'
}

export enum PrivateOption {
  GUESTS = 'guests',
  HIDDEN = 'hidden'
}

export enum ReplayOption {
  FREE = 'FREE',
  HIDDEN_NON_LIVE = 'HIDDEN_NON_LIVE',
  PAID = 'PAID',
  PAID_NON_LIVE = 'PAID_NON_LIVE'
}

interface PaidData {
  active: boolean,
  livePrice?: number,
  recipesHidden: boolean
  replayPrice?: number,
  replayType?: ReplayOption
  showPrice?: number,
}

export interface RequiredSubscriptionPlan {
  currency?: TransactionCurrency;
  id: string;
  isBundle?: boolean;
  isSeries?: boolean;
  price: number;
  type?: string;
}

export interface Stream {
  accessLevel?: StreamAccessLevel;
  addedToRecipe?: boolean;
  buttons?: CustomButton[];
  channel: StreamChannel;
  clubData?: ClubData
  cohosts?: CoHostInfo[];
  collaboratorIds?: string[];
  collaborators?: Array<StreamChefProfile | StagedChannel>;
  connectionStatus?: StreamConnectionStatus;
  description: string;
  featured?: boolean;
  guestIds?: string[];
  hidden?: boolean;
  id: string;
  isBundle?: boolean,
  isChefRecommended: boolean,
  isEarthWeek?: boolean;
  isFireChef?: boolean;
  isMushroomMonth?: boolean;

  // added on the front-end side
  isPaymentRequire?: boolean;
  isSeries?: boolean,
  liked?: boolean;
  mainCameraVideoUrl?: string;
  paid?: boolean;
  paidData?: PaidData;
  produced?: false;
  products?: Product[],
  profile?: StagedChannel;
  recipeIds?: string[];
  recordStartedAt?: string;
  requiredClubSubscriptionPlan: RequiredSubscriptionPlan | null;
  requiredSubscriptionPlan: RequiredSubscriptionPlan | null;
  scheduledVideo: {
    date: string;
  };
  secondCameraPosition?: PipCameraPositions;
  secondCameraVideoUrl?: string;
  slug: string;
  sponsored?: boolean;
  staged?: boolean;
  stagedOrder?: number;
  startedAt: string;
  status: StreamStatus;
  stoppedAt: string;
  streamBadge?: string;
  subscribed?: boolean;
  subscribedToClub: boolean;
  subscriptionPlans?: {
    channel: SubscriptionPlan;
    video: SubscriptionPlan;
  };
  tags: Tags;
  thumbnailUrl: string;
  timestamps?: VideoTimestamp[],
  title: string;
  videoCuisines?: BasicCuisineSchema[];
  videoEmailSettings?: VideoEmailSettings;
  videoPrivate?: boolean;
  videoUrl?: string;
  videoUrlMobile?: string;
  viewCount?: number;
  viewCountMax?: number;
}

export interface StreamChannel {
  bannerImage?: string;
  chefProfile: StreamChefProfile;
  id: string;
  name: string;
  subscribed?: boolean;
  tileImage?: string;
}

export interface StagedChannel {
  badgeImageUrl: string;
  channel?: {
    bannerImage?: string;
    chefProfile: StreamChefProfile,
    id: string;
    isClub: boolean;
    name: string;
    staged?: boolean;
    stagedOrder?: number;
    tileImage?: string;
  };
  chefChannel?: string;
  chefName?: string;
  chefSlug?: string;
  displayName?: string;
  fullName?: string;
  hometown?: string;
  id?: string;
  isLive?: boolean;
  itemType?: string;
  photo?: string;
  refCode?: string;
  stagedOrder?: number;
  zipCode?: string;
}

export interface StreamPrivateGuest {
  approved: boolean;
  createdAt: string;
  email: string;
  id: string;
  phoneNumber: string;
  profile: StagedChannel;
  role: string;
  updatedAt: string;
}

export interface StagedStream extends Stream {
  itemType: string;
}

export interface StreamChefProfile {
  badgeImageUrl?: string;
  channel?: {
    id: string;
    name: string;
  };
  chefName: string;
  chefSlug: string;
  id: string;
  isLive: boolean;
  photo: string;
}

export interface VideoCuisines {
  cuisines?: string[];
  streamType?: string[];
  subCuisines?: string[];
}

export interface EmailSettingsUpdate {
  postCustomTextOne?: string;
  postCustomTextTwo?: string;
  postEmailTrigger?: boolean;
  preCustomTextOne?: string;
  preCustomTextTwo?: string;
  preEmailTrigger?: boolean;
}

export interface BasicStream {
  accessLevel: StreamAccessLevel;
  buttons?: CustomButton[];
  collaboratorIds?: string[];
  description: string;
  featured: boolean;
  guestIds?: string[];
  hidden?: boolean;
  isChefRecommended: boolean;
  paidData?: {
    active: boolean,
    livePrice?: number,
    recipesHidden?: boolean,
    replayPrice?: number,
    replayType?: ReplayOption
    showPrice?: number,
  };
  products?: Product[],
  profileId?: string;
  recipeIds?: string[],
  slug: string;
  sponsored?: boolean;
  staged: boolean;
  stagedOrder: number;
  status: StreamStatus;
  streamEmailSettings?: EmailSettingsUpdate,
  tags: Tags,
  thumbnailUrl?: string;
  timestamps?: VideoTimestamp[],
  title: string;
  videoCuisines: VideoCuisines;
  videoPrivate?: boolean;
}

export interface CheckStreamStatus {
  id: string;
  status: StreamStatus;
}

export interface StreamGuest {
  fullName?: string;
  id: string;
  photo?: string;
}

export interface StreamSchedule {
  date: string;
  isDateChanged?: boolean;
  notifyFollowers: boolean;
  time?: string;
}

export interface DownloadStream {
  mainCameraVideoMpFourUrls: string[] | null,
  secondCameraVideoMpFourUrls: string[] | null,
  videoMpFourUrls: string[] | null
}

export type NewStreamButton = Omit<CustomButton, 'id'>;

export interface AgoraStreamToken {
  appId: string;
  compositeMobileRecordingRtcToken: string;
  compositeMobileRecordingRtcUidPrefix: string;
  compositeRecordingRtcToken: string;
  compositeRecordingRtcUidPrefix: string;
  expiresIn: number;
  mainCameraRecordingRtcToken: string;
  mainCameraRecordingRtcUidPrefix: string;
  rtcToken: string;
  rtmToken: string;
  secondCameraRecordingRtcToken: string;
  secondCameraRecordingRtcUidPrefix: string;
  secondCameraRtcToken: string;
  secondCameraRtcUidPrefix: string;
}

export interface SubscriptionPlan {
  currency: 'CLAMS' | 'USD';
  id: string;
  price: number;
}

export interface SimpleStreamResponse {
  videoId: string;
}

export interface CoHostInfo {
  cameraId: number;
  profileId: string;
}

export interface UploadVideoParams {
  duration: number;
  videoUrl: string;
}

export interface StreamRecordingStatusResponse {
  recording: boolean;
}
export interface StreamSlugResponse {
  id: string;
  profileId: string;
}

export interface VideoEmailSettings {
  postCustomTextOne: string;
  postCustomTextTwo: string;
  postEmailTrigger: boolean;
  preCustomTextOne: string;
  preCustomTextTwo: string;
  preEmailTrigger: boolean;
  replayCustomTextOne: string;
  replayCustomTextTwo: string;
  replayEmailTrigger: boolean;
}

export interface HardcodedSeriesEntry {
   // Dummy video that is used to track series purchases
  dummyVideo: {
    id: string;
    subscriptionPlan: { id: string; price: number };
  };
  type: SeriesType;
  // IDs of the series videos
  videoIds: string[];
}

export enum SeriesType {
  // V1
  Bundle = 'Bundle',
  // V2
  Series = 'Series',
}
