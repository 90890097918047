import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorHandlerInterceptor } from '@kitch/data-access/interceptors/http-error-handler.interceptor';
import { TokenInterceptor } from '@kitch/data-access/interceptors/token.interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorHandlerInterceptor,
    multi: true,
  },
];
