<a class="channel__link" [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug" (click)="sendGAEvent()">
  <div class="channel__wrap">
    <div class="channel__thumbnail">
      <div class="overlay"></div>
      <div *ngIf="stream.status === 'PAST'"  class="channel__past stream-status">
        <div class="channel__past__date">{{ streamDuration | durationVideo }}</div>
      </div>
        <picture class="carousel-img">
          <img class="avatar carousel-img"
            [ngSrc]="stream.thumbnailUrl"
            [fill]="true"
           alt="{{ stream.channel.name }}" />
        </picture>
    </div>
    <div class="channel__content">
      <div class="channel__content__time-age">{{ stream.stoppedAt | date:'MMM d, YYYY, h:mma' }}</div>
      <div class="channel__content__channel-name">{{ stream.title }}</div>
      <div class="channel__content__author">
        <a [routerLink]="'/' + stream.channel.chefProfile.chefSlug"
           *ngIf="!stream.collaborators?.length"
           (click)="$event.stopPropagation()">
          <app-avatar
            [url]="stream.channel.chefProfile.photo"
            [size]="'extra-small'"
            [name]="stream.channel.chefProfile.chefName"
            [title]="stream.channel.name"
            [avatarInputTextColor]="'green'"
            [isLive]="stream.channel.chefProfile.isLive"
            [isClub]="!!stream.requiredClubSubscriptionPlan"
          ></app-avatar>
        </a>
        <app-multiple-avatars
          *ngIf="stream.collaborators?.length"
          [chefs]="stream.collaborators"
        ></app-multiple-avatars>
      </div>
    </div>
  </div>
</a>
