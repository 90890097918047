export const GA_EVENT_ADD_TO_CALENDAR_CLICK = 'add_to_calendar';
export const GA_EVENT_HEART_BUTTON_CLICK = 'heart_button_click';
export const GA_EVENT_SHARE_BUTTON_CLICK = 'share_button_click';
export const GA_EVENT_DOWNLOAD_BUTTON_CLICK = 'download__button_click';
export const GA_EVENT_JOIN_CLUB_CLICK = 'join_club_click';
export const GA_EVENT_VIEW_PRODUCT_CLICK = 'view_product_click';
export const GA_EVENT_CUSTOM_BUTTON_CLICK = 'custom_button_click';
export const GA_EVENT_JOIN_CHEF_TABLE_CLICK = 'join_chef_table';
export const GA_EVENT_FOLLOW_BUTTON_CLICK = 'follow_button_click';
export const GA_EVENT_USER_RAISE_HAND_CLICK = 'user_raise_hand';
export const GA_EVENT_USER_TIP_CLAM_CLICK = 'user_tip_clam';
export const GA_EVENT_USER_SEND_EMOJI_CLICK = 'user_send_emoji';
export const GA_EVENT_USER_VIEW_RECIPE_CLICK = 'user_view_recipe';
export const GA_EVENT_USER_FAVORITE_RECIPE_CLICK = 'user_favorite_recipe';
export const GA_EVENT_USER_RECIPE_RATE = 'user_recipe_rate';
export const GA_EVENT_USER_FAVORITE_PRODUCT_CLICK = 'user_favorite_product';
export const GA_EVENT_VIDEO_STOP_CLICK = 'user_stop_watching_video';
export const GA_EVENT_VIDEO_START_CLICK = 'user_start_watching_video';
export const GA_EVENT_LIVESTREAM_START_WATCHING = 'user_start_watching_livestream';
export const GA_EVENT_LIVESTREAM_STOP_WATCHING = 'user_stop_watching_livestream';
export const GA_EVENT_CHEF_DELETE_OWN_STREAM = 'chef_delete_own_stream';
export const GA_EVENT_OPEN_LOGIN_MODAL = 'open_login';
export const GA_EVENT_OPEN_SIGNUP_MODAL = 'open_signup';
export const GA_EVENT_SUBMIT_LOGIN_MODAL = 'login';
export const GA_EVENT_SUBMIT_SIGNUP_MODAL = 'signup';
export const GA_EVENT_TOP_BANNER_CLICK = 'top_banner_click';
export const GA_EVENT_IMAGE_UPLOAD = 'image_upload';
export const GA_EVENT_AI_IMAGE_GENERATE = 'ai_image_generate';
export const GA_EVENT_SIGNUP_FROM_FIRST_TIME_MODAL = 'signup_from_first_time_modal';

export enum SaveSpotGAEvent {
  GA_EVENT_SAVE_SPOT_BUTTON_CLICK = 'save_spot_btn_click',
  GA_EVENT_SAVE_SPOT_EMAIL_SUBMIT = 'save_spot_email_submit',
}

export enum SearchGAEvents {
  GA_EVENT_USER_SEARCH_CHANNELS_RESULTS = 'user_search_channels_results',
  GA_EVENT_USER_SEARCH_INPUT_CLICK = 'user_search_input',
  GA_EVENT_USER_SEARCH_RECIPES_RESULTS = 'user_search_recipes_results',
  GA_EVENT_USER_SEARCH_STREAMS_RESULTS = 'user_search_streams_results',
  GA_EVENT_USER_SEARCH_SUBMIT_CLICK = 'user_search_submit',
  GA_EVENT_USER_SEARCH_TOTAL_RESULTS = 'user_search_total_results',
  GA_EVENT_USER_SEARCH_UPCOMING_RESULTS = 'user_search_upcoming_results'
}

export const GA_CATEGORY_UPCOMING_EVENT = 'upcoming_event_card';
export const GA_CATEGORY_LIVE_STREAM = 'live_stream';

export enum MenuGAEvents {
  allStreams = 'menu_all_streams_click',
  channels = 'menu_channels_click',
  favorites = 'menu_favorites_click',
  marketplace = 'menu_marketplace_click',
  recipes = 'menu_recipes_click',
  upcoming = 'menu_upcoming_click'
}

export enum CarouselGAEvents {
  seeAllChannels = 'see_all_channels_click',
  seeAllPast = 'see_all_past_click',
  seeAllRecipes = 'see_all_recipes_click',
  seeAllUpcoming = 'see_all_upcoming_click',
}

export enum CardGAEvents {
  channel = 'channel_card_click',
  club = 'club_card_click',
  collectionsChannel = 'Collections_Channel_Card',
  live = 'live_event_card_click',
  mainStageChannel = 'main_stage_channel_card_click',
  mainStageStream = 'main_stage_event_card_click',
  past = 'past_event_card_click',
  top = 'top_event_card_click',
  upcoming = 'upcoming_event_card_click'
}

export enum WalmartGAEvents {
  addIngredients = 'walmart_add_ingredients_click',
  cartCheckout = 'walmart_cart_checkout_click',
  changeProductQuantity = 'walmart_change_product_quantity_click',
  changeStore = 'walmart_change_store_click',
  selectNewStore = 'walmart_select_new_store_click',
  shopWithWalmartButtonClick = 'shop_with_walmart_button_click'
}

export enum FilterGAEvents {
  CHANNEL_FILTER_BUTTON_CLICK = 'channel_filter_button_click',
  CHANNEL_TAG_CLICK = 'channel_filter_tag_click',
  STREAM_FILTER_BUTTON_CLICK = 'stream_filter_button_click',
  STREAM_TAG_CLICK = 'stream_filter_tag_click',
}

export enum PurchaseGAEvents {
  BUY_COURSE_BUTTON_CLICK = 'buy_course_button_click',
  BUY_STREAM_BUTTON_CLICK = 'buy_stream_button_click',
  JOIN_COURSE_BUTTON_CLICK = 'join_course_button_click',
}

export enum TypeformGAEvents {
  LETS_GET_STARTED_UCPBUTTON_CLICK_FIRST_TIME = 'lets_get_started_ucpbutton_click_first_time',
  LETS_GET_STARTED_UCPBUTTON_CLICK_SECOND_TIME = 'lets_get_started_ucpbutton_click_second_time',
  OPEN_GET_STARTED_UCPMODAL = 'open_get_started_ucpmodal'
}
