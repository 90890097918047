<app-button
  [text]="buttonTitle"
  [iconUrl]="'assets/ui/images/svg/share.svg'"
  [role]="role"
  [isSmallOnTablet]="isSmallOnTablet"
  (click)="openModal()"
  [iconHeight]="22"
  [iconWidth]="20"
  [buttonClass]="buttonTitle ? '' : 'liked' + ' '+ buttonClass"
></app-button>

<app-modal #modal (closed)="modalClosed()">
  <ng-container header>
    <div class="modal-image-wrap">
      <img
        src="assets/ui/images/svg/share-modal-icon.svg"
        alt="Spread the love icon"
        width="116"
        height="84"
      />
    </div>

    <h2 class="modal-title">Spread the love</h2>
  </ng-container>

  <ng-container body>
    <share-buttons
      [theme]="'social-buttons'"
      [include]="socialMedia"
      [show]="socialMedia.length"
      [url]="url"
      [title]="title"
      [description]="description"
      [image]="image"
      [tags]="tags"
      (opened)="onClickMediaButton($event)"
    ></share-buttons>

    <p *ngIf="showCanvaLink" class="modal-description">
      Get Instagram Kittch Canva templates for your feed and your stories
      <a href="https://www.canva.com/design/DAFEQPyFfvM/ZGHV3IZgl7jcDZW5J8J5fQ/view?utm_content=DAFEQPyFfvM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="share-link">here</a>.
    </p>
  </ng-container>
</app-modal>

