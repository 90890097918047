import {
  AddPreparationStepsModalComponent,
} from '@kitch/ui/components/modals/add-preparation-steps-modal/add-preparation-steps-modal.component';
import { TypeformModalComponent } from '@kitch/ui/components/modals/typeform-modal/typeform-modal.component';
import { CoHostInviteWasSendModalComponent }
  from './co-host-invite-was-send-modal/co-host-invite-was-send-modal.component';
import { CreatorsTermsModalComponent } from './creators-terms-modal/creators-terms-modal.component';
import { CreatorsTermsThanksModalComponent } from './creators-terms-thanks-modal/creators-terms-thanks-modal.component';
import { FullStreamTableModalComponent } from './full-stream-table-modal/full-stream-table-modal.component';
import { InviteCodeModalComponent } from './invite-code-modal/invite-code-modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { PrivacyPolicyModalComponent } from './privacy-policy-modal/privacy-policy-modal.component';
import { RegisterModalComponent } from './register-modal/register-modal.component';
import { ReloadPageModalComponent } from './reload-page-modal/reload-page-modal.component';
import { RemoteLoginModalComponent } from './remote-login-modal/remote-login-modal.component';
import { SelectDevicesModalComponent } from './select-devices-modal/select-devices-modal.component';
import { StopStreamAlertModalComponent } from './stop-stream-alert-modal/stop-stream-alert-modal.component';
import { StreamRecordingErrorModalComponent }
  from './stream-recording-error-modal/stream-recording-error-modal.component';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { UserAlreadySignupModalComponent } from './user-already-signup-modal/user-already-signup-modal.component';
import { UserCompleteRegistrationModalComponent }
  from './user-complete-registration-modal/user-complete-registration-modal.component';
import { UserNotFoundModalComponent } from './user-not-found-modal/user-not-found-modal.component';

export * from './full-stream-table-modal/full-stream-table-modal.component';
export * from './privacy-policy-modal/privacy-policy-modal.component';
export * from './terms-modal/terms-modal.component';
export * from './user-already-signup-modal/user-already-signup-modal.component';
export * from './user-not-found-modal/user-not-found-modal.component';
export * from './co-host-invite-was-send-modal/co-host-invite-was-send-modal.component';
export * from './select-devices-modal/select-devices-modal.component';
export * from './invite-code-modal/invite-code-modal.component';
export * from './stop-stream-alert-modal/stop-stream-alert-modal.component';
export * from './remote-login-modal/remote-login-modal.component';
export * from './login-modal/login-modal.component';
export * from './register-modal/register-modal.component';
export * from './user-complete-registration-modal/user-complete-registration-modal.component';
export * from './reload-page-modal/reload-page-modal.component';
export * from './stream-recording-error-modal/stream-recording-error-modal.component';
export * from './add-preparation-steps-modal/add-preparation-steps-modal.component';
export * from './creators-terms-modal/creators-terms-modal.component';
export * from './creators-terms-thanks-modal/creators-terms-thanks-modal.component';
export * from './typeform-modal/typeform-modal.component';

export const modalsSharedComponents = [
  UserNotFoundModalComponent,
  TermsModalComponent,
  UserAlreadySignupModalComponent,
  PrivacyPolicyModalComponent,
  CoHostInviteWasSendModalComponent,
  InviteCodeModalComponent,
  SelectDevicesModalComponent,
  StopStreamAlertModalComponent,
  RemoteLoginModalComponent,
  LoginModalComponent,
  RegisterModalComponent,
  UserCompleteRegistrationModalComponent,
  FullStreamTableModalComponent,
  ReloadPageModalComponent,
  StreamRecordingErrorModalComponent,
  AddPreparationStepsModalComponent,
  CreatorsTermsModalComponent,
  CreatorsTermsThanksModalComponent,
  TypeformModalComponent,
];
