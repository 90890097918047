<div class="plan-wrap" [ngClass]="{ 'plan-wrap--selected' : isSelected }">
  <div class="plan-info-wrap">
    <img class="clams-icon" src="assets/ui/images/svg/{{ 'clams-' + index }}.svg" alt="clams"
         onerror="this.src='assets/ui/images/svg/clams-1.svg';this.onerror=null;"/>
    <div class="clams-count-wrap">
      <div class="clams-count">
        {{ clamsPlan.clams }} clams
        <span *ngIf="isSelected" class="plan-change" (click)="changeQuantity()">Change Qty</span>
      </div>
      <div *ngIf="clamsPlan.freeClams" class="free-clams-count">
        Buy {{ clamsPlan.clams }}, Get {{ clamsPlan.freeClams }} Free
      </div>
    </div>
  </div>
  <div *ngIf="!isSelected; else selectedPlanPrice" class="plan-price">
    <ng-container *ngTemplateOutlet="price; context:{ price: clamsPlan.price }"></ng-container>
  </div>

  <ng-template #selectedPlanPrice>
    <div class="selected-plan-price">
      <div class="selected-plan-price__price">
        <ng-container *ngTemplateOutlet="price; context:{ price: checkoutInfo?.amountSubtotal }"></ng-container>
      </div>
      <div class="selected-plan-price__description">One-time charge</div>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="isSelected">
  <div class="confirm-purchase-details">
    <span class="confirm-purchase-details__label">Subtotal</span>
    <span class="confirm-purchase-details__value">
      <ng-container *ngTemplateOutlet="price; context:{ price: checkoutInfo?.amountSubtotal }"></ng-container>
    </span>
  </div>

  <div class="confirm-purchase-details">
    <span class="confirm-purchase-details__label">Sales Tax</span>
    <span class="confirm-purchase-details__value">
      <ng-container *ngTemplateOutlet="price; context:{ price: checkoutInfo?.amountTax }"></ng-container>
  </span>
  </div>

  <div class="confirm-purchase-details confirm-purchase-details--total">
    <span class="confirm-purchase-details__label">Total</span>
    <span class="confirm-purchase-details__value">
      <ng-container *ngTemplateOutlet="price; context:{ price: checkoutInfo?.amountTotal }"></ng-container>
    </span>
  </div>
</ng-container>


<ng-template #price let-price="price">
  <ng-container *ngIf="price || price === 0; else calculating">
    {{ price | customCurrency: 'USD' }}
  </ng-container>

  <ng-template #calculating>
    <span class="animated--fade-in">
      Calculating...
    </span>
  </ng-template>
</ng-template>
