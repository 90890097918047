import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { formatPhone } from '@kitch/util';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit, AfterViewInit {
  @Input()
  readonly error: string;

  @Input()
  readonly submitButtonText: string;

  @Output()
  readonly login: EventEmitter<{ phoneNumber: string }> = new EventEmitter<{ phoneNumber: string }>();

  @Input() isUserLoginning = false;

  loginForm: UntypedFormGroup;

  isSubmitted = false;

  loginId: string;
  dialCode: string;

  ngOnInit(): void {
    this.initLoginForm();
  }

  ngAfterViewInit(): void {
    this.onError();
  }

  get formPhoneNumber(): AbstractControl {
    return this.loginForm.get('phoneNumber');
  }

  onDialCode(dialCode: string): void {
    this.dialCode = dialCode;
  }

  onSubmit(): void {
    if (this.loginForm.invalid) return;

    this.isUserLoginning = true;
    const phoneNumber = formatPhone(this.formPhoneNumber.value, this.dialCode);

    this.login.emit({ phoneNumber });
  }

  private initLoginForm(): void {
    this.loginForm = new UntypedFormGroup({
      phoneNumber: new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]),
    });
  }

  private onError(): void {
    setTimeout(() => {
      if (this.error) {
        this.formPhoneNumber.setErrors({ serverError: this.error });
        this.loginForm.markAllAsTouched();
      }
    });
  }
}
