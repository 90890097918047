<form class="verification__form" [formGroup]="authVerifyForm" (ngSubmit)="onConfirm()">
  <ul>
    <li class="form__item">
      <p class="verification__text">
        We have sent you access code<br> via SMS for mobile number verification
      </p>

      <div class="code-wrap">
        <div class="code">
          <div class="form__field form__field--one-symbol">
            <app-animated-input
              #first
              formControlName="first"
              type="number"
              [mask]="maskConfig"
              (input)="onInput($event, second)"
            ></app-animated-input>
          </div>

          <div class="form__field form__field--one-symbol">
            <app-animated-input
              #second
              formControlName="second"
              type="number"
              [mask]="maskConfig"
              (input)="onInput($event, third)"
            ></app-animated-input>
          </div>

          <div class="form__field form__field--one-symbol">
            <app-animated-input
              #third
              formControlName="third"
              type="number"
              [mask]="maskConfig"
              (input)="onInput($event, fourth)"
            ></app-animated-input>
          </div>

          <div class="form__field form__field--one-symbol">
            <app-animated-input
              #fourth
              formControlName="fourth"
              type="number"
              [mask]="maskConfig"
              (input)="onInput($event, submit)"
            ></app-animated-input>
          </div>
        </div>
        <app-errors-list [control]="authVerifyForm"></app-errors-list>
      </div>
    </li>
    <li class="form__item form__item--center">
      <app-button
        data-testid="login-submit-btn"
        #submit
        [text]="'sign in'"
        [type]="'submit'"
        isLoginForm="true"
        [isDisabledLoginForm]="!authVerifyForm.valid || loading && isSubmited ? 'disabled' : ''"
        [isDisabled]="!authVerifyForm.valid || loading">
      </app-button>
    </li>
  </ul>
</form>

<app-modal #privacyPolicyModal>
  <ng-container header>
    <h2 class="modal-title">Privacy policy</h2>
  </ng-container>

  <ng-container body>
    <div class="modal-content-wrap">
      <app-privacy-policy></app-privacy-policy>
    </div>
    <div class="form__item form__item--center">
      <div class="form-button form-button--close">
        <app-button
          [text]="'Close'"
          (clicked)="privacyPolicyModal.close()"
          isDisabledLoginForm=""
          >
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
