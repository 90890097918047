import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  constructor() {
  }

  transform(seconds: number): string {
    if (seconds == null) {
      return '';
    }

    const sec: number = Math.floor(seconds % 60);
    let minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);

    minutes %= 60;

    let duration: string = (hours < 10 ? `0${hours}` : hours.toString());

    duration += `:${minutes < 10 ? `0${minutes}` : minutes}`;
    duration += `:${sec < 10 ? `0${sec}` : sec}`;

    return duration;
  }
}
