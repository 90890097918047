import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-access-indicator',
  templateUrl: './access-indicator.component.html',
  styleUrls: ['./access-indicator.component.scss'],
})
export class AccessIndicatorComponent implements OnInit {
  @Input() isVideoPrivate: boolean;
  @Input() isPurchased: boolean;
  @HostBinding('style.display') private isVisible = 'none';

  indicatorText = '';

  ngOnInit() {
    this.isVisible = this.isVideoPrivate || this.isPurchased ? 'block' : 'none';
    this.setIndicatorText();
  }

  private setIndicatorText() {
    switch (true) {
      case this.isVideoPrivate:
        this.indicatorText = 'Reserved for You';
        break;
      case this.isPurchased:
        this.indicatorText = 'Purchased';
        break;
    }
  }
}
