import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-terms-creators',
  templateUrl: './terms-creators.component.html',
  styleUrls: ['../terms-shared.component.scss'],
})
export class TermsCreatorsComponent {
  @ViewChild('programmingStandards') readonly programmingStandards: ElementRef;
  @ViewChild('sponsorships') readonly sponsorships: ElementRef;

  scrollToSponsorships(): void {
    this.sponsorships.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollToProgrammingStandards(): void {
    this.programmingStandards.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
