import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '@kitch/data-access/services';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private logger: LoggerService) {
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleError(error: any): void {
    this.logger.error('SentryErrorHandler#handleError', error);
  }
}
