import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Recipe } from '@kitch/data-access/models';

export interface RecipeStatusChange {
  isSelected: boolean;
  recipeId: string;
}

@Component({
  selector: 'app-recipe-list-item',
  templateUrl: './recipe-list-item.component.html',
  styleUrls: ['./recipe-list-item.component.scss'],
})
export class RecipeListItemComponent {
  @Input() recipe: Recipe;
  @Input() isDisabled: boolean;

  @Output() changeStatus: EventEmitter<RecipeStatusChange> = new EventEmitter<RecipeStatusChange>();

  onCheckboxClick(event: Event): void {
    const isSelected = (event.target as HTMLInputElement).checked;

    this.recipe.addedToStream = isSelected;
    this.changeStatus.emit({
      isSelected,
      recipeId: this.recipe.id,
    });
  }
}
