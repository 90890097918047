<article class="cdk-modal">
  <header class="cdk-modal__header">
    <img
      class="cdk-modal__header-img"
      data-testid="image"
      [src]="'assets/ui/images/svg/chef-hat.svg'"
      alt="Chef's hat"
    >

    <button
      (click)="close()"
      class="modal-close-btn"
      data-testid="modal-close-btn"
      aria-label="close"
      type="button"
    >
      <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
      <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
    </button>

    <h2 class="creators-terms-modal__header-title">We’ve updated our terms of use for creators!</h2>
  </header>

  <main class="cdk-modal__body">
    <article class="creators-terms-modal__terms-container">
      <app-terms-creators></app-terms-creators>
    </article>

    <div class="creators-terms-modal__checkbox-container">
      <label class="checkmark" data-testid="terms-checkmark">
        <input
          type="checkbox"
          class="checkmark__input"
          [(ngModel)]="isTermsAccepted"
        />
        <span class="checkmark__label"></span>
      </label>
      <span class="terms-text">
        I accept the Kittch
        <a
          class="terms-text-link"
          target="_blank"
          [routerLink]="'/creators-terms-of-use'"
        >Creator's Terms of Service</a>
        and have read the Kittch
        <a
          class="terms-text-link"
          target="_blank"
          [routerLink]="'/privacy-policy'"
        >Privacy Statement</a>.
      </span>
    </div>

    <app-button
      class="creators-terms-modal__submit-btn"
      [text]="'Submit'"
      [type]="'submit'"
      [isDisabled]="!isTermsAccepted"
      (clicked)="submit()"
    ></app-button>

    <div class="creators-terms-modal__contact-block">
      <span class="terms-text">
        Have questions?
        <a href="mailto:creatorsupport@kittch.com">Contact Us</a>.
      </span>
    </div>
  </main>
</article>
