import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MAX_ITEMS_PER_PAGE, RESTAURANTS_URL } from '@kitch/data-access/constants';
import { AppPagesItem, SimpleResponse, IdObject } from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';
import { RestaurantListItem, RestaurantShortInfo } from '@kitch/admin/shared/models/restaurant';

@Injectable()
export class RestaurantsService {
  constructor(private apiService: ApiService) {}
  getAllRestaurants(): Observable<AppPagesItem<RestaurantListItem>> {
    return this.apiService.get(RESTAURANTS_URL, { itemsPerPage: MAX_ITEMS_PER_PAGE });
  }

  createRestaurant(data: RestaurantShortInfo): Observable<IdObject> {
    return this.apiService.post(RESTAURANTS_URL, { ...data });
  }

  updateRestaurantInfo(id: string, data: RestaurantShortInfo): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(RESTAURANTS_URL, id), { ...data });
  }
}
