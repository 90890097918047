import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
  Output,
  Inject,
} from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import { CommonUserRole } from '@kitch/data-access/models';
import { FirstTimeUserSignupService } from '@kitch/user/core/first-time-user-signup.service';

@Component({
  selector: 'app-login-register-modal-wrapper',
  templateUrl: './login-register-modal-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginRegisterModalWrapperComponent implements OnChanges {
  @Input() userRole: CommonUserRole;

  @Input()
  readonly inviteId: string;

  @Input() refCode: string;

  @Input() isLoginModal: boolean;

  @Input() isRegisterModal: boolean;

  @Input() source: string;

  @Output()
  readonly loginModalClosed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly registerModalClosed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly pageReloaded: EventEmitter<void> = new EventEmitter<void>();

  readonly pageView: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private firstTimeUserSignupService: FirstTimeUserSignupService,
    @Inject(LOCATION) private location: Location,
  ) {
    this.pageView = this.location.pathname;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoginModal?.currentValue) {
      this.setIsLoginModal(true);
    }
    if (changes.isRegisterModal?.currentValue) {
      this.setIsRegisterModal(true);
    }
    if (changes.refCode?.currentValue) {
      this.refCode = changes.refCode?.currentValue;
    }
    if (changes.userRole?.currentValue) {
      this.userRole = changes.userRole.currentValue;
    }
    if (changes.source?.currentValue) {
      this.source = changes.source.currentValue;
    }
    this.cdr.detectChanges();
  }

  setIsLoginModal(isLogin: boolean): void {
    this.firstTimeUserSignupService.isFirstTimeVisiting = false;
    if (!isLogin) {
      this.loginModalClosed.emit();
    }
    this.setIsModal(isLogin, false);
  }

  setIsRegisterModal(isRegister: boolean): void {
    this.firstTimeUserSignupService.isFirstTimeVisiting = false;
    if (!isRegister) {
      this.registerModalClosed.emit();
    }
    this.setIsModal(false, isRegister);
  }

  reloadPage(): void {
    this.pageReloaded.emit();
    this.location.reload();
  }

  private setIsModal(isLogin: boolean, isRegister: boolean): void {
    this.isLoginModal = isLogin;
    this.isRegisterModal = isRegister;
    this.cdr.detectChanges();
  }
}
