import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { PRODUCTS_URL } from '@kitch/data-access/constants';
import { ClamsPlan } from '@kitch/data-access/models/clam';
import { ApiService } from '@kitch/data-access/services/api.service';

import { makeUri } from '@kitch/util';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private apiService: ApiService) { }

  getClamPlans(): Observable<ClamsPlan[]> {
    return this.apiService.get(makeUri(PRODUCTS_URL, 'clams'));
  }
}
