import { AbstractControl, ValidatorFn } from '@angular/forms';

import { setTime12ToDate } from '@kitch/util';

export function ScheduleDateValidator(form: AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const chosenTime = control.value;
    let chosenDate = form.get('date')?.value;

    if (!chosenTime || !chosenDate) {
      return null;
    }

    const now = new Date();
    const error = { customError: 'Date can\'t be in past (update the time)' };

    chosenDate = setTime12ToDate(chosenTime, chosenDate);

    return chosenDate < now ? error : null;
  };
}
