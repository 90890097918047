<div class="transaction-wrap">
  <div style="display: flex">
    <div class="transaction-icon"></div>
    <div class="transaction-inner-wrap">
      <div>
        <div *ngIf="paidStream || clubData; else purchasedClams" class="transaction-text">
          You purchased
          <ng-container *ngIf="clubData; else gift">
            <a
              class="transaction-text__link"
              [routerLink]="'/' + clubData.chefSlug"
            >{{ clubData.channelName }}</a>
            <span *ngIf="isGift">
            &nbsp;as a gift for {{ giftReceiver.receiverFirstName }} {{ giftReceiver.receiverLastName }}
          </span>
          </ng-container>
          <ng-template #gift>
            <a
              class="transaction-text__link"
              [routerLink]="'/' + paidStream.chefSlug + '/streams/' + paidStream.slug"
            >{{ paidStream.title }} {{ isGift ? '' : '\'s Livestream' }}</a>
            <span *ngIf="isGift">
            &nbsp;as a gift for {{ giftReceiver.receiverFirstName }} {{ giftReceiver.receiverLastName }}
          </span>
          </ng-template>
         </div>
        <ng-template #purchasedClams>
          <div class="transaction-text">
            You purchased {{ transaction.transaction.amount }} Clams
          </div>
        </ng-template>
        <div class="transaction-date">{{ transaction.createdAt | date }}</div>
      </div>
      <div class="transaction-amount">{{ transaction.amount / 100 | currency:'USD' }}</div>
    </div>
  </div>
</div>
