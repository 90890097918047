import { Component, Input, OnInit } from '@angular/core';

import { Stream, StreamStatus } from '@kitch/data-access/models';

@Component({
  selector: 'app-stream-for-recipe-card',
  templateUrl: './stream-for-recipe-card.component.html',
  styleUrls: ['./stream-for-recipe-card.component.scss'],
})
export class StreamForRecipeCardComponent implements OnInit {
  @Input() stream: Stream;

  streamStatus = StreamStatus;

  constructor() { }

  ngOnInit(): void {
  }
}
