<ng-select
  *ngIf="items"
  #selectDropdown
  class="custom-select"
  [items]="items"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [multiple]="true"
  [(ngModel)]="selectedItems"
  [maxSelectedItems]="maxSelectedItems"
  [addTag]="isAddTag"
  [labelForId]="inputId"
  [id]="inputId"
  [closeOnSelect]="false"
  [clearSearchOnAdd]="true"
  [clearable]="isClearable"
  addTagText="Add "
  [isOpen]="isDropdownOpen"
  [searchFn]="customSearchFn"
  (add)="addItem($event)"
  (remove)="removeItem($event)"
  (clear)="showHidePlaceholder()"
  (focus)="onFocus()"
  (blur)="onBlur()"
  (scrollToEnd)="easyLoad()"
></ng-select>
