import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@kitch/data-access/env/environment';
import { RequestOptions, RequestParams } from '@kitch/data-access/models';
import { makeUri } from '@kitch/util';

@Injectable()
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  get apiUrl(): string {
    return environment.apiUrl;
  }

  get<T>(uri: string, params = null): Observable<T> {
    const options: RequestOptions = this.getRequestOptions(params);

    return this.httpClient.get<T>(makeUri(this.apiUrl, uri), options);
  }

  put<T>(uri: string, body = {}, params = null): Observable<T> {
    const options: RequestOptions = this.getRequestOptions(params);

    return this.httpClient.put<T>(
      makeUri(this.apiUrl, uri),
      body,
      options,
    );
  }

  patch<T>(uri: string, body = {}, params = null): Observable<T> {
    const options: RequestOptions = this.getRequestOptions(params);

    return this.httpClient.patch<T>(
      makeUri(this.apiUrl, uri),
      body,
      options,
    );
  }

  post<T>(uri: string, body = {}, params = null): Observable<T> {
    const options: RequestOptions = this.getRequestOptions(params);

    return this.httpClient.post<T>(
      makeUri(this.apiUrl, uri),
      body,
      options,
    );
  }

  delete<T>(uri: string, body = {}): Observable<T> {
    return this.httpClient.request<T>(
      'delete',
      makeUri(this.apiUrl, uri),
      { body },
    );
  }

  loadFile(uri: string): Observable<string> {
    return this.httpClient.get(makeUri(this.apiUrl, uri), { responseType: 'text' });
  }

  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress;
  }

  private getRequestOptions(params?: RequestParams): RequestOptions {
    let httpParams: HttpParams = new HttpParams();

    Object.keys(params || {})
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== '',
      )
      .forEach((key) => (httpParams = httpParams.append(key, params[key])));

    return { params: httpParams };
  }
}
