import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TermsService, TokenService } from '@kitch/data-access/services';
import {
  CreatorsTermsThanksModalComponent,
} from '@kitch/ui/components/modals/creators-terms-thanks-modal/creators-terms-thanks-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-creators-terms-modal',
  templateUrl: './creators-terms-modal.component.html',
  styleUrls: ['../modals.scss', './creators-terms-modal.component.scss'],
})
export class CreatorsTermsModalComponent implements OnDestroy {
  isTermsAccepted = false;

  constructor(
    public dialogRef: DialogRef<boolean>,
    private termsService: TermsService,
    private tokenService: TokenService,
  ) {}

  ngOnDestroy(): void {}

  close(): void {
    this.dialogRef.close(this.isTermsAccepted);
  }

  submit(): void {
    const userId = this.tokenService.getIdFromToken();

    this.termsService.patchTermsStatus(userId, { isTermsAccepted: true })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef.config.data.modalService.open(CreatorsTermsThanksModalComponent);
        this.close();
      });
  }
}
