import { Component, Input } from '@angular/core';

import { StagedChannel, StreamChefProfile } from '@kitch/data-access/models';

type AvatarSize = 'extra-small' | 'medium';
type BackgroundColor = 'white' | 'green' | '';
type DetailsNameType = 'chef' | 'channel';

@Component({
  selector: 'app-multiple-avatars',
  templateUrl: './multiple-avatars.component.html',
  styleUrls: ['./multiple-avatars.component.scss'],
})
export class MultipleAvatarsComponent {
  @Input() chefs: Array<StreamChefProfile | StagedChannel>;
  @Input() size: AvatarSize = 'extra-small';
  @Input() title: string;
  @Input() backgroundColor: BackgroundColor = 'white';
  @Input() detailsNameType: DetailsNameType = 'chef';

  constructor() { }

  getDetailsName(chef: StreamChefProfile | StagedChannel): string {
    switch (this.detailsNameType) {
      case 'chef':
        return chef.chefName;
      case 'channel':
        return chef.channel.name;
      default:
        return chef.chefName;
    }
  }
}
