import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  ConfirmDialogComponent,
  DialogData,
} from '@kitch/ui/components/confirm-dialog/confirm-dialog.component';

interface ConfirmDialogConfig {
  data?: DialogData;
  width?: string;
}

@Injectable()
export class ConfirmDialogService {
  private readonly defaultConfig: ConfirmDialogConfig = {
    width: '450px',
    data: { title: 'Are you sure?' },
  };

  constructor(private dialog: MatDialog) {}

  openDialog(config?: ConfirmDialogConfig): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      ...this.defaultConfig,
      ...config,
    });

    return dialogRef.afterClosed();
  }
}
