import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SUBSCRIPTIONS_URL } from '@kitch/data-access/constants';
import { CreditCard, SimpleResponse } from '@kitch/data-access/models';
import { BuySubscriptionPayload } from '@kitch/data-access/models/subscription';
import { ApiService } from '@kitch/data-access/services/api.service';

import { makeUri } from '@kitch/util';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
  constructor(
    private apiService: ApiService,
  ) { }

  subscribeToChef(profileId: string, cardId: string): Observable<CreditCard[]> {
    return this.apiService.post(makeUri(SUBSCRIPTIONS_URL, 'subscribe-to-chef'),
      { profileId, cardId });
  }

  subscribeToVideo(buySubscriptionPayload: BuySubscriptionPayload): Observable<CreditCard[]> {
    return this.apiService.post(makeUri(SUBSCRIPTIONS_URL, 'subscribe-to-video'), buySubscriptionPayload);
  }

  updatePlanPrice(subscriptionPlanId: string, price: number): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(SUBSCRIPTIONS_URL, 'plan', subscriptionPlanId), { price });
  }
}
