import { Injectable } from '@angular/core';
import isbot from 'isbot';
import { BehaviorSubject, Observable } from 'rxjs';
import { OLD_USER_KEY, StreamStatus } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';

@Injectable({ providedIn: 'root' })
export class FirstTimeUserSignupService {
  private delayBeforeShow = 0;
  private timeoutId: ReturnType<typeof setTimeout>;
  private canBeShowState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  streamStatusIsLive = false;

  get isFirstTimeVisiting(): boolean {
    return !localStorage.getItem(OLD_USER_KEY) && !isbot(navigator.userAgent);
  }

  set isFirstTimeVisiting(isFirstVisit: boolean) {
    if (isFirstVisit) {
      localStorage.removeItem(OLD_USER_KEY);
    } else {
      localStorage.setItem(OLD_USER_KEY, 'true');
    }
  }

  get signupCanBeShow$(): Observable<boolean> {
    return this.canBeShowState.asObservable();
  }

  set signupCanBeShow(state: boolean) {
    this.canBeShowState.next(state);
  }

  constructor(
    private tokenService: TokenService,
  ) {
    if (!this.tokenService.isGuest()) {
      this.isFirstTimeVisiting = false;
    }

    if (this.tokenService.isGuest() && this.isFirstTimeVisiting) {
      this.startTimer();
    }
  }

  showModal(): void {
    if (this.isFirstTimeVisiting) {
      clearTimeout(this.timeoutId);
      this.isFirstTimeVisiting = false;
      this.signupCanBeShow = true;
      // commented for ticket KB-1487
      // this.walmartModalService.isFirstTimeVisitModalActive = true;
    }
  }

  onStreamStatusUpdated(newStatus: StreamStatus, cappedStubStatus: boolean): void {
    if (!this.isFirstTimeVisiting && !cappedStubStatus) return;

    if (cappedStubStatus) {
      this.signupCanBeShow = true;
    }

    if (newStatus === StreamStatus.LIVE) {
      clearTimeout(this.timeoutId);
    }

    if (
      sessionStorage.getItem('STREAM_STATUS') === StreamStatus.LIVE &&
      newStatus === StreamStatus.PAST
    ) {
      this.showModal();
    }

    sessionStorage.setItem('STREAM_STATUS', newStatus);
    this.streamStatusIsLive = newStatus === StreamStatus.LIVE;
  }

  private startTimer(): void {
    this.timeoutId = setTimeout(() => {
      this.showModal();
    }, this.delayBeforeShow);
  }

  private stopTimer(): void {
    clearTimeout(this.timeoutId);
  }
}
