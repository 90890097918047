import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

import { ModalComponent } from '@kitch/ui/components';

export type UserAction = 'login' | 'signup';

@Component({
  selector: 'app-user-not-found-modal',
  templateUrl: './user-not-found-modal.component.html',
  styleUrls: ['./user-not-found-modal.component.scss'],
})
export class UserNotFoundModalComponent implements OnChanges {
  @ViewChild('userNotFoundModal', { static: false }) userNotFoundModal: ModalComponent;

  @Input() isOpen = false;

  @Output() isUserNotFoundModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() userAction: EventEmitter<UserAction> = new EventEmitter<UserAction>();

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.userNotFoundModal.open();
    } else {
      this.userNotFoundModal?.close();
    }
  }

  showSignUp(): void {
    this.isUserNotFoundModalOpen.emit(false);
    this.userAction.emit('signup');
    this.userNotFoundModal.close();
  }

  showLogin(): void {
    this.isUserNotFoundModalOpen.emit(false);
    this.userAction.emit('login');
    this.userNotFoundModal.close();
  }
}
