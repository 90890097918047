import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LeaveTheClub, Subscription } from '@kitch/data-access/models/subscription';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
})
export class SubscriptionCardComponent implements OnInit {
  @Output() unsubscribeData: EventEmitter<LeaveTheClub> = new EventEmitter<LeaveTheClub>();

  @Input() itsMe = true;
  @Input() subscription: Subscription;

  constructor() {}

  ngOnInit(): void {}

  toggleMore(el: HTMLDivElement): void {
    el.classList.contains('open') ? el.classList.remove('open') : el.classList.add('open');
  }

  emitDataEvent(profileId: string, chefName: string): void {
    this.unsubscribeData.emit({ profileId, chefName });
  }
}
