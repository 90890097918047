<section class="custom-button-section entity-form">
  <div class="entity-form__slide-toggle-container">
    <app-slide-toggle
      class="entity-form__slide-toggle"
      [isSelected]="isShowAddCustomButtons"
      (statusSlideInput)="showAddCustomButtons($event)"
    ></app-slide-toggle>
    <h3 class="entity-form__title">Add custom buttons</h3>
  </div>

  <p class="entity-form__subtitle">
    Add up to 3 buttons that will appear near your event description.
  </p>

  <div
    *ngIf="isShowAddCustomButtons || !isShowSlideToggle"
    class="custom-button-section__content"
  >
    <form [formGroup]="customButtonForm" class="custom-button-section__form">
      <div formArrayName="buttons">
        <div
          *ngFor="let button of customButtonControls; index as index"
          [formGroupName]="index"
          class="custom-button-section__content-row"
        >
          <h4
            class="custom-button-section__text custom-button-section__text--input-title"
          >Button {{ index + 1 }}</h4>
          <div class="custom-button-section__input-wrapper">
            <div class="custom-button-section__input-inner">
              <input
                formControlName="label"
                class="custom-button-section__input"
                id="custom-button-label-{{ index }}"
                placeholder="Label"
              />

              <app-errors-list
                class="errors"
                errorName="label"
                formControlName="label"
                inputType="TEXT"
              ></app-errors-list>
            </div>
            <div class="custom-button-section__input-inner">
              <input
                formControlName="url"
                class="custom-button-section__input"
                id="custom-button-url-{{ index }}"
                placeholder="URL"
              />

              <app-errors-list
                class="errors"
                errorName="url"
                formControlName="url"
                inputType="TEXT"
              ></app-errors-list>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
