<form class="register__form success-form">
  <div class="register__form-container"
    [class.not-approve-form]="isNotApprove">
    <ul>
      <li class="form__item form__item-success">

          <h2 class="register__title congrats__title">
            <img
              class="success-img"
              src="assets/ui/images/svg/cheers.svg"
              height="40"
              width="40"
              alt="cheers"
            />
            <span data-testid="modal-title" class="congrats__title-text">cheers!</span>
            <img
              class="success-img"
              src="assets/ui/images/svg/clap.svg"
              height="40"
              width="40"
              alt="clap"
            />
          </h2>
      </li>
      <li class="form__item form__item--text">
        <p class="success-text"
        [class.approve-text]="!isNotApprove"
        [class.not-approve-text]="isNotApprove">
          <ng-content select="[text]"></ng-content>
        </p>
      </li>
      <li class="form__item form__item--center form__item--button-success">
        <app-button
          data-testid="submit-btn"
          class="button-success"
          [text]="textButton"
          [type]="'submit'"
          [isLoginForm]="true"
          (clicked)="submit()">
        </app-button>
      </li>
    </ul>
  </div>
</form>
