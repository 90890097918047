import { AdminCheckboxFormComponent } from './admin-checkbox-form/admin-checkbox-form.component';
import { CustomButtonFormComponent } from './custom-button-form/custom-button-form.component';
import { MainStreamFormComponent } from './main-stream-form/main-stream-form.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { TagsComponent } from './tags/tags.component';
import { VideoChooserComponent } from './video-chooser/video-chooser.component';
import { VideoTimestampFormComponent } from './video-timestamp-form/video-timestamp-form.component';

export * from './custom-button-form/custom-button-form.component';
export * from './video-timestamp-form/video-timestamp-form.component';
export * from './product-form/product-form.component';
export * from './admin-checkbox-form/admin-checkbox-form.component';
export * from './tags/tags.component';
export * from './video-chooser/video-chooser.component';
export * from './main-stream-form/main-stream-form.component';

export const formsSharedComponents = [
  CustomButtonFormComponent,
  VideoTimestampFormComponent,
  ProductFormComponent,
  AdminCheckboxFormComponent,
  TagsComponent,
  VideoChooserComponent,
  MainStreamFormComponent,
];
