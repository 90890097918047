import { Role } from './user';

export enum TransactionEnum {
  DONATE = 'DONATE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TOPUP = 'TOPUP',
  WITHDRAW = 'WITHDRAW'
}

export interface Transaction {
  amount: number;
  club: Club | null;
  createdAt: string;
  currency: TransactionCurrency;
  giftReceiver?: GiftReceiver,
  id: string;
  isGift?: boolean;
  notes: string;
  receiver: ClamsTransactionResponseBody;
  source: ClamsTransactionResponseBody;
  type: TransactionEnum;
  video?: PaidVideo;
}

export type TransactionCurrency = 'USD' | 'CLAMS';

interface ClamsTransactionResponseBody {
  chefSlug: string;
  displayName: string;
  fullName?: string;
  id: string;
  photo: string;
  user: { role: Role };
}

export interface CardTransaction {
  amount: number;
  createdAt: string;
  id: string;
  receiptUrl: string;
  refunded: string;
  status: string;
  transaction: {
    amount: number;
    club: Club | null;
    giftReceiver?: GiftReceiver,
    isGift?: boolean;
    type: TransactionEnum;
    video?: PaidVideo;
  };
}

export interface PaidVideo {
  chefSlug: string;
  id: string;
  slug: string;
  title: string;
}

export interface UserNamesContext {
  displayName: string;
  fullName?: string;
  id: string;
  isGift?: boolean;
  receiverFirstName?: string;
  receiverLastName?: string;
  role: string;
  slug: string;
}

export interface VideoTemplateContext {
  club: Club | null;
  currency: string;
  fullName?: string;
  id: string;
  role: string;
  title: string;
  video: PaidVideo;
}

export interface GiftReceiver {
  receiverEmail: string | null;
  receiverFirstName: string | null;
  receiverLastName: string | null;
}

export interface Club {
  channelName: string;
  chefSlug: string;
  id: string;
  isClub: boolean;
}
