import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-user-complete-registration-modal',
  templateUrl: './user-complete-registration-modal.component.html',
  styleUrls: ['./user-complete-registration-modal.component.scss'],
})
export class UserCompleteRegistrationModalComponent implements OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() isOpen = false;

  @Output() isModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() openLoginForm: EventEmitter<void> = new EventEmitter<void>();

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.modal.open();
    } else {
      this.modal?.close();
    }
  }

  openLoginModal(): void {
    this.openLoginForm.emit();
    this.isModalOpen.emit(false);
  }
}
