<app-login-modal
  [isOpen]="isLoginModal"
  [userRole]="userRole"
  [pageView]="pageView"
  [source]="source"
  (isModal)="setIsLoginModal($event)"
  (openRegisterModal)="setIsRegisterModal($event)"
  (reloadPage)="reloadPage()">
</app-login-modal>

<app-register-modal
  [isOpen]="isRegisterModal"
  [inviteId]="inviteId"
  [refCode]="refCode"
  [pageView]="pageView"
  [source]="source"
  (isModal)="setIsRegisterModal($event)"
  (reloadPage)="reloadPage()"
  (openLoginModal)="setIsLoginModal($event)">
</app-register-modal>
