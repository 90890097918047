<div
  class="modal-background"
  [style]="{ minHeight: windowHeight + 'px' }"
  (click)="close()"
></div>

<ng-container *ngIf="!isStoreListShown; else storeList">
  <ng-container *ngTemplateOutlet="shoppingCart; context: persistedShoppingCartData"></ng-container>
</ng-container>

<ng-template
  #shoppingCart
  let-currentStore="currentStore"
  let-recipes="recipes"
>
  <div
    class="shopping-cart"
    [@shoppingCartTrigger]="isShown ? shoppingCartOpen : shoppingCartClose"
  >
    <header class="shopping-cart__header">
      <h5 class="shopping-cart__title">shopping cart</h5>
      <button
        class="modal-close-btn"
        (click)="close()"
        data-testid="modal-close-btn"
      >
        <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
        <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
      </button>
    </header>

    <main class="shopping-cart__main" [ngSwitch]="shoppingCartStatus">
      <ng-container *ngSwitchCase="ShoppingCartStatus.HAS_PRODUCTS">
        <button class="shopping-cart__store" (click)="showStoreList()">
          <img
            class="shopping-cart__store-image"
            src="assets/ui/images/svg/shopping-cart-location.svg"
            alt="pin"
            width="16"
            height="20"
          />
          <h5 class="shopping-cart__title">{{ currentStore?.accessPointName }} (change)</h5>
        </button>

        <div class="shopping-cart__recipe" *ngFor="let recipe of recipes">
          <h5 class="shopping-cart__title">{{ recipe.title }}</h5>
          <app-shopping-cart-product
            *ngFor="let product of recipe.products"
            [product]="product"
            [isShown]="isShown"
            (quantityChanged)="onQuantityChanged()"
          ></app-shopping-cart-product>
        </div>
      </ng-container>

      <div class="preloader" *ngSwitchCase="ShoppingCartStatus.LOADING">
        <app-preloader></app-preloader>
      </div>

      <div class="empty-state" *ngSwitchCase="ShoppingCartStatus.PRODUCTS_NOT_FOUND">
        <img
          class="empty-state__img"
          src="/assets/ui/images/svg/emty-state-picture.svg"
          alt="no products"
          width="130"
          height="130"
        />
        <h5 class="empty-state__text">
          We’re sorry. This product seems to be unavailable.
          When you checkout you’ll be able to manually add products to your cart on
          <a
            href="https://www.walmart.com/"
            target="_blank"
            rel="noopener"
          >Walmart.com</a>
        </h5>
      </div>

      <div class="empty-state" *ngSwitchCase="ShoppingCartStatus.STORE_NOT_FOUND">
        <img
          class="empty-state__img"
          src="/assets/ui/images/svg/emty-state-picture.svg"
          alt="no stores"
          width="130"
          height="130"
        />
        <h5 class="empty-state__text">
          Unfortunately, no stores were found in your area. But you can go to
          <a
            href="https://www.walmart.com/"
            target="_blank"
            rel="noopener"
          >Walmart.com</a>&nbsp;and shop directly there.
        </h5>
      </div>

      <div class="empty-state" *ngSwitchCase="ShoppingCartStatus.EMPTY">
        <img
          class="empty-state__img"
          src="/assets/ui/images/svg/emty-state-picture.svg"
          alt="no stores"
          width="130"
          height="130"
        />
        <h5 class="empty-state__text">
          Time to stock up!
        </h5>
      </div>
    </main>


    <footer class="shopping-cart__footer">
      <!-- SUMMARY-SECTIONS >> -->
      <div>
        <div
          class="shopping-cart__summary-section shopping-cart__summary-section--first"
          [ngClass]="{'shopping-cart__summary-section--without-discount': !discountIsAvailable}"
        >
          <!-- RECIPES, INGREDIENTS COUNT >> -->
          <div class="cart__summary__counters">
            <img
              src="assets/ui/images/svg/shopping-cart-recipes.svg"
              width="17"
              height="16"
              alt="recipes icon"
            />
            <span class="shopping-cart__recipes-number">
              {{ recipes?.length }} Recipe{{ recipes?.length > 1 ? 's' : '' }}
            </span>
            <img
              src="assets/ui/images/svg/shopping-cart-ingredients.svg"
              width="17"
              height="16"
              alt="ingredients icon"
            />
            <span class="shopping-cart__ingredients-number">
              {{ quantity }} Ingredient{{ quantity > 1 ? 's' : '' }}
            </span>
          </div>
          <!--<< RECIPES, INGREDIENTS COUNT -->

          <button
            class="shopping-cart__clear-cart"
            (click)="onClearCartClick()"
          >
            Clear Cart
          </button>

          <!-- TOTAL PRICE >> -->
          <span
            class="shopping-cart__price"
            [ngClass]="{'shopping-cart__price--without-discount': !discountIsAvailable}"
          >
            {{ totalPriceWithoutDiscount | customCurrency: 'USD'}}
          </span>
          <!-- TOTAL PRICE >> -->
        </div>
        <ng-container *ngIf="!isGuest && discountIsAvailable">
          <div class="shopping-cart__summary-section shopping-cart__summary-section--second">
            <span>After HOLIDAY10 savings*</span>
            <span class="shopping-cart__price">{{ totalPriceWithDiscount | customCurrency: 'USD' }}</span>
          </div>
        </ng-container>
      </div>
      <!-- << SUMMARY-SECTIONS -->

      <!-- NOTE-TOP >> -->
      <div
        class="
          shopping-cart__footer-item
          shopping-cart__footer-note
          shopping-cart__footer-note-top--guest-{{ isGuest }}
        "
      >
        <ng-container *ngIf="isGuest; else topNoteForAuthorized">
          <a
            class="note__sign-up-link"
            appNeedAuthorize
            [source]="'shopping cart'"
          >
            <strong>Sign up</strong>
          </a>
          for a free Kittch account and get
          <strong>$10 off your order.</strong>
        </ng-container>

        <ng-template #topNoteForAuthorized>
          <div>
            You can add or swap out any ingredients when you’re
            redirected to walmart.com to complete checkout.
          </div>
          <br>
          <strong>
            Use code HOLIDAY10 at checkout to get $10 off
            <br class="shopping-cart__footer-line-break">your order.
          </strong>
        </ng-template>
      </div>
      <!-- << NOTE-TOP -->

      <app-button
        [text]="isGuest ? 'Proceed to Checkout' : 'Checkout'"
        [type]="'button'"
        class="shopping-cart__footer-item shopping-cart__save-button"
        [isDisabled]="shoppingCartStatus !== ShoppingCartStatus.HAS_PRODUCTS"
        (clicked)="checkOut()"
      ></app-button>

      <!-- NOTE-BOTTOM >> -->
      <div
        class="
          shopping-cart__footer-item
          shopping-cart__footer-note
          shopping-cart__footer-note-bottom--guest-{{ isGuest }}
        "
      >
        <ng-container *ngIf="isGuest; else bottomNoteForAuthorized">
          You will be redirected to Walmart.com to complete your checkout. You can add or swap ingredients there.
        </ng-container>
        <ng-template #bottomNoteForAuthorized>
          *$50 minimum. Applies to first 3 orders. Other restrictions and fees may apply.
        </ng-template>
      </div>
      <!-- << NOTE-BOTTOM -->
    </footer>
  </div>
</ng-template>

<ng-template #storeList>
  <app-stores-list
    [stores]="persistedShoppingCartData.stores"
    [currentStore]="persistedShoppingCartData.currentStore"
    (closed)="closeStoreList()"
    (choseStore)="onChoseStore($event)"
  ></app-stores-list>
</ng-template>
