<div class="modal" [ngClass]="{ 'is-active': isActive }">
  <div class="modal-background" (click)="showCloseIcon && close()"></div>
  <div class="modal-card" >
    <header class="modal-card-head" [style.backgroundColor]="backgroundColor">
      <ng-content select="[header]"></ng-content>
      <button
        *ngIf="showCloseIcon"
        data-testid="modal-close-btn"
        class="modal-close-btn"
        aria-label="close"
        (click)="close()"
      >
        <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
        <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
      </button>
    </header>

    <section class="modal-card-body" [style.backgroundColor]="backgroundColor">
      <ng-content select="[body]"></ng-content>
    </section>
  </div>
</div>
