<div
  class="rating"
  [ngClass]="{'rating--recipe-card': source === 'recipe_card' }"
>
  <div class="rating__grade">
    <div
      class="rating__icon-container"
      (mouseover)="hoverOnRatingIconContainer()"
      (click)="clickOnRatingIconContainer($event)"
    >
      <ng-container *ngFor="let i of 5 | arrayCreator: 1">
        <ng-container
          [ngTemplateOutlet]="(showHalfRatingIcon && i === (fullRatingIconCount + 1)) ? halfRatingIcon : fullRatingIcon"
          [ngTemplateOutletContext]="{ fill: i <= fullRatingIconCount }"
        ></ng-container>
      </ng-container>
    </div>

    <ng-container
      *ngIf="showingRatingModal"
      [ngTemplateOutlet]="ratingModal"
    ></ng-container>
  </div>

  <span *ngIf="rating" class="rating__average">{{ rating }}</span>

  <span *ngIf="quantity > 2" class="rating__quantity">({{ quantity }})</span>
</div>

<ng-template #ratingModal>
  <div class="rating__modal" [ngClass]="{'rating__modal--selected': selectedRating}" (mouseleave)="closeRatingModal()">
    <div class="rating__modal-close">
      <button class="modal-close-btn" (click)="closeRatingModal()">
        <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
        <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
      </button>
    </div>
    <h2 class="rating__modal-title">Did you make this?</h2>

    <!--DESKTOP CONTENT >>-->
    <div class="rating__modal-content rating__modal-content--desktop">
      <span
        class="rating__prompt"
        [ngClass]="{'rating__prompt--initially': !(hoverRating || selectedRating)}"
      >
        {{ (hoverRating || selectedRating) | ratingDescription }}
      </span>

      <div class="rating__icon-container" (mouseleave)="onStopHover()">
        <div
          *ngFor="let i of 5 | arrayCreator: 1"
          (mouseenter)="onIconHover(i)"
          (click)="select(i)"
        >
          <ng-container
            [ngTemplateOutlet]="fullRatingBigIcon"
            [ngTemplateOutletContext]="{ fill: i <= (hoverRating || selectedRating) }"
          ></ng-container>
        </div>
      </div>
    </div>
    <!-- << DESKTOP CONTENT-->

    <!--MOBILE CONTENT >>-->
    <div class="rating__modal-content rating__modal-content--mobile">
      <span class="rating__prompt">Rate this recipe.</span>
      <div
        *ngFor="let i of 5 | arrayCreator: 1"
        (click)="select(i)"
        class="rating__modal-row"
        [ngClass]="{ 'rating__modal-row--selected': selectedRating === i }"
      >
        <div class="rating__icon-container">
          <ng-container
            *ngFor="let icon of i | arrayCreator"
            [ngTemplateOutlet]="fullRatingIcon"
            [ngTemplateOutletContext]="{ fill: true }"
          ></ng-container>
        </div>

        <span [ngSwitch]="rating" class="rating__description">{{ i | ratingDescription }}</span>
      </div>
    </div>
    <!-- << MOBILE CONTENT-->

    <ng-container *ngIf="selectedRating">
      <span class="rating__comment">Have any comments, tips or substitutions for this recipe? (optional)</span>

      <app-button class="rating__leave-comment" text="leave a comment" (clicked)="leaveComment()"></app-button>
    </ng-container>
  </div>
</ng-template>

<ng-template #fullRatingIcon let-fill="fill">
  <svg
    class="rating__icon"
    [ngClass]="{ 'rating__icon--full': fill }"
    height="18"
    width="12"
  >
    <use href="assets/ui/images/svg/sprites.svg#rating"></use>
  </svg>
</ng-template>

<ng-template #fullRatingBigIcon let-fill="fill">
  <svg
    class="rating__icon"
    [ngClass]="{ 'rating__icon--full': fill }"
    height="24"
    width="16"
  >
    <use href="assets/ui/images/svg/sprites.svg#rating-big"></use>
  </svg>
</ng-template>

<ng-template #halfRatingIcon>
<!--  NOTE: urls inside svg sprite not working when svg is in another file-->
  <svg height="0" width="0" style="position: absolute" aria-hidden="true">
    <defs>
      <clipPath id="clip0_14956_93449" >
        <rect width="5" height="18" fill="white"/>
      </clipPath>
    </defs>

    <symbol id="rating-half" width="12" height="18" viewBox="0 0 12 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.77341 1.81534C6.34588 2.50693 6.84642 3.4028 7.23975 4.40391C7.86466 5.99447 8.15402 7.68677 8.08363 8.89474C8.06177 9.26986 8.26966 9.62075 8.60917 9.78177C8.94869 9.9428 9.35194 9.88176 9.6286 9.62748L9.00443 8.94839C9.6286 9.62748 9.62881 9.62729 9.62903 9.62708L9.62949 9.62666L9.63051 9.62572L9.63287 9.62353L9.63894 9.61786L9.65626 9.60144C9.67002 9.58826 9.68815 9.5706 9.71001 9.54868C9.75367 9.5049 9.81259 9.44373 9.88156 9.36685C10.0187 9.21399 10.2 8.99409 10.3808 8.72069C10.4716 8.58344 10.5661 8.42689 10.6559 8.2535C11.0405 9.2253 11.0642 10.177 11.0767 10.6814C11.077 10.6942 11.0773 10.7066 11.0776 10.7188C11.0773 11.9646 11.0297 13.0091 10.7156 13.9421C10.4141 14.8378 9.8488 15.6779 8.70544 16.4543C8.25841 16.7579 7.3652 17.0393 6.30669 17.07C5.26244 17.1003 4.21514 16.8812 3.43901 16.3598C1.49629 15.0547 0.92236 13.0492 0.92236 10.689C0.92236 8.64642 2.14844 6.99024 3.50807 5.31397C3.57169 5.23554 3.6359 5.15667 3.70042 5.07743C4.28875 4.35487 4.90236 3.60126 5.32 2.86076C5.50448 2.53368 5.66566 2.18377 5.77341 1.81534Z" stroke="currentColor" stroke-width="1.84472" stroke-linejoin="round"/>
      <g clip-path="url(#clip0_14956_93449)">
        <path d="M5.77343 1.81569C6.34587 2.50747 6.8464 3.40357 7.23972 4.40493C7.86464 5.99596 8.15402 7.68879 8.08363 8.89717C8.06178 9.27232 8.2697 9.62321 8.60924 9.78422C8.94878 9.94523 9.35205 9.88415 9.62869 9.62982L9.00443 8.95081C9.62869 9.62982 9.6289 9.62962 9.62912 9.62942L9.62958 9.62899L9.6306 9.62806L9.63296 9.62587L9.63904 9.6202L9.65635 9.60377C9.67011 9.59059 9.68824 9.57293 9.7101 9.551C9.75375 9.50721 9.81268 9.44602 9.88165 9.36912C10.0188 9.21623 10.2 8.99627 10.3809 8.72281C10.4716 8.58555 10.5661 8.42901 10.6559 8.25563C11.0405 9.22774 11.0642 10.1798 11.0767 10.6843C11.077 10.697 11.0773 10.7095 11.0776 10.7217C11.0773 11.9678 11.0297 13.0127 10.7156 13.9461C10.414 14.842 9.8487 15.6824 8.70534 16.4591C8.25833 16.7627 7.36515 17.0442 6.30668 17.0749C5.26248 17.1052 4.21522 16.886 3.43911 16.3645C1.49634 15.059 0.92236 13.0528 0.92236 10.6919C0.92236 8.64857 2.14855 6.99184 3.50815 5.31516C3.57177 5.2367 3.63598 5.15782 3.70049 5.07856C4.28883 4.35579 4.90242 3.60199 5.32006 2.86132C5.50453 2.53416 5.66569 2.18418 5.77343 1.81569Z" fill="#FFB36D" stroke="#FFB36D" stroke-width="1.84472" stroke-linejoin="round"/>
      </g>
    </symbol>
  </svg>

  <svg
    class="rating__icon rating__icon--half"
    height="18"
    width="12"
  >
    <use href="#rating-half"></use>
  </svg>
</ng-template>
