import { Component, Input, OnInit } from '@angular/core';
import { CardTransaction, Club, GiftReceiver, PaidVideo } from '@kitch/data-access/models';

@Component({
  selector: 'app-credit-card-transaction',
  templateUrl: './credit-card-transaction.component.html',
  styleUrls: ['./credit-card-transaction.component.scss'],
})
export class CreditCardTransactionComponent implements OnInit {
  @Input() transaction: CardTransaction;

  isGift: boolean;
  clubData: Club;
  paidStream: PaidVideo;
  giftReceiver: GiftReceiver;

  ngOnInit(): void {
    this.isGift = this.transaction.transaction.isGift;
    this.clubData = this.transaction.transaction.club;
    this.paidStream = this.transaction.transaction.video;
    this.giftReceiver = this.transaction.transaction.giftReceiver;
  }
}
