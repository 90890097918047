import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SEARCH_URL, SEARCH_PAGINATION_URL, DEFAULT_ITEMS_PER_PAGE } from '@kitch/data-access/constants';
import { SearchResource, SearchResourceEnum, SearchResult, SearchResults } from '@kitch/data-access/models/search';
import { SearchParams } from '@kitch/data-access/models/search-params/search';

import { makeUri } from '@kitch/util';

import { ApiService } from './api.service';

@Injectable()
export class SearchService {
  searchResult: SearchResult;
  searchQuery: string;
  searchParams: SearchParams;

  constructor(private apiService: ApiService) {}

  getAll(query: string): Observable<SearchResult> {
    this.searchQuery = query;
    this.searchParams = {
      query,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    };

    return this.apiService.get(makeUri(SEARCH_URL), this.searchParams)
      .pipe(
        tap((result: SearchResult) => {
          this.searchResult = result;
        }),
      );
  }

  getResourceResults(resource: SearchResourceEnum): SearchResource[] {
    return this.searchResult[resource].results;
  }

  getTotalPage(resource: SearchResourceEnum): number {
    return this.searchResult[resource].pageCount;
  }

  getResourceNextPage(resource: SearchResourceEnum, pageNumber: number): Observable<SearchResource[]> {
    return this.apiService.get(
      makeUri(SEARCH_PAGINATION_URL), {
        page: pageNumber,
        resource: resource,
        ...this.searchParams,
      })
      .pipe(
        map((result: SearchResults) => result.streams?.results || result.channels?.results ||
          result.recipes?.results,
        ),
      );
  }

  getSearchQuery(): string {
    return this.searchQuery;
  }
}
