<div class="subscription__wrap {{ !subscription.recurring ? 'subscription__wrap-canceled' : '' }}">
  <ng-container *ngIf="itsMe">
    <div *ngIf="subscription.recurring" class="subscription__control" (click)="toggleMore(unsubscribe)">
      <img
        src="assets/ui/images/svg/more-vertical.svg"
        alt="more vertical"
        width="24"
        height="24"
      >
    </div>
    <div
      #unsubscribe
      class="subscription__leave-btn"
      (click)="emitDataEvent(subscription.resourceId, subscription.subscriptionData.profile.chefName)">
      Leave Cooking Club
    </div>
  </ng-container>
  <div class="subscription__info">
    <a [routerLink]="'/' + subscription.subscriptionData.slug">
      <app-avatar
        [url]="subscription.subscriptionData.profile.photo"
        [size]="'small'"
        [name]="subscription.subscriptionData.name + '/' + subscription.subscriptionData.profile.chefName"
        [title]="'You are subscribed to ' + subscription.subscriptionData.profile.chefName +'’s Cooking Club'"
      ></app-avatar>
    </a>
  </div>
  <div class="subscription__canceled" *ngIf="!subscription.recurring">
    <div class="main-cancel-msg">
      <img
        class="logo-img"
        src="assets/ui/images/svg/alert-stroke.svg"
        width="18"
        height="18"
        alt="Kittch"
      />
      <p>Subscription has been cancelled</p>
    </div>

    <p class="subscription-expired-date">Your monthly subscription is valid until {{ subscription.expiredAt | date }}</p>
  </div>
</div>
