import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaderResponse,
  HttpInterceptor, HttpProgressEvent,
  HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';

import {
  AUTH_TOKEN_EXPIRED,
  FORBIDDEN,
  GENERAL_ERROR_NOTIFICATION,
  NEED_APPROVE_ERROR,
  NOT_FOUND_USER_ERROR,
  NO_INTERNET_CONNECTION,
  REFRESH_TOKEN_URL,
  INVALID_REF_CODE_ERROR,
  SIGN_UP_URL,
} from '@kitch/data-access/constants';
import { environment } from '@kitch/data-access/env/environment';
import { RefreshTokenResponse, ServerError } from '@kitch/data-access/models';
import { AuthService } from '@kitch/data-access/services';

import { AlertService } from '@kitch/ui/services';

@UntilDestroy()
@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<ServerError>> {
    return next.handle(req).pipe(
      catchError((httpError: HttpErrorResponse) => {
        let error: ServerError;

        if (req.url.includes(environment.awsUrl) && req.method === 'PUT') {
          error = this.getImageUploadError();
        } else {
          error = this.getParsedError(httpError);
        }

        if (this.needShowAlert(req.url, error)) {
          this.alertService.error(error.message);
        }

        if (error.statusCode === AUTH_TOKEN_EXPIRED) {
          return req.url.includes(REFRESH_TOKEN_URL)
            ? this.logoutUser(error.message)
            : this.refreshToken(req, next);
        }

        return throwError(error);
      }),
      untilDestroyed(this),
    );
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((resp: RefreshTokenResponse) => {
          if (resp && resp.token) {
            this.authService.startSession();
            this.tokenSubject.next(resp.token);

            return next.handle(this.addAuthToken(req, resp.token));
          } else {
            return this.logoutUser('Session was expired');
          }
        }),
        catchError(() => this.logoutUser('Session was expired')),
        finalize(() => (this.isRefreshingToken = false)),
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => next.handle(this.addAuthToken(req, token))),
        untilDestroyed(this),
      );
    }
  }

  private addAuthToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private getParsedError(httpError: HttpErrorResponse): ServerError {
    if (isPlatformBrowser(this.platformId) && !navigator.onLine) {
      return {
        statusCode: NO_INTERNET_CONNECTION,
        message: 'Check your internet connection, please',
      };
    } else if (httpError instanceof HttpErrorResponse && httpError.error) {
      return httpError.error;
    }

    return {
      statusCode: GENERAL_ERROR_NOTIFICATION,
      message: 'Something went wrong!',
    };
  }

  private getImageUploadError(): ServerError {
    return {
      statusCode: GENERAL_ERROR_NOTIFICATION,
      message: 'The image hasn\'t been uploaded',
    };
  }

  private needShowAlert(url: string, error: ServerError): boolean {
    return !this.isErrorInModal(error.statusCode, error.message) &&
            !this.isSilentError(error.statusCode) &&
            !this.isSilentErrorMessage(url, error.message) &&
            !this.isSilentUrl(url) &&
            !this.needCustomHandle(error);
  }

  private isSilentError(errorCode: number): boolean {
    return [AUTH_TOKEN_EXPIRED].includes(errorCode);
  }

  private isSilentErrorMessage(url: string, message: string): boolean {
    if (message) {
      return (message.includes('phone_number') && url.includes(SIGN_UP_URL)) ||
        message.includes(NOT_FOUND_USER_ERROR) ||
        message.includes(INVALID_REF_CODE_ERROR);
    } else {
      return true;
    }
  }

  private isErrorInModal(errorCode: number, errorMsg: string): boolean {
    return [FORBIDDEN].includes(errorCode) && [NEED_APPROVE_ERROR].includes(errorMsg);
  }

  private isSilentUrl(url: string): boolean {
    return [
      `${environment.apiUrl}/${REFRESH_TOKEN_URL}`,
    ].includes(url);
  }

  private needCustomHandle(error: ServerError): boolean {
    return error.statusCode === FORBIDDEN && error.message === 'The stream is available only for guests';
  }

  private logoutUser(errorMessage?: string): Observable<any> {
    if (this.authService.isUserApplication) {
      return this.authService.loginAsGuest().pipe(tap(() => location.reload()));
    } else {
      this.authService.stopSession(errorMessage);

      return throwError('');
    }
  }
}
