import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-entrance-questions',
  templateUrl: './entrance-questions.component.html',
  styleUrls: ['./entrance-questions.component.scss'],
})
export class EntranceQuestionsComponent {
  @Input()
  readonly isPhoneShown: boolean;
}
