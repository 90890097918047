import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-register-step-page',
  templateUrl: './register-step-page.component.html',
  styleUrls: ['./register-step-page.component.scss'],
})
export class RegisterStepPageComponent {
  @Input() isNotApprove: boolean;
  @Input() textButton: string;

  @Output() openLoginForm: EventEmitter<void> = new EventEmitter<void>();

  submit(): void {
    this.openLoginForm.emit();
  }
}
