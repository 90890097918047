import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SocketEventMessage } from '@kitch/data-access/models';
import { WebsocketService } from '@kitch/data-access/services/websocket.service';

@Injectable()
export class ChatService {
  private messages: Observable<SocketEventMessage>;

  constructor(private wsService: WebsocketService) {
  }

  get messages$(): Observable<SocketEventMessage> {
    if (!this.messages) {
      this.connect();
    }

    return this.messages.pipe(
      finalize(() => this.disconnect()),
    );
  }

  sendMassage(message: SocketEventMessage): void {
    this.wsService.sendMassage(message);
  }

  connect(): void {
    this.messages = this.wsService.connect();
  }

  disconnect(): void {
    this.wsService.disconnect();
    this.messages = null;
  }
}
