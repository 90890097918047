<app-modal #userAlreadySignupModal [showCloseIcon]="false">
  <ng-container header>
    <h2 data-testid="modal-title" class="modal-title">Whoops, you’re already signed up!</h2>
  </ng-container>
  <ng-container body>
    <div class="user-exists-modal">
      <p data-testid="modal-message">
        Looks like there’s already an account with these credentials. Sign in below.
      </p>
    </div>
    <div class="user-exists-actions form__item form__item--center">
      <div class="form-button form-button--close">
        <app-button
          data-testid="sign-in-btn"
          [text]="'Sign in'"
          (clicked)="redirectToLoginPage()">
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
