import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-link-back',
  templateUrl: './link-back.component.html',
  styleUrls: ['./link-back.component.scss'],
})
export class LinkBackComponent {
  constructor(private location: Location) {}

  back(): void {
    this.location.back();
  }
}
