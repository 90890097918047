// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';
import { Environment } from '@kitch/data-access/env/interface';

const apiUrl = 'https://api.kitchstaging.com/api/v1';

/**
 * Environment used for staging.
 */
export const environment: Environment = {
  name: 'staging',
  production: false,
  apiUrl,
  wsUrl: 'wss://api.kitchstaging.com/api/v1/socket',
  awsUrl: 'kittch-stage.s3.us-east-1.amazonaws.com',
  gaCode: 'G-4KFCBF50JK',
  enableSentryLogs: true,
  sentryDns: 'https://7bd4d248132a411a817561b4417b284f@glitchtip.mocintra.com/8',
  ssoMetaClientId: '1602255500254581',
  ssoGoogleClientId: '532638032729-d66l1ik20964dcg6df9jb21t96fr49ua.apps.googleusercontent.com',
  captchaSiteKey: '6LcnaSwkAAAAANpiHCoTsQ77SETZuPxhQY2fz4OO',
  logging: {
    level: NgxLoggerLevel.TRACE,
    enableSourceMaps: true,
    disableConsoleLogging: false,
    timestampFormat: 'short',
    colorScheme: ['blue', 'blue', 'blue', '#b79f00', 'red', 'red', 'red'],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
