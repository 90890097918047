import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-views-counter',
  templateUrl: './views-counter.component.html',
  styleUrls: ['./views-counter.component.scss'],
})
export class ViewsCounterComponent {
  @Input() count = 0;
}
