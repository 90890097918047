import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SHARE_BUTTONS, ShareService } from 'ngx-sharebuttons';
import { TokenService } from '@kitch/data-access/services';
import { ModalComponent } from '@kitch/ui/components';
import { MediaShare } from '@kitch/ui/models';
import { MetaData, SeoService } from '@kitch/ui/services';

@Component({
  selector: 'app-invite-code-modal',
  templateUrl: './invite-code-modal.component.html',
  styleUrls: ['./invite-code-modal.component.scss'],
})

export class InviteCodeModalComponent extends MediaShare implements OnInit, OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() refCode = 'vet123';

  @Input() isOpen = false;

  @Output() modalOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  socialMedia = [
    'facebook',
    'whatsapp',
    'sms',
    'email',
    'link',
  ];

  shareUrl: string;
  description: string;
  savedMetaTags: MetaData;

  constructor(
    private share: ShareService,
    protected $gaService: GoogleAnalyticsService,
    protected tokenService: TokenService,
    private seoService: SeoService,
  ) {
    super(
      $gaService,
      tokenService,
    );
  }

  ngOnInit(): void {
    this.shareUrl = `${window.location.origin}/?refCode=${this.refCode}`;
    this.description = `Join me on Kittch by creating a free account using my invite code: ${this.shareUrl}\n#kittch`;
    this.addCustomCopyButtons();
  }

  ngOnChanges(ch): void {
    if (ch.isOpen?.currentValue) {
      this.modal.open();
      this.savedMetaTags = this.seoService.getCurrentTags();
      this.seoService.updateTags({ description: this.description });
      this.addCustomCopyButtons();
    } else {
      this.modal?.close();
    }
  }

  closeModal(): void {
    this.modalOpenChanged.emit(false);
    this.seoService.updateTags(this.savedMetaTags);
  }

  copyToClipboardRefCode(): void {
    const el = document.createElement('textarea');

    el.value = this.refCode;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  private addCustomCopyButtons(): void {
    this.share.addButton('sms', {
      ...SHARE_BUTTONS.sms,
      paramsFunc: { description: () => this.description },
    });
    this.share.addButton('email', {
      ...SHARE_BUTTONS.email,
      paramsFunc: { description: () => this.description },
    });
    this.share.addButton('link', {
      ...SHARE_BUTTONS.copy,
      icon: ['fas', 'copy'],
      data: { ...SHARE_BUTTONS.copy.data, icon: ['fas', 'copy'] },
      func: (args) => this.copyToClipboard(args, args.params.url, 'link'),
    });
  }
}
