<section class="product-section entity-form">
  <div class="entity-form__slide-toggle-container">
    <app-slide-toggle
      *ngIf="isShowSlideToggle"
      class="entity-form__slide-toggle"
      [isSelected]="isShowAddProductButtons"
      (statusSlideInput)="showAddProductsButtons($event)"
    ></app-slide-toggle>
    <h3 class="entity-form__title">Add products</h3>
  </div>

  <p class="entity-form__subtitle">
    Choose up to 3 products to appear on the “shop” tab of your stream page.
  </p>

  <div
    *ngIf="isShowAddProductButtons || !isShowSlideToggle"
    class="product-section__content"
  >
    <form [formGroup]="productForm" class="form">
      <div formArrayName="products">
        <div
          *ngFor="let product of productControls; index as index"
          [formGroupName]="index"
          class="form__item item"
        >
          <div class="product-section__content-column">
            <div class="item__label">
              <h4 class="product-section__text product-section__text--input-title">Label</h4>
              <div class="item__input-wrap">
                <input
                  formControlName="label"
                  class="form-input form-input--custom-button"
                  id="product-label-{{ index }}"
                  placeholder="Butcher Knife"
                />

                <app-errors-list
                  class="errors"
                  errorName="label"
                  formControlName="label"
                  inputType="TEXT"
                ></app-errors-list>
              </div>
            </div>

            <div class="item__url">
              <h4 class="product-section__text product-section__text--input-title">URL</h4>
              <div class="item__input-wrap">
                <input
                  formControlName="url"
                  class="form-input form-input--custom-button"
                  id="product-url-{{ index }}"
                  placeholder="URL"
                />

                <app-errors-list
                  class="errors"
                  errorName="url"
                  formControlName="url"
                  inputType="TEXT"
                ></app-errors-list>
              </div>
            </div>
          </div>

          <div class="item__price">
            <h4 class="product-section__text product-section__text--input-title">Price (optional)</h4>
            <div class="product-section__content-row">
              <p class="product-section__currency-text">$</p>
              <input
                formControlName="price"
                class="form-input form-input--price"
                id="product-price-{{ index }}"
                placeholder="20.50"
                type="text"
                appNumberFormInput
              />
            </div>
          </div>

          <div class="item__image">
            <h4 class="product-section__text product-section__text--input-title">Image (optional)</h4>
            <app-image-chooser
              [showDescription]="false"
              [imageAlt]="'product thumbnail'"
              [hasDeleteBtn]="false"
              (click)="removeProductImage(index)"
              [imageType]="'stream'"
              formControlName="image"
              [isAdmin]="true"
            ></app-image-chooser>
          </div>

          <button
            class="product-section__text product-section__remove-product-button"
            (click)="removeProduct(index)"
          >
            <svg class="product-section__button-icon">
              <use href="assets/ui/images/svg/sprites.svg#basket-icon"></use>
            </svg>
            Delete
          </button>
        </div>
      </div>
    </form>

    <app-button
      *ngIf="productsFormArray.length < 3"
      class="product-section__add-product-button"
      text="Add Product"
      iconUrl="assets/ui/images/svg/plus.svg"
      [iconHeight]="24"
      [iconWidth]="24"
      role="secondary"
      (clicked)="addProduct()"
    ></app-button>
  </div>
</section>
