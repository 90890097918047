import { DialogRef } from '@angular/cdk/dialog';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { TypeformGAEvents } from '@kitch/data-access/constants';
import { UserAccount } from '@kitch/data-access/models';
import { SettingsStatus } from '@kitch/data-access/models/settings';
import { SettingsService, TokenService, UserService } from '@kitch/data-access/services';
import { ModalService } from '@kitch/user/core/modal.service';
import { MAX_NUMBER_TYPEFORM_VIEWS } from '@kitch/user/shared/constants/modal';

@UntilDestroy()
@Component({
  selector: 'app-typeform-modal',
  templateUrl: './typeform-modal.component.html',
  styleUrls: ['../modals.scss', './typeform-modal.component.scss'],
})
export class TypeformModalComponent implements OnInit, OnDestroy {
  profileId: string;
  userAccount: UserAccount;
  private userId: string;
  private settingsStatus: SettingsStatus;

  constructor(
    private dialogRef: DialogRef,
    private $gaService: GoogleAnalyticsService,
    private modalService: ModalService,
    private tokenService: TokenService,
    private userService: UserService,
    private settingsService: SettingsService,
    @Inject(DOCUMENT) protected document: Document,
  ) {}

  ngOnInit() {
    this.profileId = this.tokenService.getProfileId();
    this.userId = this.tokenService.getIdFromToken();
    this.settingsService.settingStatus$
      .pipe(
        tap((settingsData) => this.settingsStatus = settingsData),
        mergeMap(() => this.getUserData()),
        untilDestroyed(this),
      )
      .subscribe(() => this.initTypeform());
  }

  ngOnDestroy(): void {}

  close(): void {
    this.dialogRef.close();
  }

  onShowTypeform(): void {
    this.close();
    this.updateTypeformStatus();
  }

  private updateTypeformStatus(): void {
    const numberTypeformViews = this.settingsStatus.numberTypeformViews + 1;
    const isTypeformModalCanBeShow = !this.settingsStatus.isTypeformFilled &&
      numberTypeformViews < MAX_NUMBER_TYPEFORM_VIEWS;

    this.settingsService.updateTypeformStatus(this.profileId, {
      numberTypeformViews,
    })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.settingsService.changeSettingsStatus({
          ...this.settingsStatus, numberTypeformViews, isTypeformModalCanBeShow,
        });
        this.sendGAEvent();
      });
  }

  private initTypeform(): void {
    const script = this.document.createElement('script');

    script.src = '//embed.typeform.com/next/embed.js';
    this.document.body.appendChild(script);

    window.addEventListener('message', (event) => {
      if (event.origin === 'https://form.typeform.com') {
        if (event.data.type === 'form-submit') {
          this.settingsService.changeSettingsStatus({
            ...this.settingsStatus, isTypeformFilled: true, isTypeformModalCanBeShow: false,
          });
        }
      }
    });
  }

  private sendGAEvent(): void {
    const typeformGAEvent = this.settingsStatus.numberTypeformViews < 1 ?
      TypeformGAEvents.LETS_GET_STARTED_UCPBUTTON_CLICK_FIRST_TIME :
      TypeformGAEvents.LETS_GET_STARTED_UCPBUTTON_CLICK_SECOND_TIME;

    this.$gaService.gtag('event', typeformGAEvent, {
      profile_id: this.tokenService.getProfileId(),
    });
  }

  private getUserData(): Observable<UserAccount> {
    return this.userService.getUserById(this.userId).pipe(
      tap((userAccount) => this.userAccount = userAccount),
      untilDestroyed(this),
    );
  }
}
