import { FormControl } from '@angular/forms';
import { ObjectWithArraysProps } from '@kitch/util';

export interface Tags extends ObjectWithArraysProps{
  diet: Tag[];
  difficulty: Tag[];
  equipment: Tag[];
  free: Tag[];
  lifestyle: Tag[];
  mealCategory: Tag[];
  mealType: Tag[];
  spice: Tag[];
  style: Tag[];
}

export interface TagsIds {
  diet: string[];
  difficulty: string[];
  equipment: string[];
  free: string[];
  lifestyle: string[];
  mealCategory: string[];
  mealType: string[];
  spice: string[];
  style: string[];
}

export type SpecialtyTags = Omit<Tags, 'difficulty' | 'spice'>
export type SpecialtyTagsIds = Omit<TagsIds, 'difficulty' | 'spice'>

export interface Tag {
  id: string;
  name: string;
  type: TagsType;
}

export interface FreeTag {
  id?: string;
  name: string;
  type: TagsType;
}

export enum TagsType {
  diet = 'DIET',
  difficulty = 'DIFFICULTY',
  equipment = 'EQUIPMENT',
  free = 'FREE',
  lifestyle = 'LIFESTYLE',
  mealCategory = 'MEAL_CATEGORY',
  mealType = 'MEAL_TYPE',
  spice = 'SPICE',
  style = 'STYLE',
}

export enum TagsTypeLowercase {
  diet = 'diet',
  difficulty = 'difficulty',
  equipment = 'equipment',
  free = 'free',
  lifestyle = 'lifestyle',
  mealCategory = 'mealCategory',
  mealType = 'mealType',
  spice = 'spice',
  style = 'style',
}

export enum TagRenderNames {
  diet = 'diet',
  difficulty = 'difficulty',
  equipment = 'equipment',
  free = 'free tag',
  lifestyle = 'lifestyle/occasions',
  mealCategory = 'meal category',
  mealType = 'meal type',
  spice = 'spice',
  style = 'style',
}

export enum TagRenderPlaceholder {
  diet = 'e.g. Keto, Paleo, Vegan',
  equipment = 'e.g. Instapot, Grill, Air Fryer',
  free = 'Add whatever tags you like here',
  lifestyle = 'e.g. Kid Friendly, Weeknight, Entertainment',
  mealCategory = 'e.g. Beverage, Salad, Soup',
  mealType = 'e.g. Breakfast, Dinner, Snack',
}

export interface TagsForm {
  diet: FormControl<Tag[]>;
  equipment: FormControl<Tag[]>;
  free: FormControl<Tag[]>;
  lifestyle: FormControl<Tag[]>;
  mealCategory: FormControl<Tag[]>;
  mealType: FormControl<Tag[]>;
  style: FormControl<Tag>
}

export const EMPTY_SPECIALTY_TAGS_IDS: SpecialtyTagsIds = {
  diet: [],
  equipment: [],
  free: [],
  lifestyle: [],
  mealCategory: [],
  mealType: [],
  style: [],
};

export const EMPTY_STYLE_TAG: Tag = {
  id: '',
  name: '',
  type: TagsType.style,
};

export interface MostPopularFilters {
  cuisines: MostPopularItem[];
  subCuisines?: MostPopularItem[];
  tags?: {
    diet: MostPopularItem[];
    lifestyle: MostPopularItem[];
    mealCategory: MostPopularItem[];
    mealType: MostPopularItem[];
  };
}

export interface MostPopularItem {
  count: number;
  id: string;
  name: string;
}
