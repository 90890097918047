import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-full-stream-table-modal',
  templateUrl: './full-stream-table-modal.component.html',
  styleUrls: ['./full-stream-table-modal.component.scss'],
})
export class FullStreamTableModalComponent implements OnChanges {
  @ViewChild('fullTableModal') fullTableModal: ModalComponent;

  @Input() isOpen = false;

  @Output() isFullTableModalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.fullTableModal.open();
    } else {
      this.fullTableModal?.close();
    }
  }

  closeModal(): void {
    this.isFullTableModalOpened.emit(false);
  }
}
