import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FOLLOWS_URL } from '@kitch/data-access/constants';
import { AppPagesItem, BlockFollower, Followers, SimpleResponse } from '@kitch/data-access/models';
import { FollowSearchParams } from '@kitch/data-access/models/search-params/follow';

import { makeUri } from '@kitch/util';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class FollowsService {
  constructor(private apiService: ApiService) {}

  follow(followingId: string): Observable<SimpleResponse> {
    return this.apiService.post(FOLLOWS_URL, { followingId });
  }

  unfollow(followingId: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(FOLLOWS_URL, followingId));
  }

  getFollowing(searchParams: FollowSearchParams): Observable<AppPagesItem<Followers>> {
    return this.apiService.get(makeUri(FOLLOWS_URL, 'following'), searchParams);
  }

  getFollowers(searchParams: FollowSearchParams): Observable<AppPagesItem<Followers>> {
    return this.apiService.get(makeUri(FOLLOWS_URL, 'followers'), searchParams);
  }

  blockFollower(params: BlockFollower): Observable<SimpleResponse> {
    return this.apiService.patch(makeUri(FOLLOWS_URL, 'block-follower'), params);
  }
}
