export enum WithdrawalsRequest {
  CANCELED= 'CANCELED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW_REQUEST = 'NEW_REQUEST'
}

export interface Withdrawal {
  clams: number,
  createdAt: Date,
  id: string,
  source: {
    chefName: string,
    chefSlug: string,
    id: string
  },
  status: WithdrawalsRequest,
  updatedAt: Date,
  updatedBy: {
    email: string,
    id: string
  }
}

export interface WithdrawCreate {
  transactionId: string;
  withdrawalId: string;
}
