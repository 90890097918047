import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { ModalType } from '@kitch/user/shared/models/modal';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly defaultModalConfig = {
    backdropClass: 'modal-sdk-backdrop',
    panelClass: 'modal-sdk',
    maxWidth: '90vw',
    data: {
      modalService: this,
    },
  };

  constructor(private dialog: Dialog) {}

  open(modalComponent: ComponentType<ModalType>, config?: DialogConfig): DialogRef {
    return this.dialog.open(modalComponent, {
      ...config,
      ...this.defaultModalConfig,
    });
  }
}
