import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Stream } from '@kitch/data-access/models';

export interface StreamStatusChange {
  isSelected: boolean;
  streamId: string;
}

@Component({
  selector: 'app-stream-list-item',
  templateUrl: './stream-list-item.component.html',
  styleUrls: ['./stream-list-item.component.scss'],
})
export class StreamListItemComponent {
  @Input() stream: Stream;
  @Input() isDisabled: boolean;

  @Output() changeStatus: EventEmitter<StreamStatusChange> = new EventEmitter<StreamStatusChange>();

  onStreamSelect(event: Event): void {
    const isSelected = (event.target as HTMLInputElement).checked;

    this.stream.addedToRecipe = isSelected;
    this.changeStatus.emit({
      isSelected,
      streamId: this.stream.id,
    });
  }
}
