<article class="cdk-modal">
  <header class="cdk-modal__header">
    <img
      class="cdk-modal__header-img"
      src="assets/ui/images/svg/kittch-flame-modal-top-icon.svg"
      width="105"
      height="111"
      alt="kittch-flame-modal-top-icon"
    />

    <button
      (click)="close()"
      class="modal-close-btn"
      aria-label="close"
      type="button"
    >
      <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
      <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
    </button>

    <h2 class="typeform-modal__header-title">
      Cheers!<br class="typeform-modal__line-break"> Thanks for being on Kittch!
    </h2>
    <p class="typeform-modal__header-text">
      We know everyone has their own cooking needs and we want to get to know your cooking preferences so we can personalize Kittch to be just for you.
    </p>
  </header>

  <main class="cdk-modal__body">
    <app-button
      class="typeform-modal__submit-btn"
      data-tf-popup="hXyXcaNT"
      data-tf-opacity="100"
      data-tf-size="100"
      data-tf-iframe-props="title=UCP for Platform V2"
      data-tf-auto-close="5000"
      data-tf-transitive-search-params
      data-tf-medium="snippet"
      attr.data-tf-hidden="
      profile_id={{ profileId }},
      phone={{ userAccount?.phoneNumber }},
      name={{ userAccount?.profile.fullName }},
      email={{ userAccount?.email }}"
      (click)="onShowTypeform()"
      [text]="'Let’s get started'"
      [type]="'button'"
    ></app-button>
  </main>
</article>







