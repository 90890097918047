import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { User } from '@kitch/data-access/models';
import { AuthService } from '@kitch/data-access/services';
import { formatPhone, loadCaptchaScript } from '@kitch/util';
import { EmailValidator } from '@kitch/util/validators';
import { refCodeValidatorFn } from '@kitch/util/validators/ref-code-async.validator';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFormComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  readonly inviteId: string;

  @Input()
  readonly refCode: string;

  @Input()
  readonly refCodeError: string;

  @Output()
  readonly submitPhoneForm: EventEmitter<User> = new EventEmitter<User>();

  @Input() readonly isSaving: boolean;

  @Input() toResetForm: boolean;

  @Input() isCaptchaError: boolean;

  registerForm: UntypedFormGroup;
  dialCode: string;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initRegisterForm();
  }

  ngAfterViewInit(): void {
    loadCaptchaScript();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.registerForm) {
      return;
    }

    const refCodeControl = this.registerForm.get('refCode');

    if (changes.refCodeError?.currentValue) {
      refCodeControl?.setErrors({ serverError: changes.refCodeError.currentValue });
    }
    if (changes.refCode?.currentValue) {
      refCodeControl?.patchValue(changes.refCode.currentValue);
    }
    if (changes.toResetForm?.currentValue) {
      this.registerForm.reset();
      this.phoneNumberControl.patchValue(null);
      this.phoneNumberControl.setErrors(null);
      this.emailControl.setErrors(null);
      this.cdr.detectChanges();
    }
  }

  get phoneNumberControl(): AbstractControl {
    return this.registerForm.get('phoneNumber');
  }

  get emailControl(): AbstractControl {
    return this.registerForm.get('email');
  }

  onDialCode(dialCode: string): void {
    this.dialCode = dialCode;
  }

  submit(): void {
    const phoneNumber = formatPhone(this.phoneNumberControl.value, this.dialCode);

    this.submitPhoneForm.emit({
      ...this.registerForm.value,
      phoneNumber,
      inviteId: this.inviteId,
    });
  }

  private initRegisterForm(): void {
    this.registerForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, EmailValidator()]),
      phoneNumber: new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]),
      terms: new UntypedFormControl(false, Validators.requiredTrue),
      refCode: new UntypedFormControl(this.refCode, null, [
        refCodeValidatorFn((refCode) => this.authService.checkRefCode(refCode)),
      ]),
    });

    this.registerForm.get('refCode').statusChanges.subscribe((status) => {
      if (status !== 'PENDING') {
        this.cdr.detectChanges();
      }
    });
  }
}
