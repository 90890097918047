import { DOCUMENT } from '@angular/common';
import { Call } from '@angular/compiler';
import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  Renderer2,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  Inject,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let nextId = 0;

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./../../select-dropdown-shared.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true,
    },
  ],
})
export class SelectDropdownComponent implements AfterViewInit, OnChanges {
  @Input() items: any[];
  @Input() placeholder: string;
  @Input() isAddTag = false;
  @Input() isClearable = false;
  @Input() maxSelectedItems = 3;
  @Input() selectedItems: any[] = [];
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() hasAvatar = false;
  @Input() isInviteUserToPrepModeModal = false;
  @Input() isDisabled = false;

  @Output() selectedOptions: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() addNewOptions: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild('selectDropdown') selectDropdown;

  selectedNewItemsDropdown: any;

  select: Element;
  selectContainer: Element;
  inputElement: HTMLInputElement;
  closeIcons;
  isOpenDropDown = false;

  readonly inputId = `app-select-input-${nextId++}`;

  constructor(
    protected renderer: Renderer2,
    protected changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) protected document: Document,
  ) {}

  ngAfterViewInit() {
    this.select = this.document.getElementById(this.inputId);
    this.selectContainer = this.document.querySelector(`#${this.inputId} .ng-select-container`);
    this.inputElement = this.document.querySelector(`#${this.inputId} input`);

    this.renderer.setAttribute(this.inputElement, 'placeholder', this.placeholder);

    if (this.selectedItems?.length) {
      this.showHidePlaceholder();
      this.renderer.setStyle(this.selectContainer, 'box-shadow', 'none');
    }

    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { selectedItems } = changes;

    if (!selectedItems) return;

    if (!selectedItems.firstChange) {
      this.showHidePlaceholder();
      this.renderer.setStyle(this.selectContainer, 'box-shadow', 'none');
    }
  }

  onFocus(): void {
    this.isOpenDropDown = true;
    this.renderer.setStyle(this.selectContainer, 'box-shadow', 'inset 0 -1px 0 0 #52C5F2');
  }

  writeValue(): void {}

  onBlur(): void {
    this.propagateOnTouched();
    this.showHidePlaceholder();
    this.renderer.setStyle(this.selectContainer, 'box-shadow', 'none');
    this.isOpenDropDown = false;
  }

  onChange(event?: Event): void {
    this.propagateOnChange((event.target as HTMLInputElement).value);
  }

  protected registerOnChange(fn: Call): void {
    this.propagateOnChange = fn;
  }

  protected registerOnTouched(fn: Call): void {
    this.propagateOnTouched = fn;
  }

  showHidePlaceholder(): void {
    this.creatingCloseIcon();
    if (!this.isOpenDropDown) {
      this.inputElement.blur();
    }

    this.renderer.setStyle(
      this.selectContainer,
      'box-shadow',
      this.isOpenDropDown ? 'inset 0 -1px 0 0 #52C5F2' : 'none',
    );
    this.renderer.setAttribute(
      this.inputElement,
      'placeholder',
      this.selectedItems?.length > 0 ? '' : this.placeholder,
    );

    this.selectedNewItemsDropdown = this.selectDropdown.selectedItems
      .filter((item) => item.index >= this.selectDropdown._items?.length)
      .map((item) => item.label);

    this.addNewOptions.emit(this.selectedNewItemsDropdown);
    this.selectedOptions.emit(this.selectedItems);
  }

  updateItem(): void {
    this.isOpenDropDown = this.selectedItems?.length < this.maxSelectedItems;
    this.showHidePlaceholder();
  }

  protected creatingCloseIcon(): void {
    setTimeout(() => {
      this.closeIcons = (this.document.querySelectorAll('ng-select .ng-value .ng-value-icon') as unknown) as Element[];
      for (const icon of this.closeIcons) {
        this.renderer.setProperty(icon, 'innerHTML', '');
        const closeIconSvg = this.document.createElement('img');
        const closeIconSvgSrc = this.isInviteUserToPrepModeModal ? 'close-black' : 'close-item-dropdown';

        closeIconSvg.src = `assets/ui/images/svg/${closeIconSvgSrc}.svg`;
        icon.appendChild(closeIconSvg);
      }
      this.changeDetector.detectChanges();
    });
  }

  protected propagateOnTouched: any = () => {};
  protected propagateOnChange: any = () => {};
}
