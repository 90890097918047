<form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="login-form__fields">
    <div class="login-form__field--phone">
      <div>
        <app-animated-input
          data-testid="login-phone-number-input"
          formControlName="phoneNumber"
          label='Mobile Number'
          autocomplete="username"
          type="tel"
          isLoginForm="true"
          [id]="loginId"
          (dialCode)="onDialCode($event)"
        ></app-animated-input>
        <app-errors-list formControlName="phoneNumber"></app-errors-list>
      </div>
    </div>

    <app-button
      data-testid="login-btn"
      isLoginForm="true"
      [text]="submitButtonText"
      [type]="'submit'"
      [isDisabled]="loginForm.invalid"
      [isDisabledLoginForm]="loginForm.invalid || isUserLoginning && isSubmitted ? 'disabled' : ''">
    </app-button>
  </div>
</form>
