import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AI_IMAGE_GENERATE_URL } from '@kitch/data-access/constants';
import { ApiService } from '@kitch/data-access/services';
import { ImageCreatorParams, ImageCreatorResponse } from '@kitch/ui/models/image-creator.model';

@Injectable()
export class ImageCreatorService {
  constructor(private api: ApiService) {}

  createImages(params: ImageCreatorParams): Observable<ImageCreatorResponse> {
    return this.api.get(AI_IMAGE_GENERATE_URL, params);
  }
}
