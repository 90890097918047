<section class="video-timestamp-section entity-form">
  <div class="entity-form__slide-toggle-container">
    <app-slide-toggle
      class="entity-form__slide-toggle"
      [isSelected]="isVideoTimestampContent"
      (statusSlideInput)="onToggleVideoTimestampContent($event)"
    ></app-slide-toggle>
    <h3 class="entity-form__title">Add chapters</h3>
  </div>

  <p class="entity-form__subtitle">
    Add chapters to your video to help your audience skip to the steps they want.
  </p>

  <div
    *ngIf="isVideoTimestampContent"
    class="video-timestamp-section__content"
  >
    <form [formGroup]="videoTimestampForm" class="form">
      <div formArrayName="videoTimestamps">
        <div
          *ngFor="let videoTimestamp of videoTimestampControls; index as index"
          [formGroupName]="index"
          class="item form__item video-timestamp-section__content-row"
        >
          <div class="item__time-stamp">
            <h4 class="video-timestamp-section__text video-timestamp-section__text--input-title">Time Stamp</h4>
            <input
              [imask]="timestampFormat"
              class="form-input form-input--custom-field"
              id="stream-second-{{ index }}"
              formControlName="streamSecond"
              placeholder="00:00:00"
            >
            <p class="video-timestamp-section__text video-timestamp-section__text--input-subtitle">hh:mm:ss</p>
            <app-errors-list
              class="errors"
              errorName="time stamp"
              formControlName="streamSecond"
              inputType="TEXT"
            ></app-errors-list>
          </div>

          <div class="item__label">
            <h4 class="video-timestamp-section__text video-timestamp-section__text--input-title">Chapter Title</h4>
            <div class="item__input-wrap">
              <input
                formControlName="label"
                class="form-input form-input--custom-field"
                id="product-label-{{ index }}"
                placeholder="Chapter Title"
              />
              <app-errors-list
                class="errors"
                errorName="label"
                formControlName="label"
                inputType="TEXT"
                maxLengthError="WITHOUT_NUMBERS"
              ></app-errors-list>
            </div>
          </div>

          <button
            class="video-timestamp-section__remove-button"
            (click)="removeVideoTimestamp(index)"
          >
            <img
              [src]="'assets/ui/images/svg/basket.svg'"
              alt="basket"
              width="18"
              height="18"
            />
          </button>
        </div>
      </div>
    </form>

    <app-button
      *ngIf="videoTimestampFormArray.length < 12"
      class="video-timestamp-section__add-button"
      text="Add Chapter"
      iconUrl="assets/ui/images/svg/plus.svg"
      [iconHeight]="24"
      [iconWidth]="24"
      role="secondary"
      (clicked)="addVideoTimestamp()"
    ></app-button>
  </div>
</section>
