import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  SpecialtyTags,
  SpecialtyTagsIds,
  Tag,
  TagRenderNames,
  TagRenderPlaceholder,
  Tags,
  TagsForm,
  TagsType,
  TagsTypeLowercase,
} from '@kitch/data-access/models';
import { MAX_SELECTED_TAGS } from '@kitch/ui/constants';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  @Input() tags: Tags;
  @Input() tagTypes: TagsTypeLowercase[] = [];
  @Input() selectedTagsIds: SpecialtyTagsIds;

  readonly maxSelectedTags: number = MAX_SELECTED_TAGS;
  readonly tagsTypeLowercase = TagsTypeLowercase;
  tagsForm: FormGroup<TagsForm>;
  tagNames = TagRenderNames;
  tagPlaceholder = TagRenderPlaceholder;
  private newFreeTagsNames: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.createTagsForm();
  }

  // public - used from parent component
  getTags(): SpecialtyTags {
    const selectedTags = this.tagsForm.value as SpecialtyTags;
    const newFreeTags = this.getNewFreeTags();
    const styleTagValue = this.tagsForm.get(TagsTypeLowercase.style).value;

    selectedTags[TagsTypeLowercase.free] = [...selectedTags[TagsTypeLowercase.free], ...newFreeTags];
    selectedTags[TagsTypeLowercase.style] = styleTagValue ? [styleTagValue] : [];

    return selectedTags;
  }

  // public - used from parent component
  setTag(tag: Tag, tagType: TagsTypeLowercase): void {
    if (!tag) return;

    const selectedTagId = tag.id;
    const selectedTag = this.tags[tagType].find(thisTag => thisTag.id === selectedTagId);
    const tagControl = this.tagsForm.get(`${tagType}`);

    if (selectedTag) {
      tagControl.patchValue(selectedTag);
      tagControl.markAsDirty();
    }
  }

  onTagSelect(selectedTagsIds: string[], tagType: TagsTypeLowercase): void {
    const tags = this.tags[tagType];
    const newTags = [];
    const newIds = selectedTagsIds.filter(id => id);

    newIds.forEach((id) => newTags.push(tags.find(tag => tag.id === id)));
    this.tagsForm.get(`${tagType}`).setValue(newTags);
  }

  onAddFreeTag(newFreeTagsNames: string[], tagType: TagsTypeLowercase): void {
    if (tagType === TagsTypeLowercase.free) {
      this.newFreeTagsNames = newFreeTagsNames;
    }
  }

  private createTagsForm(): void {
    this.tagsForm = this.formBuilder.group<TagsForm>({
      diet: new FormControl<Tag[]>([]),
      equipment: new FormControl<Tag[]>([]),
      free: new FormControl<Tag[]>([]),
      lifestyle: new FormControl<Tag[]>([]),
      mealCategory: new FormControl<Tag[]>([]),
      mealType: new FormControl<Tag[]>([]),
      style: new FormControl<Tag>(null),
    });
  }

  private getNewFreeTags(): Tag[] {
    return this.newFreeTagsNames.map(name => ({
      id: '',
      name,
      type: TagsType.free,
    }));
  }
}
