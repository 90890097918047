import { TabId, TabsMenu, TabTitle } from '@kitch/user/shared/models';

export const TABS_MENU: TabsMenu = {
  active: 0,
  tabs: [
    {
      id: TabId.INFO,
      title: TabTitle.INFO,
      index: 0,
    },
    {
      id: TabId.CHAT,
      title: TabTitle.CHAT,
      index: 1,
    },
    {
      id: TabId.CHEFS_TABLE,
      title: TabTitle.CHEFS_TABLE,
      index: 2,
    },
    {
      id: TabId.RECIPE_FIRST,
      title: TabTitle.RECIPE_FIRST,
      index: 3,
    },
    {
      id: TabId.RECIPE_SECOND,
      title: TabTitle.RECIPE_SECOND,
      index: 4,
    },
    {
      id: TabId.RECIPE_THIRD,
      title: TabTitle.RECIPE_THIRD,
      index: 5,
    },
    {
      id: TabId.SHOP,
      title: TabTitle.SHOP,
      index: 6,
    },
  ],
};
