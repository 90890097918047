import { DateTime } from 'luxon';
import { VideoTimestamp } from '@kitch/ui/models/video-timestamp.form';

export const getDateFromSeconds = (seconds: number): Date => {
  return new Date(seconds * 1000);
};

export const getSecondsFromDate = (date: Date): number => {
  return Math.round(date.getTime() / 1000.0);
};

export const setMinimumSeconds = (date: Date): Date => {
  date.setSeconds(0, 0);

  return date;
};

export const setMinimumTime = (date: Date): Date => {
  date.setHours(0, 0, 0, 0);

  return date;
};

export const setMaximumTime = (date: Date): Date => {
  date.setHours(23, 59, 59, 0);

  return date;
};

export const convertDateToCalendarAppFormat = (scheduledVideoDate: string): string => {
  const date = new Date(scheduledVideoDate);
  const years = date.getFullYear();
  const months = date.getMonth() + 1;
  const dates = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${years}/${months}/${dates} ${hours}:${minutes}`;
};

export const convertTime12to24 = (time12h: string): string => {
  const [time, modifier] = time12h.split(' ');

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'pm') {
    hours = (+hours + 12).toString();
  }
  hours = hours.padStart(2, '0');

  return `${hours}:${minutes}`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setTimeToDate = ({ hours, minutes = 0, seconds = 0, date }): Date => {
  const copyDate = new Date(date);

  copyDate.setHours(hours, minutes, seconds);

  return copyDate;
};

export const setTime12ToDate = (time12h: string, date: Date): Date => {
  const time24h = convertTime12to24(time12h);
  const [hours, minutes] = time24h.split(':').map(it => +it);

  return setTimeToDate({
    hours, minutes, date,
  });
};

export type HrsMin = 'Hours' | 'Minutes';

export const sortByJSONDate = (firstDate: string, secondDate: string): number => {
  return new Date(firstDate).getTime() - new Date(secondDate).getTime();
};

export const isNextDay = (dateIsoStr: string): boolean => {
  const date = DateTime.fromISO(dateIsoStr);
  const dateNextDay = date.plus({ day: 1 }).startOf('day');
  const now = DateTime.local();

  return now >= dateNextDay;
};

export const convertTimeToMinutes = (control: VideoTimestamp): number => {
  const [hours, minutes, seconds] = (control.streamSecond as string).split(':').map(Number);
  const updatedSeconds = seconds || 0;

  return hours * 60 + minutes + updatedSeconds / 60;
};

export const convertMinutesToTime = (minutes: number): string => {
  const { floor, round } = Math;
  const hours = floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = round((remainingMinutes - floor(remainingMinutes)) * 60);
  const timeParts = [hours, floor(remainingMinutes), remainingSeconds].map(num => num.toString().padStart(2, '0'));

  return timeParts.join(':');
};
