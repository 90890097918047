import { Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';
import { NGXLogger } from 'ngx-logger';
import { environment } from '@kitch/data-access/env/environment';

@Injectable()
export class LoggerService {
  constructor(private ngxLogger: NGXLogger) {
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  debug(message?: any, ...additional: any[]): void {
    this.ngxLogger.debug(message, additional);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  info(message?: any, ...additional: any[]): void {
    this.ngxLogger.info(message, additional);
  }

  warn(message: string, error: Error): void {
    this.ngxLogger.warn(message);
    this.ngxLogger.warn(error);
    if (environment.enableSentryLogs) {
      captureException(error);
    }
  }

  error(message: string, error: Error): void {
    this.ngxLogger.error(message);
    this.ngxLogger.error(error);
    if (environment.enableSentryLogs) {
      captureException(error);
    }
  }
}
