export enum RtmMassageType {
  USER_RAISED_HAND,
  CHEF_CHANGED_USER_AUDIO,
  CHEF_CHANGED_STREAM_STATUS,
  CHEF_REMOVED_USER,
  SEND_EMOJI,
  SEND_TIP,
  USER_JOINED_STREAM,
  USER_LEFT_STREAM,
  CHEF_INVITED_CO_HOST,
  USER_JOINED_AS_CO_HOST,
  USER_LEFT_AS_CO_HOST,
  CO_HOST_WANT_TO_LEAVE,
  CO_HOST_ACCEPTED_INVITE,
  CO_HOST_DECLINED_INVITE,
  MUTE_EMOGI,
  MUTE_ALL_USERS,
  PIP_POSITION_CHANGED,
  SWITCH_PIP_CAMERA,
  INVITE_USERS_TO_PREP_MODE,
  USER_ACCEPTED_INVITE,
  USER_DECLINED_INVITE,
}
