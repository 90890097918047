export function getValidSlug(string: string): string {
  return string
    .trim()
    .replace(/[^-\w]+/g, '-') //  replace any character that is not a word character or a dash with -
    .replace(/[\r\n\s]+/g, '-') // replace whitespace and new lines with -
    .replace(/-+/g, '-') // match one or more consecutive dashes and replace them with a single dash
    .replace(/(^-+)|(-+$)/g, '') // match dashes at the beginning or end of the string and replace them with an empty string.
    .toLowerCase();
}

export class UrlTool {
  static checkUrlProtocol(url: string): string {
    return /(http|https)/.test(url) ? url : `https://${url}`;
  }

  static checkUrlProtocolForControl<T extends { url: string }>(control: T): T {
    const { url } = control;

    if (url) {
      control.url = url ? UrlTool.checkUrlProtocol(url) : '';
    }

    return control;
  }
}
