import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ShareButtonFuncArgs } from 'ngx-sharebuttons';
import { Observable, of } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { TokenService } from '@kitch/data-access/services';

interface CopyToClipboardDataArgs {
  delay: number;
  icon: string[];
  role?: string;
  successIcon: string[];
  successText: string;
  text: string;
}

export abstract class MediaShare {
  protected constructor(
    protected $gaService: GoogleAnalyticsService,
    protected tokenService: TokenService,
  ) {}

  onClickMediaButton(socialMedia: string): void {
    this.$gaService.gtag(
      'event',
      `share_ref_code_${socialMedia}_button_click`,
      { profile_id: this.tokenService.getProfileId() },
    );
  }

  protected copyToClipboard(
    { data, clipboard, updater }: ShareButtonFuncArgs<CopyToClipboardDataArgs>,
    value: string,
    eventName: string,
  ): Observable<void> {
    return of(null).pipe(
      tap(() => {
        clipboard.copy(value);
        this.onClickMediaButton(eventName);
        // Disable copy button
        updater.next({
          icon: data.successIcon, text: data.successText, disabled: true,
        });
      }),
      delay(data.delay),
      tap(() => updater.next({
        icon: data.icon, text: data.text, disabled: false,
      })),
      take(1),
    );
  }
}
