<div [hidden]="isLoginForm || isSignupForm">
  <input #input
       appWasInvolved
       [type]="type"
       [id]="inputId"
       class="input"
       (input)="onChange($event)"
       (blur)="onBlur()"
       (focus)="onFocus()"
       [attr.maxlength]="maxLength"
       [attr.minlength]="minLength"
       [imask]="mask" />
  <label #label [attr.for]="inputId" style="color: '#636363'" class="label">{{ labelText }}</label>
</div>
<div [hidden]="!isLoginForm || isSignupForm">
  <ngx-intl-tel-input
    #inputLogin
    [cssClass]="'input-login'"
    [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="false"
    [enablePlaceholder]="true"
    [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
    [customPlaceholder]="labelText"
    [selectFirstCountry]="true"
    [selectedCountryISO]="selectedCountryISO"
    [numberFormat]="PhoneNumberFormat.International"
    [phoneValidation]="true"
    [separateDialCode]="true"
    name="formControlName"
    (countryChange)="onCountryChange()"
    [inputId]="id"
    appWasInvolved
    (input)="onChange($event)"
    (blur)="onBlur()"
    (focus)="onFocus()"
  >
	</ngx-intl-tel-input>
</div>
<div [hidden]="isLoginForm || !isSignupForm">
  <input #inputSignup
       appWasInvolved
       [type]="type"
       [id]="inputSignupId"
       class="input-signup"
       [class.name-input]="isHalf"
       (input)="onChange($event)"
       (blur)="onBlur()"
       (focus)="onFocus()"
       [attr.maxlength]="maxLength"
       [attr.minlength]="minLength"
       [imask]="mask" />
  <label #labelSignupForm [attr.for]="inputSignupId" style="color: '#636363'" class="label">{{ labelText }}</label>
</div>
