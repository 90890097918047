import { MostPopularItem } from '@kitch/data-access/models';

export interface SortOptions<T = SortBy> {
  key: T;
  label: string;
}

export interface FilterOption extends MostPopularItem {
  isApplied: boolean; // page is filtered by this option
  isSelected: boolean; // checkbox is checked but doesn't affect page content
  type: FilterType
}

export enum FilterType {
  cuisine = 'CUISINE',
  tag = 'TAG'

}

export enum FilterSectionName {
  cuisines = 'cuisine',
  diet = 'dietary preference',
  lifestyle = 'lifestyle/occasions',
  mealCategory = 'meal category',
  mealType = 'meal type',
  subCuisines = 'sub-cuisine'
}

export interface AppliedFilters {
  cuisinesIds: string[];
  sortBy: SortBy;
  tagIds: string[];
}

export type ChannelsSortBy = 'newest' | 'popular' | 'chefName' | 'chefNameDesc';

export type StreamsSortBy = 'stoppedAt' | 'popularity' | 'titleAsc' | 'titleDesc';

export type SortBy = ChannelsSortBy | StreamsSortBy;
