<a [routerLink]="'/' + recipe.profile.chefSlug + '/recipes/' + recipe.slug" (click)="onViewClick()">
  <div class="recipe-inline-card recipe-inline-card__wrap">
    <img
      class="recipe-inline-card__img"
      height="69"
      width="104"
      [ngSrc]="recipe.mealPhoto"
      alt="{{ recipe.recipeName }}"
    />
    <div class="recipe-inline-card__content-section">
      <div class="recipe-inline-card__title" data-testid="recipe-name">{{ recipe.recipeName }}</div>
      <div class="recipe-inline-card__bottom-section">
        <div class="recipe-inline-card__author">
          <app-avatar
            [size]="'recipe-list-item'"
            [url]="recipe.profile?.photo"
            [name]="recipe.profile?.chefName"
            [title]="recipe.profile?.channel.name"
            [isLive]="recipe.profile?.isLive"
            [isClub]="!!recipe.requiredClubSubscriptionPlan"
          ></app-avatar>
        </div>
        <div class="recipe-inline-card__favorite">
          <app-button
            appNeedAuthorize
            [source]="'like recipe'"
            [text]="''"
            [iconUrl]="iconUrl"
            [iconWidth]="20"
            [iconHeight]="29"
            [role]="'secondary'"
            (click)="onButtonClick($event)"
            (clicked)="onFavoriteButtonClick()"
            id="favorite"
          ></app-button>
        </div>
      </div>

      <app-rating
        *ngIf="recipe.ratingQuantity"
        [quantity]="recipe.ratingQuantity"
        [rating]="recipe.ratingAverage"
        class="recipe-inline-card__rating"
      ></app-rating>
    </div>
  </div>
</a>
