import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ratingDescription',
})
export class RatingDescriptionPipe implements PipeTransform {
  transform(rating: number): string {
    switch (rating) {
      case 1:
        return 'Not my cup of tea';
      case 2:
        return 'Meh, it was okay';
      case 3:
        return 'Worth a shot';
      case 4:
        return 'Yes please';
      case 5:
        return 'Can’t get enough';
      default:
        return 'Tell us what you thought';
    }
  }
}
