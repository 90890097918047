import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filter } from 'rxjs/operators';
import { SearchGAEvents } from '@kitch/data-access/constants';
import { TokenService } from '@kitch/data-access/services';
import { disableScroll, enableScroll } from '@kitch/util';
import { SidebarService } from '@kitch/user/core/sidebar.service';

@UntilDestroy()
@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnChanges, OnInit {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Input() isOpen = false;

  @Output() searchOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchValue = '';

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private $gaService: GoogleAnalyticsService,
    private sidebarService: SidebarService,
    @Inject(WINDOW) private window: Window,
  ) {
    const urlSearchParams = new URLSearchParams(window.location.search);

    this.searchValue = urlSearchParams.get('search') || '';
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      untilDestroyed(this),
    )
      .subscribe((event: any) => {
        this.updateInputValue(event.url);
      });
  }

  ngOnChanges(ch): void {
    if (ch.isOpen?.currentValue) {
      this.isOpen = true;
      disableScroll(this.window);
    }
  }

  onSubmit(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.searchInput.nativeElement.value.length > 0) {
      this.$gaService.gtag('event', SearchGAEvents.GA_EVENT_USER_SEARCH_SUBMIT_CLICK, {
        profile_id: this.tokenService.getProfileId(),
        search_value: this.searchInput.nativeElement.value,
      });

      this.router.navigate(
        ['/search/streams'],
        { queryParams: { search: this.searchInput.nativeElement.value } },
      )
        .then(() => {
          this.closeMobileSearchbar();
          this.closeMainSidebar();
        });
    }
  }

  onFocus(): void {
    this.$gaService.gtag(
      'event',
      SearchGAEvents.GA_EVENT_USER_SEARCH_INPUT_CLICK,
      { profile_id: this.tokenService.getProfileId() },
    );
  }

  clearInput(): void {
    this.searchValue = '';
  }

  back(): void {
    this.closeMobileSearchbar();
  }

  closeMobileSearchbar(): void {
    this.isOpen = false;
    enableScroll(this.window);
    this.searchOpenChanged.emit(false);
  }

  closeMainSidebar(): void {
    this.sidebarService.changeMobileState(false);
  }

  private updateInputValue(url: string) {
    if (!url.includes('/search/')) {
      this.clearInput();
    } else {
      this.searchValue = decodeURI(url.split('=')[1]);
    }
  }
}
