<app-modal #userNotFoundModal [showCloseIcon]="false">
  <ng-container header>
    <h2 class="modal-title"> Hmm, We can’t find you in our system</h2>
  </ng-container>
  <ng-container body>
    <div class="user-exists-modal">
      <p>
        Try signing in again or create a new account.
        <span> If you’re still having trouble,
          <a class="contact-link" href="mailto:support@kittch.com" rel="nofollow">contact us</a>.
        </span>
      </p>
    </div>
    <div class="user-exists-actions form__item form__item--center">
      <div class="form-button form-button--close">
        <app-button
          [text]="'Sign in'"
          (clicked)="showLogin()">
        </app-button>
      </div>
      <div class="form-button form-button--close form-button--create">
        <app-button
          [role]="'secondary'"
          [text]="'create an account'"
          (clicked)="showSignUp()"
        >
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
