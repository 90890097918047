import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-shopping-cart-button',
  templateUrl: './shopping-cart-button.component.html',
})
export class ShoppingCartButtonComponent {
  @Output() shoppingCardOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  isShoppingCardOpened = false;

  openShoppingCart(): void {
    this.isShoppingCardOpened = true;
    this.shoppingCardOpened.emit(this.isShoppingCardOpened);
  }

  closeShoppingCart(): void {
    this.isShoppingCardOpened = false;
    this.shoppingCardOpened.emit(this.isShoppingCardOpened);
  }
}
