import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Pipe({
  name: 'formArrayControls',
})
export class FormArrayControlsPipe implements PipeTransform {
  transform(form: FormGroup, formArrayControlName: string): AbstractControl[] {
    return (form.get(formArrayControlName) as FormArray).controls;
  }
}
