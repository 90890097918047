<a class="back" (click)="back()">
  <img
    class="back-icon"
    src="assets/ui/images/svg/arrow-left.svg"
    alt="back arrow"
    width="24"
    height="24"
  />
  <span class="back-text">Back</span>
</a>
