<div class="avatar--wrap">
  <div class="avatars avatars--{{ size }}">
    <ng-container *ngFor="let chef of chefs | reverse; last as last">
      <a [routerLink]="'/' + chef.chefSlug">
        <picture class="avatar avatar--{{ size }}"
         [ngClass]="{
            'avatar--bg-color': chef.isLive,
            'avatar--last': last
          }">
          <source srcset="{{ chef.photo }}"/>
          <img
            width="32"
            height="32"
            class="avatar"
            src="assets/ui/images/png/no-image.png"
            alt="{{ chef.chefName }}`s avatar"
          />
        </picture>
      </a>
    </ng-container>
  </div>

  <div class="info-details info-details--{{ backgroundColor }}">
    <div class="info-details__name">
      <ng-container *ngFor="let chef of chefs; last as last">
        <a [routerLink]="'/' + chef.chefSlug">{{ getDetailsName(chef) }}</a>
        <span class="info-details__plus" *ngIf="!last"> + </span>
      </ng-container>
    </div>
    <div *ngIf="title" class="info-details__title">
      {{ title }}
    </div>
  </div>
</div>
