import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'urlSafe',
})
export class UrlSafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: string): SafeResourceUrl {
    if (url === this.sanitizer.sanitize(SecurityContext.URL, url)) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    return null;
  }
}
