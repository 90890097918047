<label for="{{ 'videoUpload-' + formControlName }}" class="video-chooser">
  <div class="description">Upload your video file</div>
  <span *ngIf="uploadStatus.state !== 'PENDING'" class="video-chooser__interactive-preview">
    <ng-container *ngIf="uploadStatus.state === 'DONE'">
      <video
        id="video-player"
        class="video-chooser__video"
        crossorigin="anonymous"
        controls
        [src]="video"
      ></video>
    </ng-container>
    <div *ngIf="uploadStatus.state === 'IN_PROGRESS'" class="video-uploading">
      Processing video ({{uploadStatus.progress}}%)
    </div>
    <span class="video-chooser__name">
      {{ videoName }}
    </span>

    <button
      type="button"
      class="delete-btn"
      (click)="onDeleteImage($event)"
    >
      <div class="delete-btn__cross delete-btn__cross--left"></div>
      <div class="delete-btn__cross delete-btn__cross--right"></div>
    </button>
  </span>

  <app-button
    class="choose-button"
    (clicked)="uploadInput.click()"
    [text]="'choose file'"
    [role]="'secondary'"
    [iconUrl]="'assets/ui/images/svg/file.svg'"
    [type]="'button'"
  >
  </app-button>

  <input
    id="{{ 'videoUpload-' + formControlName }}"
    #uploadInput
    hidden
    type="file"
    [accept]="videoLimits.type"
    (click)="onFileClick()"
    (change)="onFileChanged($event)"
  />
</label>
