<form [formGroup]="registerForm" (ngSubmit)="submit()" class="register-form">
  <ul>
    <li class="form__item">
      <div class="form__field" data-testid="email-input">
        <app-animated-input formControlName="email" label='Email*' isSignupForm="true">
        </app-animated-input>
        <app-errors-list formControlName="email"></app-errors-list>
      </div>
    </li>

    <li class="form__item form__item--phone form__fields">
      <div class="form__field--phone" data-testid="phone-input">
        <app-animated-input
          formControlName="phoneNumber"
          label='Mobile Number*'
          type="tel"
          isLoginForm="true"
          [id]="inviteId"
          (dialCode)="onDialCode($event)"
        ></app-animated-input>
        <app-errors-list formControlName="phoneNumber"></app-errors-list>
      </div>
    </li>

    <li class="form__item--label-phone">
      <p class="label-sign-up">Your mobile number is required to sign in. No spam, we promise!</p>
    </li>

    <li class="form__item">
      <div class="form__field">
        <app-animated-input
          formControlName="refCode"
          label='Invite Code (Optional)'
          isSignupForm="true"
          maxLength="6"
        ></app-animated-input>
        <app-errors-list formControlName="refCode"></app-errors-list>
      </div>
    </li>

    <li class="form__item form__item--terms">
      <label class="checkmark" data-testid="terms-checkmark">
        <input type="checkbox" class="checkmark__input" formControlName="terms" />
        <span class="checkmark__label"></span>
      </label>
      <span class="terms-text">I accept the Kittch <a [routerLink]="'/terms-of-use'" target="_blank" rel="nofollow">Terms of Service</a> and
        have read the Kittch
        <a [routerLink]="'/privacy-policy'" target="_blank" rel="nofollow">Privacy Statement</a>.*</span>
    </li>

    <li class="form__item form__item--captcha">
      <div *ngIf="isCaptchaError" class="form-error">
        <span class="form-error__text form-error__text--captcha">
          Captcha error. Please try again or reload the page.
        </span>
      </div>
    </li>

    <li>
      <app-button
        data-testid="sign-up-btn"
        class="button-register"
        [text]="'Sign Up'"
        [type]="'submit'"
        [isLoginForm]="true"
        [isDisabled]="registerForm.invalid || isSaving"
        [isDisabledLoginForm]="registerForm.invalid || isSaving ? 'disabled' : ''"
      ></app-button>
    </li>
  </ul>
</form>
