import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, from, fromEvent, Observable, of } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  finalize,
  last,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DEFAULT_PAGE, MAX_ITEMS_PER_PAGE } from '@kitch/data-access/constants';
import { TABLET_WIDTH } from '@kitch/data-access/constants/media-queries';
import {
  AppPagesItem,
  BasicCuisineSchema,
  BasicStream,
  Categories,
  CREATED_STREAM_ID,
  CuisinesList,
  CuisineType,
  EMPTY_SPECIALTY_TAGS_IDS,
  FormType,
  HardcodedCuisinesStructure,
  IdObject,
  PrivateOption,
  Recipe,
  ReplayOption,
  Role,
  RoutingType,
  SimpleResponse,
  SocketEventMessage,
  SocketEventType,
  SpecialtyTagsIds,
  Stream,
  StreamAccessLevel,
  StreamSchedule,
  StreamStatus,
  Tag,
  Tags,
  TagsTypeLowercase,
  VideoCuisines,
  VideoEmailSettings,
} from '@kitch/data-access/models';
import { RecipesSearchParams, UsersSearchParams } from '@kitch/data-access/models/search-params';
import { CuisineSearchParams } from '@kitch/data-access/models/search-params/cuisine';
import {
  ChatService,
  CuisinesService,
  Days,
  DurationVideoService,
  LocationService,
  Months,
  ProfilesService,
  RecipesService,
  StreamsService,
  TagsService,
  TokenService,
  Upload,
  UploadService,
  UploadUrls,
} from '@kitch/data-access/services';
import { convertTime12to24, initVideoPlayer, setMaximumTime, setTime12ToDate } from '@kitch/util';
import { getValidSlug } from '@kitch/util/url.tool';
import { ScheduleDateValidator, slugValidatorFn, StreamPaidValidator } from '@kitch/util/validators';
import {
  EmailInputs,
  EmailValues,
  RecipeStatusChange,
  UserStatusChange,
} from '@kitch/ui/components';
import {
  AdminCheckboxFormComponent,
} from '@kitch/ui/components/forms/admin-checkbox-form/admin-checkbox-form.component';
import { CustomButtonFormComponent } from '@kitch/ui/components/forms/custom-button-form/custom-button-form.component';
import { ProductFormComponent } from '@kitch/ui/components/forms/product-form/product-form.component';
import { TagsComponent } from '@kitch/ui/components/forms/tags/tags.component';
import { VideoChooserComponent } from '@kitch/ui/components/forms/video-chooser/video-chooser.component';
import {
  VideoTimestampFormComponent,
} from '@kitch/ui/components/forms/video-timestamp-form/video-timestamp-form.component';
import { MAX_RECIPES_ATTACHED_TO_STREAM } from '@kitch/ui/constants';
import { Product } from '@kitch/ui/models';
import { CustomButton } from '@kitch/ui/models/custom-button-form';
import { AlertService } from '@kitch/ui/services';
import { UserInviteStatusObject } from '@kitch/admin/shared/models/invites';
import { GuestUser, UsersListItem } from '@kitch/admin/shared/models/user';
import { UsersService } from '@kitch/admin/shared/services/users.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';

interface DropdownOption<T, R> {
  extra?: string;
  key: T;
  value: R;
}

export interface Time {
  disabled?: boolean;
  format: string;
  fullFormatStr: string;
  hour: string;
  minute: string;
  name: string;
}

const COLLABORATOR_MAX_LENGTH = 2;

@UntilDestroy()
@Component({
  selector: 'app-main-stream-form',
  templateUrl: './main-stream-form.component.html',
  styleUrls: ['./main-stream-form.component.scss'],
})
export class MainStreamFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('recipesSearch', { static: false }) recipesSearch: ElementRef;
  @ViewChild('guestsSearch', { static: false }) guestsSearch: ElementRef;
  @ViewChild('collaboratorsSearch', { static: false }) collaboratorsSearch: ElementRef;
  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;
  @ViewChild('videoChooser', { static: false }) videoChooser: VideoChooserComponent;
  @ViewChild('header', { static: false }) header: ElementRef;
  @ViewChild('productForm') productForm: ProductFormComponent;
  @ViewChild('tagsSection') tagsSection: TagsComponent;
  @ViewChild('videoTimestampForm') videoTimestampForm: VideoTimestampFormComponent;
  @ViewChild('customButtonForm') customButtonForm: CustomButtonFormComponent;
  @ViewChild('adminCheckboxForm') adminCheckboxForm: AdminCheckboxFormComponent;

  formLabels = {
    title: '',
    description: 'Add basic information, recipes, custom buttons, and collaborators to your stream.',
    saveButton: 'save',
    formTitle: 'stream info',
    streamTitle: 'title your stream',
    streamDescription: 'description of stream',
    streamCategories: 'stream categories',
    streamThumbnail: 'stream thumbnail',
    streamDate: 'date and time',
  };

  replayOption = ReplayOption;

  replayOptions = [
    {
      type: ReplayOption.HIDDEN_NON_LIVE,
      text: ' Available only to users who purchased the livestream',
      description: 'Your replay is only available to those who paid for the livestream.',
      disabled: false,
    },
    {
      type: ReplayOption.PAID,
      text: 'Available to all at a separate cost',
      description: '',
      disabled: false,
    },
    {
      type: ReplayOption.FREE,
      text: 'Replay is free for all users',
      description: 'Your replay is available to everyone at no cost.',
      disabled: false,
    },
    {
      type: ReplayOption.PAID_NON_LIVE,
      text: 'Available to non-livestreamers at a separate cost',
      description:
      // eslint-disable-next-line max-len
        'Your replay is included for those who paid for the livestream, but non-livestreamers will have to pay to watch the replay.',
      disabled: false,
    },
  ];

  chefs: UsersListItem[] | UserInviteStatusObject[];
  isAdminForm = false;

  streamUrl: string;
  mainCameraVideoUrl: string;
  secondCameraVideoUrl: string;

  mainInfoForm: UntypedFormGroup;
  isLoading = false;
  isSaving = false;

  streamId: string;
  profileId: string;
  chefSlug: string;
  streamSlug: string;
  isCreateStream: boolean;
  isScheduledStream: boolean;
  isLiveStream: boolean;
  isShow: boolean;
  hasThumbnail: boolean;
  isUploadVideo: boolean;
  uploadStatus: Upload = { state: 'PENDING', progress: 0 };

  streamScheduleDate: Date;

  isDescription: boolean;

  pimarySelectedCuisines: string[] = [];
  selectedSubCuisines: string[] = [];
  newSubCuisines: string[] = [];
  hasChannelCuisines = false;

  isStreamPrivate = false;
  isStreamPaid = false;
  hasPrivateUsers = false;
  isStreamOwner = true;

  accessLevels: DropdownOption<StreamAccessLevel, string>[] = [
    {
      key: StreamAccessLevel.FREE,
      value: 'Free',
      extra: 'All users can participate in 2-way video.',
    },
    {
      key: StreamAccessLevel.PAID,
      value: 'Club Members',
      extra: 'Users must be a club member or purchase access to participate.',
    },
  ];

  notifyFollowers: DropdownOption<boolean, string>[] = [
    {
      key: true,
      value: 'Notifications On',
      extra: 'Your followers will be notified via e-mail/SMS alert.',
    },
    {
      key: false,
      value: 'Notifications Off',
      extra: 'Your followers will not receive notifications for this stream.',
    },
  ];

  cuisines: CuisinesList = {
    primary: [],
    subCuisine: [],
    stream: [],
  };

  availableSubCuisines: BasicCuisineSchema[] = [];
  hardcodedCuisines: HardcodedCuisinesStructure;

  tags: Tags;
  specialtyTagTypes: TagsTypeLowercase[] = [];
  selectedSpecialtyTagsIds: SpecialtyTagsIds = EMPTY_SPECIALTY_TAGS_IDS;

  allRecipes: Recipe[] = [];
  allUsers: GuestUser[] = [];
  collaborators: UsersListItem[] = [];
  streamCollaboratorIds: string[] = [];
  streamGuestIds: string[] = [];
  streamGuests: GuestUser[];
  streamRecipesIds: string[] = [];

  imageUrl = '';

  times: Time[] = [];

  isDisabledCollab = true;
  isSelectedSponsor: boolean;
  isSelectedCollaborators = false;
  isSelectedRecipes = false;

  guestsPage = 1;
  totalPrivateUsers: number;
  totalPagePrivateUsers: number;

  errorMessages: string[] = [];
  maxTitleLength = 56;

  isEmailNotifications: boolean;
  isPreEmailNotifications: boolean;
  isPostEmailNotifications: boolean;
  maxEmailNotificationsLength = 3000;

  isReplayPrice: boolean;

  streamPreCustomTextOne: string;
  streamPreCustomTextTwo: string;
  streamPostCustomTextOne: string;
  streamPostCustomTextTwo: string;
  streamReplayCommunicaionOne: string;
  streamReplayCommunicaionTwo: string;

  valuePreBlockFirst: string;
  valuePreBlockSecond: string;
  valuePostBlockFirst: string;
  valuePostBlockSecond: string;
  valueFirstReplayCommunicaion: string;
  valueSecondReplayCommunicaion: string;

  preBlocksText: EmailValues;
  postBlocksText: EmailValues;
  replayBlocksText: EmailValues;
  isInvalidEmailFields: boolean;

  customButtons: CustomButton[];

  isPastStream = false;
  isPastNotPaidStream: boolean;

  isSubmiting = false;

  constructor(
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private streamsService: StreamsService,
    private cuisinesService: CuisinesService,
    private recipesService: RecipesService,
    private uploadService: UploadService,
    private usersService: UsersService,
    private navigation: LocationService,
    private chatService: ChatService,
    private tagsService: TagsService,
    private tokenService: TokenService,
    private durationVideoService: DurationVideoService,
    private profilesService: ProfilesService,
    private changeDetector: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2,
    private userProfileService: UserProfileService,
  ) {}

  get isMaxRecipesAttachedToStream(): boolean {
    return this.streamRecipesIds.length >= MAX_RECIPES_ATTACHED_TO_STREAM;
  }

  ngOnInit(): void {
    this.streamId = this.route.snapshot.params.id;
    this.isScheduledStream = this.router.url.includes('schedule');
    this.isLiveStream = this.router.url.includes('live');
    this.isUploadVideo = this.router.url.includes('upload');
    this.hasThumbnail = !this.isScheduledStream;
    this.isCreateStream = this.route.snapshot.data.routing_type === RoutingType.CREATE;
    this.profileId = this.tokenService.getProfileId();
    this.isShow = this.isUploadVideo && this.isCreateStream;
    this.isAdminForm = this.route.snapshot.data.form_type === FormType.ADMIN;

    this.createMainInfoForm();
    this.getHardcodedCuisines().subscribe();

    this.userProfileService.fullUserProfile$
      .pipe(untilDestroyed(this))
      .subscribe(({ profile }) => {
        this.chefSlug = profile.chefSlug;

        if (this.isCreateStream) {
          setTimeout(() => this.customButtonForm.setCustomButtons(profile.websites));
        }
      });

    if (this.isCreateStream) {
      this.setTitle(this.isScheduledStream ? StreamStatus.SCHEDULED : StreamStatus.LIVE);

      if (this.isAdminForm) {
        this.getChefsProfile().subscribe();
      } else {
        setTimeout(() => this.createStream().subscribe());
        this.getRecipes().subscribe();
        this.getCuisines().subscribe();
        this.getTags().subscribe();
        this.getGuests().subscribe();
        this.getCollaborators().subscribe();
        this.getProducts().subscribe();
        this.getCuisinesForCurrentChef().subscribe();
      }
    } else {
      this.isLoading = true;
      forkJoin([
        this.getStreamMainInfo(),
        this.getCuisines(),
        this.getGuests(),
        this.getCollaborators(),
        this.getTags(),
      ])
        .pipe(
          tap(([
            stream,
            cuisines,
            guests,
            _collabs,
            _tags,
          ]) => {
            this.isStreamOwner = stream.channel.chefProfile.id === this.tokenService.getProfileId();
            this.fillForm(stream, cuisines, guests.results);
            this.settingEditPastStream(stream);
            this.checkShowToggle(stream);
          }),
          mergeMap(() => this.getRecipes()),
          finalize(() => this.isLoading = false),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this.markChosenCollaborators();
          if (this.streamCollaboratorIds.length && this.isStreamOwner) {
            this.addRecipesOfChosenCollaborators(this.streamCollaboratorIds);
          } else {
            this.markChosenRecipes();
          }
          this.subscribeOnVideoChanges();
          this.initAdditionalVideo();
        });
      this.chatService.connect();
    }
  }

  ngAfterViewInit(): void {
    this.subscribeOnScrollWindow();
  }

  ngOnDestroy(): void {
    this.chatService.disconnect();
    this.isLoading = false;
  }

  get isShowReplayOptionInput(): boolean {
    const replayOptionValue = this.mainInfoForm.get('replayOption').value;

    return replayOptionValue === ReplayOption.PAID
      || replayOptionValue === ReplayOption.PAID_NON_LIVE;
  }

  setStatusSubmiting(status: boolean): void {
    this.isSubmiting = status;
  }

  setStatusSponsored(status?: boolean): void {
    this.isSelectedSponsor = status;
  }

  setStatusCollaborators(status: boolean): void {
    if (!status) {
      this.streamCollaboratorIds = [];
      this.collaborators.forEach((item) => item.addedToStream = false);
      this.isDisabledCollab = true;
    }

    this.isSelectedCollaborators = status;
  }

  setStatusRecipes(status: boolean): void {
    if (!status) {
      this.streamRecipesIds = [];
      this.allRecipes.forEach((item) => item.addedToStream = false);
    }
    this.isSelectedRecipes = status;
  }

  setSelectedCuisines(cuisines: string[]): void {
    this.mainInfoForm.get('primaryCuisines').setValue(cuisines);
    this.pimarySelectedCuisines = cuisines;
    this.updateSelectedSubCuisines(cuisines);
  }

  setSelectedSubCuisine(subCuisines: string[]): void {
    const subCuisinesSelected = subCuisines.filter((item) => item);

    this.mainInfoForm.get('subCuisines').setValue(subCuisinesSelected);
    this.selectedSubCuisines = subCuisines;
  }

  setSelectedPrivateUsers(userIds: string[]): void {
    this.streamGuestIds = userIds;
    this.filterAllUsers();
    this.changeDetector.detectChanges();
  }

  setSearchTerm(term: string): void {
    this.guestsPage = 1;
    this.getGuests(term).subscribe();
  }

  setPagePrivateUsers(page: number): void {
    if (page < this.totalPagePrivateUsers) {
      this.guestsPage = page + 1;
      if (this.isCreateStream) {
        this.getGuests().subscribe();
      } else {
        this.getGuests().subscribe(() => {
          this.filterAllUsers();
        });
      }
    }
  }

  setEmailStatus(status: boolean): void {
    this.isEmailNotifications = status;
  }

  setReplayPriceStatus(status: boolean): void {
    this.isReplayPrice = status;
  }

  setPreEmailStatus(status: boolean): void {
    this.isPreEmailNotifications = status;
  }

  setPostEmailStatus(status: boolean): void {
    this.isPostEmailNotifications = status;
  }

  setValuePreBlockFirst(value: EmailInputs): void {
    this.valuePreBlockFirst = value.textContent;
    this.streamPreCustomTextOne = value.textWithHtml;
    this.isInvalidEmailFields = value.isInvalid;
  }

  setValuePreBlockSecond(value: EmailInputs): void {
    this.valuePreBlockSecond = value.textContent;
    this.streamPreCustomTextTwo = value.textWithHtml;
  }

  setValuePostBlockFirst(value: EmailInputs): void {
    this.valuePostBlockFirst = value.textContent;
    this.streamPostCustomTextOne = value.textWithHtml;
    this.isInvalidEmailFields = value.isInvalid;
  }

  setValuePostBlockSecond(value: EmailInputs): void {
    this.valuePostBlockSecond = value.textContent;
    this.streamPostCustomTextTwo = value.textWithHtml;
  }

  setFirstValueReplayCommunication(value: EmailInputs): void {
    this.valueFirstReplayCommunicaion = value.textContent;
    this.streamReplayCommunicaionOne = value.textWithHtml;
    this.isInvalidEmailFields = value.isInvalid;
  }

  setSecondValueReplayCommunication(value: EmailInputs): void {
    this.valueSecondReplayCommunicaion = value.textContent;
    this.streamReplayCommunicaionTwo = value.textWithHtml;
  }

  titleMarkAsTouched(): void {
    this.mainInfoForm.get('title').markAsTouched();
  }

  settingEditPastStream(stream: Stream): void {
    this.isPastStream = !this.isLiveStream && !this.isScheduledStream && !stream.produced;
    this.isPastNotPaidStream = this.isPastStream && !stream.paid;
    this.isUploadVideo = stream.produced;

    if (this.isPastNotPaidStream) {
      this.replayOptions = this.replayOptions.filter(item => item.type.includes('PAID'));
      this.mainInfoForm.get('replayOption').setValue(this.replayOptions[0].type);
    } else if (this.isPastStream) {
      this.mainInfoForm.get('streamPrice').disable();
      this.replayOptions.forEach((item) => {
        if (!item.type.includes('PAID')) {
          item.disabled = true;
        }
      });
    }

    this.changeDetector.detectChanges();
  }

  saveMainStreamInfo(): void {
    this.isSubmiting = true;
    this.markFormAsTouched();

    if (!this.isStreamPaid || this.isPastNotPaidStream) {
      this.mainInfoForm.get('streamPrice').setErrors(null);
    }

    if (!this.isShowReplayOptionInput || (this.isPastNotPaidStream && !this.isStreamPaid)) {
      this.mainInfoForm.get('replayPrice').setErrors(null);
    }

    const videoIsUpload = this.isUploadVideo && this.uploadStatus.state !== 'DONE';

    if (this.isValidForms || videoIsUpload || this.isInvalidEmailFields) {
      this.changeDetector.detectChanges();
      this.scrollToFirstInvalidControl();
      this.createErrorMessages();
      this.isDescription = this.mainInfoForm.get('description').status === 'INVALID';

      return;
    }

    this.isSaving = true;

    let uploadPhoto$ = of({});

    if (this.mainInfoForm.get('thumbnailUrl').dirty) {
      const imageType: string = this.mainInfoForm.get('thumbnailUrl').value.type;

      uploadPhoto$ = this.uploadService.getUploadUrls(imageType, 'video').pipe(
        tap(({ publicUrl }) => (this.imageUrl = publicUrl)),
        concatMap(({ uploadSignedUrl }) =>
          this.uploadService.upload(uploadSignedUrl, this.mainInfoForm.get('thumbnailUrl').value),
        ),
      );
    }

    const saveScheduleInfo$ = this.isScheduledStream ? this.saveScheduleInfo() : of({});
    const uploadProductPhotos$ = this.productForm.productControls.length ?
      forkJoin(this.productForm.uploadProductPhotos()) : of({});
    const saveLiveAsSchedule$ = this.isCreateStream && this.isLiveStream ?
      this.saveLiveAsSchedule() : of({});

    uploadProductPhotos$
      .pipe(
        concatMap(() => uploadPhoto$),
        concatMap(() => this.streamsService.update({
          ...this.getMainInfoFormData(),
          streamEmailSettings: this.getStreamEmailSettings(),
        }, this.streamId)),
        concatMap(() => saveScheduleInfo$),
        concatMap(() => saveLiveAsSchedule$),
        finalize(() => (this.isSaving = false)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.isLoading = true;
        this.sendStreamSocketEvent();

        if (this.isAdminForm) {
          this.router.navigate(['/streams']).then();
        } else {
          if (this.isCreateStream && (this.isScheduledStream || this.isUploadVideo)) {
            sessionStorage.setItem(CREATED_STREAM_ID, this.streamId);
          }

          if (this.isCreateStream && this.isLiveStream) {
            this.router.navigate([this.getStreamUrl()], {
              state: {
                isChefPrepMode: true,
              },
            }).then();
          } else if (this.isScheduledStream || this.isUploadVideo || this.isPastStream) {
            this.router.navigate(['/profile/my-streams']).then();
          } else {
            this.router.navigate([this.getStreamUrl()]).then();
          }
        }
      });
  }

  cancelStream(): void {
    this.navigation.back();
  }

  changeRecipesList(recipeStatus: RecipeStatusChange): void {
    if (recipeStatus.isSelected) {
      this.streamRecipesIds.push(recipeStatus.recipeId);
      this.updateTagsFromRecipe(recipeStatus.recipeId);
      this.updatedSelectedCuisinesAndSubCuisines(recipeStatus.recipeId);
    } else {
      this.streamRecipesIds = this.streamRecipesIds.filter(recipe => recipe !== recipeStatus.recipeId);
    }
  }

  changeCollaboratorsList(collaboratorStatus: UserStatusChange): void {
    const collaboratorId = collaboratorStatus.user.profile.id;

    if (collaboratorStatus.isSelected) {
      this.streamCollaboratorIds?.push(collaboratorId);
      this.addRecipesOfChosenCollaborators([collaboratorId]);
      setTimeout(() => {
        this.isDisabledCollab = this.streamCollaboratorIds?.length < COLLABORATOR_MAX_LENGTH;
        setTimeout(() => (this.isDisabledCollab = false));
        setTimeout(() => (
          this.isDisabledCollab = this.streamCollaboratorIds?.length < COLLABORATOR_MAX_LENGTH), 2000,
        );
      });
    } else {
      this.isDisabledCollab = this.streamCollaboratorIds?.length <= COLLABORATOR_MAX_LENGTH;
      this.streamCollaboratorIds
        = this.streamCollaboratorIds?.filter(user => user !== collaboratorId);
      this.removeRecipesOfRemovedCollaborator(collaboratorId);
    }
  }

  saveLiveAsSchedule(): Observable<SimpleResponse> {
    const tDate = new Date();
    const date: StreamSchedule = {
      date: tDate.getTime().toString(),
      notifyFollowers: true,
    };

    return this.streamsService.createScheduleDate(this.streamId, date);
  }

  setDateFormat(date: Date): void {
    const dayName = Days[date.getDay().toString()];
    const monthName = Months[date.getMonth().toString()];
    const dayCount = date.getDate();

    this.datePickerInput.nativeElement.value = `${dayName}, ${monthName} ${dayCount}`;
    this.setDisabledTimes();
    this.mainInfoForm.get('time').updateValueAndValidity();
  }

  setDisabledTimes(): void {
    const chosenDateStr = new Date(this.mainInfoForm.get('date').value).toDateString();
    const today = new Date();
    const todayDateStr = today.toDateString();
    const paddedMinutesStr = `${today.getMinutes()}`.padStart(2, '0');
    const paddedHoursStr = `${today.getHours()}`.padStart(2, '0');
    const todayTimeStr = `${paddedHoursStr}:${paddedMinutesStr}`;

    if (chosenDateStr !== todayDateStr) {
      this.times.forEach((time) => (time.disabled = false));

      return;
    }

    this.times = this.times.map((time) => {
      if (time.fullFormatStr < todayTimeStr) {
        time.disabled = true;
      }

      return time;
    });
  }

  setCurrentDateIfEmpty(): void {
    if (this.isCreateStream && !this.mainInfoForm.get('date').value) {
      const currentDate = new Date(new Date().getTime() + 60);

      this.mainInfoForm.get('date').setValue(currentDate);
      this.setDateFormat(currentDate);
    }
  }

  setPrivateStatus(status?: boolean): void {
    this.isStreamPrivate = status;
  }

  setPaidStatus(status?: boolean): void {
    this.isStreamPaid = status;
  }

  deleteImage(isDeleteImage: boolean): void {
    if (isDeleteImage) {
      this.mainInfoForm.get('thumbnailUrl').reset();
    }
  }

  deleteVideo(isDeleteVideo: boolean): void {
    if (isDeleteVideo) {
      this.mainInfoForm.get('videoUrl').reset();

      if (this.isAdminForm) {
        this.streamUrl = null;
        this.mainCameraVideoUrl = null;
        this.secondCameraVideoUrl = null;
      }
    }
  }

  isPossibleToPickDate = (date: Date): boolean => {
    const today = new Date();
    const todayStr = today.toDateString();
    const dateStr = date.toDateString();

    if (dateStr !== todayStr) {
      return date > today;
    }
    let copyDate = new Date(date.getTime());

    copyDate = setMaximumTime(copyDate);

    return copyDate > today;
  };

  onChefChange(event: { target: EventTarget }): void {
    const selectedChefId = (event.target as HTMLSelectElement).value;
    const selectedChef: UsersListItem = (this.chefs as UsersListItem[])
      .find(chef => chef.id === selectedChefId);

    this.hasChannelCuisines = false;
    this.profileId = selectedChef.profile.id;
    this.productForm.removeAllProducts();
    this.adminCheckboxForm.removeAllCheckboxes();

    forkJoin([
      this.usersService.getUserById(selectedChef.id),
      this.getCuisines(),
      this.getGuests(),
      this.getCollaborators(),
      this.getRecipes(),
      this.getProducts(),
    ])
      .pipe(
        tap(([
          chef,
        ]) => {
          setTimeout(() => this.customButtonForm.setCustomButtons(chef.websites));
          this.subscribeOnVideoChanges();
        }),
        concatMap(() => this.getCuisinesForCurrentChef()),
        concatMap(() => this.getTags()),
        concatMap(() => this.createStream()),
        finalize(() => this.hasChannelCuisines = true),
        untilDestroyed(this),
      ).subscribe();
  }

  private get isValidForms(): boolean {
    return !this.videoTimestampForm.videoTimestampFormIsValid ||
      !this.productForm.productsFormIsValid || !this.customButtonForm.customButtonFormIsValid ||
      !this.adminCheckboxForm.checkboxFormIsValid || !this.mainInfoForm.valid;
  }

  protected filterAvailableSubCuisines(cuisineIds: string[]): void {
    const subCuisineIds: string[] = [];

    cuisineIds.forEach(cuisineId => {
      if (this.hardcodedCuisines[cuisineId]) {
        subCuisineIds.push(...this.hardcodedCuisines[cuisineId]);
      }
    });

    const uniqueSubCuisineIds: string[] = Array.from(new Set(subCuisineIds));

    this.availableSubCuisines = this.cuisines.subCuisine
      .filter(subCuisine => uniqueSubCuisineIds.includes(subCuisine.id));
  }

  protected updateSelectedSubCuisines(cuisineIds: string[]): void {
    this.filterAvailableSubCuisines(cuisineIds);
    this.filterSelectedSubCuisines();
  }

  protected filterSelectedSubCuisines(): void {
    const availableSubCuisineIds = this.availableSubCuisines.map(subCuisine => subCuisine.id);
    const filteredSubCuisineIds = this.mainInfoForm.get('subCuisines').value
      .filter(subCuisine => availableSubCuisineIds.includes(subCuisine));

    this.setSelectedSubCuisine(filteredSubCuisineIds);
    this.selectedSubCuisines = filteredSubCuisineIds;
  }

  private getReplayEmailNotifications(streamEmailSettings: VideoEmailSettings): VideoEmailSettings {
    if (this.isReplayPrice && this.isShowReplayOptionInput) {
      return {
        ...streamEmailSettings,
        replayEmailTrigger: this.isReplayPrice,
        replayCustomTextOne: this.setValue(this.valueFirstReplayCommunicaion, this.streamReplayCommunicaionOne),
        replayCustomTextTwo: this.setValue(this.valueSecondReplayCommunicaion, this.streamReplayCommunicaionTwo),
      };
    } else {
      return {
        ...streamEmailSettings,
        replayEmailTrigger: false,
        replayCustomTextOne: '',
        replayCustomTextTwo: '',
      };
    }
  }

  private getPreEmailNotifications(streamEmailSettings: VideoEmailSettings): VideoEmailSettings {
    if (this.isPreEmailNotifications && this.valuePreBlockFirst) {
      return {
        ...streamEmailSettings,
        preEmailTrigger: this.isPreEmailNotifications,
        preCustomTextOne: this.setValue(this.valuePreBlockFirst, this.streamPreCustomTextOne),
        preCustomTextTwo: this.setValue(this.valuePreBlockSecond, this.streamPreCustomTextTwo),
      };
    } else {
      return {
        ...streamEmailSettings,
        preEmailTrigger: false,
        preCustomTextOne: '',
        preCustomTextTwo: '',
      };
    }
  }

  private getPostEmailNotifications(streamEmailSettings: VideoEmailSettings): VideoEmailSettings {
    if ((this.isUploadVideo || this.isPostEmailNotifications) && this.valuePostBlockFirst) {
      return {
        ...streamEmailSettings,
        postEmailTrigger: true,
        postCustomTextOne: this.setValue(this.valuePostBlockFirst, this.streamPostCustomTextOne),
        postCustomTextTwo: this.setValue(this.valuePostBlockSecond, this.streamPostCustomTextTwo),
      };
    } else {
      return {
        ...streamEmailSettings,
        postEmailTrigger: false,
        postCustomTextOne: '',
        postCustomTextTwo: '',
      };
    }
  }

  private setValue(value: string, name: string): string {
    return value ? name : '';
  }

  private subscribeOnScrollWindow(): void {
    fromEvent(window, 'scroll')
      .pipe(
        map(() => window.scrollY),
        debounceTime(50),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(() => {
        if (this.header) {
          if (window.innerWidth <= TABLET_WIDTH && window.pageYOffset > this.header.nativeElement.offsetTop) {
            this.renderer.setAttribute(this.header.nativeElement, 'class', 'header header--fixed');
          } else {
            this.renderer.setAttribute(this.header.nativeElement, 'class', 'header header--static');
          }
        }
      });
  }

  private createErrorMessages(): void {
    this.errorMessages = [];
    let text: string;
    const extraWord = 'Please';

    setTimeout(() => this.el.nativeElement.querySelectorAll(
      '.errors .form-error__text',
    ).forEach(el => {
      const firstWord = el.innerText.split(' ')[0];

      if (firstWord === extraWord) {
        text = el.innerText.substr(el.innerText.indexOf(' ') + 1);
      } else {
        text = el.innerText;
      }

      const firstChar = text.charAt(0).toUpperCase();
      const fullText = firstChar + text.slice(1);

      this.errorMessages.push(fullText);
    }));
  }

  private scrollToFirstInvalidControl() {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelectorAll(
        '.errors .form-error__text')[0]?.closest('.form-item');

      if (window.innerWidth <= TABLET_WIDTH) {
        window.scroll(0, firstInvalidControl.offsetTop - this.header?.nativeElement?.offsetHeight);
      } else {
        firstInvalidControl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  }

  private filterAllUsers(): void {
    if (this.streamGuests) {
      this.streamGuests.forEach((selectedUser) => {
        this.allUsers = this.allUsers.filter((user) => user.id !== selectedUser.id);
      });
      this.allUsers = [...this.streamGuests, ...this.allUsers];
    }
  }

  private sendSocketEvent(message: SocketEventMessage) {
    this.chatService.sendMassage(message);
  }

  private getStreamUrl(): string {
    return `/${this.chefSlug}/streams/${this.streamSlug}`;
  }

  private saveScheduleInfo(): Observable<SimpleResponse> {
    const dateInputValue = new Date(this.mainInfoForm.get('date').value);
    const timeInputValue = this.mainInfoForm.get('time').value;

    const dateTime = setTime12ToDate(timeInputValue, dateInputValue);

    const date: StreamSchedule = {
      date: dateTime.getTime().toString(),
      notifyFollowers: this.mainInfoForm.get('notifyFollowers').value,
      isDateChanged: (this.streamScheduleDate && this.streamScheduleDate.getTime() !== dateTime.getTime()),
    };

    return this.isCreateStream
      ? this.streamsService.createScheduleDate(this.streamId, date)
      : this.streamsService.deleteScheduleDate(this.streamId).pipe(
        concatMap(() => this.streamsService.createScheduleDate(this.streamId, date)),
        untilDestroyed(this),
      );
  }

  private subscribeOnSearchRecipesInput(): void {
    fromEvent(this.recipesSearch.nativeElement, 'input')
      .pipe(
        map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((query) => this.getRecipes(query)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeOnSearchCollaboratorsInput(): void {
    fromEvent(this.collaboratorsSearch.nativeElement, 'input')
      .pipe(
        map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((query) => this.getCollaborators(query)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeOnTitleChanges(): void {
    this.mainInfoForm.get('title').valueChanges
      .subscribe((title) => this.streamSlug = getValidSlug(title));
  }

  private subscribeOnVideoChanges(): void {
    if (!this.isUploadVideo) {
      return;
    }

    if (!this.isCreateStream) {
      this.uploadStatus = {
        progress: 0,
        state: 'DONE',
      };
    }

    this.mainInfoForm
      .get('videoUrl')
      .valueChanges.pipe(
        switchMap((value) => {
          if (typeof value === 'string' || value === null) {
            return of({});
          }
          let uploadUrls: UploadUrls;

          return this.uploadService.getUploadUrls(value.type, 'video').pipe(
            tap((urls) => (uploadUrls = urls)),
            concatMap(() =>
              this.uploadService.uploadVideo(uploadUrls.uploadSignedUrl, value).pipe(
                tap((upload) => (this.uploadStatus = upload)),
                finalize(() => (this.videoChooser.video = uploadUrls.publicUrl)),
              ),
            ),
            last(),
            concatMap(() =>
              this.durationVideoService.durationVideo$.pipe(
                switchMap((duration: number) =>
                  this.streamsService.uploadVideo(this.streamId, { videoUrl: uploadUrls.publicUrl, duration }),
                ),
              ),
            ),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private createMainInfoForm(): void {
    this.mainInfoForm = this.fb.group({
      title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(this.maxTitleLength)]),
      description: new UntypedFormControl(null, [Validators.required]),
      accessLevel: new UntypedFormControl(StreamAccessLevel.FREE, [Validators.required]),
      thumbnailUrl: new UntypedFormControl(null, [Validators.required]),
      primaryCuisines: new UntypedFormControl([], [Validators.required, Validators.maxLength(3)]),
      privateOption: new UntypedFormControl(PrivateOption.GUESTS),
      subCuisines: new UntypedFormControl([], [Validators.maxLength(3)]),
      streamCuisines: new UntypedFormControl(null, [Validators.required]),
      streamPrice: new UntypedFormControl(null, [StreamPaidValidator]),
      replayPrice: new UntypedFormControl(null, [StreamPaidValidator]),
      replayOption: new UntypedFormControl(ReplayOption.HIDDEN_NON_LIVE),
    });

    if (this.isScheduledStream) {
      this.createScheduleForm();
    }
    if (this.isUploadVideo) {
      this.mainInfoForm.addControl('videoUrl', new UntypedFormControl(null, [Validators.required]));
    }
    if (this.isCreateStream) {
      this.mainInfoForm.get('title').setAsyncValidators(
        slugValidatorFn((slug) => this.streamsService.checkSlugAvailable(slug)),
      );
      this.subscribeOnTitleChanges();
    }

    if (this.isCreateStream && this.isAdminForm) {
      this.createScheduleForm();
      this.mainInfoForm.addControl('chefs', new UntypedFormControl(null, [Validators.required]));
    }
  }

  private createScheduleForm(): void {
    this.mainInfoForm.addControl('date', new UntypedFormControl('', [Validators.required]));
    this.mainInfoForm.addControl(
      'time',
      new UntypedFormControl(null, [Validators.required, ScheduleDateValidator(this.mainInfoForm)]),
    );
    this.mainInfoForm.addControl('notifyFollowers', new UntypedFormControl(true));

    this.times = this.setTimeToSelect();
  }

  private getStreamMainInfo(): Observable<Stream> {
    this.isLoading = true;

    return this.streamsService.getById(this.streamId).pipe(
      tap((stream) => {
        this.isScheduledStream = stream.status === StreamStatus.SCHEDULED;
        this.isLiveStream = stream.status === StreamStatus.LIVE;
        this.isUploadVideo = stream.status === StreamStatus.PAST;
        this.isShow = stream.produced;
        this.hasThumbnail = stream.status !== StreamStatus.LIVE;
        this.profileId = stream.channel.chefProfile.id;
        this.setTitle(stream.status);
        if (stream.status === StreamStatus.PAST) {
          this.mainInfoForm.addControl('videoUrl', new UntypedFormControl(null, [Validators.required]));
        }
      }),
      concatMap((stream) => {
        if (stream.status === StreamStatus.SCHEDULED) {
          this.isScheduledStream = true;
          this.createScheduleForm();

          return this.getScheduleInfo().pipe(map(() => stream));
        } else {
          return of(stream);
        }
      }),
      finalize(() => {
        this.isLoading = false;
        setTimeout(() => this.subscribeOnSearchRecipesInput());
        setTimeout(() => this.subscribeOnSearchCollaboratorsInput());
        this.subscribeOnVideoChanges();
        if (this.isScheduledStream) {
          setTimeout(() => this.setDateFormat(new Date(this.mainInfoForm.get('date').value)));
        }
      }),
      untilDestroyed(this),
    );
  }

  private checkShowToggle(stream: Stream): void {
    if (stream.collaboratorIds.length) {
      this.setStatusCollaborators(true);
    }

    if (stream.recipeIds.length) {
      this.setStatusRecipes(true);
    }
  }

  private getStreamGuests(guestIds?: string[], guests?: UsersListItem[]): void {
    let userGuests;

    this.streamGuestIds = guestIds;
    this.streamGuestIds?.forEach(id => {
      userGuests = guests?.filter(user => user.id === id);
    });

    this.streamGuests = userGuests?.map((user) => {
      return { id: user.profile.id, photo: user.profile.photo, name: user.profile.fullName };
    });
    this.filterAllUsers();
    this.hasPrivateUsers = true;
  }

  private markChosenCollaborators(): void {
    this.collaborators = this.collaborators.map((chef) => {
      chef.addedToStream = this.streamCollaboratorIds?.includes(chef.profile.id);

      return chef;
    });
  }

  protected getCuisines(): Observable<BasicCuisineSchema[]> {
    //TODO: need to improve query body like { itemsPerPage: -1 }
    const params: CuisineSearchParams = {
      page: 1,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
    };

    return this.cuisinesService.getAllShort(params).pipe(
      map((cuisines) => cuisines.results),
      tap((cuisines) => {
        this.cuisines.primary = cuisines.filter((cuisine) => cuisine.type === CuisineType.PRIMARY);
        this.cuisines.subCuisine = cuisines.filter((cuisine) => cuisine.type === CuisineType.SUBCUISINE);
        this.cuisines.stream = cuisines.filter((cuisine) => cuisine.type === CuisineType.STREAM);
      }),
    );
  }

  private getTags(): Observable<Tags> {
    return this.tagsService.getAll()
      .pipe(
        tap((tags) => {
          this.tags = tags;
          this.specialtyTagTypes = this.tagsService.getSpecialtyTagsTypes(tags);
        }),
        untilDestroyed(this),
      );
  }

  private getProducts(): Observable<Product[]> {
    return this.profilesService.getProducts(this.profileId).pipe(
      tap((products) => this.productForm.setProductsValueToForm(products)),
      untilDestroyed(this),
    );
  }

  private getCuisinesForCurrentChef(): Observable<Categories> {
    return this.profilesService.getUserCategories(this.profileId).pipe(
      tap((cuisines) => {
        this.pimarySelectedCuisines = cuisines.cuisines.map((cuisine) => cuisine.id);
        this.selectedSubCuisines = cuisines.subCuisines.map((subCuisine) => subCuisine.id);
        this.mainInfoForm.get('primaryCuisines').patchValue(this.pimarySelectedCuisines);
        this.mainInfoForm.get('subCuisines').patchValue(this.selectedSubCuisines);
        this.updateSelectedSubCuisines(this.pimarySelectedCuisines);
      }),
      finalize(() => (this.hasChannelCuisines = true)),
      untilDestroyed(this),
    );
  }

  private getRecipes(query?: string): Observable<AppPagesItem<Recipe>> {
    const params: RecipesSearchParams = {
      page: DEFAULT_PAGE,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      profileId: this.profileId,
    };

    if (query) {
      params.query = query;
    }

    return this.recipesService.getAll(params).pipe(
      tap((recipes) => {
        this.allRecipes = recipes.results.filter((recipe) => recipe.published);
        this.markChosenRecipes();
      }),
      untilDestroyed(this),
    );
  }

  private addRecipesOfChosenCollaborators(streamCollaboratorIds: string[]): void {
    const params: RecipesSearchParams = {
      page: DEFAULT_PAGE,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
    };

    from(streamCollaboratorIds)
      .pipe(
        concatMap(streamCollaboratorId => {
          return this.recipesService.getByProfileId({
            ...params,
            profileId: streamCollaboratorId,
          });
        }),
        finalize(() => {
          this.markChosenRecipes();
        }),
        untilDestroyed(this),
      ).subscribe((res) => {
        const allRecipesIs = this.allRecipes.map(recipe => recipe.id);
        const uniqueRecipes = res.results.filter(recipe => !allRecipesIs.includes(recipe.id));

        this.allRecipes = [...this.allRecipes, ...uniqueRecipes];
      });
  }

  private removeRecipesOfRemovedCollaborator(collaboratorId: string): void {
    const recipeIdsToRemove = [];

    this.allRecipes = this.allRecipes.filter(recipe => {
      if (recipe.profile.id === collaboratorId) {
        recipeIdsToRemove.push(recipe.id);

        return false;
      } else {
        return true;
      }
    });
    this.streamRecipesIds = this.streamRecipesIds.filter(recipeId => !recipeIdsToRemove.includes(recipeId));
  }

  private markChosenRecipes(): void {
    const streamRecipesIds = this.streamRecipesIds;

    this.allRecipes = this.allRecipes.map((recipe) => {
      recipe.addedToStream = streamRecipesIds.includes(recipe.id);

      return recipe;
    });
  }

  private getGuests(query?: string): Observable<AppPagesItem<UsersListItem>> {
    const params: UsersSearchParams = {
      approved: true,
      page: this.guestsPage,
      itemsPerPage: 25,
      role: [Role.USER, Role.CHEF],
    };

    if (query) {
      params.query = query;
    }

    return this.usersService.getUsers(params).pipe(
      tap((users) => {
        const privateUsers = users.results.map((user) => {
          return {
            id: user.profile.id,
            photo: user.profile.photo,
            name: user.profile.fullName,
          };
        });

        this.totalPrivateUsers = users.total;
        this.totalPagePrivateUsers = users.pageCount;

        if (this.guestsPage > 1) {
          this.allUsers = [...this.allUsers, ...privateUsers];
        } else {
          this.allUsers = privateUsers;
        }
      }),
      untilDestroyed(this),
    );
  }

  private getCollaborators(query?: string): Observable<AppPagesItem<UsersListItem>> {
    const params: UsersSearchParams = {
      approved: true,
      page: DEFAULT_PAGE,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      role: [Role.CHEF],
    };

    if (query) {
      params.query = query;
    }

    return this.usersService.getUsers(params).pipe(
      tap((users) => {
        this.collaborators = users.results.filter((user) => user.profile.id !== this.tokenService.getProfileId());
        this.markChosenCollaborators();
      }),
      untilDestroyed(this),
    );
  }

  private getScheduleInfo(): Observable<StreamSchedule> {
    return this.streamsService.getScheduleDate(this.streamId).pipe(
      tap((schedule) => {
        const date = new Date(schedule.date);

        this.streamScheduleDate = date;

        let hour: any = date.getHours();
        let originalMinute = date.getMinutes();
        const divisionResult = originalMinute % 15;

        if (divisionResult !== 0) {
          if (originalMinute > 45) {
            originalMinute = 0;
            hour++;
          } else {
            originalMinute = originalMinute - divisionResult + 15;
          }
        }

        let minute = originalMinute.toString();

        minute = minute === '0' ? '00' : minute;
        const dateFormat = hour === 0 ? 'am' : hour > 11 ? 'pm' : 'am';

        if (dateFormat === 'am' && hour === 0) {
          hour = '12';
        }

        if (hour > 12) {
          hour = hour - 12;
        }
        schedule.time = `${hour}:${minute} ${dateFormat}`;
        this.mainInfoForm.patchValue(schedule);
        this.mainInfoForm.get('time').markAsDirty();
      }),
      untilDestroyed(this),
    );
  }

  private fillForm(stream: Stream, cuisines: BasicCuisineSchema[], guests: UsersListItem[]): void {
    this.mainInfoForm.patchValue(stream);
    this.selectedSpecialtyTagsIds = this.tagsService.getSpecialtyTagsIds(stream.tags);
    const selectedCuisines = stream.videoCuisines.map((cuisine) => cuisine.id);

    selectedCuisines.forEach((cuisineId) => {
      const selectedCuisine = cuisines.find((cuisine) => cuisine.id === cuisineId);

      if (selectedCuisine) {
        switch (selectedCuisine.type) {
          case CuisineType.PRIMARY:
            this.pimarySelectedCuisines = [...this.pimarySelectedCuisines, selectedCuisine.id];
            break;
          case CuisineType.SUBCUISINE:
            this.selectedSubCuisines = [...this.selectedSubCuisines, selectedCuisine.id];
            break;
          case CuisineType.STREAM:
            this.mainInfoForm.get('streamCuisines').patchValue(selectedCuisine.id);
            this.mainInfoForm.get('streamCuisines').markAsDirty();
            break;
        }
      }
    });

    this.mainInfoForm.get('primaryCuisines').patchValue(this.pimarySelectedCuisines);
    this.mainInfoForm.get('subCuisines').patchValue(this.selectedSubCuisines);
    setTimeout(() => this.tagsSection.setTag(stream.tags[TagsTypeLowercase.style][0], TagsTypeLowercase.style));
    setTimeout(() => this.customButtonForm.setCustomButtons(stream.buttons));
    setTimeout(() => this.productForm.setProductsValueToForm(stream.products));
    setTimeout(() => this.videoTimestampForm.setVideoTimestampValueToForm(stream.timestamps));
    setTimeout(() => this.adminCheckboxForm.setCheckboxValueToForm(
      stream.isChefRecommended, stream.featured, stream.staged, stream.stagedOrder,
    ));
    this.isSelectedSponsor = stream.sponsored;
    this.streamCollaboratorIds = stream.collaboratorIds;
    this.streamRecipesIds = stream.recipeIds;

    this.setPrivateOptions(stream);
    this.setPaidOptions(stream);
    this.setStreamEmailSettings(stream);
    this.setAdminVideoData(stream);
    this.getStreamGuests(stream.guestIds, guests);
    this.updateSelectedSubCuisines(this.pimarySelectedCuisines);
  }

  private getMainInfoFormData(): BasicStream {
    let isPrivateVideo: boolean;

    if (this.isStreamPrivate &&
      this.mainInfoForm.get('privateOption').value === PrivateOption.GUESTS && this.streamGuestIds?.length) {
      isPrivateVideo = true;
    } else {
      isPrivateVideo = false;
    }

    const cuisines: VideoCuisines = {
      cuisines: this.mainInfoForm.get('primaryCuisines').value,
      subCuisines: this.mainInfoForm.get('subCuisines').value,
      streamType: [this.mainInfoForm.get('streamCuisines').value],
    };

    const isHiddenStream = this.isStreamPrivate &&
      (this.mainInfoForm.get('privateOption').value === PrivateOption.HIDDEN);

    let stream: BasicStream = {
      title: this.mainInfoForm.get('title').value,
      description: this.mainInfoForm.get('description').value,
      accessLevel: this.mainInfoForm.get('accessLevel').value,
      videoCuisines: cuisines,
      sponsored: this.isSelectedSponsor,
      status: this.getStreamStatus(),
      slug: this.streamSlug,
      hidden: isHiddenStream,
      videoPrivate: isPrivateVideo,
      collaboratorIds: this.streamCollaboratorIds,
      recipeIds: this.streamRecipesIds,
      buttons: this.customButtonForm.customButtonsControlValueForSave,
      products: this.productForm.productsControlValueForSave,
      timestamps: this.videoTimestampForm.videoTimestampControlValueForSave,
      tags: this.getTagsForBE(),
      isChefRecommended: this.adminCheckboxForm.checkboxControls.isChefRecommended.value,
      featured: this.adminCheckboxForm.checkboxControls.featured.value,
      staged: this.adminCheckboxForm.checkboxControls.staged.value,
      stagedOrder: this.adminCheckboxForm.checkboxControls.stagedOrder.value,
    };

    stream.guestIds = stream.hidden ? [] : this.streamGuestIds;

    if (this.imageUrl) {
      stream.thumbnailUrl = this.imageUrl;
    }

    if (this.isAdminForm) {
      stream.profileId = this.profileId;
    }

    stream = this.addPaidDataToStream(stream);

    return stream;
  }

  private getTagsForBE(): Tags {
    const difficulty: Tag[] = [];
    const spice: Tag[] = [];

    return {
      difficulty,
      spice,
      ...this.tagsSection.getTags(),
    } as Tags;
  }

  private addPaidDataToStream(stream: BasicStream): BasicStream {
    const price = this.mainInfoForm.get('streamPrice').value;
    const replayPrice = this.mainInfoForm.get('replayPrice').value;

    if (!this.isStreamPaid && !price) {
      return stream;
    }

    stream.paidData = {
      active: this.isStreamPaid,
      recipesHidden: true,
    };

    if (this.isShow) {
      stream.paidData.showPrice = price;
    } else if (this.isPastStream) {
      stream.paidData.replayPrice = replayPrice;
      stream.paidData.livePrice = replayPrice;
      stream.paidData.replayType = this.mainInfoForm.get('replayOption').value;
    } else {
      stream.paidData.livePrice = price;
      stream.paidData.replayPrice = this.isShowReplayOptionInput ? replayPrice : price;
      stream.paidData.replayType = this.mainInfoForm.get('replayOption').value;
    }

    return stream;
  }

  private sendStreamSocketEvent(): void {
    if (!this.isCreateStream) {
      this.sendSocketEvent({
        type: SocketEventType.UPDATE_STREAM,
        payload: { streamId: this.streamId },
      });
    }
  }

  private setTitle(status: StreamStatus): void {
    switch (status) {
      case StreamStatus.LIVE:
        this.formLabels.title = 'Set up your stream';
        break;
      case StreamStatus.DRAFT:
        this.formLabels.title = 'Draft';
        break;
      case StreamStatus.PAST:
        this.formLabels.title = 'Past';
        break;
      case StreamStatus.SCHEDULED:
        this.formLabels.title = 'Schedule Stream';
        break;
    }

    if (this.isUploadVideo && this.isCreateStream) {
      this.formLabels.title = 'Upload a show';
      this.formLabels.description = 'Add basic information, recipes, custom buttons, and collaborators to your video.';
      this.formLabels.formTitle = 'stream info';
      this.formLabels.streamTitle = 'title your video';
      this.formLabels.streamDescription = 'description of video';
      this.formLabels.streamCategories = 'Categories';
      this.formLabels.streamThumbnail = 'video thumbnail';
      this.formLabels.saveButton = 'Upload';
    }

    if (this.isLiveStream) {
      this.formLabels.saveButton = 'Next';
    }
  }

  private setTimeToSelect(): Time[] {
    const times: Time[] = [];

    for (let i = 0; i < 2; i++) {
      let hour = 12;
      let min = 0;

      for (let j = 0; j < 48; j++) {
        if (min === 60) {
          hour += 1;
          min = 0;
        }

        const sFormat = i === 0 ? 'am' : 'pm';
        const sHour = hour === 12 ? 12 : hour - 12;
        const sMinute = min === 0 ? '00' : min;
        const amPmFormatStr = `${sHour}:${sMinute} ${sFormat}`;
        const paddedFullFormatStr = convertTime12to24(amPmFormatStr).padStart(5, '0');

        times.push({
          name: amPmFormatStr,
          hour: `${sHour}`,
          minute: `${sMinute}`,
          format: `${sFormat}`,
          fullFormatStr: paddedFullFormatStr,
        });

        min += 15;
      }
    }

    return times;
  }

  private getStreamStatus(): StreamStatus {
    switch (true) {
      case this.isScheduledStream:
        return StreamStatus.SCHEDULED;
      case this.isLiveStream:
        return StreamStatus.PREPMODE;
      case this.isUploadVideo:
        return StreamStatus.PAST;
    }
  }

  private getStreamEmailSettings(): VideoEmailSettings {
    let streamEmailSettings = {
      preEmailTrigger: this.isPreEmailNotifications,
      preCustomTextOne: this.streamPreCustomTextOne,
      preCustomTextTwo: this.streamPreCustomTextTwo,
      postEmailTrigger: this.isPostEmailNotifications,
      postCustomTextOne: this.streamPostCustomTextOne,
      postCustomTextTwo: this.streamPostCustomTextTwo,
      replayEmailTrigger: this.isReplayPrice,
      replayCustomTextOne: this.streamReplayCommunicaionOne,
      replayCustomTextTwo: this.streamReplayCommunicaionTwo,
    };

    if (this.isStreamPaid) {
      streamEmailSettings = this.getPreEmailNotifications(streamEmailSettings);
      streamEmailSettings = this.getPostEmailNotifications(streamEmailSettings);
      streamEmailSettings = this.getReplayEmailNotifications(streamEmailSettings);
    } else {
      streamEmailSettings = {
        preEmailTrigger: false,
        preCustomTextOne: '',
        preCustomTextTwo: '',
        postEmailTrigger: false,
        postCustomTextOne: '',
        postCustomTextTwo: '',
        replayEmailTrigger: false,
        replayCustomTextOne: '',
        replayCustomTextTwo: '',
      };
    }

    return streamEmailSettings;
  }

  private setStreamEmailSettings(stream: Stream): void {
    if (stream.videoEmailSettings) {
      const {
        preCustomTextOne,
        preCustomTextTwo,
        preEmailTrigger,
        postCustomTextOne,
        postCustomTextTwo,
        postEmailTrigger,
        replayEmailTrigger,
        replayCustomTextOne,
        replayCustomTextTwo,
      } = stream.videoEmailSettings;

      this.streamPreCustomTextOne = preCustomTextOne;
      this.streamPreCustomTextTwo = preCustomTextTwo;
      this.streamPostCustomTextOne = postCustomTextOne;
      this.streamPostCustomTextTwo = postCustomTextTwo;
      this.streamReplayCommunicaionOne = replayCustomTextOne;
      this.streamReplayCommunicaionTwo = replayCustomTextTwo;
      this.isReplayPrice = replayEmailTrigger;

      let isPostCustomText: boolean;

      if (preCustomTextOne || preCustomTextTwo) {
        this.isPreEmailNotifications = preEmailTrigger;
      } else {
        this.isPreEmailNotifications = false;
      }

      if (postCustomTextOne || postCustomTextTwo) {
        this.isPostEmailNotifications = postEmailTrigger;
        isPostCustomText = true;
      } else {
        this.isPostEmailNotifications = false;
        isPostCustomText = false;
      }

      this.preBlocksText = {
        first: preCustomTextOne,
        second: preCustomTextTwo,
      };

      this.postBlocksText = {
        first: postCustomTextOne,
        second: postCustomTextTwo,
      };

      this.replayBlocksText = {
        first: replayCustomTextOne,
        second: replayCustomTextTwo,
      };

      this.isEmailNotifications = this.isPreEmailNotifications || this.isPostEmailNotifications ||
        (this.isUploadVideo && isPostCustomText);
    }
  }

  private setPrivateOptions(stream: Stream): void {
    const privateOption =
      stream.hidden ? PrivateOption.HIDDEN : PrivateOption.GUESTS;

    this.mainInfoForm.get('privateOption').patchValue(privateOption);

    if ((stream.videoPrivate && stream.guestIds?.length) || stream.hidden) {
      this.setPrivateStatus(true);
    }
  }

  private setPaidOptions(stream: Stream): void {
    if (!stream.paidData) {
      return;
    }

    const { active, showPrice, livePrice, replayType, replayPrice } = stream.paidData;

    this.isStreamPaid = active;
    const price = this.isShow ? showPrice : livePrice;

    this.mainInfoForm.get('streamPrice').patchValue(price);
    this.mainInfoForm.get('replayPrice').patchValue(replayPrice);
    this.mainInfoForm.get('replayOption').patchValue(replayType);
  }

  private createStream(): Observable<IdObject> {
    this.productForm.isCreateNewProduct = true;

    return this.streamsService
      .create({
        ...this.getMainInfoFormData(),
        status: StreamStatus.DRAFT,
      })
      .pipe(
        tap((resp: IdObject) => (this.streamId = resp.id)),
        finalize(() => {
          this.isLoading = false;
          this.productForm.isCreateNewProduct = false;
          setTimeout(() => this.subscribeOnSearchRecipesInput(), 1);
          setTimeout(() => this.subscribeOnSearchCollaboratorsInput(), 1);
          this.subscribeOnVideoChanges();
          this.subscribeOnTitleChanges();
        }),
        untilDestroyed(this),
      );
  }

  private updateTagsFromRecipe(recipeId: string): void {
    const recipe = this.allRecipes.find(recipe => recipe.id === recipeId);
    const selectedSpecialtyTagsIds = this.tagsService.getSpecialtyTagsIds(this.tagsSection.getTags());
    const recipeSpecialtyTagsIds = this.tagsService.getSpecialtyTagsIds(recipe.tags);
    const recipeStyleTag = recipe.tags[TagsTypeLowercase.style][0];

    this.selectedSpecialtyTagsIds =
      this.tagsService.mergeSpecialtyTagsIds(selectedSpecialtyTagsIds, recipeSpecialtyTagsIds);

    if (recipeStyleTag) {
      this.tagsSection.setTag(recipeStyleTag, TagsTypeLowercase.style);
    }
  }

  private updatedSelectedCuisinesAndSubCuisines(recipeId: string): void {
    const recipe = this.allRecipes.find(recipe => recipe.id === recipeId);

    if (!recipe.cuisines?.length) return;

    const primaryCuisines = recipe.cuisines
      .filter(cuisine => cuisine.type === CuisineType.PRIMARY)
      .map(cuisine => cuisine.id);
    const subCuisines = recipe.cuisines
      .filter(cuisine => cuisine.type === CuisineType.SUBCUISINE)
      .map(cuisine => cuisine.id);
    const primarySelectedCuisines = Array.from(
      new Set([...this.pimarySelectedCuisines, ...primaryCuisines]),
    )
      .slice(0, 3);
    const selectedSubCuisines = Array.from(
      new Set([...this.selectedSubCuisines, ...subCuisines]),
    )
      .slice(0, 3);

    this.setSelectedCuisines(primarySelectedCuisines);
    this.setSelectedSubCuisine(selectedSubCuisines);
  }

  private getChefsProfile(query?: string): Observable<AppPagesItem<any>> {
    const params: UsersSearchParams = {
      role: [Role.CHEF],
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      orderDirection: 'asc' || 'desc',
      orderField: 'fullName',
    };

    if (query) {
      params.query = query;
    }

    return this.usersService.getUsers(params).pipe(
      tap((users) => {
        this.chefs = users.results;
      }),
      untilDestroyed(this),
    );
  }

  private initAdditionalVideo(): void {
    if (this.isAdminForm && this.mainCameraVideoUrl) {
      setTimeout(() => initVideoPlayer('video-player-main', this.mainCameraVideoUrl));
    }

    if (this.isAdminForm && this.secondCameraVideoUrl) {
      setTimeout(() => initVideoPlayer('video-player-pip', this.secondCameraVideoUrl));
    }
  }

  private setAdminVideoData(stream: Stream): void {
    this.streamUrl = stream.videoUrl;
    this.mainCameraVideoUrl = stream.mainCameraVideoUrl;
    this.secondCameraVideoUrl = stream.secondCameraVideoUrl;
  }

  private markFormAsTouched(): void {
    this.mainInfoForm.markAllAsTouched();
    this.productForm.productForm.markAllAsTouched();
    this.customButtonForm.customButtonForm.markAllAsTouched();
    this.videoTimestampForm.videoTimestampForm.markAllAsTouched();
    this.adminCheckboxForm.adminCheckboxForm.markAllAsTouched();
  }

  private getHardcodedCuisines(): Observable<HardcodedCuisinesStructure> {
    return this.cuisinesService.getHardcodedCuisines()
      .pipe(
        tap((hardcodedCuisines) => this.hardcodedCuisines = hardcodedCuisines),
        untilDestroyed(this),
      );
  }
}
