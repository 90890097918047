import { ValidatorFn } from '@angular/forms';
import { VideoTimestampFormControlType } from '@kitch/ui/models/video-timestamp.form';

export function RequiredTimeFieldsValidator(): ValidatorFn {
  return (control: VideoTimestampFormControlType): { [key: string]: unknown } => {
    const { label, streamSecond } = control.value;

    const isStreamSecond = !['00:00', '00:00:00', ''].includes(streamSecond as string);
    const someControlIsFilled = !!(label || isStreamSecond);
    const requiredError = { required: true };

    if (someControlIsFilled) {
      control.get('label').setErrors(label ? null : requiredError);
      control.get('streamSecond').setErrors(isStreamSecond ? null : requiredError);
    } else {
      control.get('label').setErrors(null);
      control.get('streamSecond').setErrors(null);
    }

    if (control.get('label').valid && control.get('streamSecond').valid) {
      return null;
    }

    return requiredError;
  };
}
