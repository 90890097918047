import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { convertMinutesToTime, convertTimeToMinutes } from '@kitch/util';
import { MaxLengthFieldsValidator } from '@kitch/util/validators/max-length-fields.validator';
import { RequiredTimeFieldsValidator } from '@kitch/util/validators/required-time-fields.validator';
import { VIDEO_TIMESTAMP } from '@kitch/ui/constants';
import {
  VideoTimestamp,
  VideoTimestampForm,
  VideoTimestampFormControlType,
  VideoTimestampsForm,
  VideoTimestampsFormArrayType,
} from '@kitch/ui/models/video-timestamp.form';

@Component({
  selector: 'app-video-timestamp-form',
  templateUrl: './video-timestamp-form.component.html',
  styleUrls: ['./video-timestamp-form.component.scss'],
})
export class VideoTimestampFormComponent implements OnInit {
  readonly recommendedLabelMaxLength = 35;

  videoTimestampForm: FormGroup<VideoTimestampsForm>;
  isVideoTimestampContent = false;
  timestampFormat = VIDEO_TIMESTAMP;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createVideoTimestampForm();
  }

  get videoTimestampFormIsValid(): boolean {
    return this.videoTimestampForm.valid;
  }

  get videoTimestampControlValueForSave(): VideoTimestamp[] {
    if (!this.videoTimestampControls.length) {
      return [];
    } else {
      return [...this.videoTimestampControls]
        .map(({ value }: VideoTimestampFormControlType) => value as VideoTimestamp)
        .filter(({ streamSecond, label }) => !!streamSecond && !!label)
        .map((control) => {
          const { streamSecond, label, id } = control;
          const isStreamSecond = !['00:00', '00:00:00', ''].includes(streamSecond as string);

          if (isStreamSecond && label && id === '') {
            delete control.id;
          }

          control.streamSecond = convertTimeToMinutes(control);

          return control;
        });
    }
  }

  get videoTimestampFormArray(): VideoTimestampsFormArrayType {
    return this.videoTimestampForm.get('videoTimestamps') as VideoTimestampsFormArrayType;
  }

  get videoTimestampControls(): VideoTimestampFormControlType[] {
    return this.videoTimestampFormArray.controls;
  }

  setVideoTimestampValueToForm(streamTimestamps: VideoTimestamp[]): void {
    streamTimestamps.sort((a: VideoTimestamp, b: VideoTimestamp) => +a.streamSecond - +b.streamSecond);
    const videoTimestamp: VideoTimestamp[] = streamTimestamps.map((value) => {
      value.streamSecond = convertMinutesToTime(+value.streamSecond);

      return value;
    });

    this.isVideoTimestampContent = !!videoTimestamp.length;

    videoTimestamp.forEach(() => {
      this.addVideoTimestamp();
    });

    this.videoTimestampForm.get('videoTimestamps').patchValue(videoTimestamp);
  }

  onToggleVideoTimestampContent(show?: boolean): void {
    this.isVideoTimestampContent = show;
    if (show && !this.videoTimestampControls.length) {
      this.addVideoTimestamp();
    } else {
      this.videoTimestampFormArray.controls = [];
    }
  }

  addVideoTimestamp(): void {
    const control = this.formBuilder.group<VideoTimestampForm>({
      id: new FormControl<string>(''),
      label: new FormControl<string>(''),
      streamSecond: new FormControl<string>(''),
    }, { validators: [
      RequiredTimeFieldsValidator(),
      MaxLengthFieldsValidator([{ name: 'label', maxLength: this.recommendedLabelMaxLength }]),
    ] });

    this.videoTimestampFormArray.push(control);
  }

  removeVideoTimestamp(id: number): void {
    this.videoTimestampFormArray.removeAt(id);
  }

  private createVideoTimestampForm(): void {
    this.videoTimestampForm = this.formBuilder.group<VideoTimestampsForm>({
      videoTimestamps: this.formBuilder.array<VideoTimestampFormControlType>([]),
    });
  }
}
