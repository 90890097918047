export enum RoutingType {
  CREATE = 'create',
  EDIT = 'edit',
  UPGRADE = 'upgrade'
}

export enum FormType {
  ADMIN = 'admin',
  CHEF = 'chef',
}

export enum OldRoute {
  VIEW_CHANNEL,
  VIEW_CHANNEL_RECIPES,
  VIEW_CHANNEL_STREAMS,
  VIEW_STREAM,
  VIEW_RECIPE,
}

export interface SlugAvailableResponse {
  available: boolean;
}

export interface RefCodeValidatedResponse {
  validated: boolean;
}
