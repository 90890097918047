import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UploadService } from '@kitch/data-access/services';
import { RepeaterTool, UrlTool } from '@kitch/util';
import { RequiredFieldsValidator } from '@kitch/util/validators';
import {
  CustomButton,
  CustomButtonForm,
  CustomButtonFormControlType,
  CustomButtonsForm,
  CustomButtonsFormArrayType,
} from '@kitch/ui/models/custom-button-form';

@Component({
  selector: 'app-custom-button-form',
  templateUrl: './custom-button-form.component.html',
  styleUrls: ['./custom-button-form.component.scss'],
})
export class CustomButtonFormComponent implements OnInit {
  @Input() isCreateStream: boolean;

  isShowSlideToggle = true;
  isShowAddCustomButtons = false;
  customButtonForm: FormGroup<CustomButtonsForm>;

  constructor(
    private uploadService: UploadService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.createCustomButtonForm();
  }

  get customButtonFormIsValid(): boolean {
    return this.customButtonForm.valid;
  }

  get customButtonFormArray(): CustomButtonsFormArrayType {
    return this.customButtonForm.get('buttons') as CustomButtonsFormArrayType;
  }

  get customButtonControls(): CustomButtonFormControlType[] {
    return this.customButtonFormArray.controls;
  }

  get customButtonsControlValueForSave(): CustomButton[] {
    if (!this.customButtonControls.length) {
      return [];
    } else {
      return [...this.customButtonControls]
        .map(({ value }: CustomButtonFormControlType) => value as CustomButton)
        .filter(({ url, label }) => !!url && !!label)
        .map((control) => {
          const { url } = control;

          control.url = UrlTool.checkUrlProtocol(url);

          return control;
        });
    }
  }

  setCustomButtons(customButtons: CustomButton[]): void {
    const filteredButtons = customButtons.filter(site => site.url && site.label);

    this.isShowAddCustomButtons = !!filteredButtons.length;

    if (filteredButtons.length) {
      this.repeatAddCustomButtons();
      this.customButtonForm.get('buttons').patchValue(customButtons);
    }
  }

  showAddCustomButtons(show?: boolean): void {
    this.isShowAddCustomButtons = show;
    if (show && !this.customButtonControls.length) {
      this.repeatAddCustomButtons();
    } else {
      this.customButtonFormArray.controls = [];
    }
  }

  private createCustomButtonForm(): void {
    this.customButtonForm = this.formBuilder.group<CustomButtonsForm>({
      buttons: this.formBuilder.array<CustomButtonFormControlType>([]),
    });
  }

  private repeatAddCustomButtons(): void {
    RepeaterTool.repeat(3).forEach(() => {
      this.customButtonFormArray.push(this.addCustomButton());
    });
  }

  private addCustomButton(): CustomButtonFormControlType {
    return this.formBuilder.group<CustomButtonForm>({
      id: new FormControl<string>(''),
      label: new FormControl<string>(''),
      url: new FormControl<string>(''),
    }, { validators: [RequiredFieldsValidator()] });
  }
}
