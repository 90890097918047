<a [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug">
  <div class="stream-recipe-card stream-recipe-card__wrap">
    <img
      class="stream-recipe-card__img"
      [ngSrc]="stream.thumbnailUrl"
      height="92"
      width="164"
      alt="{{ stream.title }}"
    />
    <app-views-counter
      *ngIf="stream.status === streamStatus.LIVE"
      class="stream__views-counter"
    ></app-views-counter>
    <div class="stream-recipe-card__title-section">
      <div class="stream-recipe-card__top-title-top">FEATURED IN</div>
      <div class="stream-recipe-card__title-name">{{ stream.title }}</div>
    </div>
  </div>
</a>
