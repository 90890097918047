<app-modal #modal (closed)="closeModal()" [showCloseIcon]="true">
  <ng-container header>
    <div class="modal-invite-title">your invite code</div>
  </ng-container>
  <ng-container body>
    <div class="modal-invite-body">
      <div class="ref-code">{{ refCode }}</div>
      <div class="description">Share this code with friends and get rewarded.</div>

      <app-button
        class="copy-ref-code-btn"
        [text]="'Copy Code'"
        [type]="'button'"
        [role]="'primary'"
        (clicked)="copyToClipboardRefCode()"
        [iconUrl]="'assets/ui/images/svg/copy.svg'"
      ></app-button>

      <share-buttons
        class="share-buttons"
        [theme]="'social-buttons'"
        [include]="socialMedia"
        [show]="socialMedia.length"
        [url]="shareUrl"
        [description]="description"
        (opened)="onClickMediaButton($event)"
      ></share-buttons>
    </div>
  </ng-container>
</app-modal>
