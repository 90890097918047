import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { Role } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';

@Injectable()
export class ChefGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> | boolean | UrlTree {
    if (this.tokenService.getRole() === Role.CHEF) {
      return true;
    } else {
      this.router.navigate(['/']);

      return false;
    }
  }
}
