import { Component, Input, OnInit } from '@angular/core';
import { Role } from '@kitch/data-access/models';
import { ProfilesService } from '@kitch/data-access/services';

@Component({
  selector: 'app-avatar-with-out-photo',
  templateUrl: './avatar-with-out-photo.component.html',
  styleUrls: ['./avatar-with-out-photo.component.scss'],
})
export class AvatarWithOutPhotoComponent implements OnInit {
  isDisabled = false;
  roles = Role;
  profileLink: string;
  upperCaseName: string;

  @Input() photo: string;
  @Input() role: Role;
  @Input() id: string;
  @Input() chefSlug: string;
  @Input() isLive: boolean;
  @Input() fullName: string;
  @Input() displayName: string;
  @Input() hasLink = true;

  constructor(private profileService: ProfilesService) {}

  ngOnInit(): void {
    if (!this.chefSlug || !this.hasLink) {
      this.isDisabled = true;
    } else {
      this.profileLink = this.profileService.getProfileLink(this.role, this.id, this.chefSlug);
    }

    switch (this.role) {
      case this.roles.USER:
        this.upperCaseName = this.setUpperCaseName(this.displayName);
        break;
      case this.roles.CHEF:
        this.upperCaseName = this.setUpperCaseName(this.fullName);
        break;
    }
  }

  private setUpperCaseName(name: string): string {
    if (!name) return;

    return name.match(/\b(\w)/g).join('').toUpperCase();
  }
}
