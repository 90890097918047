import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SocialNetworkUrlTool } from '@kitch/util';

export function SocialNetworkUrlValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } => {
    const { url } = control.value;
    const invalidSocialNetworkError = { invalidSocialNetwork: true };

    if (!url || SocialNetworkUrlTool.isSocialNetwork(url)) {
      control.get('url').setErrors(null);

      return null;
    } else {
      control.get('url').setErrors(invalidSocialNetworkError);

      return invalidSocialNetworkError;
    }
  };
}
