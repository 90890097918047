import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '@kitch/data-access/services';

@Injectable()
export class UserPanelGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private routerS: Router,
  ) {
  }

  canActivate(
    router: ActivatedRouteSnapshot,
  ): Observable<boolean> | boolean | UrlTree {
    if (!router.params.id) {
      return false;
    }

    const myProfileId = this.tokenService.getProfileId();

    if (myProfileId !== router.params.id) {
      return this.routerS.parseUrl(`/user-profile/${router.params.id}/recipes/`);
    } else {
      return true;
    }
  }
}
