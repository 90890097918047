import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { from } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';
import {
  GA_EVENT_OPEN_SIGNUP_MODAL,
  GA_EVENT_SIGNUP_FROM_FIRST_TIME_MODAL,
  GA_EVENT_SUBMIT_SIGNUP_MODAL,
  INVALID_CAPTCHA_ERROR,
  INVALID_REF_CODE_ERROR,
} from '@kitch/data-access/constants';
import { environment } from '@kitch/data-access/env/environment';
import { NewUser, ServerError, User } from '@kitch/data-access/models';
import { AuthService, LoggerService, TokenService } from '@kitch/data-access/services';
import { refCodeValidatorFn } from '@kitch/util/validators/ref-code-async.validator';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { TimezonePipe } from '@kitch/ui/pipes';
import { Socials } from '@kitch/user/shared/constants/social';
import { RegisterService } from '@kitch/user/shared/services/register.service';

declare const grecaptcha: any;

@UntilDestroy()
@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss', '../modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterModalComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  @Input()
  readonly inviteId: string;

  @Input() refCode: string;

  @Input()
  readonly isOpen = false;

  @Input()
  readonly pageView: string;

  @Input() source: string;

  @Output()
  readonly isModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  readonly openLoginModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  readonly reloadPage: EventEmitter<void> = new EventEmitter<void>();

  private socialAuthService: SocialAuthService;

  isAlreadySignupModal: boolean;
  isRegistrationCompletedModal: boolean;
  refCodeError: string;
  isSavingRegisterForm: boolean;
  isRefCodeInputShown = false;
  isPhoneShown = false;
  isCaptchaError = false;
  savedProfileId: string;
  initialRefCode: string;
  toResetForm = false;
  socialRegisterForm: FormGroup<{
    refCode: FormControl<string | null>
  }>;

  private usingSSO: Socials;

  constructor(
    private registerService: RegisterService,
    private $gaService: GoogleAnalyticsService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private tokenService: TokenService,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.socialAuthService = inject(SocialAuthService);
    }
  }

  ngOnInit(): void {
    this.savedProfileId = this.tokenService.getProfileId();
    this.initSocialRegisterForm();

    this.authService.socialAuthState$
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        if (this.isOpen) {
          this.usingSSO = user.SSOProvider;

          const userWithRefCode = {
            ...user,
            refCode: this.refCode,
          };

          this.authService.authorizeThroughSSO(userWithRefCode)
            .subscribe({
              next: () => {
                this.onSSOAuthorized(this.usingSSO);
              },
            });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen?.currentValue) {
      this.modal?.open();
      this.$gaService.gtag('event', GA_EVENT_OPEN_SIGNUP_MODAL, {
        time: new TimezonePipe().transform(new Date()),
        trigger_source: this.source,
      });
    }

    if (changes.refCode?.currentValue) {
      const refCode = changes.refCode.currentValue;

      this.initialRefCode = refCode;
      this.socialRegisterForm?.get('refCode').patchValue(refCode);
      this.showRefCodeInput();
    }
  }

  ngOnDestroy() {}

  get refCodeEscaped(): string {
    return this.refCode || '';
  }

  set refCodeEscaped(newRefCode: string) {
    this.refCode = newRefCode;
  }

  emitOpenLoginModal(): void {
    this.closeModal();
    this.setUserAlreadySignup(false);
    this.openLoginModal.emit(true);
  }

  onModalClosed(): void {
    this.isPhoneShown = false;
    this.isModal.emit(false);
  }

  closeModal(): void {
    this.isModal.emit(false);
    this.modal.close();
  }

  setRegistrationCompleteModal(status: boolean): void {
    this.isRegistrationCompletedModal = status;
  }

  setUserAlreadySignup(status: boolean): void {
    this.isAlreadySignupModal = status;
  }

  onSubmitPhoneForm(user: User): void {
    this.isSavingRegisterForm = true;
    this.isCaptchaError = false;
    this.validateCaptchaResponse(user);
  }

  private validateCaptchaResponse(user: User) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    grecaptcha.ready(function() {
      grecaptcha.execute(environment.captchaSiteKey, { action: 'submit' })
        .then(function(captchaResponse) {
          self.register.call(self, user, captchaResponse);
        })
        .catch((error) => {
          self.isCaptchaError = true;
          self.logger.warn('Captcha error', error);
        });
    });
  }

  register(user: User, captchaResponse: string): void {
    if (!captchaResponse) {
      this.isCaptchaError = true;
      this.isSavingRegisterForm = false;

      return;
    }

    this.refCodeError = null;
    this.isCaptchaError = false;

    this.registerService
      .signUp({ ...user, captchaResponse })
      .pipe(
        finalize(() => {
          this.isSavingRegisterForm = false;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: ({ id, profileId, approved }: NewUser) => {
          if (id && profileId && approved) {
            this.$gaService.gtag('event', GA_EVENT_SUBMIT_SIGNUP_MODAL, {
              time: new TimezonePipe().transform(new Date()),
              trigger_source: this.source,
            });
            this.setRegistrationCompleteModal(true);
            this.gtagSignUpFromFirstTimeModal();
            this.closeModal();
            this.toResetForm = true;
          }
        },
        error: ({ message }: ServerError) => {
          if (message.includes('phone_number')) {
            this.setUserAlreadySignup(true);
            this.closeModal();
          }
          if (message === INVALID_REF_CODE_ERROR) {
            this.refCodeError = 'This invite code is not valid.';
          }
          if (message === INVALID_CAPTCHA_ERROR) {
            this.isCaptchaError = true;
          }
        },
      });
    this.$gaService.pageView(this.pageView, 'Sign-up Submit', undefined, { user_phone: user.phoneNumber });
  }

  onMetaBtnClick(socialName: Socials): void {
    from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))
      .pipe(
        concatMap((socialUser) => {
          const user = {
            SSOProvider: Socials.META,
            email: socialUser.email,
            firstName: socialUser.firstName,
            lastName: socialUser.lastName,
            refCode: this.refCode,
            authToken: socialUser.authToken,
          };

          return this.authService.authorizeThroughSSO(user);
        }),
        untilDestroyed(this),
      )
      .subscribe((() => {
        this.onSSOAuthorized(socialName);
      }));
  }

  togglePhoneSection(): void {
    this.isPhoneShown = !this.isPhoneShown;
  }

  showRefCodeInput(): void {
    this.isRefCodeInputShown = true;
  }

  private onSSOAuthorized(method: string) {
    const { pathname } = location;
    const { savedProfileId } = this;
    const newProfileId = this.tokenService.getProfileId();

    this.$gaService.gtag('event', GA_EVENT_SUBMIT_SIGNUP_MODAL, {
      time: new TimezonePipe().transform(new Date()),
      trigger_source: this.source,
      method: method,
    });

    this.gtagSignUpFromFirstTimeModal();
    this.closeModal();
    if (pathname.includes(savedProfileId)) {
      location.replace(pathname.replace(savedProfileId, newProfileId));
    } else {
      this.reloadPage.emit();
    }
  }

  private initSocialRegisterForm(): void {
    this.socialRegisterForm = new FormGroup({
      refCode: new FormControl<string | null>(this.refCode, null, [
        refCodeValidatorFn((refCode) => this.authService.checkRefCode(refCode)),
      ]),
    });

    const refCodeControl = this.socialRegisterForm.get('refCode');

    refCodeControl.valueChanges.subscribe((value) => {
      this.refCode = value;
    });

    refCodeControl.statusChanges.subscribe((status) => {
      if (status !== 'PENDING') {
        this.cdr.detectChanges();
      }
    });
  }

  private gtagSignUpFromFirstTimeModal(): void {
    if (this.source === 'FirstTimeUserSignup' && !this.initialRefCode) {
      const params: { referral_code?: string } = {};

      if (this.refCode) {
        params.referral_code = this.refCode;
      }

      this.$gaService.gtag('event', GA_EVENT_SIGNUP_FROM_FIRST_TIME_MODAL, params);
    }
  }
}
