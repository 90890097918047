<app-modal (closed)="setClosedModal()" #termsModal>
  <ng-container header>
    <h2 class="modal-title">Terms of Use</h2>
  </ng-container>

  <ng-container body>
    <div class="modal-content-wrap">
        <app-terms></app-terms>
    </div>
    <div class="form__item form__item--center">
      <div class="form-button form-button--close">
        <app-button
          class="modal-button"
          [text]="'Close'"
          (clicked)="closeModal()">
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>