import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearHtml',
})
export class ClearHtmlPipe implements PipeTransform {
  transform(htmlContent: string): string {
    const container = document.createElement('div');
    let result = htmlContent;

    result = result.replace(/<\//g, ' </');
    container.innerHTML = result;
    result = container.textContent || container.innerText || '';
    result = result.replace(/\s\s+/g, ' ');

    return result;
  }
}
