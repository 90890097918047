import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';

import { catchError, concatMap, mapTo } from 'rxjs/operators';
import { AuthService, TokenService } from '@kitch/data-access/services';

import { UserProfileService } from '@kitch/user/core/user-profile.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private userProfileService: UserProfileService,
  ) {}

  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.authService.refreshToken().pipe(
      catchError(() => {
        this.authService.stopSession(null, location.pathname, router.queryParams);

        return of(false);
      }),
      concatMap(() => {
        if (
          this.tokenService.isTokenNotExpired() &&
          this.tokenService.isAuthenticated() &&
          this.tokenService.isAdmin()
        ) {
          return forkJoin([
            this.userProfileService.loadUserProfileInfo(),
            this.userProfileService.getFullUserProfile(),
          ]).pipe(mapTo(true));
        } else {
          this.authService.stopSession(null, location.pathname, router.queryParams);

          return of(false);
        }
      }),
    );
  }
}
