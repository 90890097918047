import Hls, { Events } from 'hls.js';
// import { logger } from '@kitch/util/logger.tool';

export interface ParsedVideo {
  base64: string;
  file: File;
}

export function initVideoPlayer(videoPlayerId: string, videoUrl: string, toHideControls = false): Hls | void {
  const video: HTMLMediaElement = document.getElementById(videoPlayerId) as HTMLMediaElement;

  if (typeof videoUrl === 'string' && videoUrl.includes('.m3u8') && Hls.isSupported()) {
    const hls = new Hls();

    hls.attachMedia(video);
    hls.on(Events.MEDIA_ATTACHED, () => {
      // logger.info('video and hls.js are now bound together !');
      if (toHideControls) {
        makeVideoMainstaged(video);
      }

      hls.loadSource(videoUrl);
      hls.on(Events.MANIFEST_PARSED, (event, data) => {
        // logger.info(`manifest loaded, found ${data.levels.length} quality level`);
      });
      hls.on(Events.ERROR, function (event, data) {
        // logger.info('Hls.Events.ERROR', event);
        // logger.info('Hls.Events.ERROR', data);
      });
    });

    return hls;
  } else if (toHideControls) {
    makeVideoMainstaged(video);
  }
}

export function parseVideo(file: File): Promise<ParsedVideo> {
  return new Promise((resolve, reject) => {
    const result: Partial<ParsedVideo> = {};

    result.file = file;

    if (!file) {
      reject('No File was selected');
    }

    const fr = new FileReader();

    fr.addEventListener('load', () => {
      result.base64 = fr.result as string;
      resolve(result as ParsedVideo);
    });

    fr.readAsDataURL(file);
  });
}

const makeVideoMainstaged = (video: HTMLMediaElement): void => {
  video.muted = true;
  video.play();
  video.setAttribute('disablepictureinpicture', '');
  video.setAttribute('playsinline', '');
  // 1050 - the width of desctop site transform to tablet ()
  if (document.body.clientWidth > 1050) {
    video.setAttribute('controlslist', 'nodownload nofullscreen noremoteplayback noplaybackrate');
  } else {
    video.controls = false;
  }
};
