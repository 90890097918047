import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit, OnChanges {
  @ViewChild('termsModal', { static: false }) termsModal: ModalComponent;

  @Input() isOpen = false;

  @Output() isTermsModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.termsModal.open();
    }
  }

  closeModal(): void {
    this.isTermsModalOpen.emit(false);
    this.termsModal.close();
  }

  setClosedModal(): void {
    this.isTermsModalOpen.emit(false);
  }
}
