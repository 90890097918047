<app-modal (closed)="onModalClosed()" #modal data-testid="register-modal">
  <!-- HEADER >> -->
  <ng-container header>
    <button
      *ngIf="isPhoneShown"
      (click)="togglePhoneSection()"
      class="back-btn"
      title="Back to social login section"
    >
      <img
        class="back-icon"
        src="assets/ui/images/svg/arrow-left.svg"
        alt="Back to social login section"
        width="24"
        height="24"
      />
    </button>

    <img
      class="top-flame-modal-icon"
      src="assets/ui/images/svg/kittch-flame-modal-top-icon.svg"
      width="105"
      height="111"
      alt="kittch-flame-modal-top-icon"
    />
    <h3 class="text modal-subtitle">Welcome to Kittch, the food network for a new generation!</h3>
    <h2 class="text modal-title">Create a free account to:</h2>
    <ul class="text header-list">
      <li>
        Access and save amazing recipes
      </li>
      <li>
        Ask chefs questions at any time
      </li>
      <li>
        Follow chefs and save streams
      </li>
    </ul>

    <div class="form__item--new-user question__item">
      <p class="new-user">
        <span class="new-user__text new-user__account">Already part of the Kittch community?</span>
        <button
          data-testid="sign-in-link"
          class="sign-up-in-link"
          (click)="emitOpenLoginModal()"
        >Sign in</button>
       </p>
    </div>
  </ng-container>
  <!-- << HEADER  -->

  <ng-container body>
    <!-- SOCIALS BLOCK >>  -->
    <ng-container *ngIf="!isPhoneShown; else phoneSection" [formGroup]="socialRegisterForm">
      <app-social-buttons
        class="social"
        [isLogin]="false"
        (metaBtnClick)="onMetaBtnClick($event)"
      ></app-social-buttons>

      <app-button
        data-testid="sign-up-modal-phone-btn"
        class="phone-btn"
        [text]="'Sign up with phone'"
        [type]="'button'"
        [role]="'secondary'"
        [iconHeight]="26"
        [iconWidth]="30"
        [iconUrl]="'assets/ui/images/svg/phone-icon.svg'"
        (clicked)="togglePhoneSection()"
      ></app-button>

      <button
        *ngIf="!isRefCodeInputShown"
        data-testid="ref-code-switcher"
        class="ref-code-switcher"
        (click)="showRefCodeInput()"
      >Have an invite code?</button>

      <div
        *ngIf="isRefCodeInputShown"
        data-testid="ref-code-wrapper"
        class="ref-code-wrapper"
      >
        <app-animated-input
          formControlName="refCode"
          class="ref-code"
          label='Invite Code (Optional)'
          isSignupForm="true"
          maxLength="6"
        ></app-animated-input>
        <app-errors-list formControlName="refCode"></app-errors-list>
      </div>
    </ng-container>
    <!-- << SOCIALS BLOCK -->

    <!-- PHONE BLOCK >> -->
    <ng-template #phoneSection>
      <app-register-form
        [refCode]="refCode"
        [refCodeError]="refCodeError"
        [isSaving]="isSavingRegisterForm"
        [isCaptchaError]="isCaptchaError"
        [toResetForm]="toResetForm"
        (submitPhoneForm)="onSubmitPhoneForm($event)"
      ></app-register-form>
    </ng-template>
    <!-- << PHONE BLOCK -->

    <app-entrance-questions [isPhoneShown]="isPhoneShown"></app-entrance-questions>
  </ng-container>
</app-modal>

<app-user-complete-registration-modal
  [isOpen]="isRegistrationCompletedModal"
  (openLoginForm)="emitOpenLoginModal()"
  (isModalOpen)="setRegistrationCompleteModal($event)">
</app-user-complete-registration-modal>

<app-user-already-signup-modal
  [isOpen]="isAlreadySignupModal"
  (openLoginForm)="emitOpenLoginModal()"
  (isUserAlreadySignupOpen)="setUserAlreadySignup($event)">
</app-user-already-signup-modal>
