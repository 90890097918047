import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  forwardRef,
  Renderer2,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
  Inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { GuestUser } from '@kitch/admin/shared/models/user';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';

let nextId = 0;

@UntilDestroy()
@Component({
  selector: 'app-select-dropdown-with-avatar',
  templateUrl: './select-dropdown-with-avatar.component.html',
  styleUrls: ['./select-dropdown-with-avatar.component.scss', './../../select-dropdown-shared.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownWithAvatarComponent),
      multi: true,
    },
  ],
})
export class SelectDropdownWithAvatarComponent
  extends SelectDropdownComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @Output() searchItems: EventEmitter<string> = new EventEmitter<string>();
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  page = 0;
  countItems = 0;

  readonly inputId = `app-select-dropdown-with-avatar-${nextId++}`;

  constructor(
    public renderer: Renderer2,
    protected changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    super(renderer, changeDetector, document);
  }

  ngAfterViewInit(): void {
    this.select = this.document.getElementById(this.inputId);
    this.selectContainer = this.document.querySelector(`#${this.inputId} .ng-select-container`);
    this.inputElement = this.document.querySelector(`#${this.inputId} input`);

    this.renderer.setAttribute(this.inputElement, 'placeholder', this.placeholder);
    if (this.selectedItems?.length) {
      this.showHidePlaceholder();
      this.renderer.setStyle(this.selectContainer, 'border-bottom', '1px solid #636363');
      this.isOpenDropDown = false;
    }

    this.createSelectedLabels();
    this.createSearchIcon();
    this.search();

    if (this.isInviteUserToPrepModeModal) {
      this.addSubtitle();
    }

    this.changeDetector.detectChanges();
  }

  ngOnChanges(ch): void {
    if (ch.items?.currentValue) {
      setTimeout(() => {
        this.createSelectedLabels();
        this.creatingListAvatar(`#${this.inputId} .ng-dropdown-panel-items .ng-option`);
        this.creatingCloseIcon();
      });
    }
  }

  ngOnDestroy(): void {}

  get isDropdownOpen(): boolean {
    return this.isInviteUserToPrepModeModal || this.isOpenDropDown;
  }

  easyLoad(): void {
    this.page++;
    this.changePage.emit(this.page);
  }

  addItem(item?: GuestUser): void {
    this.showHidePlaceholder();
    this.createSearchIcon();

    const valueContainer = this.document.querySelector(`#${this.inputId} .ng-value-container`);

    setTimeout(() => {
      const option = valueContainer.querySelectorAll('.ng-value')[
        valueContainer.querySelectorAll('.ng-value').length - 1
      ];

      this.createAvatar(option, item.photo, item.name);
    });

    if ((this.inputElement as HTMLInputElement).value) {
      this.searchItems.emit('');

      if (this.isInviteUserToPrepModeModal) {
        setTimeout(() => this.updateSubtitle());
      }
    }

    if (this.isInviteUserToPrepModeModal && this.countItems < this.maxSelectedItems) {
      this.countItems += 1;
      this.addOverlayAvatar(item.id);
    }
  }

  removeItem(item: { value: GuestUser }): void {
    this.createSearchIcon();
    this.showHidePlaceholder();

    if (this.isInviteUserToPrepModeModal) {
      this.countItems -= 1;
      this.removeOverlayAvatar(item.value.id);
    }
  }

  removeOverlayAvatar(id: string): void {
    this.items.forEach((item) => {
      if (item.id === id) {
        const currentEl = this.document.getElementById(id).querySelector('.avatar--overlay');

        currentEl.remove();
      }
    });
  }

  checkSelectedItem(id: string): void {
    this.items.forEach((item) => {
      if (item.id === id) {
        setTimeout(() => {
          this.addOverlayAvatar(id);
        }, 10);
      }
    });
  }

  search(): void {
    fromEvent(this.inputElement, 'input')
      .pipe(
        map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
        debounceTime(400),
        tap(() => {
          if (this.isInviteUserToPrepModeModal) {
            this.updateSubtitle();
          }
        }),
        distinctUntilChanged(),
        switchMap(async (query) => this.searchItems.emit(query)),
        untilDestroyed(this),
      )
      .subscribe(() => this.propagateOnTouched());

    this.changeDetector.detectChanges();
  }

  customSearchFn = (): boolean => {
    return true;
  };

  onFocus(): void {
    this.isOpenDropDown = true;
    this.creatingListAvatar(`#${this.inputId} .ng-dropdown-panel-items .ng-option`);

    this.renderer.setStyle(this.selectContainer, 'border-bottom', '1px solid #52C5F2');
  }

  writeValue(): void {}

  onBlur(): void {
    this.propagateOnTouched();
    this.showHidePlaceholder();
    this.renderer.setStyle(this.selectContainer, 'border-bottom', '1px solid #636363');
    this.isOpenDropDown = false;
  }

  onChange(event?: Event): void {
    this.propagateOnChange((event.target as HTMLInputElement).value);
  }

  private createSelectedLabels(): void {
    setTimeout(() => {
      const labels = this.document.querySelectorAll(`#${this.inputId} .ng-value`);

      labels.forEach((item, index) => {
        this.createAvatar(
          item,
          this.selectDropdown?.selectedItems[index]?.value?.photo,
          this.selectDropdown?.selectedItems[index]?.value?.name,
        );
      });
    });
  }

  private creatingListAvatar(path: string): void {
    setTimeout(() => {
      const options = (this.document.querySelectorAll(path) as unknown) as Element[];

      options.forEach((item, index) => {
        item.id = this.items[index]?.id;
        this.createAvatar(item, this.items[index]?.photo, this.items[index]?.name);
      });
    });
  }

  private createAvatar(item: Element, photo: string, displayName: string): void {
    setTimeout(() => {
      if (displayName && item?.querySelectorAll('.avatar').length < 1) {
        if (photo) {
          const img = this.document.createElement('img');

          img.classList.add('avatar');
          item.appendChild(img);
          img.src = photo;
        } else {
          const div = this.document.createElement('div');

          div.classList.add('avatar');
          const span = this.document.createElement('span');

          span.innerHTML = this.getFirstSymbName(displayName);
          item.appendChild(div);
          div.appendChild(span);
        }
      }
    });
  }

  private createSearchIcon(): void {
    setTimeout(() => {
      const inputContainer = this.document.querySelector(`#${this.inputId} .ng-input`);
      const imgSearch = this.document.querySelector(`#${this.inputId} .ng-input img`);

      if (!this.selectedItems?.length) {
        const img = this.document.createElement('img');

        img.classList.add('search-icon');
        inputContainer.appendChild(img);
        img.src = 'assets/ui/images/svg/search-icon.svg';
      } else if (imgSearch) {
        inputContainer.removeChild(imgSearch);
      }
    });
  }

  private getFirstSymbName(name: string): string {
    return name
      ?.match(/\b(\w)/g)
      .join('')
      .toUpperCase();
  }

  private addOverlayAvatar(id: string): void {
    const selectEl = this.document.getElementById(id);
    const div = this.document.createElement('div');
    const img = this.document.createElement('img');

    div.classList.add('avatar--overlay');
    img.src = 'assets/ui/images/svg/checked-black.svg';

    div.appendChild(img);
    selectEl.appendChild(div);
  }

  private addSubtitle(): void {
    const selectContainerEl = this.document.querySelector('.ng-select-container');
    const span = this.document.createElement('span');

    span.classList.add('subtitle');
    selectContainerEl.after(span);
    this.updateSubtitle();
  }

  private updateSubtitle(): void {
    const subtitleEl = this.document.querySelector('.subtitle');

    subtitleEl.textContent = this.inputElement.value.length ? 'Search results' : 'Suggestions';
  }
}
