import { Pipe, PipeTransform } from '@angular/core';
import { RepeaterTool } from '@kitch/util';

@Pipe({
  name: 'arrayCreator',
})
export class ArrayCreatorPipe implements PipeTransform {
  transform(amount: number, startIndexingFrom = 0): number[] {
    return RepeaterTool.repeat(amount).map((i) => i + startIndexingFrom);
  }
}
