import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { ONE_DIGIT } from '@kitch/ui/constants';
import { AnimatedInputComponent } from '../animated-input/animated-input.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-login-verification',
  templateUrl: './login-verification.component.html',
  styleUrls: ['./login-verification.component.scss'],
})
export class LoginVerificationComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('first', { static: true }) firstControl;
  @ViewChild('submit', { static: true }) submitButton;

  authVerifyForm: UntypedFormGroup;
  maskConfig = ONE_DIGIT;
  isSubmited: boolean;

  @Input() loading: boolean;

  @Input() code: string;

  @Input() error: Observable<string>;

  @Output() verifyAuthCode: EventEmitter<string> = new EventEmitter<string>();

  @Output() resendAuthCode: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {
    this.authVerifyForm = new UntypedFormGroup({
      first: new UntypedFormControl(null, [Validators.required]),
      second: new UntypedFormControl(null, [Validators.required]),
      third: new UntypedFormControl(null, [Validators.required]),
      fourth: new UntypedFormControl(null, [Validators.required]),
    });
    this.error.subscribe((error) =>
      this.authVerifyForm.setErrors({ serverError: error }),
    );
    if (this.code) {
      this.setOtp();
    }
  }

  ngAfterViewInit() {
    if (this.code) {
      this.submitButton.button.nativeElement.focus();
    } else {
      this.firstControl.input.nativeElement.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.code && changes.code.currentValue && !changes.code.firstChange) {
      this.code = changes.code.currentValue;
      this.setOtp();
    }
  }

  onConfirm(): void {
    if (this.authVerifyForm.valid) {
      this.isSubmited = false;
      const form = this.authVerifyForm.value;
      const code = form.first + form.second + form.third + form.fourth;

      this.verifyAuthCode.next(code);
    }
  }

  onRecover(): void {
    this.resendAuthCode.next();
    this.authVerifyForm.reset();
    this.authVerifyForm.markAsUntouched();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onInput(event, next): void {
    if (event.target.value) {
      if (next instanceof AnimatedInputComponent) {
        next.input.nativeElement.select();
      }
      if (next instanceof ButtonComponent && this.authVerifyForm.valid) {
        next.button.nativeElement.focus();
      }
    }
  }

  private setOtp(): void {
    const numbers = this.code.split('');

    this.authVerifyForm.get('first').setValue(numbers[0]);
    this.authVerifyForm.get('second').setValue(numbers[1]);
    this.authVerifyForm.get('third').setValue(numbers[2]);
    this.authVerifyForm.get('fourth').setValue(numbers[3]);
  }
}
