import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GA_EVENT_DOWNLOAD_BUTTON_CLICK } from '@kitch/data-access/constants';
import { DownloadStream, Stream } from '@kitch/data-access/models';
import { StreamsService, TokenService } from '@kitch/data-access/services';
import { disableScroll, enableScroll } from '@kitch/util';

@UntilDestroy()
@Component({
  selector: 'app-download-stream-button',
  templateUrl: './download-stream-button.component.html',
  styleUrls: ['./download-stream-button.component.scss'],
})
export class DownloadStreamButtonComponent implements OnInit, OnDestroy {
  @Input() stream: Stream;
  @Input() isAdmin = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  buttonText: string;
  buttonIcon: string;
  windowScroll: number;
  isMobile: boolean;
  isLoading = false;
  isDownloadingStream = false;
  isShownMenu = false;
  isShownBackground = false;
  downloadStream: DownloadStream;

  @HostListener('document:click', ['$event'])
  documentClick(event: { pageY: number, target: Object }): void {
    if (!this.isLoading && !this.elem.nativeElement.contains(event.target)) {
      this.isShownMenu = false;
    }

    if (this.isMobile && !this.isShownMenu && this.elem.nativeElement.contains(event.target)) {
      this.isShownBackground = false;
      window.scrollTo({
        top: event.pageY - 350,
        behavior: 'smooth',
      });
      setTimeout(() => {
        this.windowScroll = window.scrollY;
        disableScroll(this.window);
        this.isShownBackground = true;
      }, 570);
    }
  }

  constructor(
    private readonly elem: ElementRef,
    private readonly streamService: StreamsService,
    private readonly tokenService: TokenService,
    private readonly $gaService: GoogleAnalyticsService,
    private readonly httpClient: HttpClient,
    private readonly responsive: BreakpointObserver,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngOnInit() {
    this.buttonText = this.isAdmin ? 'Download' : '';
    this.buttonIcon = this.isAdmin ? '' : 'assets/ui/images/svg/download.svg';

    this.responsive.observe(['(min-width: 600px)'])
      .pipe(untilDestroyed(this))
      .subscribe((result: BreakpointState) => {
        this.isMobile = Boolean(!result.matches);

        if (this.isMobile && this.isShownMenu) {
          this.windowScroll = window.scrollY;
          this.isShownBackground = true;
          disableScroll(this.window);
        }

        if (!this.isMobile && this.isShownMenu) {
          enableScroll(this.window);
          this.isShownBackground = false;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get infoText(): string {
    return this.isEmptyDownloadStream ?
      'Sorry, something went wrong. Please contact support for help.' :
      'Download options';
  }

  private get isEmptyDownloadStream(): boolean {
    return !this.downloadStream?.videoMpFourUrls && !this.downloadStream?.secondCameraVideoMpFourUrls
      && !this.downloadStream?.mainCameraVideoMpFourUrls;
  }

  onCancelDownloadStream(): void {
    this.destroy$.next();
    this.isLoading = false;
    this.isShownMenu = false;
    this.isDownloadingStream = false;

    if (this.isMobile) {
      enableScroll(this.window);
    }
  }

  onToggleDownloadStream(): void {
    if (!this.isShownMenu) {
      setTimeout(() => this.isShownMenu = true);
      this.isLoading = true;

      this.streamService.getDownloadStream(this.stream.id)
        .pipe(untilDestroyed(this))
        .subscribe((response: DownloadStream) => {
          this.downloadStream = response;
          this.isLoading = false;

          if (!response.secondCameraVideoMpFourUrls && response.mainCameraVideoMpFourUrls) {
            this.onDownloadSelectStream(response.mainCameraVideoMpFourUrls, 'Main.mp4');
          }

          if (!this.isAdmin) {
            this.$gaService.gtag('event', GA_EVENT_DOWNLOAD_BUTTON_CLICK, {
              profile_id: this.tokenService.getProfileId(),
            });
          }
        });
    } else {
      if (!this.isLoading) {
        this.isShownMenu = false;

        if (this.isMobile) enableScroll(this.window);
      }
    }
  }

  onDownloadSelectStream(urls: Array<string>, type: string): void {
    const processedName = this.stream.title.split(' ').join('-');
    const uppercaseFirstLetter = processedName.charAt(0).toUpperCase() + processedName.slice(1);
    const fileName = `${uppercaseFirstLetter}-${type}`;

    this.isLoading = true;
    this.isDownloadingStream = true;
    const request = urls.map((url: string) => {
      return this.httpClient
        .get(url, {
          observe: 'response',
          responseType: 'blob',
        });
    });

    forkJoin(request)
      .pipe(
        takeUntil(this.destroy$))
      .subscribe((response) => {
        response.forEach((item) => {
          const blob: Blob = item.body as Blob;
          const a = document.createElement('a');

          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
        });
        this.onCancelDownloadStream();
      });
  }
}
