export const USERS_URL = 'users';

export const PROFILES_URL = 'profiles';

export const RESTAURANTS_URL = 'restaurants';

export const SPECIALITIES_URL = 'specialities';

export const FOLLOWS_URL = 'follows';

export const SUBSCRIPTIONS_URL = 'subscriptions';

export const REPORTS_URL = 'reports';
