import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { EMPTY, from, fromEvent, Observable } from 'rxjs';
import { catchError, concatMap, filter, map, reduce } from 'rxjs/operators';
import { WalmartGAEvents } from '@kitch/data-access/constants';
import {
  CheckoutParams,
  SHOPPING_CART_KEY,
  ShoppingCartData,
  ShoppingCartRecipe,
  ShoppingCartStatus,
  WalmartRecipesProducts,
  WalmartStoresObject,
} from '@kitch/data-access/models';
import { TokenService, WalmartService } from '@kitch/data-access/services';
import { disableScroll, enableScroll } from '@kitch/util';
import { ProductsQuantityService } from '@kitch/user/core/products-quantity.service';

const TRANSITION_DURATION = 500; // ms
const shoppingCartAnimation: AnimationTriggerMetadata[] = [
  trigger('shoppingCartTrigger', [
    state('open', style({ transform: 'translateX(0)' })),
    state('close', style({ transform: 'translateX(100%)' })),
    transition('open => close', [animate(`${TRANSITION_DURATION}ms ease-in`)]),
    transition('close => open', [animate(`${TRANSITION_DURATION}ms ease-out`)]),
  ]),
];

@UntilDestroy()
@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [shoppingCartAnimation],
})
export class ShoppingCartComponent implements OnInit, OnChanges, OnDestroy {
  @Output()
  readonly closed: EventEmitter<void> = new EventEmitter<void>();

  @Input() walmartRecipeId?: number;

  @Input() portions?: number;

  @Input() isShown: boolean;

  isGuest: boolean;
  isStoreListShown = false;
  quantity: number;
  price: number;
  windowHeight: number = this.window.innerHeight;
  persistedShoppingCartData: ShoppingCartData;
  shoppingCartStatus: ShoppingCartStatus = ShoppingCartStatus.HAS_PRODUCTS;
  ShoppingCartStatus = ShoppingCartStatus;

  readonly shoppingCartOpen = 'open';
  readonly shoppingCartClose = 'close';

  get discountIsAvailable(): boolean {
    return this.totalPriceWithoutDiscount >= 50 && !this.isGuest;
  }

  get totalPriceWithoutDiscount(): number {
    return this.price;
  }

  get totalPriceWithDiscount(): number {
    return this.price - 10;
  }

  constructor(
    private readonly tokenService: TokenService,
    private readonly walmartService: WalmartService,
    private readonly cdr: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer,
    private readonly productsQuantityService: ProductsQuantityService,
    private readonly $gaService: GoogleAnalyticsService,
    private element: ElementRef,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit() {
    this.element.nativeElement.remove();
    this.isGuest = this.tokenService.isGuest();
    this.getShoppingCardDataFromStorage();

    this.productsQuantityService.productsQuantity$
      .pipe(untilDestroyed(this))
      .subscribe((quantity: number) => {
        this.quantity = quantity;
      });

    this.productsQuantityService.productsPrice$
      .pipe(untilDestroyed(this))
      .subscribe((price: number) => {
        this.price = price;
      });

    if (isPlatformBrowser(this.platformId)) {
      this.subscribeToWindowResize();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isShown?.currentValue) {
      this.persistedShoppingCartData = this.productsQuantityService.persistedShoppingCartData;
      this.open();
      this.windowHeight = this.window.innerHeight;

      if (this.walmartRecipeId) {
        this.updateShoppingCartData();
      }
    }
  }

  ngOnDestroy(): void {
    this.element.nativeElement.remove();
    enableScroll(this.window);
  }

  getShoppingCardDataFromStorage(): void {
    const cartData: string = localStorage.getItem(SHOPPING_CART_KEY);

    if (cartData) {
      this.persistedShoppingCartData = JSON.parse(cartData);
    }
  }

  close(): void {
    this.isShown = false;

    if (this.isStoreListShown) {
      // In the future, we should add slide-animation to store list.
      // For the moment, we close whole cart immidiatly in this case.
      this.isStoreListShown = false;
      this.closed.emit();
      this.element.nativeElement.remove();
      enableScroll(this.window);
    } else {
      setTimeout(() => {
        this.isStoreListShown = false;
        this.closed.emit();
        this.element.nativeElement.remove();
        enableScroll(this.window);
      }, TRANSITION_DURATION);
    }
  }

  showStoreList(): void {
    this.isStoreListShown = true;

    this.$gaService.gtag('event', WalmartGAEvents.changeStore, {
      profile_id: this.tokenService.getProfileId(),
      walmart_recipe_id: this.walmartRecipeId,
      current_store_name: this.persistedShoppingCartData.currentStore.accessPointName,
      current_store_id: this.persistedShoppingCartData.currentStore.accessPointId,
    });
  }

  closeStoreList(): void {
    this.isStoreListShown = false;
  }

  onQuantityChanged(): void {
    // we pass product to shopping-cart-product.comp as object,
    // so changing its property(quantity) there will be occurred here, in parent comp
    this.saveChanges();
  }

  onChoseStore(walmartStoresObject: WalmartStoresObject): void {
    this.closeStoreList();
    this.shoppingCartStatus = ShoppingCartStatus.LOADING;
    this.updateStoresInShoppingCartData(walmartStoresObject);

    this.$gaService.gtag('event', WalmartGAEvents.selectNewStore, {
      profile_id: this.tokenService.getProfileId(),
      walmart_recipe_id: this.walmartRecipeId,
      new_store_name: walmartStoresObject.currentStore.accessPointName,
      new_store_id: walmartStoresObject.currentStore.accessPointId,
    });

    this.getShoppingCardDataWithUpdatedProducts().subscribe(shoppingCardData => {
      this.persistedShoppingCartData = shoppingCardData;
      this.saveChanges();
      this.shoppingCartStatus = ShoppingCartStatus.HAS_PRODUCTS;
    });
  }

  checkOut(): void {
    this.shoppingCartStatus = ShoppingCartStatus.LOADING;
    const checkoutProducts = this.walmartService.getProductsFromRecipes(this.persistedShoppingCartData);
    const checkoutParams: CheckoutParams = {
      ap: this.persistedShoppingCartData.currentStore.accessPointId,
      storeId: this.persistedShoppingCartData.currentStore.fulfillmentStoreId,
      offers: checkoutProducts,
    };

    this.$gaService.gtag('event', WalmartGAEvents.cartCheckout, {
      profile_id: this.tokenService.getProfileId(),
      walmart_recipe_id: this.walmartRecipeId,
      checkout_access_point_id: checkoutParams.ap,
      walmart_store_id: checkoutParams.storeId,
      offers_list: JSON.stringify(checkoutParams.offers),
    });

    const win: Window = window.open();

    this.walmartService.getRedirectLink(checkoutParams)
      .subscribe(({ checkoutUrl }) => {
        const safeCheckoutUrl = this.sanitizer.sanitize(SecurityContext.URL, checkoutUrl);

        this.emptyCart();
        this.close();
        // safari doesn't allow window.open() in async code
        win.location.href = safeCheckoutUrl;
      });
  }

  onClearCartClick(): void {
    this.shoppingCartStatus = ShoppingCartStatus.EMPTY;
    this.emptyCart();
    this.close();
  }

  private initShoppingCartData(): void {
    const profileId = this.tokenService.getProfileId();

    this.walmartService.getStores({ profileId }).subscribe(
      stores => {
        if (stores.length) {
          this.updateStoresInShoppingCartData({ stores, currentStore: stores[0] });
          this.getSingleRecipeShoppingCartData().subscribe(
            shoppingCartData => {
              this.persistedShoppingCartData = shoppingCartData;
              this.saveChanges();
              this.shoppingCartStatus = ShoppingCartStatus.HAS_PRODUCTS;
            },
          );
        } else {
          this.shoppingCartStatus = ShoppingCartStatus.STORE_NOT_FOUND;
          this.cdr.markForCheck();
        }
      },
    );
  }

  private updateShoppingCartData(): void {
    this.shoppingCartStatus = ShoppingCartStatus.LOADING;
    if (!this.persistedShoppingCartData.currentStore) {
      // first init
      this.initShoppingCartData();

      return;
    }

    this.getSingleRecipeShoppingCartData().subscribe(
      shoppingCartData => {
        shoppingCartData.recipes.forEach(recipe => this.addRecipeToShoppingCartData(recipe));
        this.saveChanges();
        this.shoppingCartStatus = ShoppingCartStatus.HAS_PRODUCTS;
      },
    );
  }

  private getSingleRecipeShoppingCartData(): Observable<ShoppingCartData> {
    const { stores, currentStore } = this.persistedShoppingCartData;

    return this.walmartService.getRecipesProducts(this.walmartRecipeId, currentStore.fulfillmentStoreId, this.portions)
      .pipe(
        map(recipeProducts => {
          return this.walmartService.mapToShoppingCartData(recipeProducts, { stores, currentStore });
        }),
        catchError(() => {
          this.shoppingCartStatus = ShoppingCartStatus.PRODUCTS_NOT_FOUND;
          console.log(this.shoppingCartStatus);
          this.cdr.markForCheck();

          return EMPTY;
        }),
      );
  }

  private getShoppingCardDataWithUpdatedProducts(): Observable<ShoppingCartData> {
    const { stores, currentStore } = this.persistedShoppingCartData;

    return from(this.persistedShoppingCartData.recipes)
      .pipe(
        concatMap(recipe => {
          return this.walmartService.getRecipesProducts(
            recipe.recipeId,
            currentStore.fulfillmentStoreId,
            recipe.portions,
          );
        }),
        reduce((acc: WalmartRecipesProducts, current: WalmartRecipesProducts) => {
          return {
            products: [...acc.products, ...current.products],
            recipes: [...acc.recipes, ...current.recipes],
          };
        }),
        map((recipeProducts) => {
          return this.walmartService.mapToShoppingCartData(recipeProducts, { stores, currentStore });
        }),
        catchError(() => {
          this.shoppingCartStatus = ShoppingCartStatus.PRODUCTS_NOT_FOUND;
          console.log(this.shoppingCartStatus);
          this.cdr.markForCheck();

          return EMPTY;
        }),
      );
  }

  private open(): void {
    this.getShoppingCardDataFromStorage();
    this.isShown = true;
    disableScroll(this.window);
    document.body.appendChild(this.element.nativeElement);
  }

  private saveChanges(): void {
    localStorage.setItem(SHOPPING_CART_KEY, JSON.stringify(this.persistedShoppingCartData));
    this.cdr.markForCheck();
    this.productsQuantityService.updateProductsQuantity(this.persistedShoppingCartData);
  }

  private updateStoresInShoppingCartData(walmartStoresObject: WalmartStoresObject): void {
    this.persistedShoppingCartData.currentStore = walmartStoresObject.currentStore;
    this.persistedShoppingCartData.stores = walmartStoresObject.stores;
    this.saveChanges();
  }

  private addRecipeToShoppingCartData(recipe: ShoppingCartRecipe): void {
    const existingRecipeIds = this.persistedShoppingCartData.recipes.map(
      existingRecipe => existingRecipe.recipeId,
    );

    if (existingRecipeIds.includes(recipe.recipeId)) {
      this.addExistedRecipe(recipe);
    } else {
      this.addNewRecipe(recipe);
    }
  }

  private addExistedRecipe(recipeToAdd: ShoppingCartRecipe) {
    const persistedRecipe = this.persistedShoppingCartData.recipes
      .find(recipe => recipe.recipeId === recipeToAdd.recipeId);

    persistedRecipe.products.forEach(
      (product, index) => {
        product.quantity = product.quantity + recipeToAdd.products[index]?.quantity || 0;
      },
    );
  }

  private addNewRecipe(recipeToAdd: ShoppingCartRecipe) {
    this.persistedShoppingCartData.recipes.push(recipeToAdd);
  }

  private emptyCart(): void {
    this.productsQuantityService.initEmptyShoppingCartData();
    this.persistedShoppingCartData = this.productsQuantityService.persistedShoppingCartData;
    localStorage.removeItem(SHOPPING_CART_KEY);
    this.productsQuantityService.changeProductsQuantity(0);
    this.productsQuantityService.changeProductsPrice(0);
  }

  private subscribeToWindowResize(): void {
    fromEvent(window, 'resize').pipe(
      filter(() => this.isShown),
      untilDestroyed(this),
    )
      .subscribe(() => {
        this.windowHeight = window.innerHeight;
        this.cdr.markForCheck();
      });
  }
}
