import { RecipeStep, RecipeStepType } from '@kitch/data-access/models';

export class HtmlStepParserTool {
  constructor(private document: Document) {}

  parse(htmlCode: string): RecipeStep[] {
    return this.breakHtmlToSteps(htmlCode).map((stepHtmlCode: string) => this.formatStepHtmlToStepObject(stepHtmlCode));
  }

  private breakHtmlToSteps(html: string): string[] {
    const breakTag = '<br>';
    const startStringTag = '<p>';
    const endStringTag = '</p>';
    const doubleBreak = breakTag + breakTag;
    const regExpSeparator = new RegExp(`${endStringTag}|${doubleBreak}`, 'g');

    return html
      .split(regExpSeparator)
      .filter((step: string) => !!step)
      .map((step: string) => {
        let stepHtmlCode = step;

        if (!step.startsWith(startStringTag) && !step.startsWith(breakTag)) {
          stepHtmlCode = startStringTag + stepHtmlCode;
        }

        if (!step.endsWith(endStringTag)) {
          stepHtmlCode = stepHtmlCode + endStringTag;
        }

        return stepHtmlCode;
      });
  }

  private getStepType(stepHtmlCode: string): RecipeStepType {
    const stepText = this.getTextFromHtml(stepHtmlCode);

    return stepText.slice(-1) === ':' ? 'header' : 'step';
  }

  private formatHtmlCodeForHeader(stepHtmlCode: string): string {
    return this.getTextFromHtml(stepHtmlCode).slice(0, -1);
  }

  private getTextFromHtml(html: string): string {
    const tempDivElement = this.document.createElement('div');

    tempDivElement.innerHTML = html;

    return tempDivElement.textContent.trim();
  }

  private formatStepHtmlToStepObject(stepHtmlCode: string): RecipeStep {
    const type = this.getStepType(stepHtmlCode);

    return {
      type,
      value: type === 'header' ? this.formatHtmlCodeForHeader(stepHtmlCode) : stepHtmlCode,
    };
  }
}
