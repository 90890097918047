<ng-container *ngTemplateOutlet="recipeProduct; context: product"></ng-container>

<ng-template
  #recipeProduct
  let-title="title"
  let-price="offerPrice"
  let-quantity="quantity"
>
  <div
    *ngIf="product"
    class="recipe-product"
    [ngClass]="{'recipe-product--zero-quantity': quantity === 0}"
  >
    <img
      class="recipe-product__img"
      [src]="productImgUrl"
      alt="{{ title }}"
    />
    <div class="recipe-product__content" >
      <h5 class="recipe-product__title">{{ title }}</h5>
      <div class="recipe-product__info">
        <ng-container *ngIf="product.status === ProductStatus.IN_STOCK; else outOfStock">
          <span class="recipe-product__price">${{ price }}</span>

          <!-- MINUS(BUCKET), QUANTITY, PLUS, ADD BUTTONS BLOCK >> -->
          <div class="recipe-product__quantity-block">
            <ng-container *ngIf="quantity > 0; else addButton">
              <button
                class="recipe-product__quantity-button button-states"
                (click)="changeQuantity(quantity - 1)"
              >
                <img
                  *ngIf="quantity > 1; else removeIcon"
                  src="assets/ui/images/svg/shopping-cart-minus.svg"
                  alt="Decrease quantity"
                  width="24"
                  height="24"
                />
                <ng-template #removeIcon>
                  <img
                    src="assets/ui/images/svg/shopping-cart-bucket.svg"
                    alt="Remove item"
                    width="24"
                    height="24"
                  />
                </ng-template>
              </button>

              <span class="recipe-product__quantity">{{ quantity }}</span>

              <button
                class="recipe-product__quantity-button button-states"
                (click)="changeQuantity(quantity + 1)"
              >
                <img
                  src="assets/ui/images/svg/shopping-cart-plus.svg"
                  width="24"
                  height="24"
                  alt="Increase quantity"/>
              </button>
            </ng-container>

            <ng-template #addButton>
              <button
                class="recipe-product__add-button button-states"
                (click)="changeQuantity(1)"
              >
                <img
                  class="recipe-product__add-button-plus"
                  src="assets/ui/images/svg/show-description.svg"
                  alt="Add item"
                  width="16"
                  height="16"
                />
                <span class="recipe-product__add-button-text">Add</span>
              </button>
            </ng-template>
          </div>
          <!-- << MINUS(BUCKET), QUANTITY, PLUS, ADD BUTTONS BLOCK -->
        </ng-container>

        <ng-template #outOfStock>
          <span class="recipe-product__out-ofStock">OUT OF STOCK</span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
