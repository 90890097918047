import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, ParamMap } from '@angular/router';
import { NgxLoggerLevel } from 'ngx-logger';
import { forkJoin, Observable } from 'rxjs';
import { catchError, concatMap, mapTo, switchMap } from 'rxjs/operators';
import { AuthService, TokenService } from '@kitch/data-access/services';
import { UserProfileService } from '@kitch/user/core/user-profile.service';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private userProfileService: UserProfileService,
  ) {}

  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const instagramSSOLoginId = router.queryParams.instagram_sso_login_id;

    if (instagramSSOLoginId) {
      return this.loginWithInstagramSSOId(instagramSSOLoginId, router.paramMap);
    }

    return this.authService.refreshToken().pipe(
      catchError(() => {
        return this.loginAsGuest();
      }),
      concatMap(() => {
        if (
          this.tokenService.isTokenNotExpired() &&
          this.tokenService.isAuthenticated()
        ) {
          return this.loadInfoForAuthenticatedUser();
        } else {
          return this.loginAsGuest();
        }
      }),
    );
  }

  private loadInfoForAuthenticatedUser(): Observable<boolean> {
    if (!this.tokenService.isGuest()) {
      this.authService.setTimeoutToRefreshToken();
    }
    if (this.tokenService.isAdmin()) {
      // Todo: ssr temp workaround
      // logger.updateConfig({
      //   ...logger.getConfigSnapshot(),
      //   level: NgxLoggerLevel.TRACE,
      // });
    }
    // Todo: ssr temp workaround
    // logger.setCustomHttpHeaders(new HttpHeaders(`Authorization: Bearer ${this.tokenService.getToken()}`));

    return forkJoin([
      this.userProfileService.loadUserProfileInfo(),
      this.userProfileService.getFullUserProfile(),
    ]).pipe(mapTo(true));
  }

  private loginWithInstagramSSOId(instagramSSOLoginID: string, paramMap: ParamMap): Observable<boolean> {
    return this.authService.loginWithInstagramSSOId(instagramSSOLoginID, paramMap)
      .pipe(
        switchMap(() => this.loadInfoForAuthenticatedUser()),
      );
  }

  private loginAsGuest(): Observable<boolean> {
    return this.authService.loginAsGuest().pipe(
      concatMap(() => forkJoin([
        this.userProfileService.loadUserProfileInfo(),
        this.userProfileService.getFullUserProfile(),
      ])),
      mapTo(true),
    );
  }
}
