import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TAGS_URL } from '@kitch/data-access/constants';
import {
  SpecialtyTags,
  SpecialtyTagsIds,
  Tags,
  TagsTypeLowercase,
} from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services/api.service';
import { MAX_SELECTED_TAGS } from '@kitch/ui/constants';

@Injectable()
export class TagsService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<Tags> {
    return this.apiService.get(TAGS_URL);
  }

  getSpecialtyTagsTypes(tags: Tags): TagsTypeLowercase[] {
    const tagTypes = [] as TagsTypeLowercase[];

    Object.keys(tags).forEach(tagKey => {
      switch (tagKey) {
        case TagsTypeLowercase.difficulty:
        case TagsTypeLowercase.spice:
          break;
        default:
          tagTypes.push(TagsTypeLowercase[tagKey]);
      }
    });

    return tagTypes;
  }

  getSpecialtyTagsIds(tags: Tags | SpecialtyTags): SpecialtyTagsIds {
    const specialtyTags = { ...tags };
    const tagsIds = {};

    delete specialtyTags[TagsTypeLowercase.difficulty];
    delete specialtyTags[TagsTypeLowercase.spice];

    Object.keys(specialtyTags).forEach(tagKey => {
      tagsIds[tagKey] = specialtyTags[tagKey].map(tag => tag.id);
    });

    return <SpecialtyTagsIds>tagsIds;
  }

  mergeSpecialtyTagsIds(oldTags: SpecialtyTagsIds, newTags: SpecialtyTagsIds): SpecialtyTagsIds {
    const resultTagsIds: SpecialtyTagsIds = { ...oldTags };

    Object.keys(newTags).forEach(tagKey => {
      const newTagIds = newTags[tagKey];
      const resultTagIds = resultTagsIds[tagKey];

      newTagIds.forEach(newTagId => {
        const isNewTagAlreadyAdded = !!resultTagIds.find(oldTagId => oldTagId === newTagId);

        if (!isNewTagAlreadyAdded) {
          resultTagIds.push(newTagId);
        }
      });

      resultTagsIds[tagKey] = resultTagIds.slice(0, MAX_SELECTED_TAGS);
    });

    return resultTagsIds;
  }
}
