import { Component, Input, OnInit } from '@angular/core';
import { AvatarSize } from '@kitch/ui/types';

type AvatarInputTextColor = 'black' | 'white' | 'green';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() url: string;
  @Input() altText = 'avatar';
  @Input() size: AvatarSize = 'medium';
  @Input() avatarInputTextColor: AvatarInputTextColor = 'black';
  @Input() name: string;
  @Input() userName: string;
  @Input() title: string;
  @Input() isYou: boolean;
  @Input() isLive: boolean;
  @Input() isClub = false;

  upperCaseName = '';

  constructor() {}

  ngOnInit() {
    this.setUpperCaseName();
  }

  private setUpperCaseName(): void {
    const matchedName = (this.name || this.userName || this.upperCaseName).match(/\b(\w)/g);

    if (matchedName) {
      this.upperCaseName = matchedName.join('').toUpperCase();
    }
  }
}
