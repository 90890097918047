import { Channel } from '@kitch/data-access/models/profile';
import { Product } from '@kitch/ui/models';
import { CustomButton } from '@kitch/ui/models/custom-button-form';
import { Socials } from '@kitch/user/shared/constants/social';

export enum Role {
  CHEF = 'CHEF',
  GUEST = 'GUEST',
  USER = 'USER'
}

export enum CommonUserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface User {
  bannerImage: string;
  bio: string;
  captchaResponse?: string;
  chefChannel: string;
  chefName: string;
  chefSlug?: string;
  cuisines: {
    cuisines: string[];
    subCuisines: string[];
  };
  displayName?: string;
  email: string;
  featured?: boolean;
  firstName?: string;
  fullName?: string;
  hometown: string;
  inviteId?: string;
  lastName?: string;
  phoneNumber: string;
  photo: string;
  products?: Product[];
  recommendedPlaces: Partial<CustomButton>[];
  referral?: string;
  tileImage: string;
  websites: Partial<CustomButton>[];
  zipCode?: number;
}

export interface SSOUser {
  SSOProvider: Socials;
  authToken: string;
  email: string;
  firstName?: string;
  inviteId?: string;
  lastName?: string;
  phoneNumber?: string;
  refCode?: string;
}

export interface NewUser {
  approved?: boolean;
  id: string;
  planId?: string;
  profileId: string;
}

export interface UserAccount {
  cuisines: UserCuisine[];
  email: string;
  phoneNumber: string;
  products: Product[];
  profile: UserChannel;
  recipes: UserRecipes[];
  recommendedPlaces: CustomButton[],
  restaurants: CustomButton[];
  role: Role;
  subCuisines: UserCuisine[];
  videos: UserVideos[];
  websites: CustomButton[];
}

interface UserChannel {
  badgeImageUrl?: string;
  bio: string;
  channel: Channel;
  chefChannel: string;
  chefName: string;
  displayName: string;
  fullName?: string;
  hometown: string;
  id: string;
  photo: string;
  zipCode?: string;
}

export interface UserCuisine {
  id: string;
  name: string;
}

interface UserRecipes {
  id: string;
  recipeName: string;
}

interface UserVideos {
  id: string;
  title: string;
  videoUrl: string;
}
