import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnInit,
  SimpleChange,
  ViewContainerRef,
} from '@angular/core';
import { ACTION_AFTER_LOGIN_KEY, CommonUserRole, REF_CODE_KEY, STREAM_ID } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';
import { LoginRegisterModalWrapperComponent } from '@kitch/ui/components';

export type AfterLoginAction = 'showDevicesModal' |'showPurchaseModal';

@Directive({
  selector: '[appNeedAuthorize]',
})
export class NeedAuthorizeDirective implements OnInit {
  @Input() afterLoginAction: AfterLoginAction;

  @Input() streamId: string;

  @Input() source: string;

  componentRef: ComponentRef<LoginRegisterModalWrapperComponent>;
  isFirstClick = true;

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private tokenService: TokenService,
  ) {
  }

  ngOnInit() {
    if (this.tokenService.isGuest()) {
      this.interceptClickEvents();
    }
  }

  private interceptClickEvents(): void {
    this.element.nativeElement.addEventListener('click', (event) => {
      if (this.isFirstClick) {
        this.initLoginModal();
        this.componentRef.instance.source = this.source;
      }

      this.isFirstClick = false;
      const changes = {
        isRegisterModal: new SimpleChange(this.componentRef.instance.isRegisterModal, true, this.isFirstClick),
        userRole: new SimpleChange(this.componentRef.instance.userRole, CommonUserRole.USER, this.isFirstClick),
        refCode: new SimpleChange(
          this.componentRef.instance.refCode,
          window.sessionStorage.getItem(REF_CODE_KEY),
          this.isFirstClick,
        ),
        source: new SimpleChange(this.componentRef.instance.source, this.source, this.isFirstClick),
      };

      setTimeout(() => this.componentRef.instance.ngOnChanges(changes));

      event.stopPropagation();
      event.preventDefault();
    }, true);
  }

  private initLoginModal(): void {
    const componentFactory = this.componentFactoryResolver
      .resolveComponentFactory(LoginRegisterModalWrapperComponent);

    this.componentRef = this.viewContainerRef.createComponent(componentFactory);

    if (this.afterLoginAction) {
      this.componentRef.instance.pageReloaded
        .subscribe(() => {
          window.sessionStorage.setItem(ACTION_AFTER_LOGIN_KEY, this.afterLoginAction);
          if (this.streamId) {
            window.sessionStorage.setItem(STREAM_ID, this.streamId);
          }
        });
    }

    document.body.appendChild(this.componentRef.location.nativeElement);
  }
}
