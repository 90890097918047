export interface Discount {
  code: string;
  id: string;
  type: DiscountType;
  value: number;
}

export enum DiscountType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}
