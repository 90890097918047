import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@kitch/data-access/services';
import { ModalComponent } from '@kitch/ui/components';
import { DevicesFormValue } from '@kitch/user/shared/models';

@Component({
  selector: 'app-select-devices-modal',
  templateUrl: './select-devices-modal.component.html',
  styleUrls: ['./select-devices-modal.component.scss'],
})
export class SelectDevicesModalComponent implements OnInit, OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() isOpen = false;

  @Input() isSubmitDisabled = false;

  @Input() isStreamOwner: boolean;

  @Input() microphones: MediaDeviceInfo[] = [];

  @Input() cameras: MediaDeviceInfo[] = [];

  @Output() formSubmit: EventEmitter<DevicesFormValue> = new EventEmitter<DevicesFormValue>();

  @Output() modalOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  devicesForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private logger: LoggerService,
  ) { }

  ngOnInit(): void {
    this.createDevicesForm();
  }

  async ngOnChanges(ch): Promise<void> {
    if (ch.isOpen) {
      if (ch.isOpen.currentValue) {
        await this.fillDevicesForm();
        this.modal.open();
      } else {
        this.modal?.close();
      }
    }
  }

  submit(): void {
    this.devicesForm.markAllAsTouched();
    if (this.devicesForm.valid) {
      this.formSubmit.emit(this.devicesForm.value);
    }
  }

  closeModal(): void {
    this.modalOpenChanged.emit(false);
  }

  private createDevicesForm(): void {
    const validators = this.isStreamOwner ?
      [Validators.required] :
      [];

    this.devicesForm = this.fb.group({
      microphone: new UntypedFormControl(null, validators),
      camera: new UntypedFormControl(null, validators),
    });

    if (this.isStreamOwner) {
      this.devicesForm.addControl('secondCamera', new UntypedFormControl(null));
    }
  }

  private async fillDevicesForm() {
    try {
      await this.getMicrophones();
      this.setDefaultMicrophone();
    } catch (e) {
      this.logger.warn('#fillDevicesForm getMicrophones error: ', e);
      try {
        await this.getMicrophonesBrowserApi();
        this.setDefaultMicrophone();
      } catch (e) {
        this.logger.warn('#fillDevicesForm getMicrophonesBrowserApi error: ', e);
      }
    }

    try {
      await this.getCameras();
      this.setDefaultCamera();
    } catch (e) {
      this.logger.warn('#fillDevicesForm getCameras error: ', e);
      try {
        await this.getCamerasBrowserApi();
        this.setDefaultCamera();
      } catch (e) {
        this.logger.warn('#fillDevicesForm getCamerasBrowserApi error: ', e);
      }
    }
  }

  private setDefaultMicrophone(): void {
    const currentMic = this.microphones[0];

    if (currentMic) {
      this.devicesForm.get('microphone').patchValue(currentMic.deviceId);
    } else {
      this.logger.warn('#setDefaultMicrophone empty default microphone: ', new Error('Empty default microphone'));
    }
  }

  private setDefaultCamera(): void {
    const currentCam = this.cameras[0];

    if (currentCam) {
      this.devicesForm.get('camera').patchValue(currentCam.deviceId);
    } else {
      this.logger.warn('#setDefaultCamera empty default camera: ', new Error('Empty default camera'));
    }
  }

  private async getCameras(): Promise<void> {
    // fix empty label in case when user doesn't save his requested permission answer (not set checkbox)
    // if user closes the modal window and tries to join the stream again we get empty string for device label
    this.cameras = (await AgoraRTC.getCameras()).map((device) => {
      this.logger.info('#getCameras get camera device from agora: ', device);
      if (!device.label) {
        const foundCamera = this.cameras.find((cam) => cam.deviceId === device.deviceId);

        if (foundCamera?.label) return foundCamera;
      }

      return device;
    });
    this.logger.info('#getCameras full cameras list: ', this.cameras);
  }

  private async getMicrophones(): Promise<void> {
    // fix empty label in case when user doesn't save his requested permission answer (not set checkbox)
    // if user closes the modal window and tries to join the stream again we get empty string for device label
    this.microphones = (await AgoraRTC.getMicrophones()).map((device) => {
      this.logger.info('#getMicrophones get microphone device from agora: ', device);
      if (!device.label) {
        const foundMicrophone = this.microphones.find((mic) => mic.deviceId === device.deviceId);

        if (foundMicrophone?.label) return foundMicrophone;
      }

      return device;
    });
    this.logger.info('#getMicrophones full microphones list: ', this.microphones);
  }

  private async getMicrophonesBrowserApi(): Promise<void> {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    this.microphones = (await navigator.mediaDevices.enumerateDevices())
      .filter((device) => device.kind === 'audioinput');
    this.logger.info('#getMicrophonesBrowserApi microphones: ', this.microphones);
  }

  private async getCamerasBrowserApi(): Promise<void> {
    await navigator.mediaDevices.getUserMedia({ video: true });
    this.cameras = (await navigator.mediaDevices.enumerateDevices())
      .filter((device) => device.kind === 'videoinput');
    this.logger.info('#getCamerasBrowserApi cameras: ', this.cameras);
  }
}
