<div
  *ngIf="isShownMenu && isShownBackground"
  class="download-menu-background"
  [ngStyle]="{ top: windowScroll + 'px' }"
  (click)="onToggleDownloadStream()"
></div>

<div class="download-wrapper">
  <app-button
    class="download-wrapper__button"
    [text]="buttonText"
    [iconUrl]="buttonIcon"
    [role]="'secondary'"
    (click)="onToggleDownloadStream()"
  >
    <ng-container right-side>
      <div class="download-menu download-menu__tooltip">
        <h3 class="text download-menu__tooltip-title">Download stream</h3>
      </div>
    </ng-container>
  </app-button>

  <div class="download-menu download-menu__dropdown" *ngIf="isShownMenu">
    <h3 class="download-menu__dropdown-title" *ngIf="!isLoading">{{ infoText }}</h3>
    <ng-container *ngIf="isLoading else dropdownMenu">
      <div  class="download-menu__progress-wrapper">
        <div class="progress">
          <div class="progress__line"></div>
        </div>
      </div>
      <button
        *ngIf="isDownloadingStream"
        class="text download-menu__button"
        type="button"
        (click)="onCancelDownloadStream()"
      >Cancel</button>
    </ng-container>

    <ng-template #dropdownMenu>
      <button
        *ngIf="downloadStream?.videoMpFourUrls"
        class="text download-menu__button"
        type="button"
        (click)="onDownloadSelectStream(downloadStream.videoMpFourUrls,'Full.mp4')"
      >Full Video</button>

      <button
        *ngIf="downloadStream?.mainCameraVideoMpFourUrls"
        class="text download-menu__button"
        type="button"
        (click)="onDownloadSelectStream(downloadStream.mainCameraVideoMpFourUrls,'Main.mp4')"
      >Main Screen Only</button>

      <button
        *ngIf="downloadStream?.secondCameraVideoMpFourUrls"
        class="text download-menu__button"
        type="button"
        (click)="onDownloadSelectStream(downloadStream.secondCameraVideoMpFourUrls,'PIP.mp4')"
      >Picture-in-Picture Only</button>
    </ng-template>
  </div>
</div>
