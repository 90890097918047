<div class="card-wrap card-wrap--{{type}}" [ngClass]="{ 'card-wrap--selected': card.id === selectedCardId }">
  <div *ngIf="type === 'modal'" class="card-label">Payment Method</div>
  <div class="card-inner-wrap card-inner-wrap--{{type}}">
    <div class="card-inner-wrap">
      <div class="card-brand card-brand--{{type}}"></div>
<!--      <picture class="card-brand">-->
<!--        <source srcset="{{ 'assets/ui/images/png/no-image.png' }}" />-->
<!--        <img class="card-brand" src="assets/ui/images/png/no-image.png" alt="{{ card.brand }}" />-->
<!--      </picture>-->
      <div class="card-info-wrap">
        <div class="card-name-wrap">
          <div class="card-name">{{ card.brand?.toUpperCase() }} ending in {{ card.last4 }}</div>
          <div *ngIf="type === 'wallet' && card.primary" class="card-primary">Default Payment Method</div>
          <div *ngIf="!card.primary" class="make-card-primary">Make Default Card</div>
        </div>
        <div class="card-expire-date">
          Expires {{ card.expMonth > 9 ? card.expMonth : '0' + card.expMonth }}/{{ card.expYear.toString() | slice:2 }}
        </div>
      </div>
    </div>
    <button
      *ngIf="type === 'wallet'"
      class="card-delete-btn"
      (click)="deleteCard($event)"
      data-testid="credit-card-delete-btn"
    >Delete</button>
  </div>
</div>
