import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auth-required-block',
  templateUrl: './auth-required-block.component.html',
  styleUrls: ['./auth-required-block.component.scss'],
})
export class AuthRequiredBlockComponent {
  @Input() source: string;
}
