import { ArrayCreatorPipe } from '@kitch/ui/pipes/array-creator/array-creator.pipe';
import { RatingDescriptionPipe } from '@kitch/ui/pipes/rating-description/rating-description.pipe';
import { ClearHtmlPipe } from './clear-html/clear-html.pipe';
import { ConvertMinutesToTimePipe } from './convert-minutes-to-time/convert-minutes-to-time.pipe';
import { CustomCurrencyPipe } from './custom-currency/custom-currency.pipe';
import { DurationVideoPipe } from './duration-video/duration-video.pipe';
import { DurationPipe } from './duration/duration.pipe';
import { FormArrayControlsPipe } from './form-array-controls/form-array-controls.pipe';
import { ReversePipe } from './reverse/reverse.pipe';
import { UrlSafePipe } from './safe-url/url-safe.pipe';
import { ShortNamePipe } from './short-name/short-name.pipe';
import { ShortNumberPipe } from './short-number/short-number.pipe';
import { TimezonePipe } from './timezone/timezone.pipe';
import { LimitWordsPipe } from './words-limit/words-limit.pipe';

export * from './array-creator/array-creator.pipe';
export * from './rating-description/rating-description.pipe';
export * from './clear-html/clear-html.pipe';
export * from './custom-currency/custom-currency.pipe';
export * from './duration/duration.pipe';
export * from './duration-video/duration-video.pipe';
export * from './short-name/short-name.pipe';
export * from './short-number/short-number.pipe';
export * from './timezone/timezone.pipe';
export * from './safe-url/url-safe.pipe';
export * from './words-limit/words-limit.pipe';
export * from './form-array-controls/form-array-controls.pipe';
export * from './reverse/reverse.pipe';
export * from './convert-minutes-to-time/convert-minutes-to-time.pipe';

export const uiPipes = [
  ClearHtmlPipe,
  CustomCurrencyPipe,
  DurationPipe,
  ShortNamePipe,
  ShortNumberPipe,
  DurationVideoPipe,
  TimezonePipe,
  UrlSafePipe,
  LimitWordsPipe,
  FormArrayControlsPipe,
  ReversePipe,
  ArrayCreatorPipe,
  RatingDescriptionPipe,
  ConvertMinutesToTimePipe,
];
