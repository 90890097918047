<app-modal #modalReloadPage class="decline-modal" [showCloseIcon]="true">

  <ng-container header>
    <h2 class="modal-title">{{ message }}</h2>
  </ng-container>
  <ng-container body>
    <div class="form__item form__item--center">
      <div class="form-button form-button--success">
        <app-button
          data-testid="stop-stream-modal-btn"
          [text]="'reload'"
          (clicked)="reloadPage()">
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
