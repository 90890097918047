import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['../terms-shared.component.scss'],
})
export class TermsComponent {
  @ViewChild('disputesSection') disputesSection: ElementRef;
  @ViewChild('supplementalSection') supplementalSection: ElementRef;

  scrollToDisputesSection(): void {
    this.disputesSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollToSupplementalSection(): void {
    this.supplementalSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
