<div class="access-indicator" data-testid="wrapper">
  <img
    class="access-indicator__img"
    height="16"
    width="16"
    src="assets/ui/images/svg/lock.svg"
    alt="access-indicator"
  />
  <span class="access-indicator__text" data-testid="text">{{ indicatorText }}</span>
</div>
