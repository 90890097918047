import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-co-host-invite-was-send-modal',
  templateUrl: './co-host-invite-was-send-modal.component.html',
  styleUrls: ['./co-host-invite-was-send-modal.component.scss'],
})
export class CoHostInviteWasSendModalComponent implements OnInit, OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() isOpen = false;

  @Output() modalOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.modal.open();
    } else {
      this.modal?.close();
    }
  }

  closeModal(): void {
    this.modalOpenChanged.emit(false);
  }
}
