import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import {
  AlertComponent,
  AlertData,
} from '@kitch/ui/components/alert/alert.component';

interface AlertConfig {
  data?: AlertData;
  duration?: number;
  horizontalPosition?: MatSnackBarHorizontalPosition;
  panelClass?: string | string[];
  verticalPosition?: MatSnackBarVerticalPosition;
}

@Injectable()
export class AlertService {
  private readonly defaultConfig: AlertConfig = {
    data: { message: 'Something went wrong!' },
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  };

  constructor(private snackBar: MatSnackBar) {}

  error(message: string, config?: AlertConfig): void {
    const conf = {
      ...config,
      panelClass: ['alert', 'alert--error'],
    };

    this.show(message, conf);
  }

  success(message: string, config?: AlertConfig): void {
    const conf = {
      ...config,
      panelClass: ['alert', 'alert--success'],
    };

    this.show(message, conf);
  }

  private show(message: string, config?: AlertConfig) {
    this.snackBar.openFromComponent(AlertComponent, {
      ...this.defaultConfig,
      data: { message },
      ...config,
    });
  }
}
