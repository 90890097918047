export const ingredientUnits = [
  'pounds',
  'grams',
  'tsps',
  'tbsps',
  'pinch',
  'slice',
  'cans',
  'fl. oz.',
  'milliliters',
  'liters',
  'ounce',
  'cubic foot',
  'cups',
  'pints',
  'quarts',
  'drizzle',
  'bunch',
  'large',
  'medium',
  'small',
  'clove',
  'head',
  'dash',
  'packet',
  'sprig',
  'ear',
  'stick',
  'loaf',
] as const;

export const walmartErrorPhrase =
  'ERROR: Your recipe did not register with Walmart.' +
  ' Please review the ingredients section,' +
  ' make sure everything is spelled correctly, turn the toggle on and resubmit.';

export const MAX_RECIPES_ATTACHED_TO_STREAM = 3;
