<article class="cdk-modal preparation-steps-modal">
  <header class="cdk-modal__header">
    <h2 class="header-text">Add preparation steps</h2>
  </header>

  <main class="cdk-modal__body">
    <form class="preparation-steps-modal__form" [formGroup]="form">
      <span>Type or copy/paste preparation steps from any word doc, spreadsheet, PDF or website.</span>
      <app-wysiwyg-editor
        [extraToolBarElements]="['link']"
        formControlName="steps"
        placeholder="Pre-heat the oven to 375C degrees
Line a baking sheet with parchment paper."
      ></app-wysiwyg-editor>
      <span>To add headers use a colon  :  for example To Garnish:</span>
    </form>

    <div class="preparation-steps-modal__buttons">
      <app-button
        text="Cancel"
        role="secondary-white--black-text"
        [type]="'button'"
        (clicked)="close()"
      ></app-button>

      <app-button
        [isDisabled]="!form.valid"
        class="preparation-steps-modal__add-btn"
        text="ADD STEPS"
        role="secondary"
        [type]="'button'"
        (clicked)="addSteps()"
      ></app-button>
    </div>
  </main>
</article>
